import { post, API_URL, get } from "../../services/api";
import { Grid, Button, Box, Autocomplete, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CenteredProgress from "../../scenes/global/CenteredProgress";

const RemoveRole = ({
  setResponseMessage,
  setIsSuccess,
  selectedUser,
  setRemoveRoleMode,
  onUserUpdateSuccess,
}) => {
  const [role, setRole] = useState({
    label: "TechSupport",
    value: "TechSupport",
  });
  const [roles, setRoles] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const primaryMainColor = theme.palette.background.default;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserRoles = async () => {
      let localRoles = localStorage.getItem("roles");

      if (localRoles && localRoles !== "undefined") {
        // Parse the stored string back into an array and update the state
        const parsedRoles = JSON.parse(localRoles);
        setRoles(parsedRoles.map((rl) => ({ label: rl, value: rl })));
      } else {
        try {
          const response = await get(
            `${API_URL}/v2/FastballUser/GetRoles`,
            true
          );
          if (response && Array.isArray(response.data)) {
            // Save the data to local storage in stringified form
            localStorage.setItem("roles", JSON.stringify(response.data));

            // Set the timezones state
            setRoles(response.data.map((rl) => ({ label: rl, value: rl })));
          }
        } catch (error) {
          console.error("Error fetching roles:", error);
        }
      }
    };

    fetchUserRoles();
  }, []);

  const handleRoleChange = (newValue) => {
    setRole(newValue);
  };

  const handleRemoveRole = async () => {
    if (!selectedUser) {
      setResponseMessage("Invalid User");
      setIsSuccess(false);
      return;
    }
    setIsLoading(true);
    try {
      const response = await post(
        `${API_URL}/v2/FastballUser/RemoveRole?email=${selectedUser.userName}&roleName=${role.value}`,
        null,
        true
      );
      if (response.statusCode === "OK") {
        if (response.data) {
          setResponseMessage(response.messages[0]);
          setIsSuccess(true);
          setRemoveRoleMode(false);
          onUserUpdateSuccess(true);
        } else {
          setIsSuccess(false);
          setResponseMessage(response?.messages[0]);
        }
      } else if (response.statusCode === "BadRequest") {
        setIsSuccess(false);
        setResponseMessage(
          response.errors && response.errors.length > 0
            ? response.errors[0]
            : "Error removing role to user."
        );
      } else {
        setIsSuccess(false);
        setResponseMessage("Error removing role to user.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        // If it's a 400 error and has error messages, display the first error message
        setIsSuccess(false);
        setResponseMessage(error.response.data.errors[0]);
      } else {
        // For other errors
        setIsSuccess(false);
        setResponseMessage("Error removing role to user.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          options={roles}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={role}
          onChange={(event, newValue) => handleRoleChange(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Roles"
              variant="outlined"
              fullWidth
            />
          )}
          ListboxProps={{
            sx: {
              bgcolor: primaryMainColor,
            },
          }}
        />
      </Grid>
      {isLoading && <CenteredProgress />}
      <Box display="flex" justifyContent="center" width="100%" gap={2} mt={2}>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginRight: "8px",
          }}
          disabled={isLoading}
          onClick={handleRemoveRole}
        >
          <RemoveCircleOutlineIcon sx={{ mr: "10px" }} />
          Remove Role
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setRemoveRoleMode(false)}
        >
          Cancel
        </Button>
      </Box>
    </Grid>
  );
};

export default RemoveRole;
