import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { useFetchAppSettings } from "../../components/invoice/useFetchInvoiceInfo";
import ActionToolbar from "../../components/common/ActionToolbar";
import DynamicEdit from "../../components/common/DynamicEdit";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import Header from "../../components/Header";

const AppSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [status, setStatus] = useState({
    edit: { disabled: false, tooltip: "Update app settings" },
  });
  const [isUpdated, setIsUpdated] = useState(false);

  const {
    data: appData,
    isLoading,
    isError,
    Error,
  } = useFetchAppSettings(isUpdated);
  const handleBack = () => {
    navigate(-1);
  };
  const DataCard = ({ data, title }) => {
    return (
      <Card
        variant="outlined"
        sx={{ mb: 2, backgroundColor: colors.primary[400] }}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          {Object.entries(data).map(([key, value]) => {
            // Check if the value is an object itself, in which case, render another DataCard
            if (typeof value === "object" && value !== null) {
              return <DataCard key={key} data={value} title={key} />;
            }
            return (
              <Typography key={key} variant="body1">
                {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
              </Typography>
            );
          })}
        </CardContent>
      </Card>
    );
  };
  const handleEdit = () => {
    setEditMode(true);
  };

  useEffect(() => {
    // Additionally, handle errors from React Query
    if (isError && Error) {
      // Display a more specific error message if available, or a generic message
      toast.error(Error.message || "An error occurred while fetching data.");
    }
  }, [isError, Error]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Application Settings" subtitle="OmnyManage Application Settings" />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
            padding: "15px",
          }}
        >
          {appData && !editMode && (
            <>
              <ActionToolbar status={status} onEdit={handleEdit} />
              <DataCard data={appData} title="App Data" />
            </>
          )}
          {appData && editMode && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header title="Edit" subtitle="Application Settings" />
              </Box>
              <DynamicEdit
                data={appData}
                setEditMode={setEditMode}
                setIsUpdated={setIsUpdated}
              />
            </>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default AppSettings;
