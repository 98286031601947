import { Grid, TextField, Typography, InputAdornment } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { validateTenantName } from "./utilities";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import CategoryIcon from "@mui/icons-material/CategoryOutlined";
import Inventory2Icon from "@mui/icons-material/Inventory2Outlined";
import ClassIcon from "@mui/icons-material/ClassOutlined";
import CodeIcon from "@mui/icons-material/CodeOutlined";
import RTE from "./RTE";
import SkuAndDurationForm from "./components/Duration";
import CountrySelectForm from "./components/Country";
import PriceField from "./components/Price";
import { countries } from "../common/constants";
import { useState, useEffect } from "react";

const isEmptyEditorContent = (content) => {
  // Adjust this function according to what you consider "empty" for your editor
  const doc = new DOMParser().parseFromString(content, "text/html");
  return !doc.body.textContent.trim() && !doc.querySelector("img");
};

const CategoryForm = ({
  component,
  setIsContentSaved,
  selectedItem,
  isEdit = false,
}) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const content = isEdit ? selectedItem.description : "";
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [maxContentCount, setMaxContentCount] = useState(1000);
  const shouldValidate = !(component === "SKU" && isEdit);

  useEffect(() => {
    if (component === "ProductCore") {
      setMaxContentCount(3000);
    }
  }, [component]);

  const handleCountryChange = (event) => {
    const selectedCountry = countries.find(
      (country) => country.country_id === event.target.value
    );
    setCurrencySymbol(selectedCountry ? selectedCountry.currency_symbol : "");
  };

  let icon;
  if (component === "ProductCore") {
    icon = <Inventory2Icon sx={{ color: colors.greenAccent[700] }} />;
  } else if (component === "SubCategory") {
    icon = <ClassIcon sx={{ color: colors.pastelColor.brown }} />;
  } else {
    icon = <CategoryIcon sx={{ color: colors.blueAccent[600] }} />;
  }

  const validationRules = shouldValidate ? {
    required: `${component} Code is required`,
    minLength: {
      value: 2,
      message: `${component} Code must be at least 2 characters`,
    },
    maxLength: {
      value: 3,
      message: `${component} Code must be no more than 3 characters`,
    },
    pattern: {
      value: /^[A-Z]{2,3}$/,
      message: `${component} Code must be 2-3 capital letters`,
    },
  } : {};

  return (
    <Grid container spacing={2}>
      {component !== "SKU" && (
        <Grid item xs={12}>
          <TextField
            {...register("name", {
              required: `${component} name is required`,
              validate: validateTenantName,
            })}
            label={`${component} Name`}
            required
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 30 }}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          {...register("code", validationRules)}
          label={`${component} Code`}
          fullWidth
          required={component !== "SKU"}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: isEdit,
            startAdornment: (
              <InputAdornment position="start">
                <CodeIcon style={{ color: colors.other.blue }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          error={!!errors.code}
          helperText={errors.code?.message}
          inputProps={{ maxLength: 3 }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h7"
          gutterBottom
          style={{ color: colors.greenAccent[400] }}
        >
          {component} Description *
        </Typography>
        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{
            validate: (value) => {
              // Check if the editor content is empty
              const errorMessage =
                "Description must be saved first & cannot be empty";
              if (isEmptyEditorContent(value)) {
                return errorMessage;
              }

              // Check if the content exceeds the maximum character count
              const contentCount = value.length;
              if (contentCount > maxContentCount) {
                return `The content must not exceed ${maxContentCount} characters (current: ${contentCount})`;
              }

              return true;
            },
          }}
          render={({ field }) => (
            <RTE
              name="description"
              setIsContentSaved={setIsContentSaved}
              content={content}
            />
          )}
        />
        {errors.description && (
          <Typography color="error">{errors.description.message}</Typography>
        )}
      </Grid>
      {component == "SKU" && (
        <>
          {!isEdit && (
            <>
              <SkuAndDurationForm />
              <CountrySelectForm handleCountryChange={handleCountryChange} />
            </>
          )}
          <PriceField currencySymbol={currencySymbol} />
        </>
      )}
    </Grid>
  );
};

export default CategoryForm;
