import React, { useState, useEffect } from "react";
import { Box, Container, IconButton } from "@mui/material";
import AddTenantAdmin from "../../components/user/addTenantAdmin";
import { useTimedMessage } from "../../components/forms/utilities";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const AddUserForm = () => {
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [isSuccess, setIsSuccess] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }
  }, [responseMessage, isSuccess]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Tenant Admin" subtitle="Add new tenant admin." />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="5px"
        >
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
        </Box>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
            padding: "15px",
          }}
        >
          <AddTenantAdmin
            setResponseMessage={setResponseMessage}
            setIsSuccess={setIsSuccess}
          />
        </Container>
      </Box>
    </Box>
  );
};
export default AddUserForm;
