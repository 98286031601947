import { useQuery } from "@tanstack/react-query";
import { get, API_URL } from "../../services/api";
import { useState, useEffect } from "react";

const version = "v2";

export const useFetchList = (
  component,
  page,
  PerPage,
  filtersArray = [],
  sortModel = [],
  shouldFetch = false,
  extended = null
) => {
  // Create a query key that includes all relevant parameters
  const queryKey = [`${component}List`, page, PerPage, filtersArray, sortModel];

  // Define the query function
  const queryFn = async () => {
    const queryStringParts = [
      `page=${encodeURIComponent(page)}`,
      `PerPage=${encodeURIComponent(PerPage)}`,
    ];

    // Handle filters, support both array and single value filters
    filtersArray.forEach((filter) => {
      if (Array.isArray(filter.value)) {
        // If the value is an array, create multiple query params for the same filter type
        filter.value.forEach((value) => {
          queryStringParts.push(
            `${encodeURIComponent(filter.type)}=${encodeURIComponent(value)}`
          );
        });
      } else if (filter.value) {
        // For single values, just append them
        queryStringParts.push(
          `${encodeURIComponent(filter.type)}=${encodeURIComponent(
            filter.value
          )}`
        );
      }
    });

    // Handle sorting
    if (sortModel.length > 0 && sortModel[0].field) {
      const sortField = sortModel[0].field;
      const sortDirection = sortModel[0].sort || "asc";
      queryStringParts.push(
        `sort=${encodeURIComponent(sortField)}:${encodeURIComponent(
          sortDirection
        )}`
      );
    }

    const queryString = queryStringParts.join("&");

    let url = `${API_URL}/${version}/${component}/list?${queryString}`;
    if (component === "FirmwareList") {
      url = `${API_URL}/${version}/${component}?${queryString}`;
    } else if (component === "Report") {
      url = `${API_URL}/${version}/${component}/list?reportType=${extended}&${queryString}`;
    } else if (component === "TenantPatient" || component === "TenantDevice") {
      url = `${API_URL}/${version}/${component}/list?tenantCode=${extended}&${queryString}`;
    } else if (component === "PatientAnonymous") {
      url = `${API_URL}/${version}/Patient/anonymouslist?${queryString}`;
    } else if (component === "ActiveUsers") {
      url = `${API_URL}/${version}/Report/GetActiveUsersSummary?${queryString}`;
    } else if (component === "UserRolePermission") {
      url = `${API_URL}/${version}/SubCategory/listRolePermission?${queryString}`;
    }
    const response = await get(url, true);
    if (response.statusCode !== "OK") {
      throw new Error(`Error fetching ${component} list`);
    }
    return response.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    enabled: shouldFetch,
    // Add any additional options you need, like staleTime or cacheTime
  });
};

export const useFetchAutoComplete = ({
  component,
  searchTerm = "",
  tenantCode = null,
  tenantId = null,
  patientId = null,
  userId = null,
  categoryId = null,
  subCategoryId = null,
}) => {
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchSuggestedItems = async ({ queryKey }) => {
    const [_key, term] = queryKey;
    if (!term.trim()) return []; // Avoid fetching with empty or whitespace-only query
    const encodedTerm = encodeURIComponent(term);
    let url = `${API_URL}/${version}/${component}/AutoComplete?contains=${encodedTerm}&limit=10`;
    if (tenantCode) {
      url += `&tenantCode=${tenantCode}`;
    } else if (tenantId) {
      url += `&tenantID=${tenantId}`;
    }
    if (userId) {
      url += `&userID=${userId}`;
    }
    if (categoryId) {
      url += `&categoryID=${categoryId}`;
    }
    if (subCategoryId) {
      url += `&subCategoryId=${subCategoryId}`;
    }
    if (patientId) {
      url += `&patientID=${patientId}`;
    }
    try {
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data || [];
      } else {
        throw new Error(
          response.errors?.[0] || `Error fetching suggested ${component}`
        );
      }
    } catch (error) {
      // Log the error or handle it as needed
      throw error; // Rethrowing the error so React Query can catch it
    }
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [`${component}ac`, debouncedTerm],
    queryFn: fetchSuggestedItems,
    enabled: !!debouncedTerm,
  });

  return { data, isLoading, isError, error };
};

export const useFetchAutoCompleteFull = ({ component, searchTerm = "" }) => {
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchSuggestedItems = async ({ queryKey }) => {
    const [_key, term] = queryKey;
    if (!term.trim()) return []; // Avoid fetching with empty or whitespace-only query
    const encodedTerm = encodeURIComponent(term);
    let url = `${API_URL}/${version}/${component}/list?Page=1&PerPage=20&filter=${encodedTerm}`;
    try {
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data.items || [];
      } else {
        throw new Error(
          response.errors?.[0] || `Error fetching suggested ${component}`
        );
      }
    } catch (error) {
      // Log the error or handle it as needed
      throw error; // Rethrowing the error so React Query can catch it
    }
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [`${component}acFull`, debouncedTerm],
    queryFn: fetchSuggestedItems,
    enabled: !!debouncedTerm,
  });

  return { data, isLoading, isError, error };
};
