import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ReadOnlyContent from "../../components/common/HtmlDisplay";
import TreeView from "../../components/product/itemTree";
import { StartOutlined, ExitToAppOutlined } from "@mui/icons-material";
import { Typography, Grid, Box, Tooltip } from "@mui/material";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import DetailsFooter, {
  formatEndDate,
} from "../../components/common/DetailsFooter";

const ItemView = ({
  component,
  subComponent,
  selectedItem,
  dependentData,
  colors,
}) => {
  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom={2}
          >
            <Typography variant="h2" gutterBottom>
              {selectedItem.name}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: colors.greenAccent[400], // Using a theme color
              }}
            >
              Code: {selectedItem.code}
            </Typography>
          </Box>
          {component === "SubCategory" && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginBottom={2}
            >
              <Typography variant="h3" gutterBottom>
                Category: {selectedItem.category}
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: colors.greenAccent[400], // Using a theme color
                }}
              >
                Category Code: {selectedItem.categoryCode}
              </Typography>
            </Box>
          )}
          <Box
            p={2}
            borderRadius={2}
            sx={{
              border: "1px solid #1976d2",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ReadOnlyContent content={selectedItem.description} />
          </Box>
        </Grid>
      </Grid>
      {component === "ProductCore" && (
        <Box
          p={2}
          mb={2}
          mt={2}
          borderRadius={2}
          style={{ border: "1px solid #1976d2" }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={5}>
              <List>
                <ListItem>
                  <Tooltip title={"Demo"}>
                    <ListItemIcon>
                      {selectedItem.isDemo ? (
                        <CheckCircleOutline
                          fontSize="large"
                          style={{ color: colors.pastelColor.green }}
                        />
                      ) : (
                        <HighlightOff
                          fontSize="large"
                          style={{ color: colors.pastelColor.red }}
                        />
                      )}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={<Typography variant="h5">Demo</Typography>}
                  />
                </ListItem>
                <ListItem>
                  <Tooltip title={"Is Active"}>
                    <ListItemIcon>
                      {selectedItem.isActive ? (
                        <CheckCircleOutline
                          fontSize="large"
                          style={{ color: colors.pastelColor.green }}
                        />
                      ) : (
                        <HighlightOff
                          fontSize="large"
                          style={{ color: colors.pastelColor.red }}
                        />
                      )}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={<Typography variant="h5">Is Active</Typography>}
                  />
                </ListItem>

                <ListItem>
                  <Tooltip title={"Published"}>
                    <ListItemIcon>
                      {selectedItem.published ? (
                        <CheckCircleOutline
                          fontSize="large"
                          style={{ color: colors.pastelColor.green }}
                        />
                      ) : (
                        <HighlightOff
                          fontSize="large"
                          style={{ color: colors.pastelColor.red }}
                        />
                      )}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={<Typography variant="h5">Published</Typography>}
                  />
                </ListItem>
                <ListItem>
                  <Tooltip title={"Start Date"}>
                    <ListItemIcon>
                      <StartOutlined
                        fontSize="large"
                        style={{
                          color: colors.pastelColor.green,
                        }}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {formatEndDate(selectedItem.startDate, "No start date")}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <Tooltip title={"End Date"}>
                    <ListItemIcon>
                      <ExitToAppOutlined
                        fontSize="large"
                        style={{
                          color: colors.pastelColor.red,
                        }}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {formatEndDate(selectedItem.endDate, "No end date")}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundcolor: colors.greenAccent[400] }}
            />
            <Grid item xs={5}></Grid>
          </Grid>
        </Box>
      )}
      {component && subComponent && dependentData && (
        <TreeView items={dependentData} subComponent={subComponent} />
      )}
      <DetailsFooter
        createdOn={selectedItem.createdOn}
        lastActionOn={selectedItem.lastActionOn}
        lastActionBy={selectedItem.lastActionBy}
        customColor={"teal"}
        dividerColor={"grey.300"}
      />
    </>
  );
};

export default ItemView;
