import React from "react";
import CustomDataGrid, { HighlightOnHoverCell } from "../common/customDataGrid";
import { getGridStringOperators } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@mui/material";
import { teal } from "@mui/material/colors";
import { formatDateTime, formatDate } from "../forms/utilities";

const ReportTable = ({
  isLoading,
  itemRequests,
  selectionModel,
  setSelectionModel,
  paginationModel,
  onPaginationModelChange,
  rowCount,
  onFilterModelChange,
  filterModel,
  onSortModelChange,
  sortModel,
  handleCellClick,
  handleOnView,
  columnVisibilityModel,
  handleColumnVisibilityChange,
  headers,
}) => {
  const rows = itemRequests.map((item) => {
    return {
      ...item,
    };
  });
  const hasCustomReportType = rows.some((row) => row.reportType === "custom");
  const hasDailyReportType = rows.some((row) => row.reportType === "daily");
  const stringOperators = getGridStringOperators().filter((op) =>
    ["equals", "contains"].includes(op.value)
  );
  const baseColumns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="View Report" placement="top" arrow>
              <VisibilityIcon
                sx={{
                  color: teal[400],
                }}
              />
            </Tooltip>
          }
          label="View"
          onClick={() => handleOnView(params.row)}
        />,
      ],
    },
    {
      field: "patientName",
      headerName: "Name",
      cellClassName: "fn-column--cell",
      renderCell: (params) => (
        <HighlightOnHoverCell value={params.value} customCN="fn-column--cell" />
      ),
      width: 150,
      filterOperators: stringOperators,
    },
    {
      field: "patientEmail",
      headerName: "Email",
      cellClassName: "email-column--cell",
      renderCell: (params) => (
        <HighlightOnHoverCell
          value={params.value}
          customCN="email-column--cell"
        />
      ),
      width: 200,
      filterOperators: stringOperators,
    },
    {
      field: "tenantName",
      headerName: "Hospital",
      width: 150,
      cellClassName: "hospital-column--cell",
      renderCell: (params) => (
        <HighlightOnHoverCell
          value={params.value}
          customCN="hospital-column--cell"
        />
      ),
      filterable: false,
    },
    {
      field: "tenantCode",
      headerName: "Hospital Code",
      width: 130,
      filterable: false,
    },
    {
      field: "reportType",
      headerName: "Report Type",
      width: 100,
      filterable: false,
    },
    {
      field: "timezone",
      headerName: "Timezone",
      width: 150,
      filterable: false,
    },
  ];

  const dailyReportColumns = [
    {
      field: "reportDate",
      headerName: "Report Date",
      width: 180,
      renderCell: (params) => formatDate(params.value),
    },
  ];

  const customReportColumns = [
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      renderCell: (params) => formatDateTime(params.value),
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 180,
      renderCell: (params) => formatDateTime(params.value),
    },
  ];

  const hiddenColumns = [];
  const initialVisibilityModel = hiddenColumns.reduce((model, columnName) => {
    model[columnName] = false;
    return model;
  }, {});
  let dynamicColumns = baseColumns;
  if (hasDailyReportType) {
    dynamicColumns = [...dynamicColumns, ...dailyReportColumns];
  }
  if (hasCustomReportType) {
    dynamicColumns = [...dynamicColumns, ...customReportColumns];
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={dynamicColumns}
      isLoading={isLoading}
      selectionModel={selectionModel}
      setSelectionModel={setSelectionModel}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      columnVisibilityModel={columnVisibilityModel}
      handleColumnVisibilityChange={handleColumnVisibilityChange}
      rowCount={rowCount}
      onFilterModelChange={onFilterModelChange}
      filterModel={filterModel}
      onSortModelChange={onSortModelChange}
      sortModel={sortModel}
      handleCellClick={handleCellClick}
      initialVisibilityModel={initialVisibilityModel}
      headers={headers}
    />
  );
};

export default ReportTable;
