import React, { useState } from "react";
import { useFetchTenantInfo } from "../tenant/useFetchTenantInfo";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CenteredProgress from "../../scenes/global/CenteredProgress";

const DeleteTenant = ({
  selectedTenant,
  setSelectedTenant,
  setResponseMessage,
  setIsSuccess,
  openDeleteDialog,
  setOpenDeleteDialog,
  setIsLoading,
  onTenantUpdated,
  setIsTenantDeleted,
}) => {
  const { deleteTenant } = useFetchTenantInfo(
    setResponseMessage,
    setIsSuccess,
    setIsLoading
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLocalLoading] = useState(false);

  const handleTenantDelete = async () => {
    setIsLocalLoading(true);
    const success = await deleteTenant(selectedTenant.uid);
    if (success) {
      if (typeof onTenantUpdated === "function") {
        onTenantUpdated(null);
      }
      setIsTenantDeleted(true);
    } else {
      setResponseMessage("Could not delete tenant.");
    }
    setIsLocalLoading(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      {/* The Dialog JSX */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: colors.primary[400],
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: colors.greenAccent[400], typography: "h5" }}
        >
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Tenant? This action cannot be
            undone.
          </DialogContentText>
          {isLoading && <CenteredProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            onClick={handleTenantDelete}
            disabled={isLoading}
          >
            <DeleteOutlineIcon
              sx={{ mr: "10px", color: colors.pastelColor.red }}
            />
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteTenant;
