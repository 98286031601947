import { useState, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { get, deleteRequest, API_URL } from "../../services/api";
import { v4 as uuidv4 } from "uuid";

const API_BASE_URL = `${API_URL}/v2/Tenant`;

export const useFetchTenantDetails = (uid, code, id, initialData) => {
  return useQuery({
    queryKey: ["tenantDetails", uid, code, id],
    queryFn: async () => {
      let response;
      if (uid) {
        // If uid is provided, fetch details by uid
        response = await get(`${API_BASE_URL}/GetByUid?id=${uid}`, true);
      } else if (id) {
        // If id is provided, fetch details by id
        response = await get(`${API_BASE_URL}/GetById?id=${id}`, true);
      } else if (code) {
        // If code is provided, fetch details by code
        response = await get(`${API_BASE_URL}/GetByCode?code=${code}`, true);
      } else {
        return; // Return early if neither uid nor code is provided
      }

      // Handle the response
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching tenant details"
        );
      }
    },
    enabled: !!(uid || code || id) && !initialData, // Only run the query if either uid or code is truthy
    initialData: initialData,
  });
};

export const useFetchTenantStats = (code) => {
  return useQuery({
    queryKey: ["tenantStats", code],
    queryFn: async () => {
      let response;
      if (code) {
        // If uid is provided, fetch details by uid
        response = await get(
          `${API_BASE_URL}/GetTenantStats?code=${code}`,
          true
        );
      } else {
        return; // Return early if neither uid nor code is provided
      }
      // Handle the response
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching tenant stat details"
        );
      }
    },
    enabled: !!code,
  });
};

export const useFetchTenantInfo = (
  setResponseMessage,
  setIsSuccess,
  setIsLoading
) => {
  const [selectedTenant, setSelectedTenant] = useState(null);

  const handleResponse = useCallback(
    (response, successMessage) => {
      if (response.statusCode === "OK") {
        setIsSuccess(true);
        setResponseMessage(successMessage);
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "An error occurred while fetching data."
        );
      }
    },
    [setIsSuccess, setResponseMessage]
  );

  const handleError = useCallback(
    (error) => {
      setIsSuccess(false);
      setResponseMessage(
        error?.response?.data?.errors?.[0] ||
          error.message ||
          "Error occurred during the API call."
      );
    },
    [setIsSuccess, setResponseMessage]
  );

  const deleteTenant = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        const requestId = uuidv4();
        const response = await deleteRequest(
          `${API_BASE_URL}/Delete`,
          { requestId, data: { id } },
          true
        );
        return handleResponse(response, "Tenant deleted successfully.");
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleResponse, handleError, setIsLoading]
  );

  return {
    selectedTenant,
    setSelectedTenant,
    deleteTenant,
  };
};
