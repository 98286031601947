import React, { useState } from "react";
import {
  Grid,
  FormControlLabel,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { MuiPhone } from "../forms/MuiPhone";
import PasswordInput from "./PasswordInput";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  capitalizeWords,
  validateName,
  validateAndNormalizeEmail,
  isPhoneValid,
} from "./utilities";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";

const UserForm = ({ isEdit = false, selectedUser = null }) => {
  const {
    watch,
    register,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext();
  const emailValue = watch("email");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hasInteracted, setHasInteracted] = useState(false);

  const handlePhoneChange = (value, onChange) => {
    onChange(value); // Use the passed onChange directly
    if (!hasInteracted) {
      setHasInteracted(true);
      return;
    }
    const isValid = isPhoneValid(value);
    if (!isValid) {
      setError("mobileNumber", {
        type: "manual",
        message: "Invalid mobile number",
      });
    } else {
      clearErrors("mobileNumber");
    }
  };

  return (
    <Grid container spacing={2}>
      {/* Name */}
      <Grid item xs={6}>
        <TextField
          {...register("firstName", {
            required: "First name is required",
            validate: validateName,
          })}
          label="First Name"
          fullWidth
          required
          variant="outlined"
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon style={{ color: colors.other.blue }} />
              </InputAdornment>
            ),
          }}
          onBlur={(e) => {
            const capitalizedValue = capitalizeWords(e.target.value);
            setValue("firstName", capitalizedValue, { shouldValidate: true });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...register("lastName", {
            required: "Last name is required",
            validate: validateName,
            setValueAs: (value) => capitalizeWords(value),
          })}
          label="Last Name"
          fullWidth
          required
          variant="outlined"
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          onBlur={(e) => {
            const capitalizedValue = capitalizeWords(e.target.value);
            setValue("lastName", capitalizedValue, { shouldValidate: true });
          }}
        />
      </Grid>
      {/* Contact */}
      <Grid item xs={12}>
        <TextField
          {...register("email", {
            required: "Email is required",
            validate: {
              validEmail: (value) => {
                const { isValid, errorMessage } =
                  validateAndNormalizeEmail(value);
                return isValid || errorMessage;
              },
            },
          })}
          label="Email"
          type="email"
          fullWidth
          required
          variant="outlined"
          disabled={isEdit && !errors.email}
          error={!!errors.email}
          helperText={errors.email?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon style={{ color: colors.other.blue }} />
              </InputAdornment>
            ),
            endAdornment: isEdit && selectedUser.isEmailVerified && (
              <InputAdornment position="end">
                <VerifiedIcon sx={{ color: "teal" }} />
              </InputAdornment>
            ),
          }}
          onBlur={(e) => {
            const { isValid, normalizedEmail } = validateAndNormalizeEmail(
              e.target.value
            );
            if (isValid) {
              setValue("email", normalizedEmail, { shouldValidate: true });
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="mobileNumber"
          rules={{
            required: "Mobile number is required",
            validate: (value) => isPhoneValid(value) || "Invalid mobile number",
          }}
          defaultValue={isEdit && selectedUser ? selectedUser.mobileNumber : ""}
          render={({ field, fieldState: { error } }) => (
            <MuiPhone
              {...field}
              label="Mobile Number"
              onChange={(e) =>
                handlePhoneChange(e.target.value, field.onChange)
              }
              error={!!error}
              helperText={error ? error.message : null}
              required
              disabled={isEdit && selectedUser.isPhoneVerified}
              InputProps={{
                endAdornment:
                  isEdit && selectedUser.isPhoneVerified ? (
                    <InputAdornment position="end">
                      <VerifiedIcon sx={{ color: "teal" }} />
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...register("userName")}
          label="Username"
          fullWidth
          variant="outlined"
          error={!!errors.userName}
          helperText={errors.userName?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon style={{ color: colors.other.blue }} />
              </InputAdornment>
            ),
          }}
          readOnly={!isEdit}
          disabled={isEdit}
          value={!isEdit ? emailValue || "" : selectedUser.userName}
          autoComplete="off" // Disabling autoComplete
          name={`user_name_${Math.random()}`} // Using a non-standard name
        />
      </Grid>
      {[{ label: "Account Enabled", name: "enabled" }].map((item, index) => (
        <Grid item xs={3} key={index}>
          <FormControlLabel
            control={
              <Controller
                name={item.name}
                control={control}
                defaultValue={isEdit ? selectedUser?.[item.name] ?? true : true}
                render={({ field }) => (
                  <Checkbox {...field} checked={field.value} />
                )}
              />
            }
            label={item.label}
          />
        </Grid>
      ))}
      {!isEdit && (
        <Grid item xs={12}>
          <PasswordInput />
        </Grid>
      )}
    </Grid>
  );
};

export default UserForm;
