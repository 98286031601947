import React from "react";
import { Typography, Grid, Box, IconButton, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CodeIcon from "@mui/icons-material/Code";
import AccessibleIcon from "@mui/icons-material/Accessible";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import List from "@mui/material/List";
import { ManageHistory } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import DetailsFooter, {
  formatEndDate,
} from "../../components/common/DetailsFooter";

const DeviceDetails = ({ data, handleIconClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) return null;

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom={2}
        >
          <MonitorHeartIcon
            style={{ color: colors.other.blue, fontSize: "4.5rem" }}
          />
          <Typography variant="h4" gutterBottom>
            {data.serialNo}
          </Typography>
        </Box>
        <Divider
          style={{
            backgroundColor: colors.pastelColor.blue,
            marginBottom: "16px",
          }}
        />
      </Grid>
      {/* Section 1: Basic Information */}
      <Grid item xs={12}>
        <Box
          p={2}
          mb={2}
          borderRadius={2}
          style={{ border: `2px solid ${colors.pastelColor.blue}` }}
        >
          <List>
            <ListItem>
              <Tooltip title={"Device ID"}>
                <ListItemIcon>
                  <SettingsEthernetIcon
                    fontSize="large"
                    style={{ color: colors.other.blue }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{data.deviceId}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Tenant Name"}>
                <ListItemIcon>
                  <IconButton
                    onClick={() =>
                      data.tenantCode &&
                      handleIconClick({
                        field: "Tenant",
                        value: data.tenantCode,
                      })
                    }
                    disabled={!data.tenantCode}
                    style={{
                      padding: 0, // Remove padding
                    }}
                  >
                    <HealthAndSafetyIcon
                      fontSize="large"
                      style={{ color: colors.other.coral }}
                    />
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{data.tenant}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Device Size"}>
                <ListItemIcon>
                  <AspectRatioIcon
                    fontSize="large"
                    style={{ color: colors.other.blue }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{data.size}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Device Status"}>
                <ListItemIcon>
                  <VolunteerActivismIcon
                    fontSize="large"
                    style={{ color: colors.pastelColor.green }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{data.status}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Patient Name"}>
                <ListItemIcon>
                  <IconButton
                    onClick={() =>
                      data.patientUid &&
                      handleIconClick({
                        field: "Patient",
                        value: data.patientUid,
                      })
                    }
                    disabled={!data.patientUid}
                    style={{
                      padding: 0,
                    }}
                  >
                    <AccessibleIcon
                      fontSize="large"
                      style={{ color: colors.pastelColor.green }}
                    />
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h6">{data.patientName}</Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Grid>

      {/* Section 2: Address */}
      <Grid item xs={12}>
        <Box
          p={2}
          mb={2}
          borderRadius={2}
          style={{ border: `2px solid ${colors.pastelColor.blue}` }}
        >
          <List>
            <ListItem>
              <Tooltip title={"Device id"}>
                <ListItemIcon>
                  <Grid3x3Icon
                    fontSize="medium"
                    style={{ color: colors.other.blue }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h7">{data.id}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Tenant Code"}>
                <ListItemIcon>
                  <IconButton
                    onClick={() =>
                      data.tenantCode &&
                      handleIconClick({
                        field: "Tenant",
                        value: data.tenantCode,
                      })
                    }
                    disabled={!data.tenantCode}
                    style={{
                      padding: 0, // Remove padding
                    }}
                  >
                    <CodeIcon
                      fontSize="medium"
                      style={{ color: colors.other.coral }}
                    />
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h7">{data.tenantCode}</Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Current Tenant ID"}>
                <ListItemIcon>
                  <Grid3x3Icon
                    fontSize="medium"
                    style={{ color: colors.other.coral }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h7">{data.currentTenantId}</Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Last Assigned on"}>
                <ListItemIcon>
                  <ManageHistory
                    fontSize="medium"
                    style={{ color: colors.other.blue }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h5">
                    {formatEndDate(data.lastAssignedOn, "Not Assigned yet")}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Grid>

      {/* Section 3: Additional Information */}
      <Grid item xs={12}>
        <Box
          p={2}
          borderRadius={2}
          style={{ border: `2px solid ${colors.pastelColor.blue}` }}
        >
          <List>
            <ListItem>
              <Tooltip title={"Imported"}>
                <ListItemIcon>
                  <ImportExportIcon
                    fontSize="medium"
                    style={{ color: colors.other.blue }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" component="div">
                    {" "}
                    {/* Adjusted the variant */}
                    <Box display="flex" alignItems="center" gap={1}>
                      {data.isImported ? (
                        <CheckCircleIcon
                          style={{ color: colors.pastelColor.green }}
                        />
                      ) : (
                        <CancelIcon style={{ color: colors.pastelColor.red }} />
                      )}
                    </Box>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Import ID"}>
                <ListItemIcon>
                  <Grid3x3Icon
                    fontSize="medium"
                    style={{ color: colors.other.blue }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h7">{data.importID}</Typography>}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ width: "100%" }}>
          <DetailsFooter
            createdOn={data.createdOn}
            lastActionOn={data.lastActionOn}
            lastActionBy={data.lastActionBy}
            customColor={colors.pastelColor.blue}
            dividerColor={colors.pastelColor.blue}
          />
        </Box>
      </Grid>

      {/* Images */}
      <Grid item xs={12}>
        {data.imageUrl && (
          <img
            src={data.imageUrl}
            alt="Tenant"
            style={{
              maxWidth: "100%",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          />
        )}
        {data.photo && (
          <img
            src={data.photo}
            alt="Tenant"
            style={{ maxWidth: "100%", borderRadius: "8px" }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceDetails;
