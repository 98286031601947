import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import ItemSearch from '../../search/itemSearch';

const SearchComponent = ({ control, name, component, filter, onItemSelected, rules = {} }) => {
  const handleItemSelected = (item, field) => {
    field.onChange(item.id);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Box width="100%" mt={2}>
          <ItemSearch
            component={component}
            filter={filter}
            onItemSelected={(item) => onItemSelected(item, field)}
            {...field}
          />
          {error && (
            <Typography
              color="error"
              variant="caption"
              display="block"
              mt={1}
            >
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};

export default SearchComponent;
