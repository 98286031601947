import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import PendingActionsIcon from "@mui/icons-material/PendingActionsOutlined";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { AssessmentOutlined } from "@mui/icons-material";
import WheelchairPickupIcon from "@mui/icons-material/WheelchairPickupOutlined";
import DevicesOtherIcon from "@mui/icons-material/DevicesOtherOutlined";
import MedicalServicesIcon from "@mui/icons-material/MedicalServicesOutlined";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function BasicSpeedDial() {
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const colors = tokens(muiTheme.palette.mode);

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  const actions = [
    {
      icon: (
        <WheelchairPickupIcon
          sx={{
            color: colors.grey[100],
            "&:hover": {
              color: colors.greenAccent[400],
            },
          }}
        />
      ),
      name: "Add Patient",
      action: () => handleMenuItemClick("/addpatient"),
      bgcolor: colors.greenAccent[700],
    },
    {
      icon: (
        <MedicalServicesIcon
          sx={{
            color: colors.grey[100],
            "&:hover": {
              color: colors.redAccent[400],
            },
          }}
        />
      ),
      name: "Add Tenant",
      action: () => handleMenuItemClick("/addtenant"),
      bgcolor: colors.greenAccent[700],
    },
    {
      icon: (
        <DevicesOtherIcon
          sx={{
            color: colors.grey[100],
            "&:hover": {
              color: colors.greenAccent[400],
            },
          }}
        />
      ),
      name: "Add Device",
      action: () => handleMenuItemClick("/adddevice"),
      bgcolor: colors.greenAccent[700],
    },
    {
      icon: (
        <AssessmentOutlined
          sx={{
            color: colors.grey[100],
            "&:hover": {
              color: colors.greenAccent[400],
            },
          }}
        />
      ),
      name: "View Report",
      action: () => handleMenuItemClick("/reports"),
      bgcolor: colors.greenAccent[700],
    },
  ];

  return (
    <Box sx={{ position: "relative", mt: 3, height: 50 }}>
      <StyledSpeedDial
        ariaLabel="SpeedDial with dynamic actions"
        icon={
          <SpeedDialIcon
            openIcon={
              <PendingActionsIcon
                sx={{
                  color: colors.grey[100],
                }}
              />
            }
          />
        }
        direction="left" // This ensures it opens to the left
        FabProps={{
          sx: {
            bgcolor: colors.blueAccent[600],
            color: colors.grey[100],
            "&:hover": {
              bgcolor: colors.blueAccent[600], // Hover background color
              color: colors.white, // Assuming you want to change the icon color on hover as well
            },
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
            sx={{ bgcolor: action.bgcolor }}
          />
        ))}
      </StyledSpeedDial>
    </Box>
  );
}
