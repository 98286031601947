import { Typography, Box, Paper, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { BarChart } from "@mui/x-charts/BarChart";

const HRVAnalysisDisplay = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartSettings = {
    width: 500,
    height: 300,
  };

  // Function to determine color based on feedback
  const feedbackColor = (feedback) => {
    switch (feedback) {
      case "normal":
        return theme.palette.success.main;
      case "good":
        return theme.palette.info.main;
      default:
        return theme.palette.grey[500];
    }
  };

  const seriesData = [
    {
      data: [data.rmssdMin, data.pnn50Min, data.sdnnMin, data.sdannMin],
      label: "Min",
      outerRadius: 80,
      highlighted: { additionalRadius: 10 },
    },
    {
      data: [data.rmssd, data.pnn50, data.sdnn, data.sdann],
      label: "Value",
      outerRadius: 80,
      highlighted: { additionalRadius: 10 },
    },
    {
      data: [data.rmssdMax, data.pnn50Max, data.sdnnMax, data.sdannMax],
      label: "Max",
      outerRadius: 80,
      highlighted: { additionalRadius: 10 },
    },
  ];

  console.log(seriesData);

  const xAxisData = ["RMSSD", "pNN50", "SDNN", "SDANN"];
  // Convert HRV analysis data into an array for mapping
  const hrvMetrics = [
    {
      label: "RMSSD",
      value: data.rmssd,
      min: data.rmssdMin,
      max: data.rmssdMax,
      feedback: data.rmssdFeedback,
    },
    {
      label: "pNN50",
      value: data.pnn50,
      min: data.pnn50Min,
      max: data.pnn50Max,
      feedback: data.pnn50Feedback,
    },
    {
      label: "SDNN",
      value: data.sdnn,
      min: data.sdnnMin,
      max: data.sdnnMax,
      feedback: data.sdnnFeedback,
    },
    {
      label: "SDANN",
      value: data.sdann,
      min: data.sdannMin,
      max: data.sdannMax,
      feedback: data.sdannFeedback,
    },
  ];

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        backgroundColor: colors.primary[400],
        border: 1,
        borderColor: theme.palette.divider,
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        HRV Analysis
      </Typography>
      <Grid container spacing={2}>
        {hrvMetrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box key={index} sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <FavoriteIcon sx={{ color: feedbackColor(metric.feedback) }} />
                {metric.label}
              </Typography>
              <Typography variant="body2">
                Value: {metric.value}, Range: {metric.min} - {metric.max}
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: feedbackColor(metric.feedback) }}
              >
                {metric.feedback.charAt(0).toUpperCase() +
                  metric.feedback.slice(1)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" flexDirection="column" alignItems="left" ml={15}>
        <BarChart
          xAxis={[{ scaleType: "band", data: xAxisData }]} // Categories for each group
          series={seriesData.map((series) => ({
            data: series.data,
            label: series.label,
            highlightScope: { faded: "global", highlighted: "series" },
          }))}
          width={500}
          height={300}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 0,
              itemMarkHeight: 5,
            },
          }}
          {...chartSettings}
        />
      </Box>
    </Paper>
  );
};

export default HRVAnalysisDisplay;
