import React, { useEffect, useState, Suspense } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { put, API_URL } from "../../services/api";
import { Button, Alert } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useTimedMessage } from "../forms/utilities";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import { UpdateOutlined } from "@mui/icons-material";
import CenteredProgress from "../../scenes/global/CenteredProgress";
const UserForm = React.lazy(() => import("../forms/UserForm"));

const EditUser = ({
  selectedUser,
  setResponseMessage,
  setIsSuccess,
  setEditMode,
  onUserUpdateSuccess,
}) => {
  const methods = useForm();
  const [localResponseMessage, setLocalResponseMessage] = useTimedMessage();
  const [isLocalSuccess, setIsLocalSuccess] = React.useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      methods.reset(selectedUser);
    }
  }, [selectedUser, methods]);

  const sanitizeselectedUser = (user) => {
    // Create a shallow copy of patient to avoid direct mutation
    const sanitizedUser = { ...user };

    // Replace null mobileNumber value with an empty string
    if (sanitizedUser.mobileNumber === null) {
      sanitizedUser.mobileNumber = "";
    }

    return sanitizedUser;
  };

  useEffect(() => {
    if (selectedUser) {
      methods.reset(sanitizeselectedUser(selectedUser));
    }
  }, [selectedUser, methods]);

  const onSubmit = async (formData) => {
    const changedValues = Object.keys(formData).reduce((acc, key) => {
      if (key === "roles" || key === "deviceIds") {
        return acc;
      }
      if (formData[key] !== sanitizeselectedUser(selectedUser)[key]) {
        acc[key] = formData[key]; // Only add to acc if the value has changed
      }
      return acc;
    }, {});
    if (Object.keys(changedValues).length === 0) {
      setIsLocalSuccess(false);
      setLocalResponseMessage("No changes made to user data");
      return;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };
    setIsLoading(true);
    try {
      const response = await put(
        `${API_URL}/v2/FastballUser/UpdateUserDetail`,
        payload,
        true
      );
      if (response.statusCode === "OK") {
        setResponseMessage(response.messages[0]);
        setIsSuccess(true);
        setEditMode(false);
        methods.reset();
        const updateWasSuccessful = true;
        onUserUpdateSuccess(updateWasSuccessful);
      } else {
        setIsLocalSuccess(false);
        setLocalResponseMessage("Error updating User.");
      }
    } catch (error) {
      if (
        error.response.data.validationErrors &&
        error.response.data.validationErrors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.validationErrors
          .map((err) => `${err.property}: ${err.error}`)
          .join(" ");
        setIsLocalSuccess(false);
        setLocalResponseMessage(validationErrorMessages);
      } else {
        // Handle other errors
        setIsLocalSuccess(false);
        setLocalResponseMessage("Error updating User.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Suspense fallback={<CenteredProgress />}>
          <UserForm
            isEdit={true}
            selectedUser={sanitizeselectedUser(selectedUser)}
          />
        </Suspense>
        {localResponseMessage && (
          <Alert
            severity={isLocalSuccess ? "success" : "error"}
            style={{ display: "flex", justifyContent: "center", margin: "1em" }}
          >
            {localResponseMessage}
          </Alert>
        )}
        {isLoading && <CenteredProgress />}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            type="submit"
            disabled={isLoading}
          >
            <UpdateOutlined sx={{ mr: "10px" }} />
            Update
          </Button>
          <Button
            onClick={() => {
              setEditMode(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditUser;
