import React, { useState, useEffect } from "react";
import { Container, Box, IconButton } from "@mui/material";
import {
  useFetchUserByEmail,
  useFetchUserDetails,
} from "../../components/user/useFetchUserInfo";
import { useTimedMessage } from "../../components/forms/utilities";
import UserDetails from "./UserDetails";
import EditUser from "../../components/user/editUser";
import AssignRole from "../../components/user/assignRole";
import RemoveRole from "../../components/user/removeRole";
import ActionToolbar from "../../components/common/ActionToolbar";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteUser from "../../components/user/deleteUser";
import { ROLES } from "../../components/common/constants";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import CenteredProgress from "../global/CenteredProgress";
import ItemSearch from "../../components/search/itemSearch";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useAuth } from "../../AuthProvider";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../components/auth/storage";
import { useQueryClient } from "@tanstack/react-query";

const UserProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userDetails } = useAuth();
  const queryClient = useQueryClient();
  const [userUpdateSuccess, setUserUpdateSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [assignRoleMode, setAssignRoleMode] = useState(false);
  const [removeRoleMode, setRemoveRoleMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const location = useLocation();
  const locationState = location.state || {};
  const queryParams = new URLSearchParams(location.search);
  const urlInitialEmail = queryParams.get("email");
  const [userEmail, setUserEmail] = useState("");
  const initialUserState = location.state?.initialUser;
  const [initialUser, setInitialUser] = useState(initialUserState);
  const initialEmail = urlInitialEmail || locationState.initialEmail;
  const [selectedUserUid, setSelectedUserUid] = useState(null);

  useEffect(() => {
    if (!initialUser && !initialEmail) {
      setSelectedUserUid(getLocalStorageItem("userInfo"));
    }
  }, []);

  const isAdmin = userDetails.roles.some(
    (role) => role === "Admin" || role === "HospitalAdmin"
  );
  const isSearchBarEnabled = isAdmin;
  const [status, setStatus] = useState({
    add: { disabled: false, tooltip: "Add new user" },
    edit: { disabled: false, tooltip: "Edit selected user" },
    delete: { disabled: false, tooltip: "Delete selected user" },
    assignRole: { disabled: false, tooltip: "Assign Role to user" },
    removeRole: { disabled: false, tooltip: "Remove Role from user" },
  });
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const {
    data: userProfile,
    isLoading,
    isError,
    error,
    refetch: refetchProfile,
  } = useFetchUserByEmail(userEmail, initialUser);
  const {
    data: omnykUserDetails,
    isLoading: isUserDetailsLoading,
    isError: isUserDetailsError,
    error: userDetailsError,
    refetch: refetchUserDetails,
  } = useFetchUserDetails(selectedUserUid, initialUser);

  useEffect(() => {
    if (selectedUser) {
      setLocalStorageItem("userInfo", selectedUser.uid);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (initialUser) {
      setUserEmail("");
      setSelectedUserUid(initialUser.uid);
    }
  }, [initialUser]);

  useEffect(() => {
    if (initialEmail) {
      setUserEmail(initialEmail);
    }
  }, [initialEmail]);

  useEffect(() => {
    return () => {
      setSelectedUser(null);
      setSelectedUserUid(null);
      setUserEmail("");
    };
  }, []);

  useEffect(() => {
    if (userProfile) {
      setSelectedUser(userProfile);
    }
  }, [userProfile]);

  useEffect(() => {
    if (omnykUserDetails) {
      setSelectedUser(omnykUserDetails);
    }
  }, [omnykUserDetails]);

  const handleAdd = () => {
    navigate("/adduser");
    setEditMode(false);
  };

  const handleEdit = () => {
    setSelectedUserUid(selectedUser.uid);
    setEditMode(true);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleAssignRole = () => {
    setAssignRoleMode(true);
  };

  const handleRemoveRole = () => {
    setRemoveRoleMode(true);
  };

  const handleUserUpdateSuccess = (success) => {
    setUserUpdateSuccess(success);
  };

  const handleUserSelected = (user) => {
    setInitialUser(null);
    setUserEmail("");
    setSelectedUserUid(user.uid);
  };

  const handleUserDeleted = () => {
    queryClient.removeQueries({
      queryKey: ["userDetails", selectedUser.uid],
    });
    queryClient.removeQueries({
      queryKey: ["userByEmail", selectedUser.email],
    });
    removeLocalStorageItem("userInfo");
    setSelectedUser(null);
    setSelectedUserUid(null);
  };

  useEffect(() => {
    if (selectedUser) {
      setStatus((prevStatus) => {
        // Initialize the new status object based on the previous status
        const newStatus = JSON.parse(JSON.stringify(prevStatus));

        // Reset all disable flags and tooltips to default values
        newStatus.add.disabled = false;
        newStatus.add.tooltip = "Add new user";
        newStatus.edit.disabled = false;
        newStatus.edit.tooltip = "Edit selected user";
        newStatus.delete.disabled = false;
        newStatus.delete.tooltip = "Delete selected user";
        newStatus.assignRole.disabled = false;
        newStatus.assignRole.tooltip = "Assign Role to user";
        newStatus.removeRole.disabled = false;
        newStatus.removeRole.tooltip = "Remove Role from user";

        if (
          selectedUser.roles &&
          ROLES.every((role) => selectedUser.roles.includes(role))
        ) {
          newStatus.assignRole.disabled = true;
          newStatus.assignRole.tooltip =
            "User is already assigned all possible roles!";
        }
        if (!selectedUser.roles || selectedUser.roles.length === 0) {
          newStatus.removeRole.disabled = true;
          newStatus.removeRole.tooltip = "User has no assigned roles!";
        }

        // Return the updated status object
        return newStatus;
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userUpdateSuccess) {
      if (selectedUserUid) {
        refetchUserDetails();
      } else {
        refetchProfile();
      }
      setUserUpdateSuccess(false);
    }
  }, [userUpdateSuccess]);

  useEffect(() => {
    // Show success or custom error messages from responseMessage
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }

    // Additionally, handle errors from React Query
    if (isError && error) {
      // Display a more specific error message if available, or a generic message
      toast.error(
        error.message || "An error occurred while fetching user profile."
      );
    }

    if (isUserDetailsError && userDetailsError) {
      // Display a more specific error message if available, or a generic message
      toast.error(
        userDetailsError.message ||
          "An error occurred while fetching user details."
      );
    }
  }, [
    responseMessage,
    isSuccess,
    isError,
    error,
    isUserDetailsError,
    userDetailsError,
  ]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Omnyk Users" subtitle="User details" />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
            padding: "15px",
          }}
        >
          {isSearchBarEnabled &&
            !editMode &&
            !assignRoleMode &&
            !removeRoleMode && (
              <Box my={2}>
                <ItemSearch onItemSelected={handleUserSelected} component="FastballUser" />
              </Box>
            )}
          {(isLoading || isUserDetailsLoading) && <CenteredProgress />}
          <DeleteUser
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setResponseMessage={setResponseMessage}
            setIsSuccess={setIsSuccess}
            openDeleteDialog={openDeleteDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            onUserUpdateSuccess={handleUserUpdateSuccess}
            setIsLoading={setIsLoading}
            onUserDeleted={handleUserDeleted}
          />
          {assignRoleMode && selectedUser && !editMode && (
            <AssignRole
              selectedUser={selectedUser}
              setResponseMessage={setResponseMessage}
              setIsSuccess={setIsSuccess}
              setAssignRoleMode={setAssignRoleMode}
              onUserUpdateSuccess={handleUserUpdateSuccess}
            />
          )}
          {removeRoleMode && selectedUser && !editMode && (
            <RemoveRole
              selectedUser={selectedUser}
              setResponseMessage={setResponseMessage}
              setIsSuccess={setIsSuccess}
              setRemoveRoleMode={setRemoveRoleMode}
              onUserUpdateSuccess={handleUserUpdateSuccess}
            />
          )}
          {!isloading && selectedUser && !editMode && (
            <>
              <ActionToolbar
                status={status}
                onAdd={handleAdd}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onAssignRole={handleAssignRole}
                onRemoveRole={handleRemoveRole}
              />
              <UserDetails selectedUser={selectedUser} />
            </>
          )}
          {editMode && selectedUser && (
            <EditUser
              selectedUser={selectedUser}
              setResponseMessage={setResponseMessage}
              setIsSuccess={setIsSuccess}
              setEditMode={setEditMode}
              onUserUpdateSuccess={handleUserUpdateSuccess}
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default UserProfile;
