import React, { useState, useEffect } from "react";
import { Container, Box, IconButton } from "@mui/material";
import AddItemForm from "../../components/product/addItem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const AddItem = ({ component = "Category" }) => {
  const [newId, setNewId] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (newId) {
      toast.success(`Added a new ${component}.`);
      navigate(`/${component.toLowerCase()}-details`, {
        state: { initialId: newId },
      });
    }
  }, [newId, navigate]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={`${component}`} subtitle={`Add a new ${component}.`} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="5px"
        >
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
        </Box>
        <Container
          maxWidth="md"
          sx={{
            backgroundColor: colors.primary[400],
            border: `1px solid ${colors.greenAccent[400]}`,
            padding: "15px",
          }}
        >
          <AddItemForm component={component} setNewId={setNewId} />
        </Container>
      </Box>
    </Box>
  );
};
export default AddItem;
