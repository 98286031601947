import React, { useState, forwardRef, useEffect } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  useTheme,
  TextField,
  CircularProgress,
} from "@mui/material";
import CategoryIcon from "@mui/icons-material/CategoryOutlined";
import Inventory2Icon from "@mui/icons-material/Inventory2Outlined";
import ClassIcon from "@mui/icons-material/ClassOutlined";
import TimelapseIcon from "@mui/icons-material/TimelapseOutlined";
import { WheelchairPickupOutlined } from "@mui/icons-material";
import { LocalHospitalOutlined } from "@mui/icons-material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useFetchAutoComplete } from "../common/useFetchListInfo";
import { toast } from "react-toastify";
import { tokens } from "../../theme";

const ItemSearch = forwardRef(
  ({ onItemSelected, component, filter, clearSearch = false, clearSelectedItem }, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const primaryMainColor = theme.palette.background.default;
    const [inputValue, setInputValue] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const {
      data: suggestedItems,
      isLoading,
      isError,
      error,
    } = useFetchAutoComplete({
      component: component,
      searchTerm: searchTerm,
      ...filter,
    });

    let icon;
    if (component === "ProductCore") {
      icon = <Inventory2Icon sx={{ color: colors.greenAccent[600] }} />;
    } else if (component === "Product") {
      icon = <Inventory2Icon sx={{ color: colors.greenAccent[300] }} />;
    } else if (component === "SubCategory") {
      icon = <ClassIcon sx={{ color: colors.pastelColor.brown }} />;
    } else if (component === "SKU") {
      icon = <TimelapseIcon sx={{ color: colors.blueAccent[300] }} />;
    } else if (component === "Patient") {
      icon = <WheelchairPickupOutlined sx={{ color: "lightblue" }} />;
    } else if (component === "Tenant") {
      icon = <LocalHospitalOutlined sx={{ color: "lightcoral" }} />;
    } else if (component === "FastballUser") {
      icon = <PersonOutlineIcon sx={{ color: "lightblue" }} />;
    } else {
      icon = <CategoryIcon sx={{ color: colors.blueAccent[600] }} />;
    }

    // Handle error state
    useEffect(() => {
      if (isError) {
        toast.error(error.message);
      }
    }, [isError, error]);

    useEffect(() => {
      if (clearSelectedItem) {
        setInputValue("");
        setSearchTerm("");
      }
    }, [clearSelectedItem]);

    const handleInputChange = (event, value) => {
      setInputValue(value);
      setSearchTerm(value);
    };

    const handleOptionSelected = (event, item) => {
      if (item) {
        setInputValue(
          component === "Patient" || component === "FastballUser"
            ? `${item.email || ""} | ${item.firstName || ""} ${item.lastName || ""}`
            : `${item.name || ""} | ${item.code || ""}`
        );
        setSearchTerm(""); // Reset searchTerm to avoid unwanted API calls
        onItemSelected(item);
        if (clearSearch) {
          setTimeout(() => {
            setInputValue("");
          }, 3000);
        }
      }
    };

    const getOptionLabel = (option) => {
      if (component === "Patient" || component === "FastballUser") {
        return `${option.email || ""} | ${option.firstName || ""} ${option.lastName || ""}`;
      }
      return `${option.name || ""} | ${option.code || ""}`;
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Autocomplete
          freeSolo
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleOptionSelected}
          options={suggestedItems || []}
          getOptionLabel={getOptionLabel}
          loading={isLoading}
          ListboxProps={{
            sx: {
              bgcolor: primaryMainColor,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={component === "FastballUser" ? `Filter by User`: `Filter by ${component}`}
              placeholder={component === "FastballUser" ? `Select a User...`: `Select a ${component}...`}
              variant="outlined"
              fullWidth
              required
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">{icon}</InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              // Forward the ref to the TextField
              ref={ref}
            />
          )}
        />
      </Box>
    );
  }
);

export default ItemSearch;