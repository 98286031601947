import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  CheckCircleOutline,
  HighlightOff,
  ExitToAppOutlined,
  StartOutlined,
  Inventory2Outlined,
  AttachMoney,
  Public,
  Schedule,
} from "@mui/icons-material";
import DetailsFooter, {
  formatEndDate,
} from "../../components/common/DetailsFooter";
import ReadOnlyContent from "../../components/common/HtmlDisplay";
import {
  formatSkuType,
  formatDuration,
} from "../../components/forms/utilities";
import {
  getCurrencyIcon,
  formatCurrency,
} from "../../components/common/constants";
import TreeView from "../../components/product/itemTree";

const SkuView = ({ component, subComponent, dependentData, selectedItem, colors }) => {
  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom={2}
          >
            <Typography variant="h2" gutterBottom>
              {selectedItem.productName}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: colors.greenAccent[400],
              }}
            >
              Code: {selectedItem.code || selectedItem.prefixProductCode}
            </Typography>
            <Typography variant="h5" gutterBottom>
              Category: {selectedItem.category}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: colors.greenAccent[400],
              }}
            >
              SubCategory: {selectedItem.subCategory}
            </Typography>
          </Box>
          <Box
            p={2}
            borderRadius={2}
            sx={{
              border: "1px solid #1976d2",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ReadOnlyContent content={selectedItem.productDescription} />
            {component === "SKU" && (
              <ReadOnlyContent content={selectedItem.description} />
            )}
          </Box>
        </Grid>
      </Grid>
      <Box
        p={2}
        mb={2}
        mt={2}
        borderRadius={2}
        style={{ border: "1px solid #1976d2" }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <List>
              <ListItem>
                <Tooltip title="Demo">
                  <ListItemIcon>
                    {selectedItem.isDemo ? (
                      <CheckCircleOutline
                        fontSize="large"
                        style={{ color: colors.pastelColor.green }}
                      />
                    ) : (
                      <HighlightOff
                        fontSize="large"
                        style={{ color: colors.pastelColor.red }}
                      />
                    )}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={<Typography variant="h5">Demo</Typography>}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="Is Active">
                  <ListItemIcon>
                    {selectedItem.isActive ? (
                      <CheckCircleOutline
                        fontSize="large"
                        style={{ color: colors.pastelColor.green }}
                      />
                    ) : (
                      <HighlightOff
                        fontSize="large"
                        style={{ color: colors.pastelColor.red }}
                      />
                    )}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={<Typography variant="h5">Is Active</Typography>}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="Published">
                  <ListItemIcon>
                    {selectedItem.published ? (
                      <CheckCircleOutline
                        fontSize="large"
                        style={{ color: colors.pastelColor.green }}
                      />
                    ) : (
                      <HighlightOff
                        fontSize="large"
                        style={{ color: colors.pastelColor.red }}
                      />
                    )}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={<Typography variant="h5">Published</Typography>}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="Start Date">
                  <ListItemIcon>
                    <StartOutlined
                      fontSize="large"
                      style={{ color: colors.pastelColor.green }}
                    />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={
                    <Typography variant="h5">
                      {formatEndDate(selectedItem.productStartDate) ||
                        "No start date"}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <Tooltip title="End Date">
                  <ListItemIcon>
                    <ExitToAppOutlined
                      fontSize="large"
                      style={{ color: colors.pastelColor.red }}
                    />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={
                    <Typography variant="h5">
                      {formatEndDate(selectedItem.productEndDate) ||
                        "No end date"}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Divider orientation="vertical" flexItem />
          {component === "SKU" && (
            <Grid item xs={12} md={5}>
              <List>
                <ListItem>
                  <Tooltip title="SKU Type">
                    <ListItemIcon>
                      <Inventory2Outlined
                        style={{ color: colors.pastelColor.teal }}
                        fontSize="large"
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {formatSkuType(selectedItem.skuType)}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <Tooltip title="Price">
                    <ListItemIcon>
                      {getCurrencyIcon(selectedItem.country)}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {formatCurrency(
                          selectedItem.price,
                          selectedItem.currency
                        )}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <Tooltip title="Country">
                    <ListItemIcon>
                      <Public
                        style={{ color: colors.pastelColor.brown }}
                        fontSize="large"
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {selectedItem.country}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <Tooltip title="Duration">
                    <ListItemIcon>
                      <Schedule
                        style={{ color: colors.pastelColor.blue }}
                        fontSize="large"
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {formatDuration(selectedItem.duration)}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          )}
        </Grid>
      </Box>
      {component && subComponent && dependentData && (
        <TreeView items={dependentData} subComponent={subComponent} />
      )}
      <DetailsFooter
        createdOn={selectedItem.createdOn}
        lastActionOn={selectedItem.lastActionOn}
        lastActionBy={selectedItem.lastActionBy}
        customColor={"teal"}
        dividerColor={"grey.300"}
      />
    </>
  );
};

export default SkuView;
