import React from 'react';
import { Box, Typography, LinearProgress, useTheme } from '@mui/material';
import { tokens } from "../../theme";

const ProgressBarWithMilestones = ({ currentStatus }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const getStatusColor = (status) => {
        switch (status) {
            case 'UnAllocated':
                return colors.pastelColor.red
            case 'Tenant Allocated':
                return colors.pastelColor.orange
            case 'Shipping':
                return colors.pastelColor.blue
            case 'Hospital Received':
                    return colors.pastelColor.yellow
            case 'Patient Allocated':
                return colors.pastelColor.green
            default:
                return colors.grey[400]; // default grey color from the theme
        }
    };

    const milestones = [
        { label: 'UnAllocated', status: 'UnAllocated' },
        { label: 'Tenant Allocated', status: 'Tenant Allocated' },
        { label: 'Shipping', status: 'Shipping' },
        { label: 'Hospital Received', status: 'Hospital Received' },
        { label: 'Patient Allocated', status: 'Patient Allocated' },
    ];

    const currentStatusIndex = milestones.findIndex(milestone => milestone.status === currentStatus);

    // Calculate the progress percentage
    const progress = (currentStatusIndex + 1) / (milestones.length) * 100; // Adjusted the progression to consider the added status

    return (
        <Box marginY={4}>
            <LinearProgress variant="determinate" value={progress} color="secondary" />
            <Box display="flex" justifyContent="space-between" marginTop={1}>
                {milestones.map((milestone, index) => (
                    <Box key={milestone.label} textAlign="center" position="relative" minWidth={35}>
                        <Box
                            bgcolor={currentStatusIndex >= index ? getStatusColor(milestone.status) : colors.grey[600]}
                            width={30}
                            height={30}
                            borderRadius="50%"
                            position="absolute"
                            top={-20}
                            left="50%"
                            style={{ transform: 'translateX(-50%)' }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="common.white"
                        >
                            {index <= currentStatusIndex ? '✓' : ''}
                        </Box>
                        <Typography variant="caption" display="block" marginTop={4}>
                            {milestone.label}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default ProgressBarWithMilestones;
