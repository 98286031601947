import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import FilterRow from "../../components/search/FilterRow";
import { useNavigate, useLocation } from "react-router-dom";
import {
  SearchTermDisplay,
  useFilters,
} from "../../components/search/filterSearchDisplay";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { Box, Grid, Button, IconButton, Tooltip } from "@mui/material";
import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import { setLocalStorageItem, getLocalStorageItem } from "../auth/storage";

function useInitialFilters(
  defaultFilters,
  storageFile,
  storageFilePm,
  storageFileSM
) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [initialFilters, setInitialFilters] = useState([]);
  const [initialPaginationModel, setInitialPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [initialSortModel, setInitialSortModel] = useState([]);
  const [source, setSource] = useState("unset"); // 'search', 'state', 'storage', or 'default'

  useEffect(() => {
    let filtersFromQueryParams = defaultFilters.map((filter) => ({
      ...filter,
      value: queryParams.has(filter.type)
        ? queryParams.get(filter.type)
        : filter.value,
    }));

    const isFilteredFromQuery = defaultFilters.some((filter) =>
      queryParams.has(filter.type)
    );

    const pageFromQuery = queryParams.get("page");
    const pageSizeFromQuery = queryParams.get("pageSize");

    if (isFilteredFromQuery) {
      setInitialFilters(filtersFromQueryParams);
      setSource("search");
      if (pageFromQuery || pageSizeFromQuery) {
        setInitialPaginationModel((prevModel) => ({
          pageSize: pageSizeFromQuery
            ? parseInt(pageSizeFromQuery, 10)
            : prevModel.pageSize,
          page: pageFromQuery ? parseInt(pageFromQuery, 10) : prevModel.page,
        }));
      }
    } else if (pageFromQuery || pageSizeFromQuery) {
      setInitialPaginationModel((prevModel) => ({
        pageSize: pageSizeFromQuery
          ? parseInt(pageSizeFromQuery, 10)
          : prevModel.pageSize,
        page: pageFromQuery ? parseInt(pageFromQuery, 10) : prevModel.page,
      }));
      setSource("search");
    } else if (location.state?.initialFilters) {
      // If filters are passed through the router's location state
      setInitialFilters(location.state.initialFilters);
      setSource("state");
    } else {
      // Attempt to load from localStorage
      const savedFiltersString = getLocalStorageItem(storageFile);
      const storedPaginationModel = getLocalStorageItem(storageFilePm);
      const savedSortModel = getLocalStorageItem(storageFileSM);
      if (savedFiltersString) {
        setInitialFilters(savedFiltersString);
        setSource("storage");
      } else {
        setInitialFilters(defaultFilters);
        setSource("default");
      }
      if (storedPaginationModel) {
        setInitialPaginationModel(storedPaginationModel);
      }
      if (savedSortModel) {
        setInitialSortModel(savedSortModel);
      }
    }
  }, [location.search, location.state, defaultFilters, storageFile]);

  return { initialFilters, source, initialPaginationModel, initialSortModel };
}

const ListHandler = ({
  headers,
  defaultFilters,
  dgFilterTypes,
  genericFilterTypes,
  useFetchList,
  ItemTable,
  BulkUpdateDialog,
  extended,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [initFilters, setInitFilters] = useState(false);
  const [initPM, setInitPM] = useState(false);
  const [initSM, setInitSM] = useState(false);
  const storageFile = `${headers.storagePrefix}Filters`;
  const storageFilePm = `${headers.storagePrefix}Pm`;
  const storageFileSM = `${headers.storagePrefix}SM`;
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const { initialFilters, source, initialPaginationModel, initialSortModel } =
    useInitialFilters(
      defaultFilters,
      storageFile,
      storageFilePm,
      storageFileSM
    );
  const [filterType, setFilterType] = useState(headers.filterRows.default);
  const [clearFilterType, setClearFilterType] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(true);
  const [itemRequests, setItemRequests] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isBulkUpdateDialogOpen, setIsBulkUpdateDialogOpen] = useState(false);
  const [initialLocation, setInitialLocation] = useState({
    country: null,
    state: null,
    city: null,
  });

  const {
    filters,
    updateFilter,
    updateFilters,
    clearFilter,
    clearAllFilters,
    getFilterValue,
  } = useFilters(defaultFilters);

  const [pagination, setPagination] = useState({
    totalPages: 0,
    totalCount: 0,
    recordsPerPage: 10,
  });

  useEffect(() => {
    if (initialFilters) {
      updateFilters(initialFilters);
      setInitFilters(true);
    }
  }, [initialFilters]);

  useEffect(() => {
    if (initialPaginationModel) {
      setPaginationModel(initialPaginationModel);
      setInitPM(true);
    }
  }, [initialPaginationModel]);

  useEffect(() => {
    if (initialSortModel) {
      setSortModel(initialSortModel);
      setInitSM(true);
    }
  }, [initialSortModel]);

  const shouldFetch = initFilters && initPM && initSM;

  const { data, isLoading, error, refetch } = useFetchList(
    headers.component,
    paginationModel.page + 1,
    paginationModel.pageSize,
    filters,
    sortModel,
    shouldFetch,
    extended?.queryChoice
  );

  useEffect(() => {
    if (source === "default" || source === "storage") {
      try {
        setLocalStorageItem(storageFile, filters);
      } catch (error) {
        console.error("Error saving filters to localStorage:", error);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (source === "default" || source === "storage") {
      try {
        setLocalStorageItem(storageFilePm, paginationModel);
      } catch (error) {
        console.error("Error saving pagination model to localStorage:", error);
      }
    }
  }, [paginationModel]);

  useEffect(() => {
    if (source === "default" || source === "storage") {
      try {
        setLocalStorageItem(storageFileSM, sortModel);
      } catch (error) {
        console.error("Error saving sort model to localStorage:", error);
      }
    }
  }, [sortModel]);

  useEffect(() => {
    if (data) {
      setPagination({
        totalPages: data.totalPages || data.TotalPages || 0,
        totalCount: data.totalCount || data.TotalCount || 0,
        recordsPerPage: data.recordsPerPage || data.RecordsPerPage || 0,
      });

      setItemRequests(data.items || data.Items || []);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error.message || "An error occurred while fetching data.");
    }
  }, [error]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClearFilter = (type) => {
    if (headers.item === "tenant") {
      setInitialLocation((prevLocation) => {
        // Start with the previous state
        const newLocation = { ...prevLocation };

        // Based on the type, nullify the appropriate fields
        if (type === "country") {
          newLocation.country = null;
          newLocation.state = null;
          newLocation.city = null;
        } else if (type === "state") {
          newLocation.state = null;
          newLocation.city = null;
        } else if (type === "city") {
          newLocation.city = null;
        }

        // Return the updated state
        return newLocation;
      });
    }
    if (dgFilterTypes.includes(type) || type === "filter") {
      setFilterModel({ items: [] });
    }
    handleClearSearch(type);
    clearFilter(type);
  };

  const handleClearSearch = (type) => {
    if (
      (type === "tenantId" || type === "tenantCode") &&
      filterType === "tenant"
    ) {
      setClearFilterType(filterType);
    }
    if (type === "productSubCategoryId" && filterType === "Product") {
      setClearFilterType(filterType);
    }
    if (type === "categoryId" && filterType === "Category") {
      setClearFilterType(filterType);
    }
    if (type === "subCategoryId" && filterType === "SubCategory") {
      setClearFilterType(filterType);
    }
    if (type === "productId" && filterType === "ProductCore") {
      setClearFilterType(filterType);
    }
    if (type === "skuId" && filterType === "SKU") {
      setClearFilterType(filterType);
    }
    if (
      (type === "patientId" || type === "userId" || type === "email") &&
      (filterType === "patient" || filterType === "user")
    ) {
      setClearFilterType(filterType);
    }
    if (type === "startDate" || type === "reportDate" || type === "issueDate") {
      setClearFilterType("StartDate");
    }
    if (type === "endDate") {
      setClearFilterType("EndDate");
    }
  };

  const handleOnView = async (selectedItem, mode = "none") => {
    if (headers.item === "firmware") {
      const { success, message } = await extended.handleOnView(
        selectedItem,
        mode
      );
      if (success) {
        refetch();
      } else {
        toast.error(message);
      }
    } else if (headers.item === "product") {
      navigate("/product-details", {
        state: { initialId: selectedItem.id },
      });
    } else if (headers.item === "SKU") {
      navigate("/sku-details", {
        state: { initialId: selectedItem.id },
      });
    } else if (headers.item === "productCore") {
      navigate("/productcore-details", {
        state: { initialId: selectedItem.id },
      });
    } else if (headers.item === "subCategory") {
      navigate("/subcategory-details", {
        state: { initialId: selectedItem.id },
      });
    } else if (headers.item === "category") {
      navigate("/category-details", {
        state: { initialId: selectedItem.id },
      });
    } else if (headers.item === "invoice") {
      navigate("/invoice-details", {
        state: { initialProductUid: selectedItem.uid },
      });
    } else if (headers.item === "consumerinvoice") {
      navigate("/consumer-invoice-details", {
        state: { selectedProduct: selectedItem },
      });
    } else if (headers.item === "drr") {
      const initialdata = itemRequests.find(
        (item) => item.uid === selectedItem
      );
      navigate("/device-return-details", {
        state: { selectedProduct: initialdata },
      });
    } else if (headers.item === "dvr") {
      navigate("/device-request-details", {
        state: { initialProductUid: selectedItem },
      });
    } else if (
      headers.item === "dailyReport" ||
      headers.item === "customReport"
    ) {
      navigate("/patient-report", {
        state: { initialReportUid: selectedItem.uid },
      });
    }
  };

  // Filter Search & Display functions

  const toggleFilterActive = () => {
    // When turning the filter off, ensure to fetch all devices again
    if (isFilterActive) {
      clearAllFilters();
      setFilterModel({ items: [] });
    }
    setIsFilterActive(!isFilterActive);
  };

  const handleFilterUpdate = (type, value, valueSecondary = "") => {
    updateFilter({ type, value, valueSecondary });
  };

  const handleGenericSelected = async (searchfilter) => {
    handleFilterUpdate("filter", searchfilter);
  };

  const handleTenantSelected = (tenant) => {
    if (
      headers.item === "dailyReport" ||
      headers.item === "customReport" ||
      headers.item === "invoice"
    ) {
      handleFilterUpdate("tenantId", tenant.id, tenant.code);
    } else {
      handleFilterUpdate("tenantCode", tenant.code);
    }
  };

  const handleProductSelected = (item) => {
    handleFilterUpdate("productSubCategoryId", item.id, item.name);
  };

  const handleCategorySelected = (item) => {
    handleFilterUpdate("categoryId", item.id, item.name);
  };
  const handleSubCategorySelected = (item) => {
    handleFilterUpdate("subCategoryId", item.id, item.name);
  };
  const handleProductCoreSelected = (item) => {
    handleFilterUpdate("productId", item.id, item.name);
  };
  const handleSKUSelected = (item) => {
    handleFilterUpdate("skuId", item.id, item.code);
  };

  const handleDateSelected = (date, label = "None") => {
    if (headers.item === "dailyReport") {
      handleFilterUpdate("reportDate", date);
    } else if (
      headers.item === "customReport" ||
      headers.item === "patient" ||
      headers.item === "product" ||
      headers.item === "productCore"
    ) {
      if (label === "Start Date") {
        handleFilterUpdate("startDate", date);
      } else if (label === "End Date") {
        handleFilterUpdate("endDate", date);
      }
    } else if (
      headers.item === "invoice" ||
      headers.item === "consumerinvoice"
    ) {
      handleFilterUpdate("issueDate", date);
    }
  };

  const handlePatientSelected = (patient) => {
    if (
      headers.item === "dailyReport" ||
      headers.item === "customReport" ||
      headers.item === "device"
    ) {
      handleFilterUpdate(
        "patientId",
        patient.id,
        `${patient.firstName} ${patient.lastName}`
      );
    } else if (headers.item === "user") {
      handleFilterUpdate("filter", patient.email);
    } else if (headers.item === "consumerinvoice") {
      handleFilterUpdate(
        "userId",
        patient.userId,
        `${patient.firstName} ${patient.lastName}`
      );
    } else {
      handleFilterUpdate("email", patient.email);
    }
  };

  const handleFilterChange = (newFilterType) => {
    setFilterType(newFilterType);
  };

  const handleLocationChange = (location) => {
    setInitialLocation((prevLocation) => ({
      ...prevLocation,
      ...location,
    }));
    // If a country is selected, update the country filter
    if (location.country) {
      handleFilterUpdate("country", location.country.label);
    } else {
      // If there's no country, clear the country filter
      clearFilter("country");
    }

    // If a state is selected, update the state filter, which includes the country
    if (location.state) {
      handleFilterUpdate("state", location.state.label);
    } else {
      // If there's no state, clear the state filter
      clearFilter("state");
    }

    // If a city is selected, update the city filter, which includes the country and state
    if (location.city) {
      handleFilterUpdate("city", location.city.label);
    } else {
      // If there's no city, clear the city filter
      clearFilter("city");
    }
  };

  const onClearLocationFilters = () => {
    clearFilter("country");
    clearFilter("state");
    clearFilter("city");
  };

  // Data Grid Display functions

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const handleFilterModelChange = (newModel) => {
    const prevFilters = filterModel.items.reduce((acc, item) => {
      acc[item.field] = item; // Map previous filters by their field
      return acc;
    }, {});

    const updatedItems = newModel.items.map((item) => {
      const filterType = determineFilterType(item);
      const prevItem = prevFilters[item.field];
      if (prevItem && prevItem.value !== item.value) {
        // If there's a previous item for the same field and the value has changed
        return item; // Use the new item as it is
      } else if (!prevItem) {
        const filterValue = getFilterValue(filterType);
        // If this is a new field or the value hasn't been updated
        return { ...item, value: filterValue.value }; // Reset the value
      }
      return item; // Return the item unchanged if none of the above conditions are met
    });
    setFilterModel({ ...newModel, items: updatedItems });
  };

  const handleRowSelection = (selectedIds) => {
    setSelectionModel(selectedIds);
  };

  function determineFilterType(filterItem) {
    const itemType = headers.item;
    const field = filterItem.field;

    // Handle different item types with specific logic for each
    if (itemType === "tenant") {
      if (field === "code") {
        return "tenantCode";
      } else if (field === "name") {
        return "tenantName";
      } else if (genericFilterTypes.includes(field)) {
        return "filter";
      }
      return field;
    } else if (itemType === "user") {
      if (field === "firstName" || field === "lastName") {
        return "name";
      }
      if (genericFilterTypes.includes(field)) {
        return "filter";
      }
      return field;
    } else {
      if (field === "firstName" || field === "lastName") {
        return "patientName";
      } else if (field === "days") {
        return "daysSince";
      } else if (genericFilterTypes.includes(field)) {
        return "filter";
      }
      return field;
    }
  }

  useEffect(() => {
    filterModel.items.forEach((filterItem) => {
      const filterType = determineFilterType(filterItem);

      if (filterItem.value !== undefined && filterItem.value !== "") {
        updateFilter({ type: filterType, value: filterItem.value });
      } else if (filterItem.value === "") {
        // Only clear the filter if it previously had a non-empty value
        const previousValue = getFilterValue(filterType);
        if (previousValue !== undefined && previousValue !== "") {
          clearFilter(filterType);
        }
      }
    });
    if (headers.item === "user") {
      filterModel.items = filterModel.items.map((item) => {
        if (item.field === "excludeAnonymous" && item.value === "true") {
          return { ...item, value: null }; // Set value to null if conditions are met
        }
        return item; // Return the item unchanged if conditions are not met
      });
    }
  }, [filterModel]);

  useEffect(() => {
    if (selectionModel.length > 0) {
      setSelectedItems(
        itemRequests.filter((item) => selectionModel.includes(item.id))
      );
    } else {
      setSelectedItems([]);
    }
  }, [itemRequests, selectionModel]);

  const handleCellClick = (params) => {
    if (headers.item === "subCategory") {
      if (params.field === "category") {
        navigate("/category-details", {
          state: { initialId: params.row.categoryId },
        });
      }
    } else if (headers.item === "userRolePermission") {
      if (params.field === "category") {
        navigate("/category-details", {
          state: { initialId: params.row.categoryId },
        });
      }
      if (params.field === "subCategory") {
        navigate("/subcategory-details", {
          state: { initialId: params.row.subCategoryId },
        });
      }
    } else if (headers.item === "product") {
      if (params.field === "subCategory") {
        navigate("/subcategory-details", {
          state: { initialId: params.row.subCategoryId },
        });
      }
      if (params.field === "productName") {
        navigate("/productcore-details", {
          state: { initialId: params.row.productId },
        });
      }
    } else if (headers.item === "SKU") {
      if (params.field === "productName") {
        navigate("/product-details", {
          state: { initialId: params.row.productSubCategoryId },
        });
      }
    } else if (
      headers.item === "dailyReport" ||
      headers.item === "customReport"
    ) {
      if (params.field === "patientEmail" || params.field === "patientName") {
        const email = params.row.patientEmail;
        navigate("/patientprofile", {
          state: { initialEmail: email },
        });
      } else if (params.field === "tenantName") {
        const code = params.row.tenantCode;
        navigate("/tenantinfo", { state: { initialTenantCode: code } });
      }
    } else if (headers.item === "user") {
      if (params.field === "tenantEmail") {
        const email = params.row.tenantEmail;
        navigate("/userprofile", {
          state: { initialEmail: email },
        });
      } else if (params.field === "userName") {
        const selectedItem = params.row; // Get the entire row data
        navigate("/userprofile", { state: { initialUser: selectedItem } });
      } else if (params.field === "email") {
        if (params.row.roles && params.row.roles.includes("Patient")) {
          const email = params.value;
          navigate("/patientprofile", { state: { initialEmail: email } });
        }
      } else if (params.field === "tenant" || params.field === "tenantName") {
        if (params.row.tenantCode) {
          const code = params.row.tenantCode;
          navigate("/tenantinfo", { state: { initialTenantCode: code } });
        }
      } else if (params.field === "deviceIds" || params.field === "ring") {
        if (params.value) {
          const deviceId = params.value[0];
          navigate("/deviceinfo", { state: { initialDeviceMId: deviceId } });
        }
      } else if (params.field === "firstName") {
        const selectedItem = params.row; // Get the entire row data
        navigate("/userprofile", { state: { initialUser: selectedItem } });
      }
    } else {
      if (params.field === "email") {
        const selectedItem = params.row;
        const email = params.value;
        navigate("/patientprofile", {
          state: { initialPatient: selectedItem, initialEmail: email },
        });
      } else if (
        params.field === "deviceId" ||
        params.field === "device" ||
        params.field === "ring"
      ) {
        if (params.value) {
          const deviceId = params.value;
          navigate("/deviceinfo", { state: { initialDeviceMId: deviceId } });
        }
      } else if (params.field === "hospital") {
        if (params.value) {
          const code = params.row.hospitalCode;
          navigate("/tenantinfo", { state: { initialTenantCode: code } });
        }
      } else if (params.field === "firstName" || params.field === "firstname") {
        if (params.value) {
          const selectedItem = params.row;
          const email = params.row.email;
          navigate("/patientprofile", {
            state: { initialPatient: selectedItem, initialEmail: email },
          });
        }
      } else if (params.field === "tenant") {
        if (params.row.tenantCode) {
          const code = params.row.tenantCode;
          navigate("/tenantinfo", { state: { initialTenantCode: code } });
        }
      } else if (
        params.field === "name" &&
        headers.item !== "product" &&
        headers.item !== "productCore" &&
        headers.item !== "category"
      ) {
        const selectedItem = params.row;
        navigate("/tenantinfo", { state: { initialTenant: selectedItem } });
      } else if (params.field === "patientName") {
        if (params.value) {
          const uid = params.row.patientUid;
          navigate("/patientprofile", { state: { initialPatientUid: uid } });
        }
      } else if (params.field === "requested") {
        const email = params.value;
        navigate("/userprofile", { state: { initialEmail: email } });
      }
    }
  };

  // Bulk Update Functions

  const handleOpenBulkUpdateDialog = () => {
    setIsBulkUpdateDialogOpen(true);
  };

  const handleCloseBulkUpdateDialog = () => {
    setIsBulkUpdateDialogOpen(false);
  };

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={headers.title} subtitle={headers.subtitle} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="5px"
        >
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
        </Box>
        {initialFilters && (
          <>
            <SearchTermDisplay
              filters={filters}
              onClearFilter={handleClearFilter}
              onClearLocationFilters={onClearLocationFilters}
            />
            {headers.filterRows.enabled && (
              <FilterRow
                filterType={filterType}
                clearFilterType={clearFilterType}
                setClearFilterType={setClearFilterType}
                onGenericSelected={handleGenericSelected}
                onTenantSelected={handleTenantSelected}
                onProductSelected={handleProductSelected}
                onCategorySelected={handleCategorySelected}
                onSubCategorySelected={handleSubCategorySelected}
                onProductCoreSelected={handleProductCoreSelected}
                onSKUSelected={handleSKUSelected}
                onPatientSelected={handlePatientSelected}
                onDateSelected={handleDateSelected}
                onFilterChange={handleFilterChange}
                onLocationChange={handleLocationChange}
                isFilterActive={isFilterActive}
                toggleFilterActive={toggleFilterActive}
                enableTenantSearch={headers.filterRows.enableTenantSearch}
                enablePatientSearch={headers.filterRows.enablePatientSearch}
                enableUserSearch={headers.filterRows.enableUserSearch}
                enableDateSearch={headers.filterRows.enableDateSearch}
                enableMultiDateSearch={headers.filterRows.enableMultiDateSearch}
                enableProductSearch={headers.filterRows.enableProductSearch}
                enableCategorySearch={headers.filterRows.enableCategorySearch}
                enableSubCategorySearch={
                  headers.filterRows.enableSubCategorySearch
                }
                enableProductCoreSearch={
                  headers.filterRows.enableProductCoreSearch
                }
                enableSKUSearch={headers.filterRows.enableSKUSearch}
                configLabels={headers.filterLabels}
                enableGenericSearch={headers.filterRows.enableGenericSearch}
                enableLocationSearch={headers.filterRows.enableLocationSearch}
                initialLocation={initialLocation}
              />
            )}
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              mt={2}
            >
              <Grid item>
                <Tooltip title={`Refresh ${headers.item} list`} placement="top">
                  <span>
                    <IconButton onClick={() => refetch()} aria-label="refetch">
                      <RefreshIcon
                        sx={{
                          color: colors.pastelColor.blue,
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            {itemRequests && (
              <ItemTable
                isLoading={isLoading}
                itemRequests={itemRequests}
                selectionModel={selectionModel}
                setSelectionModel={handleRowSelection}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                onFilterModelChange={handleFilterModelChange}
                onSortModelChange={handleSortModelChange}
                rowCount={pagination.totalCount}
                filterModel={filterModel}
                sortModel={sortModel}
                handleCellClick={handleCellClick}
                handleOnView={handleOnView}
                headers={headers}
                refetch={refetch}
              />
            )}
            {headers.bulkUpdate.enabled && (
              <>
                <BulkUpdateDialog
                  open={isBulkUpdateDialogOpen}
                  onClose={handleCloseBulkUpdateDialog}
                  selectedItems={selectedItems}
                  refetch={refetch}
                />
                <Grid
                  item
                  xs={12}
                  mt={2}
                  ml={2}
                  style={{ display: "flex", justifyContent: "left" }}
                >
                  <Button
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={handleOpenBulkUpdateDialog}
                    disabled={selectionModel.length === 0}
                  >
                    <UpgradeOutlinedIcon sx={{ mr: "10px" }} />
                    Bulk Update
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ListHandler;
