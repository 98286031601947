import { useQuery } from "@tanstack/react-query";
import { get, API_URL } from "../../services/api";

const API_BASE_URL = `${API_URL}/v2/Invoice`;

export const useFetchInvoiceDetails = (uid, initialData) => {
  return useQuery({
    queryKey: ["productDetails", uid],
    queryFn: async () => {
      if (!uid) {
        return {};
      }
      const response = await get(`${API_BASE_URL}/GetByUid?id=${uid}`, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching device details"
        );
      }
    },
    enabled: !!uid && !initialData, // Only run the query if the uid is truthy
    initialData: initialData,
  });
};

export const useFetchAppSettings = (isUpdated = false) => {
  return useQuery({
    queryKey: ["appSettings", isUpdated],
    queryFn: async () => {
      const response = await get(`${API_URL}/v2/AppSettings/GetSettings`, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(response.errors?.[0] || "Error fetching App settings");
      }
    },
  });
};


