import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { de, enGB, zhCN, enUS } from "date-fns/locale";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";

const locales = { "en-gb": enGB, "en-us": enUS, "zh-cn": zhCN, de };

const ProductForm = ({ isEdit = false, selectedItem = null }) => {
  const {
    register,
    setValue,
    setError,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const country = watch("country");
  const primaryMainColor = theme.palette.background.default;
  const startDateValue = watch("startDate");
  const [endDateError, setEndDateError] = useState("");
  const locale = "en-gb";

  // Calculate today and tomorrow's dates for minDate usage
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  useEffect(() => {
    // Clear custom end date error when start date changes
    setEndDateError("");
    clearErrors("endDate");
  }, [startDateValue, clearErrors]);

  const handleEndDateChange = (newValue, onChange) => {
    const startDateTime = new Date(startDateValue);
    if (newValue <= startDateTime) {
      // Setting custom error state and React Hook Form error
      setEndDateError("End date/time cannot be before start date/time.");
      setError("endDate", {
        type: "custom",
        message: "End date/time cannot be before start date/time.",
      });
    } else {
      setEndDateError("");
      clearErrors("endDate");
      onChange(newValue); // Update the form value if validation passes
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locales[locale]}
        >
          <Controller
            name="startDate"
            control={control}
            rules={{ required: "Start Date is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DateTimePicker
                label="Start date time"
                format="dd-MMM-yyyy HH:mm:ss"
                inputFormat="yyyy-MM-dd HH:mm:ss"
                value={value ? new Date(value) : null}
                onChange={onChange}
                slotProps={{
                  actionBar: { actions: ["clear", "accept"] },
                  textField: {
                    required: true,
                    readOnly: true,
                    error: !!error,
                    helperText: error ? error.message : null,
                  },
                  popper: {
                    sx: {
                      "& .MuiDialogActions-root .MuiButton-text": {
                        color: "white",
                        backgroundColor: colors.greenAccent[700],
                      },
                    },
                  },
                }}
                openTo="day"
                views={["year", "month", "day", "hours", "minutes"]}
                minDate={today}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>

      {/* End Date TimePicker */}
      <Grid item xs={6}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locales[locale]}
        >
          <Controller
            name="endDate"
            control={control}
            rules={{ required: "End Date is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DateTimePicker
                label="End date time"
                format="dd-MMM-yyyy HH:mm:ss"
                inputFormat="yyyy-MM-dd HH:mm:ss"
                value={value ? new Date(value) : null}
                onChange={(newValue) => handleEndDateChange(newValue, onChange)}
                slotProps={{
                  actionBar: { actions: ["clear", "accept"] },
                  textField: {
                    required: true,
                    readOnly: true,
                    error: !!error || !!endDateError,
                    helperText: error
                      ? error.message
                      : endDateError
                      ? endDateError
                      : null,
                  },
                  popper: {
                    sx: {
                      "& .MuiDialogActions-root .MuiButton-text": {
                        color: "white",
                        backgroundColor: colors.greenAccent[700],
                      },
                    },
                  },
                }}
                openTo="day"
                views={["year", "month", "day", "hours", "minutes"]}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      {[
        { label: "Demo", name: "isDemo" },
        { label: "Active", name: "isActive" },
        { label: "Published", name: "published" },
      ].map((item, index) => (
        <Grid item xs={3} key={index}>
          <FormControlLabel
            control={
              <Controller
                name={item.name}
                control={control}
                defaultValue={
                  isEdit
                    ? selectedItem?.[item.name] ?? item.name !== "isDemo"
                    : item.name !== "isDemo"
                }
                render={({ field }) => (
                  <Checkbox {...field} checked={field.value} />
                )}
              />
            }
            label={item.label}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductForm;
