import { useForm, FormProvider, Controller } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { get, post, API_URL } from "../../services/api";
import {
  Grid,
  Button,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ItemSearch from "../search/itemSearch";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CenteredProgress from "../../scenes/global/CenteredProgress";

const AddPermissionForm = ({ component, selectedItem, setPermissionMode }) => {
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role] = useState({ label: "Patient", value: "Patient" });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleItemSelected = (item, field) => {
    field.onChange(item.id);
  };

  useEffect(() => {
    const fetchUserRoles = async () => {
      setIsLoading(true);
      let localRoles = localStorage.getItem("userRoles");

      if (localRoles && localRoles !== "undefined") {
        // Parse the stored string back into an array and update the state
        const parsedRoles = JSON.parse(localRoles);
        setRoles(
          parsedRoles.map((rl) =>
            typeof rl === "string" ? { label: rl, value: rl } : rl
          )
        );
        setIsLoading(false);
      } else {
        try {
          const response = await get(
            `${API_URL}/v2/FastballUser/GetRoles?endUser=true`,
            true
          );
          if (response && Array.isArray(response.data)) {
            // Save the data to local storage in stringified form
            localStorage.setItem(
              "userRoles",
              JSON.stringify(
                response.data.map((rl) => ({ label: rl, value: rl }))
              )
            );
            // Set the roles state
            setRoles(response.data.map((rl) => ({ label: rl, value: rl })));
          }
        } catch (error) {
          console.error("Error fetching roles:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    if (component === "UserRole") {
      fetchUserRoles();
    }
  }, []);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    if (component === "UserRole") {
      formData.subCategoryId = selectedItem.id;
    } else if (component === "ProductCore") {
      formData.productId = selectedItem.id;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };

    try {
      const response = await post(
        `${API_URL}/v2/SubCategory/AddRolePermission`,
        payload,
        true
      );
      if (response.statusCode === "OK") {
        toast.success(response.messages[0]);
        if (response.data) {
          setPermissionMode(false);
        }
        methods.reset();
      } else if (response.statusCode === "BadRequest") {
        toast.error(response.errors[0]);
      } else {
        toast.error(`Error adding ${component}.`);
      }
    } catch (error) {
      if (error.response.data && error.response.data.errors.length > 0) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage =
          `Error adding ${component} permission.` +
          ".\n" +
          validationErrorMessages.join("\n");
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error(`Error adding ${component} permission.`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {component === "UserRole" && (
          <Box justifyContent="center" mt={2} mb={2}>
            <Controller
              name="role"
              control={methods.control}
              rules={{ required: "Role required" }}
              defaultValue={role.value} // Ensure this default value is a string as expected
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={
                    roles.find((option) => option.value === field.value) || null
                  } // Ensure the Autocomplete recognizes the selected value
                  options={roles}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  onChange={(event, newValue) => {
                    field.onChange(newValue ? newValue.value : ""); // Update the form field value directly to the string value
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Roles"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
            />
          </Box>
        )}

        {component === "Tenant" && (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Controller
              name="tenantId"
              control={methods.control}
              rules={{ required: "Tenant" }}
              render={({ field, fieldState: { error } }) => (
                <Box width="100%">
                  {" "}
                  <ItemSearch
                    component="Tenant"
                    onItemSelected={(item) => handleItemSelected(item, field)}
                    {...field}
                  />
                  {error && (
                    <Typography
                      color="error"
                      variant="caption"
                      display="block"
                      mt={1}
                    >
                      {error.message}
                    </Typography>
                  )}
                </Box>
              )}
            />
          </Box>
        )}
        {isLoading && <CenteredProgress />}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              disabled={isLoading}
              type="submit"
            >
              <AddCircleOutlineIcon sx={{ mr: "10px" }} />
              Permission
            </Button>
            <Button
              onClick={() => {
                setPermissionMode(false);
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AddPermissionForm;
