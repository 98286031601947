import React, { useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { deleteRequest, API_URL } from "../../services/api";
import { v4 as uuidv4 } from "uuid";

const DeleteItem = ({
  component,
  openDeleteDialog,
  setOpenDeleteDialog,
  selectedItem,
  onItemDeleted,
}) => {
  const API_BASE_URL = `${API_URL}/v2/${component}`;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLocalLoading] = useState(false);

  const deleteItem = useCallback(async (id) => {
    setIsLocalLoading(true);
    try {
      let url;
      if (component === "UserRolePermission") {
        url = `${API_URL}/v2/SubCategory/RemoveRolePermission`;
      } else {
        url = `${API_BASE_URL}/Delete`;
      }
      const requestId = uuidv4();
      const response = await deleteRequest(
        url,
        { requestId, data: { id } },
        true
      );
      if (response.statusCode === "OK") {
        const successMsg =
          response?.data?.errors?.[0] || `${component} deleted successfully`;
        toast.success(successMsg);
        return true;
      } else {
        throw new Error(
          response.errors?.[0] || "An error occured during delete"
        );
      }
    } catch (error) {
      const errorMsg =
        error?.response?.data?.errors?.[0] ||
        error.message ||
        `Error occurred during ${component} delete.`;
      toast.error(errorMsg);
    } finally {
      setIsLocalLoading(false);
    }
  });

  const handleItemDelete = async () => {
    setIsLocalLoading(true);
    const success = await deleteItem(selectedItem.uid);

    if (success) {
      onItemDeleted();
      setOpenDeleteDialog(false);
    } else {
      setOpenDeleteDialog(false);
    }
  };

  return (
    <>
      {/* The Dialog JSX */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: colors.primary[400],
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: colors.greenAccent[400], typography: "h5" }}
        >
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {component}? This action cannot
            be undone.
          </DialogContentText>
          {isLoading && <CenteredProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            onClick={handleItemDelete}
            disabled={isLoading}
          >
            <DeleteOutlineIcon
              sx={{ mr: "10px", color: colors.pastelColor.red }}
            />
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteItem;
