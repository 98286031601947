import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CenteredProgress from "../global/CenteredProgress";
import {
  useTheme,
  useMediaQuery,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  ListItemAvatar,
  Avatar,
  Tooltip,
} from "@mui/material";
import LocalHospitalIcon from "@mui/icons-material/LocalHospitalOutlined";
import { toast } from "react-toastify";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutlineOutlined";
import DevicesOtherIcon from "@mui/icons-material/DevicesOtherOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import GradingIcon from "@mui/icons-material/GradingOutlined";
import InventoryIcon from "@mui/icons-material/Inventory2Outlined";
import { get, API_URL } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { tokens } from "../../theme";
import { styled } from "@mui/material/styles";
import { useAuth } from "../../AuthProvider";
import { capitalizeFirstLetter } from "../../components/forms/utilities";
import { formatDate } from "../../components/forms/utilities";
import BasicSpeedDial from "../../components/common/SpeedDial";
import PatientStats from "../../components/patients/PatientStats";
import ToggleOffIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnIcon from "@mui/icons-material/ToggleOnOutlined";

const HoverableTableCell = ({
  onClick,
  children,
  hoverColor,
  align = "left",
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        cursor: "pointer",
        color: isHovered ? hoverColor : "inherit", // Use hoverColor when hovered
        textAlign: align,
      }}
    >
      {children}
    </TableCell>
  );
};

const useDashboardData = () => {
  return useQuery({
    queryKey: ["dashboardData"],
    queryFn: () => get(`${API_URL}/v2/Dashboard/Data`, true),
  });
};

function Dashboard() {
  const { data: dashboardData, isLoading, error } = useDashboardData();
  const hasCardData =
    dashboardData &&
    dashboardData.data &&
    Array.isArray(dashboardData.data.cardData);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  const [statsMode, setStatsMode] = useState(false);

  const handleToggle = () => {
    setStatsMode(!statsMode);
  };

  const iconMappings = {
    "Total Hospitals": LocalHospitalIcon,
    "Total Patients": PeopleOutlineIcon,
    "Total Devices": DevicesOtherIcon,
    "Total Orders": GradingIcon,
  };

  const cardColors = {
    "Total Hospitals": "#f44336", // red
    "Total Patients": "#3f51b5", // indigo
    "Total Devices": "#DDA0DD", // plum
    "Total Orders": "#4caf50", // green
  };

  const onInvoiceClicked = (uid) => {
    navigate("/device-request-details", { state: { initialProductUid: uid } });
  };

  const onTenantClicked = (code) => {
    navigate("/tenantinfo", { state: { initialTenantCode: code } });
  };

  const onTenantUidClicked = (uid) => {
    navigate("/tenantinfo", { state: { initialTenantId: uid } });
  };

  const onProductClicked = (id) => {
    navigate("/productcore-details", { state: { initialId: id } });
  };

  const onUserClicked = (email) => {
    navigate("/userprofile", { state: { initialEmail: email } });
  };

  const onPatientClicked = (email) => {
    navigate("/patientprofile", { state: { initialEmail: email } });
  };

  const onRequestNoClicked = (uid) => {
    navigate("/device-return-details", { state: { initialProductUid: uid } });
  };

  const AnimatedIcon = styled(IconButton)(({ theme }) => ({
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)", // Example: Scale up the icon on hover
    },
  }));

  const getIcon = (title, color) => {
    const IconComponent = iconMappings[title];
    return IconComponent ? (
      <AnimatedIcon
        onClick={() => {
          // Navigation logic
          if (title === "Total Hospitals") {
            navigate("/tenantlist");
          }
          if (title === "Total Devices") {
            navigate("/devicelist");
          }
          if (title === "Total Patients") {
            navigate("/patientlist");
          }
          if (title === "Total Orders") {
            navigate("/invoicelist");
          }
        }}
      >
        <IconComponent style={{ fontSize: "48px", color: color }} />
      </AnimatedIcon>
    ) : null;
  };

  useEffect(() => {
    if (error) {
      toast.error(`Error fetching dashboard data: ${error.message}`);
    }
  }, [error]);

  return (
    <Box m="20px">
      {/* HEADER */}

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header
          title="DASHBOARD"
          subtitle={`Welcome to OmnyManage, ${capitalizeFirstLetter(
            userDetails.firstName
          )} ${capitalizeFirstLetter(userDetails.lastName)}!`}
        />
        <BasicSpeedDial />
      </Box>
      {isLoading && <CenteredProgress />}
      <Box display="flex" justifyContent="flex-end">
        <Tooltip
          title={statsMode ? "Patient Statistics" : "Dashboard"}
          placement="top"
          arrow
        >
          <IconButton onClick={handleToggle} sx={{ fontSize: "32px" }}>
            {statsMode ? (
              <ToggleOnIcon sx={{ color: colors.pastelColor.c1, fontSize: "inherit" }} />
            ) : (
              <ToggleOffIcon
                sx={{ color: colors.pastelColor.c2, fontSize: "inherit" }}
              />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {hasCardData && !statsMode && (
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          style={{ marginTop: "20px", marginBottom: "10px" }}
        >
          {/* Card Data */}
          {dashboardData.data?.cardData?.map((card) => (
            <Grid item xs={12} sm={6} md={3} key={card.cardorder}>
              <Box
                width="100%"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={card.value}
                  subtitle={card.title}
                  icon={getIcon(card.title, cardColors[card.title])}
                />
              </Box>
            </Grid>
          ))}
          {/* Device Requests */}
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
              Recent Device Requests
            </Typography>
            <TableContainer style={{ backgroundColor: colors.primary[400] }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice No</TableCell>
                    <TableCell align="right">Tenant</TableCell>
                    <TableCell align="right">Requested By</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Request No</TableCell>
                    <TableCell align="right">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardData.data?.recentlyDeviceRequest?.map((request) => (
                    <TableRow key={request.uid}>
                      <HoverableTableCell
                        hoverColor={colors.greenAccent[400]}
                        onClick={() => onInvoiceClicked(request.uid)}
                      >
                        {request.invoiceNo}
                      </HoverableTableCell>
                      <HoverableTableCell
                        align="right"
                        hoverColor={colors.greenAccent[400]}
                        onClick={() => onTenantClicked(request.tenantCode)}
                      >
                        {request.tenant}
                      </HoverableTableCell>
                      <HoverableTableCell
                        align="right"
                        hoverColor={colors.greenAccent[400]}
                        onClick={() => onUserClicked(request.requested)}
                      >
                        {request.requested.toLowerCase()}
                      </HoverableTableCell>
                      <TableCell align="right">
                        {request.status.replace(/_/g, " ")}
                      </TableCell>
                      <TableCell align="right">{request.requestNo}</TableCell>
                      <TableCell align="right">
                        {formatDate(request.createdOn)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Device Return Requests */}
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
              Recent Device Return Requests
            </Typography>
            <TableContainer style={{ backgroundColor: colors.primary[400] }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Request No</TableCell>
                    <TableCell align="right">Tenant</TableCell>
                    <TableCell align="right">Reason</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardData.data?.recentlyDeviceReturnRequest?.map(
                    (request) => (
                      <TableRow key={request.id}>
                        <HoverableTableCell
                          hoverColor={colors.greenAccent[400]}
                          onClick={() => onRequestNoClicked(request.uid)}
                        >
                          {request.requestNo}
                        </HoverableTableCell>
                        <HoverableTableCell
                          align="right"
                          hoverColor={colors.greenAccent[400]}
                          onClick={() => onTenantClicked(request.tenantCode)}
                        >
                          {request.tenant}
                        </HoverableTableCell>
                        <TableCell align="right">{request.reason}</TableCell>
                        <TableCell align="right">{request.status}</TableCell>
                        <TableCell align="right">
                          {formatDate(request.createdOn)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container spacing={1} m={2}>
            {/* Recently Registered Patients on the left */}
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Card
                elevation={0}
                style={{ backgroundColor: colors.primary[400] }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ color: colors.greenAccent[500] }}
                  >
                    Recently Registered Patients
                  </Typography>
                  <List dense>
                    {dashboardData.data?.recentlyRegisteredPatients?.map(
                      (patient, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <IconButton
                                  onClick={() =>
                                    onPatientClicked(patient.email)
                                  }
                                  sx={{
                                    color: colors.pastelColor.blue,
                                    "&:hover": {
                                      color: colors.greenAccent[400],
                                    },
                                  }}
                                >
                                  <PersonIcon />
                                </IconButton>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={patient.name} />
                          </ListItem>

                          {index !==
                            dashboardData.data?.recentlyRegisteredPatients
                              ?.length -
                              1 && <Divider />}
                        </React.Fragment>
                      )
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            {/* Recently Added Products Middle */}
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                elevation={0}
                style={{ backgroundColor: colors.primary[400] }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ color: colors.greenAccent[500] }}
                  >
                    Recently Added Products
                  </Typography>
                  <List dense>
                    {dashboardData.data?.recentlyAddedProducts?.map(
                      (product, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <IconButton
                                  onClick={() => onProductClicked(product.id)}
                                  sx={{
                                    color: colors.pastelColor.blue,
                                    "&:hover": {
                                      color: colors.greenAccent[400],
                                    },
                                  }}
                                >
                                  <InventoryIcon />
                                </IconButton>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={product.name} />
                          </ListItem>

                          {index !==
                            dashboardData.data?.recentlyAddedProducts?.length -
                              1 && <Divider />}
                        </React.Fragment>
                      )
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>

            {/* Recently Registered Hospitals on the right */}
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Card
                elevation={0}
                style={{ backgroundColor: colors.primary[400] }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ color: colors.greenAccent[500] }}
                  >
                    Recently Registered Hospitals
                  </Typography>
                  <List dense>
                    {dashboardData.data?.recentlyRegisteredHospitals?.map(
                      (hospital, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <IconButton
                                  onClick={() =>
                                    onTenantUidClicked(hospital.uid)
                                  }
                                  sx={{
                                    color: colors.other.coral,
                                    "&:hover": {
                                      color: colors.redAccent[600],
                                    },
                                  }}
                                >
                                  <LocalHospitalIcon />
                                </IconButton>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={hospital.name} />
                          </ListItem>
                          {index !==
                            dashboardData.data?.recentlyRegisteredHospitals
                              ?.length -
                              1 && <Divider />}
                        </React.Fragment>
                      )
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
      {statsMode && (
        <Grid item xs={12}>
          <PatientStats />
        </Grid>
      )}
    </Box>
  );
}

export default Dashboard;
