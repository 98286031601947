import EuroIcon from "@mui/icons-material/EuroOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYenOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoneyOutlined";
import { teal } from "@mui/material/colors";
import { ToWords }  from 'to-words';

export const ROLES = [
  "Admin",
  "OmnykUser",
  "User",
  "MobileApp",
  "ReportUser",
  "WAAdmin",
  "TechSupport",
  "CustomerSupport",
];

// countryData Never change this. Will break database connections.
export const countries = [
  {
    country_name: "India",
    country_value: "India",
    country_code: "IN",
    country_id: 1,
    currency_symbol: "₹",
    currency_code: "INR", // Indian Rupee
  },
  {
    country_name: "UAE",
    country_value: "UAE",
    country_code: "AE",
    country_id: 2,
    currency_symbol: "د.إ",
    currency_code: "AED", // UAE Dirham
  },
  {
    country_name: "Saudi Arabia",
    country_value: "SaudiArabia",
    country_code: "SA",
    country_id: 3,
    currency_symbol: "ر.س",
    currency_code: "SAR", // Saudi Riyal
  },
  {
    country_name: "Australia",
    country_value: "Australia",
    country_code: "AU",
    country_id: 4,
    currency_symbol: "A$",
    currency_code: "AUD", // Australian Dollar
  },
  {
    country_name: "Bangladesh",
    country_value: "Bangladesh",
    country_code: "BD",
    country_id: 5,
    currency_symbol: "৳",
    currency_code: "BDT", // Bangladeshi Taka
  },
  {
    country_name: "USA",
    country_value: "USA",
    country_code: "US",
    country_id: 6,
    currency_symbol: "$",
    currency_code: "USD", // US Dollar
  },
  {
    country_name: "Canada",
    country_value: "Canada",
    country_code: "CA",
    country_id: 7,
    currency_symbol: "C$",
    currency_code: "CAD", // Canadian Dollar
  },
  {
    country_name: "Germany",
    country_value: "Germany",
    country_code: "DE",
    country_id: 8,
    currency_symbol: "€",
    currency_code: "EUR", // Euro
  },
  {
    country_name: "France",
    country_value: "France",
    country_code: "FR",
    country_id: 9,
    currency_symbol: "€",
    currency_code: "EUR", // Euro
  },
  {
    country_name: "Japan",
    country_value: "Japan",
    country_code: "JP",
    country_id: 10,
    currency_symbol: "¥",
    currency_code: "JPY", // Japanese Yen
  },
];

export const getCurrencyCode = (countryName) => {
  const country = countries.find((c) => c.country_name === countryName);
  return country ? country.currency_code : "USD";
};

export const getCurrencyIcon = (country) => {
  switch (country) {
    case "India": // Indian Rupee
      return <CurrencyRupeeIcon sx={{ color: teal[500] }} fontSize="large" />;
    case "USA":
    case "Canada":
    case "Australia":
      return <AttachMoneyIcon sx={{ color: teal[500] }} fontSize="large" />;
    case "Germany":
    case "France": // Euro
      return <EuroIcon sx={{ color: teal[500] }} fontSize="large" />;
    case "Japan": // Japanese Yen
      return <CurrencyYenIcon sx={{ color: teal[500] }} fontSize="large" />;
    default: // Default icon
      return <AttachMoneyIcon sx={{ color: teal[500] }} fontSize="large" />;
  }
};

export function formatCurrency(value, currencyCode, locale) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
  }).format(value);
}

export function numberInWords(number, locale = 'en-IN') {
  const toWords = new ToWords({
    localeCode: locale,
    converterOptions: {
      currency: false,  // Not using currency format
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });

  return toWords.convert(number);
}
