import React, { useState, Suspense, useEffect } from "react";
import {
  Paper,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import { tokens } from "../../theme";
import FilterListIcon from "@mui/icons-material/FilterList";
import EventIcon from "@mui/icons-material/EventOutlined";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ItemSearch from "./itemSearch";
import { CustomDatePicker } from "../common/dateTimePicker";
import GenericSearch from "./genericSearch";
import ManIcon from "@mui/icons-material/TransgenderOutlined";
import { useTheme } from "@mui/material/styles";
import ApartmentIcon from "@mui/icons-material/ApartmentOutlined";
import Inventory2Icon from "@mui/icons-material/Inventory2Outlined";
import CategoryIcon from "@mui/icons-material/CategoryOutlined";
import ClassIcon from "@mui/icons-material/ClassOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import TimelapseIcon from "@mui/icons-material/TimelapseOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import {
  ContactsOutlined,
  WheelchairPickupOutlined,
} from "@mui/icons-material";
import useClearSelectedItem from "./useClearSelectedItem";

const LocationSelect = React.lazy(() => import("./locationSelect"));

const FilterRow = ({
  filterType,
  clearFilterType,
  setClearFilterType,
  onTenantSelected,
  onProductSelected,
  onPatientSelected,
  onDateSelected,
  onGenericSelected,
  onCategorySelected,
  onSubCategorySelected,
  onProductCoreSelected,
  onSKUSelected,
  onFilterChange,
  isFilterActive,
  toggleFilterActive,
  enableTenantSearch,
  enablePatientSearch,
  enableUserSearch,
  enableDateSearch,
  enableMultiDateSearch,
  enableGenericSearch,
  enableGenderSearch,
  enableCategorySearch,
  enableSubCategorySearch,
  enableProductCoreSearch,
  enableSKUSearch,
  enableProductSearch,
  enableLocationSearch,
  configLabels,
  onLocationChange,
  initialLocation,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { clearSelectedItem, triggerClearItem } = useClearSelectedItem();

  const toggleDrawer = (isOpen) => {
    setIsDrawerOpen(isOpen);
  };
  const handleOpenDrawer = () => {
    toggleDrawer(true);
  };
  useEffect(() => {
    if (clearFilterType) {
      triggerClearItem(clearFilterType);
      setClearFilterType("");
    }
  }, [clearFilterType]);

  return (
    <Paper
      style={{
        margin: "2px",
        padding: "32px",
        backgroundColor: colors.primary[400],
        borderColor: colors.greenAccent[400],
        borderWidth: "2px",
        borderStyle: "solid",
      }}
    >
      <Grid container alignItems="center">
        <Grid item>
          <Tooltip title="Filter options">
            <IconButton
              onClick={toggleFilterActive}
              color={isFilterActive ? "secondary" : "default"}
            >
              {isFilterActive ? <FilterListIcon /> : <FilterAltOffIcon />}
            </IconButton>
          </Tooltip>
          {enableGenderSearch && (
            <Tooltip title="Filter by Gender">
              <IconButton
                onClick={handleOpenDrawer}
                color={isDrawerOpen ? "primary=" : "default"}
              >
                <ManIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {isFilterActive && (
          <Grid item xs>
            <FormControl variant="outlined" size="small">
              <InputLabel id="filter-select-label">Filter</InputLabel>
              <Select
                labelId="filter-select-label"
                id="filter-select"
                value={filterType}
                onChange={(event) => onFilterChange(event.target.value)}
                label="Filter"
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: colors.primary[400],
                    },
                  },
                }}
              >
                {enableTenantSearch && (
                  <MenuItem value="tenant">
                    <Tooltip title="By Tenant" placement="right">
                      <ListItemIcon>
                        <ApartmentIcon
                          fontSize="medium"
                          sx={{ color: colors.pastelColor.red }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableProductSearch && (
                  <MenuItem value="Product">
                    <Tooltip title="By Product" placement="right">
                      <ListItemIcon>
                        <Inventory2Icon
                          fontSize="medium"
                          sx={{ color: colors.greenAccent[300] }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableCategorySearch && (
                  <MenuItem value="Category">
                    <Tooltip title="By Category" placement="right">
                      <ListItemIcon>
                        <CategoryIcon
                          fontSize="medium"
                          sx={{ color: colors.blueAccent[600] }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableSubCategorySearch && (
                  <MenuItem value="SubCategory">
                    <Tooltip title="By SubCategory" placement="right">
                      <ListItemIcon>
                        <ClassIcon
                          fontSize="medium"
                          sx={{ color: colors.pastelColor.brown }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableProductCoreSearch && (
                  <MenuItem value="ProductCore">
                    <Tooltip title="By ProductCore" placement="right">
                      <ListItemIcon>
                        <Inventory2Icon
                          fontSize="medium"
                          sx={{ color: colors.greenAccent[600] }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableSKUSearch && (
                  <MenuItem value="SKU">
                    <Tooltip title="By SKU" placement="right">
                      <ListItemIcon>
                        <TimelapseIcon
                          fontSize="medium"
                          sx={{ color: colors.blueAccent[300] }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enablePatientSearch && (
                  <MenuItem value="patient">
                    <Tooltip title="By Patient" placement="right">
                      <ListItemIcon>
                        <WheelchairPickupOutlined
                          fontSize="medium"
                          sx={{ color: colors.pastelColor.blue }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableUserSearch && (
                  <MenuItem value="user">
                    <Tooltip title="By User" placement="right">
                      <ListItemIcon>
                        <ContactsOutlined
                          fontSize="medium"
                          sx={{ color: colors.pastelColor.blue }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableDateSearch && (
                  <MenuItem value="date">
                    <Tooltip
                      title={configLabels.title || "By Date"}
                      placement="right"
                    >
                      <ListItemIcon>
                        <EventIcon
                          fontSize="medium"
                          sx={{ color: colors.pastelColor.blue }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableMultiDateSearch && (
                  <MenuItem value="multi-date">
                    <Tooltip
                      title={configLabels.title || "By Date Range"}
                      placement="right"
                    >
                      <ListItemIcon>
                        <EventIcon
                          fontSize="medium"
                          sx={{ color: colors.pastelColor.blue }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableGenericSearch && (
                  <MenuItem value="generic">
                    <Tooltip title="Generic" placement="right">
                      <ListItemIcon>
                        <SearchIcon
                          fontSize="medium"
                          sx={{ color: colors.pastelColor.blue }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
                {enableLocationSearch && (
                  <MenuItem value="location">
                    <Tooltip title="Location" placement="right">
                      <ListItemIcon>
                        <LocationOnIcon
                          fontSize="medium"
                          sx={{ color: "red" }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        )}
        {isFilterActive && (
          <Grid item xs={10}>
            {/* ... (conditional rendering of search bars based on the selected filter) */}
            {enableTenantSearch && filterType === "tenant" && (
              <ItemSearch
                onItemSelected={onTenantSelected}
                component="Tenant"
                clearSelectedItem={clearSelectedItem.tenant}
              />
            )}
            {enableProductSearch && filterType === "Product" && (
              <ItemSearch
                onItemSelected={onProductSelected}
                component="Product"
                clearSelectedItem={clearSelectedItem.Product}
              />
            )}
            {enableCategorySearch && filterType === "Category" && (
              <ItemSearch
                onItemSelected={onCategorySelected}
                component="Category"
                clearSelectedItem={clearSelectedItem.Category}
              />
            )}
            {enableSubCategorySearch && filterType === "SubCategory" && (
              <ItemSearch
                onItemSelected={onSubCategorySelected}
                component="SubCategory"
                clearSelectedItem={clearSelectedItem.SubCategory}
              />
            )}
            {enableProductCoreSearch && filterType === "ProductCore" && (
              <ItemSearch
                onItemSelected={onProductCoreSelected}
                component="ProductCore"
                clearSelectedItem={clearSelectedItem.ProductCore}
              />
            )}
            {enableSKUSearch && filterType === "SKU" && (
              <ItemSearch
                onItemSelected={onSKUSelected}
                component="SKU"
                clearSelectedItem={clearSelectedItem.SKU}
              />
            )}
            {enablePatientSearch && filterType === "patient" && (
              <ItemSearch
                onItemSelected={onPatientSelected}
                component="Patient"
                clearSelectedItem={clearSelectedItem.patient}
              />
            )}
            {enableUserSearch && filterType === "user" && (
              <ItemSearch
                onItemSelected={onPatientSelected}
                component="FastballUser"
                clearSelectedItem={clearSelectedItem.user}
              />
            )}
            {enableDateSearch && filterType === "date" && (
              <CustomDatePicker
                onDateSelected={onDateSelected}
                legendLabel={configLabels.l1}
                availablePickerTypes={configLabels.options}
                clearSelectedItem={clearSelectedItem.StartDate}
              />
            )}
            {enableMultiDateSearch && filterType === "multi-date" && (
              <>
                <CustomDatePicker
                  onDateSelected={onDateSelected}
                  legendLabel={configLabels.l1}
                  availablePickerTypes={configLabels.options}
                  clearSelectedItem={clearSelectedItem.StartDate}
                />
                <CustomDatePicker
                  onDateSelected={onDateSelected}
                  legendLabel={configLabels.l2}
                  availablePickerTypes={configLabels.options}
                  clearSelectedItem={clearSelectedItem.EndDate}
                />
              </>
            )}
            {enableGenericSearch && filterType === "generic" && (
              <GenericSearch onGenericSelected={onGenericSelected} />
            )}
            {enableLocationSearch && filterType === "location" && (
              <Suspense fallback={<CenteredProgress />}>
                <LocationSelect
                  onLocationChange={onLocationChange}
                  initialLocation={initialLocation}
                />
              </Suspense>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default FilterRow;
