import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { formatDuration, formatSkuType } from "../utilities";

const SkuAndDurationForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const durationOptions = ["FortNight", "Month", "Quarter", "HalfYear", "Year"];
  const skuTypeOptions = ["SubscriptionOnly", "DeviceAndSubscription"];

  return (
    <>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="sku-type-label">SKU Type</InputLabel>
          <Select
            {...register("skuType", {
              required: "SKU Type is required",
            })}
            labelId="sku-type-label"
            label="SKU Type"
            defaultValue=""
          >
            {skuTypeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {formatSkuType(option)}
              </MenuItem>
            ))}
          </Select>
          {errors.skuType && (
            <Typography color="error">{errors.skuType.message}</Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="duration-type-label">Duration Type</InputLabel>
          <Select
            {...register("duration", {
              required: "Duration Type is required",
            })}
            labelId="duration-type-label"
            label="Duration Type"
            defaultValue=""
          >
            {durationOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {formatDuration(option)}
              </MenuItem>
            ))}
          </Select>
          {errors.durationType && (
            <Typography color="error">{errors.durationType.message}</Typography>
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default SkuAndDurationForm;
