import React, { useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import { Box, useTheme, Divider } from "@mui/material";
import { SidebarHeader } from "../../components/common/SidebarHeader.tsx";
import { SidebarFooter } from "../../components/common/SidebarFooter.tsx";
import CodeIcon from "@mui/icons-material/Code";
import InputIcon from "@mui/icons-material/Input";
import WheelchairPickupIcon from "@mui/icons-material/WheelchairPickup";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  AssessmentOutlined,
  PeopleAltOutlined,
  PersonAdd,
} from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AirlineSeatFlatAngledIcon from "@mui/icons-material/AirlineSeatFlatAngled";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRightOutlined";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeartOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import ListIcon from "@mui/icons-material/ListOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCartOutlined";
import ReceiptIcon from "@mui/icons-material/ReceiptOutlined";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLongOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoneyOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettingsOutlined";
import PersonRemoveIcon from "@mui/icons-material/PersonRemoveOutlined";
import ApprovalIcon from "@mui/icons-material/ApprovalOutlined";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import CategoryIcon from "@mui/icons-material/CategoryOutlined";
import Inventory2Icon from "@mui/icons-material/Inventory2Outlined";
import ClassIcon from "@mui/icons-material/ClassOutlined";
import { useNavigate } from "react-router-dom";
import packageJson from "../../../package.json";
import { useAuth } from "../../AuthProvider";

import { tokens } from "../../theme";

const Omnybar = () => {
  const muiTheme = useTheme();
  const colors = tokens(muiTheme.palette.mode);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { version } = packageJson;
  const { userDetails } = useAuth();
  const isAdmin = userDetails?.roles.some((role) => role === "Admin");
  const isHospitalAdmin = userDetails?.roles.some(
    (role) => role === "HospitalAdmin"
  );
  const isPatient = userDetails?.roles.some((role) => role === "Patient");

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
      color: colors.grey[100],
      backgroundColor: "transparent !important",
    },
    icon: {
      backgroundColor: "transparent !important", // From .menu-icon
      [`&.${menuClasses.disabled}`]: {
        color: colors.grey[100],
      },
    },
    SubMenuExpandIcon: {
      color: colors.greenAccent[400],
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: level === 0 ? colors.primary[400] : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: colors.grey[100],
      },
      "&:hover": {
        color: `${colors.greenAccent[400]} !important`,
        backgroundColor: "transparent !important",
      },
      "&.active": {
        color: `${colors.grey[100]} !important`,
        backgroundColor: "transparent !important",
      },
    },
    sidebar: {
      border: "none",
    },
    menuAnchor: {
      color: "inherit !important",
      backgroundColor: "transparent !important",
    },
  };

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        minHeight: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
      }}
    >
      <Sidebar
        breakPoint="md"
        collapsed={isCollapsed}
        onToggle={() => setIsCollapsed(!isCollapsed)}
        onBackdropClick={() => setIsCollapsed(!isCollapsed)}
        backgroundColor={colors.primary[400]}
        rootStyles={{
          height: "100vh",
          border: "none",
          padding: 0,
        }}
      >
        <main>
          <Menu menuItemStyles={menuItemStyles}>
            {isCollapsed ? (
              <MenuItem
                icon={<ChevronRightIcon />}
                onClick={() => setIsCollapsed(!isCollapsed)}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<ChevronLeftIcon />}
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <div
                  style={{
                    padding: "9px",
                    // textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: 14,
                    letterSpacing: "1px",
                    color: colors.greenAccent[400],
                  }}
                ></div>
              </MenuItem>
            )}
            <SidebarHeader style={{ marginBottom: "5px", marginTop: "16px" }} />
            <Divider />
            {(isAdmin || isHospitalAdmin) && (
              <MenuItem onClick={() => handleMenuItemClick("/adminDash")}>
                <AdminPanelSettingsIcon />
                <span>Admin Dashboard</span>
              </MenuItem>
            )}
            {isPatient && (
              <MenuItem onClick={() => handleMenuItemClick("/my-dashboard")}>
                <DashboardIcon />
                <span>Dashboard</span>
              </MenuItem>
            )}
            {(isAdmin || isHospitalAdmin) && (
              <>
                <SubMenu label={"Devices"} icon={<DevicesOtherIcon />}>
                  <MenuItem onClick={() => handleMenuItemClick("/adddevice")}>
                    <ControlPointDuplicateIcon /> <span>Add Device</span>
                  </MenuItem>

                  <MenuItem onClick={() => handleMenuItemClick("/deviceinfo")}>
                    <VisibilityIcon /> <span>Device Details</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/devicelist")}>
                    <DeviceHubIcon /> <span>Device List</span>
                  </MenuItem>
                  <SubMenu label={"Requests"} icon={<ApprovalIcon />}>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/devicereturnrequest")
                      }
                    >
                      <KeyboardReturnIcon /> <span>Device Returns</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/device-return-details")
                      }
                    >
                      <KeyboardReturnIcon /> <span>Device Return Details</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuItemClick("/devicerequest")}
                    >
                      <InputIcon /> <span>Device Requests</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/device-request-details")
                      }
                    >
                      <InputIcon /> <span>Device Request Details</span>
                    </MenuItem>
                  </SubMenu>
                </SubMenu>
                <SubMenu
                  defaultOpen
                  label={"Tenants"}
                  icon={<LocalHospitalIcon />}
                >
                  <MenuItem onClick={() => handleMenuItemClick("/addtenant")}>
                    <MedicalServicesIcon /> <span>Add tenant</span>
                  </MenuItem>

                  <MenuItem onClick={() => handleMenuItemClick("/tenantinfo")}>
                    <MedicalInformationIcon /> <span>Tenant Profile</span>
                  </MenuItem>

                  <MenuItem onClick={() => handleMenuItemClick("/tenantlist")}>
                    <ListIcon /> <span>Tenant List</span>
                  </MenuItem>
                  {userDetails?.roles.some((role) =>
                    ["Admin", "HospitalAdmin"].includes(role)
                  ) ? (
                    <>
                      <MenuItem
                        onClick={() =>
                          handleMenuItemClick("/tenant-patient-list")
                        }
                      >
                        <AirlineSeatFlatAngledIcon />{" "}
                        <span>Tenant Patients</span>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleMenuItemClick("/tenant-device-list")
                        }
                      >
                        <DevicesOtherIcon /> <span>Tenant Devices</span>
                      </MenuItem>
                    </>
                  ) : null}
                </SubMenu>
                <SubMenu
                  defaultOpen
                  label={"Patients"}
                  icon={<AirlineSeatFlatAngledIcon />}
                >
                  <MenuItem
                    onClick={() => handleMenuItemClick("/patientprofile")}
                  >
                    <ManageAccountsIcon /> <span>Patient Profile</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/reports")}>
                    <AssessmentOutlined /> <span>Patient Reports</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/addpatient")}>
                    <WheelchairPickupIcon /> <span>Add Patient</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/patientlist")}>
                    <PersonSearchIcon /> <span>Patient List</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("/anonymouslist")}
                  >
                    <PersonRemoveIcon sx={{ color: colors.redAccent[400] }} />{" "}
                    <span>Deleted Patients</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("/patientmonitor")}
                  >
                    <MonitorHeartIcon /> <span>Active Patients</span>
                  </MenuItem>
                </SubMenu>
              </>
            )}
            {isAdmin && (
              <>
                <SubMenu label={"Products"} icon={<InventoryIcon />}>
                  <MenuItem onClick={() => handleMenuItemClick("/add-Product")}>
                    <AddCircleOutlineIcon /> <span>Product</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("/Product-details")}
                  >
                    <VisibilityIcon /> <span>Product Details</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/add-Sku")}>
                    <AddShoppingCartIcon /> <span>Add SKU</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/sku-details")}>
                    <VisibilityIcon /> <span>Sku Details</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/productlist")}>
                    <ListIcon /> <span>Product List</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/sku-list")}>
                    <FormatListBulletedIcon /> <span>SKU List</span>
                  </MenuItem>
                </SubMenu>
                <SubMenu label={"Invoices"} icon={<ReceiptLongIcon />}>
                  <MenuItem onClick={() => handleMenuItemClick("/addinvoice")}>
                    <ReceiptIcon /> <span>Add Invoice</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("/invoice-details")}
                  >
                    <AttachMoneyIcon /> <span>Invoice Details</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/invoicelist")}>
                    <ListIcon /> <span>Invoice List</span>
                  </MenuItem>
                  <SubMenu label={"Consumer"} icon={<PeopleAltOutlined />}>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/consumer-invoicelist")
                      }
                    >
                      <ListIcon sx={{ color: colors.pastelColor.purple }} />{" "}
                      <span>Invoice List</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/consumer-invoice-details")
                      }
                    >
                      <AttachMoneyIcon
                        sx={{ color: colors.pastelColor.purple }}
                      />{" "}
                      <span>Invoice Details</span>
                    </MenuItem>
                  </SubMenu>
                </SubMenu>
                <SubMenu label={"System"} icon={<SettingsIcon />}>
                  <MenuItem
                    onClick={() => handleMenuItemClick("/addtenantadmin")}
                  >
                    <AddModeratorIcon />
                    <span>Add Tenant Admin</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/adduser")}>
                    <PersonAdd />
                    <span>Add User</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/userlist")}>
                    <PersonSearchIcon />
                    <span>User List</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/userprofile")}>
                    <ManageAccountsIcon />
                    <span>User Profile</span>
                  </MenuItem>
                  <SubMenu label={"Product Core"} icon={<ApprovalIcon />}>
                    <MenuItem
                      onClick={() => handleMenuItemClick("/category-details")}
                    >
                      <CategoryIcon /> <span>Category Details</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/SubCategory-details")
                      }
                    >
                      <ClassIcon /> <span>SubCategory Details</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/productcore-details")
                      }
                    >
                      <Inventory2Icon /> <span>Product Core Details</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuItemClick("/add-category")}
                    >
                      <AddCircleOutlineIcon /> <span>Category</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuItemClick("/add-subcategory")}
                    >
                      <AddCircleOutlineIcon /> <span>SubCategory</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuItemClick("/add-productcore")}
                    >
                      <AddCircleOutlineIcon /> <span>ProductCore</span>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick("/core-list")}>
                      <ListIcon /> <span>Core List</span>
                    </MenuItem>
                  </SubMenu>
                  <MenuItem
                    onClick={() => handleMenuItemClick("/app-settings")}
                  >
                    <DisplaySettingsIcon />
                    <span>App Settings</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/firmware")}>
                    <CodeIcon />
                    <span>Avida Firmware</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/info")}>
                    <InfoIcon />
                    <span>{`${version}`}</span>
                  </MenuItem>
                </SubMenu>
              </>
            )}
          </Menu>
        </main>
        <Box mt={3}>
          <SidebarFooter collapsed={isCollapsed} />
        </Box>
      </Sidebar>
    </Box>
  );
};

export default Omnybar;
