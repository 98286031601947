import { post, API_URL } from "../../services/api";
import { Container, Grid, Button, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import isEmail from "validator/lib/isEmail";
import React, { useState } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForwardOutlined";
import ItemSearch from "../search/itemSearch";
import { toast } from "react-toastify";

const AllocatePatient = ({
  selectedDevice,
  setAssignPatientMode,
  setSearchTerm,
  onDeviceUpdateSuccess,
  tenantId,
}) => {
  const [patientEmail, setPatientEmail] = useState("");
  const [patientUID, setPatientUID] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  const handlePatientSelected = (patient) => {
    setPatientEmail(patient.email);
    setPatientUID(patient.uid);
  };

  const handleAssignPatient = async () => {
    if (!patientEmail.trim()) {
      toast.info("Email Address cannot be empty!");
      return;
    }
    if (!isEmail(patientEmail)) {
      toast.error("Invalid Email Address!");
      return;
    }

    if (!selectedDevice) {
      toast.info("Please select a device!");
      return;
    }
    setIsLoading(true);
    try {
      const requestId = uuidv4();
      const sendData = {
        requestId,
        data: {
          patientId: patientUID,
          tenantId: selectedDevice.currentTenantUid,
          deviceID: selectedDevice.uid,
          allocatedOn: new Date().toISOString(),
        },
      };
      const response = await post(
        `${API_URL}/v2/Patient/RingAllocation`,
        sendData,
        true
      );
      if (response.statusCode === "OK") {
        const allMessages = response.messages.join("\n");
        toast.success(allMessages);
        setPatientEmail("");
        setSearchTerm("");
        const updateWasSuccessful = true;
        onDeviceUpdateSuccess(updateWasSuccessful);
        setAssignPatientMode(false);
      } else {
        toast.error("Error Assigning to patient.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        // If it's a 400 error and has error messages, display the first error message
        const allErrors = error.response.data.errors.join("\n");
        toast.error(allErrors);
      } else {
        // For other errors
        toast.error("Error Assigning to patient.!!!!");
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Container>
      <div>
        <Box my={2}>
          <ItemSearch
            onItemSelected={handlePatientSelected}
            filter={{tenantId: tenantId}}
            component="Patient"
          />
        </Box>
        {isLoading && <CenteredProgress />}
        {/* Assign Device to patient button */}
        <Grid container justifyContent="center" style={{ marginTop: "16px" }}>
          <Grid item xs={12} md={6}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  marginRight: "8px",
                }}
                onClick={handleAssignPatient}
                disabled={!isEmail(patientEmail) || isLoading}
              >
                <AccessibleForwardIcon sx={{ mr: "10px" }} />
                Assign Patient
              </Button>
              <Button
                onClick={() => {
                  setAssignPatientMode(false);
                  setPatientEmail("");
                }}
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default AllocatePatient;
