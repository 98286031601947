import React from "react";
import { Typography, Box } from "@mui/material";
import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery20Icon from "@mui/icons-material/Battery20";
import Battery30Icon from "@mui/icons-material/Battery30";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery60Icon from "@mui/icons-material/Battery60";
import Battery80Icon from "@mui/icons-material/Battery80";
import Battery90Icon from "@mui/icons-material/Battery90";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryAlertOutlinedIcon from "@mui/icons-material/BatteryAlertOutlined";

const selectBatteryIconAndColor = (batteryLevel) => {
  let Icon;
  let color;

  if (batteryLevel < 10) {
    Icon = Battery0BarIcon;
    color = "red";
  } else if (batteryLevel < 20) {
    Icon = Battery20Icon;
    color = "red";
  } else if (batteryLevel < 30) {
    Icon = Battery20Icon;
    color = "orange";
  } else if (batteryLevel < 50) {
    Icon = Battery30Icon;
    color = "orange";
  } else if (batteryLevel < 60) {
    Icon = Battery50Icon;
    color = "orange";
  } else if (batteryLevel < 80) {
    Icon = Battery60Icon;
    color = "green";
  } else if (batteryLevel < 90) {
    Icon = Battery80Icon;
    color = "green";
  } else if (batteryLevel < 100) {
    Icon = Battery90Icon;
    color = "green";
  } else if (batteryLevel === "_ _") {
    Icon = BatteryAlertOutlinedIcon;
    color = "yellow";
  } else {
    Icon = BatteryFullIcon;
    color = "green";
  }

  return { Icon, color };
};

const BatteryLevelDisplay = ({ batteryLevel }) => {
  const { Icon, color } = selectBatteryIconAndColor(batteryLevel);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="right">
        <Icon sx={{ color: color, marginRight: 1 }} />
        <Typography variant="body1" align="right">
          {batteryLevel}%
        </Typography>
      </Box>
      {batteryLevel < 10 && (
        <Typography variant="body1" align="right" sx={{ color: "red", marginTop: 1 }}>
          Request patient to recharge Avida.
        </Typography>
      )}
    </Box>
  );
};

export default BatteryLevelDisplay;
