import React, { useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { toast } from "react-toastify";
import { deleteRequest, API_URL } from "../../services/api";
import { v4 as uuidv4 } from "uuid";

const API_BASE_URL = `${API_URL}/v2/Device`;

const DeleteDevice = ({
  selectedDevice,
  openDeleteDialog,
  setOpenDeleteDialog,
  setIsLoading,
  onDeviceDeleted,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLocalLoading] = useState(false);

  const deleteDevice = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const requestId = uuidv4();
      const response = await deleteRequest(
        `${API_BASE_URL}/Delete`,
        { requestId, data: id },
        true
      );
      if (response.statusCode === "OK") {
        toast.success("Device deleted successfully.");
        return response.data;
      } else {
        const allErrors = response.errors.join("\n");
        toast.error(allErrors);
      }
    } catch (error) {
      const allErrors = error.response?.data?.errors.join("\n");
      toast.error(allErrors);
    } finally {
      setIsLoading(false);
    }
  });

  const handleDeviceDelete = async () => {
    setIsLocalLoading(true);
    const success = await deleteDevice(selectedDevice.uid);
    if (success) {
      onDeviceDeleted();
    }
    setIsLocalLoading(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      {/* The Dialog JSX */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: colors.primary[400],
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: colors.greenAccent[400], typography: "h5" }}
        >
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this device? This action cannot be
            undone.
          </DialogContentText>
          {isLoading && <CenteredProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            disabled={isLoading}
            onClick={handleDeviceDelete}
          >
            <DeleteOutlineIcon
              sx={{ mr: "10px", color: colors.pastelColor.red }}
            />
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDevice;
