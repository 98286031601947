import React, { useState, useEffect } from "react";
import { Container, Box } from "@mui/material";
import { useFetchUserInfo } from "../../components/user/useFetchUserInfo";
import { useTimedMessage } from "../../components/forms/utilities";
import UserDetails from "./UserDetails";
import EditUser from "../../components/user/editUser";
import ActionToolbar from "../../components/common/ActionToolbar";
import ChangePassword from "../../components/user/changePassword";
import { getLocalStorageItem } from "../../components/user/storage.js";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";

const MyProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [changePasswordMode, setChangePasswordMode] = useState(false);
  const [status] = useState({
    edit: { disabled: false, tooltip: "Edit your profile" },
    passwordReset: { disabled: false, tooltip: "Change your password" },
  });

  const { fetchUserDetails } = useFetchUserInfo(
    setResponseMessage,
    setIsSuccess,
    setIsLoading,
    setSelectedUser
  );

  const handleGetUserProfile = async (updated = false) => {
    const storedUserDetails = getLocalStorageItem("userDetails");
    if (storedUserDetails && !updated) {
      setSelectedUser(storedUserDetails);
    } else {
      setSelectedUser(await fetchUserDetails(null, true));
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleChangePassword = () => {
    setEditMode(false);
    setChangePasswordMode(true);
  };

  const handleChangePasswordSuccess = (successMessage) => {
    setChangePasswordMode(false);
    setResponseMessage(successMessage);
    setIsSuccess(true);
  };

  const handleUserUpdateSuccess = (success) => {
    handleGetUserProfile(true);
  };
  useEffect(() => {
    handleGetUserProfile();
  }, []);

  useEffect(() => {}, [selectedUser]);

  useEffect(() => {
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }
  }, [responseMessage, isSuccess]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header
            title={`Welcome ${selectedUser?.firstName}!`}
            subtitle="Review your profile."
          />
        </Box>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
            padding: "15px",
          }}
        >
          {!isloading && selectedUser && !editMode && !changePasswordMode && (
            <>
              <ActionToolbar
                status={status}
                onEdit={handleEdit}
                onPasswordReset={handleChangePassword}
              />
              <UserDetails selectedUser={selectedUser} />
            </>
          )}
          {editMode && selectedUser && (
            <EditUser
              selectedUser={selectedUser}
              setResponseMessage={setResponseMessage}
              setIsSuccess={setIsSuccess}
              setEditMode={setEditMode}
              onUserUpdateSuccess={handleUserUpdateSuccess}
            />
          )}
          {selectedUser && changePasswordMode && !editMode && (
            <ChangePassword
              onUpdateSuccess={handleChangePasswordSuccess}
              setChangePasswordMode={setChangePasswordMode}
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default MyProfile;
