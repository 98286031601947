import isEmail from "validator/lib/isEmail";
import normalizeEmail from "validator/lib/normalizeEmail";
import isPostalCode from "validator/lib/isPostalCode";
import { isAlphanumeric } from "validator";
import { useState, useEffect, useRef } from "react";
import { PhoneNumberUtil } from "google-libphonenumber";
import { parseISO, addMinutes, isPast } from "date-fns";
import { getLocalStorageItem } from "../auth/storage.js";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const phoneUtil = PhoneNumberUtil.getInstance();

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatSkuType = (skuType) => {
  switch (skuType) {
    case 'DeviceAndSubscription':
      return 'Device and Subscription';
    case 'SubscriptionOnly':
      return 'Subscription Only';
    default:
      return skuType;  // Return the original if no specific case matches
  }
};

export function pascalCaseToSpace(str) {
  // This regex inserts a space before each uppercase letter that is preceded by a lowercase letter.
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function underscoreToSpace(str) {
  // This replaces all underscores with spaces
  return str.replace(/_/g, ' ');
}

export const formatDuration = (duration) => {
  switch (duration) {
    case 'FortNight':
      return 'FortNight (15-Days)';
    case 'Month':
      return 'One Month';
    case 'Quarter':
      return 'Quarter (3-Months)';
    case 'HalfYear':
      return 'Half-Year (6-Months)';
    case 'Year':
      return 'Year';
    default:
      return duration;  // Return the original if no specific case matches
  }
};


export function formatDateTime(dateTimeStr) {
  if (
    dateTimeStr === null ||
    dateTimeStr === undefined ||
    dateTimeStr.startsWith("0001-01-01")
  ) {
    return <HighlightOffOutlinedIcon />;
  } else {
    const utcDateStr = dateTimeStr.endsWith("Z")
      ? dateTimeStr
      : `${dateTimeStr}Z`;

    const date = new Date(utcDateStr);
    const formattedDate = date
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    // Combine the date and time strings
    return `${formattedDate} ${formattedTime}`;
  }
}

export function formatTZDateTime(dateTimeStr) {
  if (!dateTimeStr || dateTimeStr.startsWith("0001-01-01")) {
    return <HighlightOffOutlinedIcon />;
  }

  try {
    const date = new Date(dateTimeStr);
    // Check if the date object is valid
    if (isNaN(date.getTime())) {
      // getTime() is used to check for Invalid Date
      throw new Error("Invalid date");
    }

    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");

    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    return `${formattedDate} ${formattedTime}`;
  } catch (e) {
    console.error("Error formatting date:", e);
    return <HighlightOffOutlinedIcon />;
  }
}

export function daysSince(dateTimeStr) {
  if (
    dateTimeStr === null ||
    dateTimeStr === undefined ||
    dateTimeStr.startsWith("0001-01-01")
  ) {
    // If the date is not valid, return an indicator or message
    return <HighlightOffOutlinedIcon />;
  } else {
    const utcDateStr = dateTimeStr.endsWith("Z")
      ? dateTimeStr
      : `${dateTimeStr}Z`;
    const date = new Date(utcDateStr);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert from milliseconds to days
    return `${daysDiff} Days`;
  }
}

export function formatDate(dateTimeStr) {
  if (
    dateTimeStr === null ||
    dateTimeStr === undefined ||
    dateTimeStr.startsWith("0001-01-01")
  ) {
    return <HighlightOffOutlinedIcon />;
  } else {
    const utcDateStr = dateTimeStr;
    const date = new Date(utcDateStr);
    const formattedDate = date
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");

    return `${formattedDate}`;
  }
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const emailoptions = {
  allow_display_name: false, // Allows an email with a display name
  require_display_name: false, // Does not require a display name
  allow_utf8_local_part: false, // Disallows non-English UTF8 characters in the local part
  require_tld: false, // Requires a Top-Level Domain in the email
  allow_ip_domain: false, // Disallows IP addresses as the domain part
  allow_underscores: false, // Disallows underscores in the domain part
  domain_specific_validation: true, // Enables domain-specific validations
  blacklisted_chars: "~!#$%^&*=", // Blacklists specific char
  host_blacklist: ["example.com"], // Blacklists 'example.com' as a host
  ignore_max_length: false, // Enforces the standard max length of an email
  host_whitelist: [], // No specific whitelist
};

const normalizeOptions = {
  all_lowercase: true,
  gmail_lowercase: true,
  gmail_remove_dots: true,
  gmail_remove_subaddress: false,
  gmail_convert_googlemaildotcom: true,
  outlookdotcom_lowercase: true,
  outlookdotcom_remove_subaddress: true,
  yahoo_lowercase: true,
  yahoo_remove_subaddress: true,
  icloud_lowercase: true,
  icloud_remove_subaddress: true,
};

export const isEmailValid = (email) => {
  return isEmail(email, emailoptions);
};

export const checkTokenExpiration = () => {
  const createdTimeString = getLocalStorageItem("createdTime");
  const expiresInMinutes = parseInt(getLocalStorageItem("expiresIn"), 10);

  if (createdTimeString && !isNaN(expiresInMinutes)) {
    // Parse the ISO date string to a Date object
    const createdTime = parseISO(createdTimeString);
    // Calculate the expiration time by adding expiresIn minutes plus a 5-minute buffer
    const expirationTime = addMinutes(createdTime, expiresInMinutes - 5);
    // Check if the current time is past the expiration time
    return isPast(expirationTime);
  }

  return true;
};

export const validateUserName = (value) => {
  // Regular expression for validating a regular username (adjust as needed)
  const usernameRegex = /^[a-zA-Z0-9._-]+$/;

  if (isEmail(value) || usernameRegex.test(value)) {
    return true;
  } else {
    return "Please enter a valid email address or username";
  }
};

export const isPhoneValid = (phone) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(number);
  } catch (error) {
    return false;
  }
};

export const sanitizeEditData = (data) => {
  return Object.keys(data).reduce((newData, key) => {
    newData[key] = data[key] === null ? "" : data[key];
    return newData;
  }, {});
};

export const validateMacAddress = (macAddress) => {
  const regex = /^([0-9A-Fa-f]{2}:){5}([0-9A-Fa-f]{2})$/;
  return regex.test(macAddress);
};

export const validateSerialNumber = (serial) => {
  const regex = /^OCFA\d{5}$/;
  return regex.test(serial);
};

export const handleSerialInputChange = (e) => {
  const value = e.target.value;
  if (!value.startsWith("OCFA")) {
    e.target.value = "OCFA" + value.replace(/[^\d]/g, ""); // Only allow digits after OCFA
  }
};

export const handleMacInputChange = (e) => {
  let value = e.target.value.toUpperCase();
  value = value.replace(/[^0-9A-F]/gi, ""); // Only allow hex characters
  if (value.length > 2) {
    const parts = [];
    for (let i = 0; i < value.length; i += 2) {
      parts.push(value.substring(i, i + 2));
    }
    value = parts.join(":");
  }
  e.target.value = value;
};

export const validateTenantCode = (code) => {
  // Check if the code meets the length requirements
  if (code.length < 5 || code.length > 7) {
    return "Tenant code must be between 5 and 7 characters long";
  }

  // Check if the code starts with an alphabet
  if (!/^[A-Z]/.test(code)) {
    return "Tenant code must start with a capitalized alphabet";
  }

  // Check if all alphabets are capitalized
  if (/[a-z]/.test(code)) {
    return "All alphabets in tenant code must be capitalized";
  }

  // Check if the code contains at least one alphabet and one number
  if (!/[A-Z].*\d|\d.*[A-Z]/.test(code)) {
    return "Tenant code must contain at least one alphabet and one number";
  }

  // Use the validator's isAlphanumeric to ensure the code contains only letters and numbers
  if (!isAlphanumeric(code, "en-US")) {
    return "Tenant code must be alphanumeric";
  }

  // If all checks pass, return null indicating no errors
  return null;
};

export const validateAndNormalizeEmail = (email) => {
  if (!isEmail(email, emailoptions)) {
    return {
      isValid: false,
      normalizedEmail: "",
      errorMessage: "Email is invalid",
    };
  }

  const normalizedEmail = normalizeEmail(email, normalizeOptions);
  return {
    isValid: true,
    normalizedEmail,
    errorMessage: null,
  };
};

export const capitalizeWords = (str) => {
  return str
    .trim()
    .replace(/\s+/g, " ")
    .replace(/(^|\s|[-.])[a-z]/gi, (match) => match.toUpperCase());
};

export const validateName = (value) => {
  const noContinuousSpaces = !/\s{2,}/.test(value);
  const noContinuousSpecialChars = !/([-'.]){2,}/.test(value);
  const allowedCharacters = /^[A-Za-z\s.'-]+$/;

  if (!noContinuousSpaces) {
    return "Continuous spaces are not allowed";
  }
  if (!noContinuousSpecialChars) {
    return "Continuous special characters are not allowed";
  }
  if (!allowedCharacters.test(value)) {
    return "Invalid characters used";
  }
  return true;
};

export const validateTenantName = (value) => {
  // Temporarily replace ", " with a placeholder to bypass the continuous special character check.
  const placeholder = value.replace(/, /g, "PLACEHOLDER");

  const noContinuousSpaces = !/\s{2,}/.test(placeholder);
  // Adjust regex to ignore the placeholder and focus on other special characters
  const noContinuousSpecialChars = !/([-'.]){2,}/.test(placeholder);
  // Include comma in allowed characters, considering the original input
  const allowedCharacters = /^[A-Za-z\s.',-]+$/;

  if (!noContinuousSpaces) {
    return "Continuous spaces are not allowed";
  }
  if (!noContinuousSpecialChars) {
    return "Continuous special characters are not allowed";
  }
  if (!allowedCharacters.test(value)) {
    // Ensure to check against the original input here
    return "Invalid characters used";
  }
  return true;
};

export const validateAddress = (value) => {
  // Trim the value to remove leading and trailing spaces
  const trimmedValue = value.trim();

  // Replace multiple continuous spaces with a single space
  const singleSpacedValue = trimmedValue.replace(/\s+/g, " ");

  // Check if the address is empty after trimming
  if (!singleSpacedValue) {
    return "Address is required";
  }

  // Allowable characters in address: letters, numbers, spaces, and certain special characters
  const addressPattern = /^[A-Za-z0-9\s,'.#/-]+$/;
  if (!addressPattern.test(singleSpacedValue)) {
    return "Address contains invalid characters";
  }

  // Check for continuous use of special characters
  const continuousSpecialChars = /([,'.#/-])\1+/;
  if (continuousSpecialChars.test(singleSpacedValue)) {
    return "Continuous special characters are not allowed";
  }

  // Check for length if needed
  if (singleSpacedValue.length < 12 || singleSpacedValue.length > 150) {
    return "Address length must be between 12 and 150 characters";
  }

  return true;
};

export const validateZipCode = (value, locale = "US") => {
  if (!isPostalCode(value, locale)) {
    return `Invalid ZIP code for ${locale}`;
  }
  return true;
};

export const useTimedMessage = (timeout = 8000) => {
  const [message, setMessage] = useState("");
  const timerRef = useRef(null); // Create a ref to store the current timer

  const showMessage = (newMessage) => {
    clearTimeout(timerRef.current); // Clear any existing timer
    setMessage(newMessage);
    // Set a new timer
    timerRef.current = setTimeout(() => setMessage(""), timeout);
  };

  useEffect(() => {
    // Cleanup function to clear the timer when the component unmounts or the message changes
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return [message, showMessage];
};
