import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  ListItem,
  Typography,
  Popover,
} from "@mui/material";
import ManageHistory from "@mui/icons-material/ManageHistory";
import FormatAlignCenter from "@mui/icons-material/FormatAlignCenter";
import SupervisorAccountTwoTone from "@mui/icons-material/SupervisorAccountTwoTone";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useFetchUserDetailsQuick } from "../../components/user/useFetchUserInfo";
import { formatDateTime } from "../forms/utilities";

export function formatEndDate(endDate, info) {
  if (!endDate || endDate.startsWith("0001-01-01")) {
    return info;
  }
  return formatDateTime(endDate);
}

function DetailsFooter({
  createdOn,
  lastActionOn,
  lastActionBy,
  customColor,
  dividerColor,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState({
    username: "Loading...",
    email: "Loading...",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-info-popover" : undefined;

  const { data } =
    useFetchUserDetailsQuick(lastActionBy);

  useEffect(() => {
    if (data && data.length > 0) {
      setUserData(data[0]);
    }
  }, [data]);

  return (
    <Grid item xs={12}>
      <Box sx={{ width: "100%" }}>
        <Divider variant="middle" sx={{ borderColor: dividerColor }} />
        <ListItem>
          <Box sx={{ ml: "auto", textAlign: "right" }}>
            <ManageHistory
              fontSize="medium"
              style={{ verticalAlign: "bottom", color: customColor }}
            />
            <Typography variant="subtitle1" display="inline" sx={{ ml: 1 }}>
              Created: {formatEndDate(createdOn, "No created on")}
            </Typography>
          </Box>
        </ListItem>
        <Divider variant="middle" sx={{ borderColor: dividerColor }} />
        <ListItem>
          <Box sx={{ ml: "auto", textAlign: "right" }}>
            <FormatAlignCenter
              fontSize="medium"
              style={{ verticalAlign: "bottom", color: customColor }}
            />
            <Typography variant="subtitle1" display="inline" sx={{ ml: 1 }}>
              Last update on: {formatEndDate(lastActionOn, "No updates yet!")}
            </Typography>
          </Box>
        </ListItem>
        <Divider variant="middle" sx={{ borderColor: dividerColor }} />
        <ListItem>
          <Box
            sx={{ ml: "auto", textAlign: "right" }}
            aria-describedby={id}
            onMouseEnter={handleClick}
            onMouseLeave={handleClose}
          >
            <SupervisorAccountTwoTone
              fontSize="medium"
              style={{ verticalAlign: "bottom" }}
            />
            <Typography variant="subtitle1" display="inline" sx={{ ml: 1 }}>
              Last Update By: {lastActionBy}
            </Typography>
          </Box>
          {data && (
            <Popover
              id={id}
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handleClose}
              disableRestoreFocus
            >
              <Box sx={{ p: 2, background: colors.greenAccent[800] }}>
                <Typography>
                  Name: {userData?.firstName} {userData?.lastName}
                </Typography>
                <Typography>Email: {userData?.email.toLowerCase()}</Typography>
                <Typography>Roles: {userData?.roles?.join(", ")}</Typography>
              </Box>
            </Popover>
          )}
        </ListItem>
      </Box>
    </Grid>
  );
}

export default DetailsFooter;
