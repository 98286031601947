import { Grid, TextField, InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { teal } from '@mui/material/colors';

const PriceField = ({ currencySymbol }) => {
  const { register, setValue, watch, formState: { errors } } = useFormContext();
  const priceValue = watch("price"); // This function will watch the specific field and return its value

  const handleBlur = () => {
    // Ensure the value is only formatted when the user leaves the field
    const value = parseFloat(priceValue);
    if (!isNaN(value)) {
      setValue("price", value.toFixed(2), { shouldValidate: true });
    }
  };

  return (
    <Grid item xs={3}>
      <TextField
        {...register("price", {
          required: "Price is required",
          validate: {
            min: value =>
              parseFloat(value) >= 9 ||
              "Value must be at least 9",
            max: value =>
              parseFloat(value) <= 999999 ||
              "Value must not exceed 999999",
          },
        })}
        type="number"
        label="Price"
        required
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ color: teal[500] }}>
              {currencySymbol}
            </InputAdornment>
          )
        }}
        inputProps={{
          step: "0.01"  // Allows entering decimals
        }}
        onChange={e => setValue("price", e.target.value)} // Manually handle input changes
        onBlur={handleBlur}  // Format on blur
        error={!!errors.price}
        helperText={errors.price?.message}
      />
    </Grid>
  );
};

export default PriceField;
