import React, { useState, useEffect } from "react";
import { Box, Container, IconButton } from "@mui/material";
import AddUser from "../../components/user/addUser";
import { useTimedMessage } from "../../components/forms/utilities";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const AddUserForm = () => {
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [isSuccess, setIsSuccess] = useState(true);
  const [newUserEmail, setNewUserEmail] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (newUserEmail) {
      toast.success("Added new user.");
      navigate("/userprofile", { state: { initialEmail: newUserEmail } });
    }
  }, [newUserEmail, navigate]);

  useEffect(() => {
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }
  }, [responseMessage, isSuccess]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Omnyk Users" subtitle="Add new user." />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="5px"
        >
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
        </Box>
        <Container
          maxWidth="md"
          sx={{
            backgroundColor: colors.primary[400],
            border: `1px solid ${colors.greenAccent[400]}`,
            padding: "15px",
          }}
        >
          <AddUser
            setResponseMessage={setResponseMessage}
            setIsSuccess={setIsSuccess}
            setNewUserEmail={setNewUserEmail}
          />
        </Container>
      </Box>
    </Box>
  );
};
export default AddUserForm;
