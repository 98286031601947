import { useState, useCallback } from 'react';

const useClearSelectedItem = () => {
  const [clearSelectedItem, setClearSelectedItem] = useState({
    Product: false,
    Category: false,
    SubCategory: false,
    ProductCore: false,
    SKU: false,
    patient: false,
    user: false,
    tenant: false,
    StartDate: false,
    EndDate: false,
  });

  const triggerClearItem = useCallback((type) => {
    setClearSelectedItem((prev) => ({
      ...prev,
      [type]: true,
    }));
    setTimeout(() => {
      setClearSelectedItem((prev) => ({
        ...prev,
        [type]: false,
      }));
    }, 0); // Setting to false immediately after ensures it can be triggered again
  }, []);

  return { clearSelectedItem, triggerClearItem };
};

export default useClearSelectedItem;
