import { useState, useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { get, post, API_URL } from "../../services/api";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../AuthProvider";

const API_BASE_URL = `${API_URL}/v2/Patient`;
const API_RPM_URL = `${API_URL}/v2/RpmRequest`;
const API_FB_URL = `${API_URL}/v2/FastballUser`;
const API_REPORT_URL = `${API_URL}/v2/Report`;

export const useFetchSuggestedPatients = (
  searchTerm = "",
  tenantCode = null
) => {
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchSuggestedPatientsFull = async ({ queryKey }) => {
    const [_key, term] = queryKey;
    if (!term.trim()) return []; // Avoid fetching with empty or whitespace-only query
    const encodedTerm = encodeURIComponent(term);
    let url = `${API_BASE_URL}/list?Page=1&PerPage=20&filter=${encodedTerm}`;
    if (tenantCode) {
      url += `&tenantCode=${tenantCode}`;
    }
    try {
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data.items || [];
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching suggested patients"
        );
      }
    } catch (error) {
      // Log the error or handle it as needed
      throw error; // Rethrowing the error so React Query can catch it
    }
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["suggestedPatients", debouncedTerm],
    queryFn: fetchSuggestedPatientsFull,
    enabled: !!debouncedTerm,
  });

  return { data, isLoading, isError, error };
};

const fetchDataSummaryByEmail = async ({ queryKey }) => {
  const [_, options] = queryKey;
  const { fromDate, toDate, email, vital, agg, interval, summary, timezone } =
    options;
  const urlEncodedEmail = encodeURIComponent(email);
  const queryString = new URLSearchParams({
    fromDate,
    toDate,
    email,
    vital,
    agg,
    interval,
    summary: summary.toString(),
    timezone,
  }).toString();
  const response = await get(
    `${API_URL}/v2/Vitals/GetDataSummaryByEmail?${queryString}`,
    true
  );

  if (response.statusCode === "OK") {
    return response.data;
  } else {
    throw new Error(
      response.data.errors?.[0] || "Error fetching data summary by email"
    );
  }
};

export const useGetDataSummaryByEmail = (options, initialData) => {
  return useQuery({
    queryKey: ["dataSummaryByEmail", options],
    queryFn: fetchDataSummaryByEmail,
    enabled: !!options.email, // Only run the query if the email is truthy
    initialData: initialData,
    retry: false,
  });
};

export const useFetchSelfPatientDetails = (initialData, isDependent = true) => {
  const { updatePatientDetails } = useAuth();
  const url = `${API_BASE_URL}/GetCurrentPatient`;

  return useQuery({
    queryKey: ["patientSelfDetails", isDependent],
    queryFn: async () => {
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        updatePatientDetails(response.data);
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching self-profile details"
        );
      }
    },
    enabled: !initialData && !isDependent,
    initialData: initialData,
  });
};

export const useFetchPatientDetails = (uid, initialData) => {
  const url = `${API_BASE_URL}/GetByUid?id=${uid}`;

  return useQuery({
    queryKey: ["patientDetails", uid],
    queryFn: async () => {
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching profile details"
        );
      }
    },
    enabled: !!uid && !initialData, // Only run the query if the uid is truthy
    initialData: initialData,
  });
};

export const useFetchPatientReport = (uid, initialData) => {
  return useQuery({
    queryKey: ["patientDetails", uid],
    queryFn: async () => {
      if (!uid) {
        return {};
      }
      const response = await get(
        `${API_REPORT_URL}/GetReportByUUID/${uid}`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching report details"
        );
      }
    },
    enabled: !!uid && !initialData, // Only run the query if the uid is truthy
    initialData: initialData,
  });
};

export const useFetchLatestPatientReport = () => {
  return useQuery({
    queryKey: ["patientReportDetails"],
    queryFn: async () => {
      const response = await get(
        `${API_REPORT_URL}/GetLatestCurrentPatientReport`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching report details"
        );
      }
    },
    retry: false,
  });
};

export const useGetPatientRPMList = (email) => {
  return useQuery({
    queryKey: ["patientRPMList", email],
    queryFn: async () => {
      if (!email) return;
      const urlEncodedEmail = encodeURIComponent(email);
      const response = await get(
        `${API_RPM_URL}/GetPatientList?email=${urlEncodedEmail}`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching RPM Patient list details"
        );
      }
    },
    enabled: !!email, // Only run the query if the email is truthy
  });
};

export const useGetCareTakerList = (email) => {
  return useQuery({
    queryKey: ["careTakerList", email],
    queryFn: async () => {
      if (!email) return;
      const urlEncodedEmail = encodeURIComponent(email);
      const response = await get(
        `${API_RPM_URL}/GetCareTakerList?email=${urlEncodedEmail}`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching CareTaker list details"
        );
      }
    },
    enabled: !!email,
  });
};

export const useFetchPatientProfile = (email, initialData) => {
  return useQuery({
    queryKey: ["patientProfile", email],
    queryFn: async () => {
      if (!email) return;
      const urlEncodedEmail = encodeURIComponent(email);
      const response = await get(
        `${API_BASE_URL}/GetPatientProfile?email=${urlEncodedEmail}`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        const error = new Error(
          response.errors?.[0] || "Error fetching patient profile"
        );
        error.statusCode = response.statusCode;
        error.errors = response.errors;
        throw error;
      }
    },
    enabled: !!email && !initialData, // Only run the query if the uid is truthy
    initialData: initialData,
    retry: (failureCount, error) => {
      // Don't retry if statusCode is "BadRequest" and errors include "Patient not found"
      return !(
        error.statusCode === "BadRequest" &&
        error.errors?.includes("Patient not found")
      );
    },
  });
};

export const useFetchHealthProfile = (
  email = "",
  isAdmin = false,
  initialData = null
) => {
  let url = `${API_BASE_URL}/GetCurrentPatientProfile`;
  let urlEncodedEmail = "";
  if (email) {
    urlEncodedEmail = encodeURIComponent(email);
    if (isAdmin) {
      url = `${API_BASE_URL}/GetPatientProfile?email=${urlEncodedEmail}`;
    } else {
      url = `${API_BASE_URL}/GetCurrentRelatedProfile?email=${urlEncodedEmail}`;
    }
  }
  return useQuery({
    queryKey: ["patientSelfProfile", email],
    queryFn: async () => {
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching health profile"
        );
      }
    },
    enabled: !initialData,
    initialData: initialData,
    retry: false,
  });
};

export const useFetchPatientInfo = (
  setResponseMessage,
  setIsSuccess,
  setIsLoading,
  setSuggestedPatients,
  setPagination,
  setPatientRequests
) => {
  const handleResponse = useCallback(
    (response, successMessage, all = false) => {
      if (response.statusCode === "OK") {
        setIsSuccess(true);
        setResponseMessage(successMessage);
        if (all) {
          return response;
        } else return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "An error occurred while fetching data."
        );
      }
    },
    [setIsSuccess, setResponseMessage]
  );

  const handleError = useCallback(
    (error) => {
      setIsSuccess(false);
      setResponseMessage(
        error?.response?.data?.errors?.[0] ||
          error.message ||
          "Error occurred during the API call."
      );
    },
    [setIsSuccess, setResponseMessage]
  );

  const deletePatient = useCallback(
    async (email) => {
      setIsLoading(true);
      try {
        const requestId = uuidv4();
        const response = await post(
          `${API_FB_URL}/ObfuscateUserByEmail`,
          { requestId, data: email },
          true
        );
        return handleResponse(response, "Patient deleted successfully.");
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleResponse, handleError, setIsLoading]
  );

  const fetchSuggestedPatients = useCallback(
    async (searchTerm) => {
      setIsLoading(true);
      try {
        const encodedTerm = encodeURIComponent(searchTerm);
        const response = await get(
          `${API_BASE_URL}/AutoComplete?contains=${encodedTerm}&limit=10`
        );
        setSuggestedPatients(response || []);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, setIsLoading]
  );

  const fetchPatientDetails = useCallback(
    async (uid) => {
      setIsLoading(true);
      try {
        const response = await get(`${API_BASE_URL}/GetByUid?id=${uid}`, true);
        return handleResponse(
          response,
          "Patient profile retrieved successfully."
        );
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, setIsLoading]
  );

  const fetchPatientList = useCallback(
    async (page, PerPage, filtersArray = []) => {
      setIsLoading(true);
      const filtersObject = filtersArray.reduce((obj, filter) => {
        if (filter.value) {
          obj[filter.type] = filter.value;
        }
        return obj;
      }, {});

      const params = { page, PerPage, ...filtersObject };
      const queryString = Object.entries(params)
        .filter(([, value]) => value != null) // Exclude null or undefined values
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      try {
        const response = await get(`${API_BASE_URL}/list?${queryString}`, true);
        setPatientRequests(response.data.items);
        setPagination({
          totalPages: response.data.totalPages,
          totalCount: response.data.totalCount,
          recordsPerPage: response.data.recordsPerPage,
        });
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, setIsLoading, setPatientRequests, setPagination]
  );

  const fetchTenantPatientList = useCallback(
    async (page, tenant = null, filter = "", gender = "") => {
      setIsLoading(true);
      // Initialize the params with page and gender
      const params = new URLSearchParams({ page });

      // If tenant is provided, use tenantCode as a parameter, otherwise use filter
      if (tenant) {
        params.append("tenantCode", tenant);
      }
      if (filter) {
        params.append("filter", filter);
      }
      if (gender) {
        params.append("gender", gender);
      }

      const paramString = params.toString();

      try {
        const response = await get(
          `${API_URL}/v2/TenantPatient/list?${paramString}`,
          true
        );
        setPatientRequests(response.data.items);
        setPagination({
          totalPages: response.data.totalPages,
          totalCount: response.data.totalCount,
          recordsPerPage: response.data.recordsPerPage,
        });
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, setIsLoading, setPatientRequests, setPagination]
  );

  const fetchPatientProfile = useCallback(
    async (email) => {
      setIsLoading(true);
      try {
        const urlEncodedEmail = encodeURIComponent(email);
        const response = await get(
          `${API_BASE_URL}/GetPatientProfile?email=${urlEncodedEmail}`,
          true
        );
        return handleResponse(
          response,
          "Patient profile retrived successfully."
        );
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, setIsLoading]
  );

  return {
    deletePatient,
    fetchSuggestedPatients,
    fetchPatientDetails,
    fetchPatientList,
    fetchTenantPatientList,
    fetchPatientProfile,
  };
};
