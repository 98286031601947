import { useState, useCallback, useRef, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { get, API_URL } from "../../services/api";

const API_BASE_URL = `${API_URL}/v2/Device`;

export const useFetchSuggestedFirmware = (searchTerm = "") => {
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchSuggestedFirmwareFull = async ({ queryKey }) => {
    const [_key, term] = queryKey;
    if (!term.trim()) return []; // Avoid fetching with empty or whitespace-only query
    const encodedTerm = encodeURIComponent(term);
    try {
      const response = await get(
        `${API_URL}/v2/FirmwareList?Page=1&PerPage=20&filter=${encodedTerm}&active=true`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data.Items || [];
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching suggested firmware"
        );
      }
    } catch (error) {
      // Log the error or handle it as needed
      throw error; // Rethrowing the error so React Query can catch it
    }
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["suggestedFirmware", debouncedTerm],
    queryFn: fetchSuggestedFirmwareFull,
    enabled: !!debouncedTerm,
  });

  return { data, isLoading, isError, error };
};

export const useFetchDVRDetails = (uid, initialData) => {
  return useQuery({
    queryKey: ["productDetails", uid],
    queryFn: async () => {
      if (!uid) {
        return {};
      }
      const response = await get(
        `${API_URL}/v2/DeviceRequest/GetByUid?id=${uid}`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(response.errors?.[0] || "Error fetching dvr details");
      }
    },
    enabled: !!uid && !initialData, // Only run the query if the uid is truthy
    initialData: initialData,
  });
};

export const useFetchDRRDetails = (uid, initialData) => {
  return useQuery({
    queryKey: ["productDetails", uid],
    queryFn: async () => {
      if (!uid) {
        return {};
      }
      const response = await get(
        `${API_URL}/v2/DeviceReturnRequest/GetByUid?id=${uid}`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(response.errors?.[0] || "Error fetching drr details");
      }
    },
    enabled: !!uid && !initialData, // Only run the query if the uid is truthy
    initialData: initialData,
  });
};

export const useFetchDeviceLocations = (deviceId) => {
  return useQuery({
    queryKey: ["deviceLocations", deviceId],
    queryFn: async () => {
      if (!deviceId) return [];
      const response = await get(
        `${API_BASE_URL}/GetLastLocation?deviceId=${deviceId}`,
        true
      );
      if (response.statusCode === "OK") {
        return Array.isArray(response.data) ? response.data : [];
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching device details"
        );
      }
    },
    enabled: !!deviceId,
  });
};

export const useFetchDeviceDetails = (uid, initialData) => {
  return useQuery({
    queryKey: ["deviceDetails", uid],
    queryFn: async () => {
      let response;
      if (!uid) {
        return;
      }
      response = await get(`${API_BASE_URL}/GetByUid?id=${uid}`, true);
      // Handle the response
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching device details"
        );
      }
    },
    enabled: !!uid && !initialData, // Only run the query if either uid or deviceId is truthy
    initialData: initialData,
  });
};

export const useFetchDeviceDetailsMId = (deviceId, initialData) => {
  return useQuery({
    queryKey: ["deviceDetailsMId", deviceId],
    queryFn: async () => {
      let response;
      if (!deviceId) {
        return;
      }
      response = await get(`${API_BASE_URL}/list?Filter=${deviceId}`, true);
      // Handle the response
      if (response.statusCode === "OK") {
        return response.data.items[0];
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching device details"
        );
      }
    },
    enabled: !!deviceId && !initialData, // Only run the query if either uid or deviceId is truthy
    initialData: initialData,
  });
};

export const useFetchDeviceInfo = (
  setResponseMessage,
  setIsSuccess,
  setIsLoading,
  setSuggestedDevices,
  setPagination,
  setDeviceRequests
) => {
  const [selectedDevice, setSelectedDevice] = useState(null);

  const handleResponse = useCallback(
    (response, successMessage) => {
      if (response.statusCode === "OK") {
        setIsSuccess(true);
        setResponseMessage(successMessage);
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] ||
            "An error occurred while processing your request."
        );
      }
    },
    [setIsSuccess, setResponseMessage]
  );

  const handleError = useCallback(
    (error) => {
      setIsSuccess(false);
      setResponseMessage(
        error?.response?.data?.errors?.[0] ||
          error.message ||
          "Error occurred during the API call."
      );
    },
    [setIsSuccess, setResponseMessage]
  );

  const fetchDeviceLocation = useCallback(
    async (deviceId) => {
      setIsLoading(true);
      try {
        const response = await get(
          `${API_BASE_URL}/GetLastLocation?deviceId=${deviceId}`,
          true
        );
        return handleResponse(
          response,
          "Device location retrieved successfully."
        );
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleResponse, handleError, setIsLoading]
  );

  return {
    selectedDevice,
    setSelectedDevice,
    fetchDeviceLocation,
  };
};
