import { Typography, Box, Paper, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import HotelIcon from "@mui/icons-material/Hotel";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { BarChart } from "@mui/x-charts/BarChart";

const ActivityAnalysisDisplay = ({ activityAnalysis }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartSettings = {
    width: 600,
    height: 400,
  };
  const activityTypes = {
    leisure: { icon: <DirectionsWalkIcon />, color: colors.pastelColor.brown },
    resting: { icon: <HotelIcon />, color: colors.pastelColor.gray },
    vigorous: { icon: <DirectionsRunIcon />, color: colors.pastelColor.red },
    moderate: { icon: <FitnessCenterIcon />, color: colors.pastelColor.yellow },
    overall: { icon: <FavoriteIcon />, color: colors.pastelColor.pink },
    brisk: { icon: <DirectionsRunIcon/>, color: colors.pastelColor.pink },
  };
  const xAxisData = ["Leisure", "Vigorous", "Moderate", "Overall", "Brisk"];
  const activityTypesData = Object.keys(activityAnalysis).filter(
    (key) => activityAnalysis[key] !== null
  );
  const seriesData = [
    {
      label: "Activity Duration",
      data: activityTypesData.map((type) =>
        (activityAnalysis[type].activityDuration / 60).toFixed(2)
      ), // Convert seconds to minutes and fix to 2 decimal places
    },
    {
      label: "Expected Average HR",
      data: activityTypesData.map(
        (type) => activityAnalysis[type].expectedAverageHR
      ),
    },
    {
      label: "Computed Average HR",
      data: activityTypesData.map(
        (type) => activityAnalysis[type].computedAverageHR
      ),
    },
    {
      label: "Heart Health Score",
      data: activityTypesData.map(
        (type) => activityAnalysis[type].heartHealthScore
      ),
    },
  ];

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        backgroundColor: colors.primary[400],
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Grid container spacing={2}>
        {Object.entries(activityAnalysis).map(
          ([activityType, details]) =>
            details && (
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  key={activityType}
                  sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box sx={{ color: activityTypes[activityType]?.color }}>
                      {activityTypes[activityType]?.icon}
                    </Box>
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      {activityType.charAt(0).toUpperCase() +
                        activityType.slice(1)}{" "}
                      - {formatDuration(details.activityDuration)}
                    </Typography>
                  </Box>
                  {details.expectedAverageHR !== undefined && (
                    <Typography variant="body2" sx={{ pl: 2 }}>
                      Expected Avg HR: {details.expectedAverageHR} bpm
                    </Typography>
                  )}
                  {details.computedAverageHR !== undefined && (
                    <Typography variant="body2" sx={{ pl: 2 }}>
                      Computed Avg HR: {details.computedAverageHR} bpm
                    </Typography>
                  )}
                </Box>
              </Grid>
            )
        )}
      </Grid>
      <Box display="flex" flexDirection="column" alignItems="left" ml={3}>
        <BarChart
          xAxis={[{ scaleType: "band", data: xAxisData }]} // Categories for each group
          series={seriesData.map((series) => ({
            data: series.data,
            label: series.label,
            highlightScope: { faded: "global", highlighted: "series" },
          }))}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 0,
              itemMarkHeight: 5,
            },
          }}
          {...chartSettings}
        />
      </Box>
    </Paper>
  );
};

export default ActivityAnalysisDisplay;
