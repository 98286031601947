import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UpdateOutlined } from "@mui/icons-material";

const RpmStatusOveride = ({ open, setOpen, onStatusOveride }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [status, setStatus] = useState("");

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = () => {
    setOpen(false);
    onStatusOveride(status);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* The Dialog JSX */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: colors.primary[400],
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: colors.greenAccent[400], typography: "h5" }}
        >
          {"Override Patient's RPM Status"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense" variant="standard">
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={status}
              label="Status"
              onChange={handleStatusChange}
              autoWidth
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Authorised">Authorised</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="DND">DND</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            onClick={handleSubmit}
          >
            <UpdateOutlined sx={{ mr: "10px" }} />
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RpmStatusOveride;
