import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SessionExpirationDialog from "./components/user/SessionExpirationDialog";
import { refreshToken } from "./services/api";
import {
  clearLocalStorage,
  saveCredentials,
  setLocalStorageItem,
  getLocalStorageItem,
} from "./components/auth/storage";

export const AuthContext = createContext(null);

const isValidToken = () => {
  const token = getLocalStorageItem("accessToken");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.exp * 1000 > Date.now();
    } catch (error) {
      return false;
    }
  }
  return false;
};

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());
  const [userDetails, setUserDetails] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const loginUser = (userData, credentials, patientDetails, isNewPatient) => {
    saveCredentials(credentials);
    setLocalStorageItem("userDetails", JSON.stringify(userData));
    setLocalStorageItem("patientDetails", JSON.stringify(patientDetails));
    setIsAuthenticated(true);
    setUserDetails(userData);
    setPatientDetails(patientDetails);
    let welcomeMessage;
    if (isNewPatient) {
      welcomeMessage = `Thank you for choosing OmnyKare, ${patientDetails.firstName} ${patientDetails.lastName}.`;
    } else {
      welcomeMessage = `Welcome back, ${userData.firstName} ${userData.lastName}.`;
    }

    // Append a notification message if pending notifications exist
    if (userData.pendingNotificationExists) {
      welcomeMessage += " You have pending notifications!";
    } else {
      welcomeMessage += " You have no pending notifications!";
    }

    toast.success(welcomeMessage);
  };

  const logoutUser = () => {
    setIsAuthenticated(false);
    toast.dismiss();
    setUserDetails(null);
    setPatientDetails(null);
    clearLocalStorage();
    toast.info(`Successfully logged out.`);
    navigate("/adminLogin");
  };

  const updateUserDetails = (newDetails) => {
    setLocalStorageItem("userDetails", JSON.stringify(newDetails));
    setUserDetails(newDetails);
  };

  const updatePatientDetails = (newDetails) => {
    setLocalStorageItem("patientDetails", JSON.stringify(newDetails));
    setPatientDetails(newDetails);
  };

  const handleExtendSession = async () => {
    setIsDialogOpen(false);

    try {
      const credentialsData = await refreshToken();

      if (credentialsData) {
        saveCredentials(credentialsData);
        toast.success("Session extended successfully");
      } else {
        toast.error("Unable to refresh session. Please log in again.");
        setIsAuthenticated(false);
        setUserDetails(null);
        setPatientDetails(null);
        clearLocalStorage();
        navigate("/adminLogin");
      }
    } catch (error) {
      console.error("Error occurred while extending session:", error);
      toast.error("An error occurred. Please log in again.");
      setIsAuthenticated(false);
      setUserDetails(null);
      setPatientDetails(null);
      clearLocalStorage();
      navigate("/adminLogin");
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    logoutUser();
    navigate("/adminLogin");
  };

  useEffect(() => {
    const checkTokenAndUserDetails = () => {
      const token = getLocalStorageItem("accessToken");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const isExpiringSoon =
            decodedToken.exp * 1000 - 5 * 60 * 1000 < Date.now();
          if (isExpiringSoon) {
            if (decodedToken.exp * 1000 + 5 * 60 * 1000 < Date.now()) {
              // Token has been expired for more than 5 minutes
              handleCloseDialog();
              toast.info("Session expired, please login again.");
            } else {
              // Token has expired or is expiring soon
              setIsDialogOpen(true);
            }
          } else {
            // Token is valid
            setIsAuthenticated(true);
            // Check for user details
            const storedUserDetails = getLocalStorageItem("userDetails");
            if (storedUserDetails) {
              setUserDetails(storedUserDetails);
            }
            const storedPatientDetails = getLocalStorageItem("patientDetails");
            if (storedPatientDetails) {
              setPatientDetails(storedPatientDetails);
            }
          }
        } catch (error) {
          // Handle token decode errors (e.g., invalid token)
          setIsAuthenticated(false);
          clearLocalStorage();
        }
      } else {
        setIsAuthenticated(false);
        clearLocalStorage();
      }
    };

    // Run the check on component mount
    checkTokenAndUserDetails();

    // Set up interval for periodic token check
    const tokenCheckInterval = setInterval(checkTokenAndUserDetails, 120000); // Check every two minutes

    // Clean up interval and storage listener
    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        userDetails,
        patientDetails,
        loginUser,
        logoutUser,
        updateUserDetails,
        updatePatientDetails,
      }}
    >
      {children}
      <SessionExpirationDialog
        open={isDialogOpen}
        onExtendSession={handleExtendSession}
        onClose={handleCloseDialog}
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
