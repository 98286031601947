import React, { useState } from 'react';
import { LinearProgress, Box, Typography, Tooltip, ClickAwayListener } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { teal } from '@mui/material/colors';
import { validateMacAddress, isPhoneValid } from '../forms/utilities';
import isEmail from 'validator/lib/isEmail';

const ProfileCompletenessBar = ({ patient }) => {
    const [open, setOpen] = useState(false);
    const sanitizeValue = (value) => {
        if (value === null) {
            return '';
        } else if (Array.isArray(value)) {
            return value.map(sanitizeValue);
        } else if (typeof value === 'object') {
            return sanitizeObject(value);
        }
        return value;
    };

    const sanitizeObject = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key] = sanitizeValue(obj[key]);
            return acc;
        }, {});
    };
    const sanitizedPatientData = sanitizeObject(patient);

    const fields = {
        'headerEntity.name': 10,
        'headerEntity.email': 25,
        'headerEntity.phoneNum': 25,
        'bodyEntity.EmergencyContactName': 10,
        'bodyEntity.EmergencyPhoneNumber': 15,
        'bodyEntity.EmergencyEmail': 15,
        'bodyEntity.DOB': 10,
        'bodyEntity.RingID': 20,
        'bodyEntity.Diagnosis': 5,
        'bodyEntity.Gender': 5,
        'bodyEntity.BMI': 5,
        'bodyEntity.EjectionFraction': 5,
        'bodyEntity.Dyslipidemia': 5,

    };

    const getFieldsToImprove = () => {
        const fieldsToImprove = [];

        // Check headerEntity fields
        if (!sanitizedPatientData.headerEntity.name) fieldsToImprove.push('Name');

        if (!isEmail(sanitizedPatientData.headerEntity.email)) {
            fieldsToImprove.push('Email');
        } else if (!sanitizedPatientData.isEmailVerified) {
            fieldsToImprove.push('Verify Email');
        }

        if (!isPhoneValid(sanitizedPatientData.headerEntity.phoneNum)) {
            fieldsToImprove.push('Phone Number');
        } else if (!sanitizedPatientData.isPhoneNoVerified) {
            fieldsToImprove.push('Verify Phone');
        }

        // Check bodyEntity fields
        sanitizedPatientData.bodyEntity.forEach(section => {
            section.content.forEach(item => {
                let isValid = true;
                switch (item.title) {
                    case 'Emergency Contact Name':
                        isValid = item.value !== '';
                        break;
                    case 'Emergency Phone Number':
                        isValid = isPhoneValid(item.value);
                        break;
                    case 'Emergency Email Address':
                        isValid = isEmail(item.value);
                        break;
                    case 'Ring ID':
                        isValid = validateMacAddress(item.value);
                        break;
                    case 'Date of Birth':
                        const age = calculateAge(item.value);
                        if (!(age >= 18 && age <= 100)) {
                            isValid = false;
                        }
                        break;
                    case 'Height':
                        const height = parseFloat(item.value);
                        if (!(height >= 100 && height <= 272)) {
                            isValid = false;
                        }
                        break;
                    case 'Weight':
                        const weight = parseFloat(item.value);
                        if (!(weight >= 30 && weight <= 200)) {
                            isValid = false;
                        }
                        break;
                    case 'Gender':
                        const validGenders = ['Male', 'Female', 'Others'];
                        if (!validGenders.includes(item.value)) {
                            isValid = false;
                        }
                        break;

                }
                if (!isValid) fieldsToImprove.push(item.title);
            });
        });

        // Return a string of fields that need improvement
        return fieldsToImprove.join(', ');
    };
    const calculateCompleteness = () => {
        let score = 0;

        if (typeof sanitizedPatientData.headerEntity.name === 'string' && sanitizedPatientData.headerEntity.name.length > 3) {
            score += 10;
        }
        // Assigning weight for email verification
        if (sanitizedPatientData.isEmailVerified) {
            score += 25;
        } else if (sanitizedPatientData.headerEntity.email && isEmail(sanitizedPatientData.headerEntity.email)) {
            score += 10;
        }

        // Assigning weight for phone number verification
        if (sanitizedPatientData.isPhoneNoVerified) {
            score += 25;
        } else if (sanitizedPatientData.headerEntity.phoneNum && isPhoneValid(sanitizedPatientData.headerEntity.phoneNum)) {
            score += 10;
        }
        sanitizedPatientData.bodyEntity.forEach(section => {
            section.content.forEach(item => {
                let increment = 0; // Variable to track the score increment for this item.
                switch (item.title) {
                    case 'Emergency Phone Number':
                        if (isPhoneValid(item.value)) increment = 15;
                        break;
                    case 'Emergency Contact Name':
                        if (item.value) increment = 10;
                        break;
                    case 'Emergency Email Address':
                        if (isEmail(item.value)) increment = 15;
                        break;
                    case 'Date of Birth':
                        const age = calculateAge(item.value);
                        if (age >= 18 && age <= 100) increment = 10;
                        break;
                    case 'Ring ID':
                        if (validateMacAddress(item.value)) increment = 20;
                        break;
                    case 'Tenant ID':
                        // No increment, as the case is to be ignored.
                        break;
                    case 'Gender':
                        const validGenders = ['Male', 'Female', 'Others'];
                        if (validGenders.includes(item.value)) increment = 10;
                        break;
                    default:
                        if (typeof item.value === 'boolean' || item.value === "True" || item.value === "False") {
                            increment = item.value === "True" ? 0 : 0;
                        } else {
                            if (item.value) increment = 5;
                        }
                        break;
                }
                if (increment > 0) {
                    score += increment;
                }
            });
        });


        return score;
    };

    const calculateAge = (dob) => {
        const [day, month, year] = dob.split('-').map(num => parseInt(num, 10));
        const birthDate = new Date(year, month - 1, day);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const completenessScore = calculateCompleteness();
    const maxScore = Object.values(fields).reduce((sum, weight) => sum + weight, 0);
    const completenessPercentage = (completenessScore / maxScore) * 100;

    const color = completenessPercentage < 60 ? 'error' : completenessPercentage < 85 ? 'warning' : 'success';

    const handleTooltipOpen = () => setOpen(true);
    const handleTooltipClose = () => setOpen(false);

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Box textAlign="center" mb={2}>
                <Typography variant="h6" gutterBottom>
                    Patient Profile Completeness
                </Typography>
                <Tooltip
                    PopperProps={{ disablePortal: true }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={getFieldsToImprove()}
                >
                    <Box onClick={handleTooltipOpen}>
                        <LinearProgress
                            variant="determinate"
                            value={completenessPercentage}
                            color={color}
                            sx={{ height: 15, borderRadius: 5, cursor: 'pointer' }}
                        />
                        {sanitizedPatientData.isEmailVerified && (
                            <VerifiedIcon style={{ color: teal[500], verticalAlign: 'bottom' }} />
                        )}
                        {sanitizedPatientData.isPhoneNoVerified && (
                            <VerifiedIcon style={{ color: teal[500], verticalAlign: 'bottom' }} />
                        )}
                    </Box>
                </Tooltip>
                <Typography variant="body1" color={color} mt={2}>
                    {completenessPercentage.toFixed(2)}%
                </Typography>
            </Box>
        </ClickAwayListener>
    );
};

export default ProfileCompletenessBar;
