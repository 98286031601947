import React, { useState, useEffect } from "react";
import { API_URL, get } from "../../services/api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import LoginIcon from "@mui/icons-material/Login";
import packageJson from "../../../package.json";
import { useNavigate } from "react-router-dom";
import DnsIcon from "@mui/icons-material/Dns";
import ApiIcon from "@mui/icons-material/Api";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CenteredProgress from "./CenteredProgress";
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";

const Information = () => {
  const appVersion = packageJson.version; // Assuming this is the React app version
  const [healthCheck, setHealthCheck] = useState(null);
  const [backendVersion, setBackendVersion] = useState("");
  const [backendVersionError, setBackendVersionError] = useState(false);
  const [redisCheck, setRedisCheck] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isDevelopment = API_URL.includes("avida.dev.omnyk.com");
  const serverType = isDevelopment ? "Development Server" : "Production Server";
  const iconColor = isDevelopment ? "orange" : "green";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    async function fetchHealthCheck() {
      setLoading(true);

      // Individual try-catch for each check to handle them separately
      try {
        const healthData = await get(`${API_URL}/v2/HealthCheck/Check`, true);
        if (healthData.statusCode === "OK" && healthData.data === "Ok") {
          setHealthCheck(true);
        } else {
          setHealthCheck(false);
        }
      } catch (error) {
        console.error("Failed to fetch health check status", error);
        setHealthCheck(false);
      }

      try {
        const versionData = await get(
          `${API_URL}/v2/HealthCheck/GetVersion`,
          true
        );
        if (versionData.statusCode === "OK") {
          setBackendVersion(versionData.data.replace("v.", ""));
        } else {
          setBackendVersionError(true);
        }
      } catch (error) {
        console.error("Failed to fetch backend version", error);
        setBackendVersionError(true);
      }

      try {
        const redisData = await get(
          `${API_URL}/v2/HealthCheck/CheckRedisConnection`,
          true
        );
        setRedisCheck(redisData.statusCode === "OK");
      } catch (error) {
        console.error("Failed to check Redis connection", error);
        setRedisCheck(false);
      }

      setLoading(false);
    }

    fetchHealthCheck();
  }, []);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="center" alignItems="bottom">
          <ApiIcon style={{ fontSize: 60, marginRight: 8, color: "teal" }} />
          <Header
            title="System Information"
            subtitle="OmnyManage details."
          />
        </Box>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
            padding: "15px",
          }}
        >
          {loading && (
            <Box
              sx={{
                padding: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CenteredProgress />
            </Box>
          )}
          {!loading && (
            <List>
              <ListItem>
                <ListItemIcon>
                  <DnsIcon style={{ color: iconColor }} />
                </ListItemIcon>
                <ListItemText primary={serverType} />
              </ListItem>
              <Divider
                component="li"
                style={{ backgroundColor: "lightgreen" }}
              />
              <ListItem>
                <ListItemIcon>
                  {healthCheck ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <ErrorIcon style={{ color: "red" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Health Check Status"
                  secondary={healthCheck ? "Healthy" : "Unhealthy"}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {appVersion ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <ErrorIcon style={{ color: "red" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary="App Version" secondary={appVersion} />
              </ListItem>
              <Divider component="li" style={{ backgroundColor: "lightred" }} />
              <ListItem>
                <ListItemIcon>
                  {backendVersionError ? (
                    <ErrorIcon style={{ color: "red" }} />
                  ) : (
                    <CheckCircleIcon style={{ color: "green" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Backend Version"
                  secondary={
                    backendVersionError
                      ? "Error fetching version."
                      : backendVersion
                  }
                />
              </ListItem>
              {healthCheck && backendVersionError && (
                <ListItem>
                  <ListItemIcon>
                    <IconButton
                      edge="end"
                      color="primary"
                      onClick={() => navigate("/adminLogin")}
                    >
                      <LoginIcon />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Please login and retry." />
                </ListItem>
              )}
              <ListItem>
                <ListItemIcon>
                  {redisCheck ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <ErrorIcon style={{ color: "red" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Redis Connection"
                  secondary={redisCheck ? "Connected" : "Disconnected"}
                />
              </ListItem>
            </List>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Information;
