import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { useNavigate } from "react-router-dom";
import {
  formatDuration,
  formatSkuType,
} from "../../components/forms/utilities";
import {
  countries,
  formatCurrency,
  getCurrencyCode,
} from "../../components/common/constants";

const TreeView = ({ items, subComponent }) => {
  const navigate = useNavigate();
  const [groupedItems, setGroupedItems] = useState({});

  useEffect(() => {
    // Separate handling for "SKU" and other types of subcomponents
    if (subComponent === "SKU") {
      // Grouping by country and then by SKU type
      const grouped = items.reduce((acc, item) => {
        const { country, skuType } = item;
        const formattedSkuType = formatSkuType(skuType);
        if (!acc[country]) {
          acc[country] = {};
        }
        if (!acc[country][formattedSkuType]) {
          acc[country][formattedSkuType] = [];
        }
        acc[country][formattedSkuType].push(item);
        return acc;
      }, {});
      setGroupedItems(grouped);
    } else {
      // Simple grouping for non-SKU items under a default label
      setGroupedItems({ [subComponent]: items });
    }
  }, [subComponent, items]);

  // Handler for item click, navigating to the detail view
  const handleItemClick = (newId) => {
    navigate(`/${subComponent.toLowerCase()}-details`, {
      state: { initialId: newId },
    });
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: 400,
        overflowY: "auto",
        mt: 2,
        mb: 2,
      }}
    >
      <SimpleTreeView expanded={Object.keys(groupedItems)}>
        {Object.entries(groupedItems).map(([key, value]) => {
          // Distinguish between SKU and non-SKU handling
          if (subComponent === "SKU") {
            // Render SKU-specific structure
            return (
              <TreeItem
                key={key}
                itemId={key}
                label={`${key} (${Object.keys(value).reduce(
                  (acc, key) => acc + value[key].length,
                  0
                )})`}
              >
                {Object.entries(value).map(([type, skuItems]) => (
                  <TreeItem
                    key={type}
                    itemId={`${key}-${type}`}
                    label={`${type} (${skuItems.length})`}
                  >
                    {skuItems.map((item) => (
                      <TreeItem
                        key={item.id}
                        itemId={item.id.toString()}
                        label={`${formatDuration(item.name)} (${
                          item.code
                        }) - ${formatCurrency(
                          item.price,
                          getCurrencyCode(item.country),
                          "en-IN"
                        )}`}
                        onClick={() => handleItemClick(item.id)}
                      />
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            );
          } else {
            // Render general structure for other subcomponents
            return (
              <TreeItem
                key={key}
                itemId={key}
                label={`${key} (${value.length})`}
              >
                {value.map((item) => (
                  <TreeItem
                    key={item.id}
                    itemId={item.id.toString()}
                    label={`${formatDuration(item.name)} (${item.code})`}
                    onClick={() => handleItemClick(item.id)}
                  />
                ))}
              </TreeItem>
            );
          }
        })}
      </SimpleTreeView>
    </Box>
  );
};

export default TreeView;
