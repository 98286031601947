import { Grid } from "@mui/material";
import appStoreBadge from "../../assets/ios_store.svg";

export const AppDownloadButtons = () => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "20px" }}
    >
      <Grid item>
        <a
          href="https://play.google.com/store/apps/details?id=com.omnyk.app.omnytraq&hl=en_IN&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            style={{ height: "145px" }}
          />
        </a>
      </Grid>
      <Grid item>
        <a
          href="https://apps.apple.com/in/app/omnytraq/id6475280639"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={appStoreBadge}
            alt="Download on the App Store"
            style={{ height: "100px" }}
          />
        </a>
      </Grid>
    </Grid>
  );
};
