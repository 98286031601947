import { useForm, FormProvider, Controller } from "react-hook-form";
import React, { useState } from "react";
import { post, API_URL } from "../../services/api";
import { Grid, Button, Box, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ItemSearch from "../search/itemSearch";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import InsertLinkIcon from "@mui/icons-material/InsertLinkOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { useNavigate } from "react-router-dom";

const LinkItemForm = ({ component, selectedItem, setLinkMode }) => {
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleItemSelected = (item, field) => {
    field.onChange(item.id);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    if (component === "SubCategory") {
      formData.subCategoryId = selectedItem.id;
    } else if (component === "ProductCore") {
      formData.productId = selectedItem.id;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };

    try {
      const response = await post(
        `${API_URL}/v2/Product/Add`,
        payload,
        true
      );
      if (response.statusCode === "OK") {
        toast.success(response.messages[0]);
        if (response.data) {
          navigate(`/product-details`, {
            state: { initialId: response.data.id },
          });
          setLinkMode(false);
        }
        methods.reset();
      } else if (response.statusCode === "BadRequest") {
        toast.error(response.errors[0]);
      } else {
        toast.error(`Error adding ${component}.`);
      }
    } catch (error) {
      if (error.response.data && error.response.data.errors.length > 0) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage =
          `Error adding ${component} Link.` +
          ".\n" +
          validationErrorMessages.join("\n");
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error(`Error adding ${component} Link.`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {component === "SubCategory" && (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Controller
              name="productId"
              control={methods.control}
              rules={{ required: "Product Core" }}
              render={({ field, fieldState: { error } }) => (
                <Box width="100%">
                  {" "}
                  <ItemSearch
                    component="ProductCore"
                    onItemSelected={(item) => handleItemSelected(item, field)}
                    {...field}
                  />
                  {error && (
                    <Typography
                      color="error"
                      variant="caption"
                      display="block"
                      mt={1}
                    >
                      {error.message}
                    </Typography>
                  )}
                </Box>
              )}
            />
          </Box>
        )}
        {component === "ProductCore" && (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Controller
              name="subCategoryId"
              control={methods.control}
              rules={{ required: "SubCategory" }}
              render={({ field, fieldState: { error } }) => (
                <Box width="100%">
                  {" "}
                  <ItemSearch
                    component="SubCategory"
                    onItemSelected={(item) => handleItemSelected(item, field)}
                    {...field}
                  />
                  {error && (
                    <Typography
                      color="error"
                      variant="caption"
                      display="block"
                      mt={1}
                    >
                      {error.message}
                    </Typography>
                  )}
                </Box>
              )}
            />
          </Box>
        )}
        {isLoading && <CenteredProgress />}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              disabled={isLoading}
              type="submit"
            >
              <InsertLinkIcon sx={{ mr: "10px" }} />
              Link
            </Button>
            <Button
              onClick={() => {
                setLinkMode(false);
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LinkItemForm;
