import React, { useState, useEffect } from "react";
import CenteredProgress from "../global/CenteredProgress";
import ErrorsRefetch from "../global/ErrorsRefetch";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import {
  useFetchSelfPatientDetails,
  useFetchHealthProfile,
} from "../../components/patients/useFetchPatientInfo";
import {
  Typography,
  Container,
  Box,
  Grid,
  Avatar,
  IconButton,
  Divider,
} from "@mui/material";
import EditPatient from "../../components/patients/editPatient";
import { useTimedMessage } from "../../components/forms/utilities";
import ActionToolbar from "../../components/common/ActionToolbar";
import { useNavigate, useLocation } from "react-router-dom";
import { red } from "@mui/material/colors";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ProfileCompletenessBar from "../../components/patients/profileStatus";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useAuth } from "../../AuthProvider";

const PatientMyProfile = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isAuthenticated, userDetails } = useAuth();
  const linkIconTitles = ["Tenant ID", "Ring ID"];
  const [patientUpdateSuccess, setPatientUpdateSuccess] = useState(false);
  const [patientEmail, setPatientEmail] = useState("");
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [isSuccess, setIsSuccess] = React.useState(true);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const initialProfile = useState(props.initialProfile)
  const isDependent = location.state?.isDependent || false;
  const isAdmin = location.state?.isAdmin || false;
  const dependentType = location.state?.dependentType || "self";
  const relatedEmail = location.state?.relatedEmail || "";
  const isEditProfile = location.state?.isEditProfile || false;
  let header;
  let subtitle;

  if (dependentType === "careTakerName") {
    header = `Your authorised caretaker's profile`;
    subtitle = "Caretaker monitoring you.";
  } else if (dependentType === "patientName") {
    header = `Your approved patient's profile`;
    subtitle = "Patient being monitored.";
  } else {
    header = "Health Profile";
    subtitle = "Your health profile.";
  }
  const handleBack = () => {
    navigate(-1);
  };
  const {
    data: patientProfile,
    isLoading: isPatientProfileLoading,
    isError: isPatientProfileError,
    error: patientProfileError,
    refetch: refetchPatientProfile,
  } = useFetchHealthProfile(isDependent ? relatedEmail : "", isAdmin, isDependent ? null : initialProfile);

  const {
    data: patientDetails,
    isLoading: isPatientDetailsLoading,
    isError: isPatientDetailsError,
    error: patientDetailsError,
    refetch: refetchPatientDetails,
  } = useFetchSelfPatientDetails(null, isDependent);

  const emailVerified = patientProfile?.isEmailVerified;
  const phoneVerified = patientProfile?.isPhoneNoVerified;

  const [status] = useState({
    edit: { disabled: isDependent, tooltip: "Update your profile" },
  });

  useEffect(() => {
    if (isEditProfile && patientProfile) {
      handleEdit();
    } else if (!isEditProfile) {
      setEditMode(false);
    }
  }, [patientProfile, isEditProfile]);

  useEffect(() => {
    return () => {
      setEditMode(false);
    };
  }, []);

  useEffect(() => {
    if (patientUpdateSuccess) {
      refetchPatientProfile();
      refetchPatientDetails();
      setPatientUpdateSuccess(false);
    }
  }, [patientUpdateSuccess, refetchPatientProfile]);

  const handlePatientUpdateSuccess = (success) => {
    setPatientUpdateSuccess(success);
  };

  const handleEdit = async () => {
    try {
    } catch (err) {
      throw err;
    }
    setEditMode(true);
  };

  const handleIconClick = (params) => {
    if (params.field === "Tenant ID") {
      const code = params.value;
      navigate("/tenantinfo", { state: { initialTenantCode: code } });
    } else if (params.field === "Ring ID") {
      if (params.value) {
        const deviceId = params.value;
        navigate("/deviceinfo", { state: { initialDeviceMId: deviceId } });
      }
    }
  };

  useEffect(() => {
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }

    // Additionally, handle errors from React Query
    if (isPatientProfileError && patientProfileError) {
      let message = "An error occurred while fetching patient profile.";
      if (patientProfileError.message == "Not Found") {
        message = "The rpm request is yet to be approved.";
      }
      toast.error(message);
    }
    if (isPatientDetailsError && patientDetailsError) {
      toast.error(
        patientProfileError.message ||
          "An error occurred while fetching patient profile."
      );
    }
  }, [
    responseMessage,
    isSuccess,
    isPatientProfileError,
    patientProfileError,
    isPatientDetailsError,
    patientDetailsError,
  ]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={header} subtitle={subtitle} />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
          }}
        >
          {isPatientProfileError && (
            <ErrorsRefetch
              message={
                isDependent
                  ? "You may not have access to the profile yet."
                  : "Something went wrong, please retry."
              }
              subtitle="Retry fetching the profile."
              refetch={refetchPatientProfile}
            />
          )}
          {patientProfile && (
            <ProfileCompletenessBar patient={patientProfile} />
          )}
          {editMode && patientDetails && (
            <>
              {isPatientDetailsLoading && <CenteredProgress />}
              <EditPatient
                selectedPatientProfile={patientProfile}
                selectedPatient={patientDetails}
                setResponseMessage={setResponseMessage}
                setPatientEmail={setPatientEmail}
                setIsSuccess={setIsSuccess}
                setEditMode={setEditMode}
                onPatientUpdateSuccess={handlePatientUpdateSuccess}
              />
            </>
          )}
          {!editMode && (
            <>
              {isPatientProfileLoading && <CenteredProgress />}
              {patientProfile && !editMode && (
                <>
                  {!isDependent && (
                    <ActionToolbar status={status} onEdit={handleEdit} />
                  )}

                  <Box
                    sx={{
                      marginTop: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <Avatar
                        src={patientProfile.headerEntity.avatar}
                        alt="avatar"
                        sx={{
                          width: 80, // Adjust the width as needed
                          height: 80, // Adjust the height as needed
                          marginRight: "20px",
                        }}
                      />
                      <Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{ marginBottom: "10px" }}
                          >
                            {patientProfile.headerEntity.name}
                          </Typography>
                          {emailVerified && phoneVerified && (
                            <VerifiedUserIcon
                              sx={{ color: "teal", marginLeft: "5px" }}
                            />
                          )}
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body1"
                            component="h2"
                            sx={{ marginBottom: "5px" }}
                          >
                            Email: {patientProfile.headerEntity.email}
                          </Typography>
                          {emailVerified && (
                            <VerifiedIcon
                              sx={{ color: "teal", marginLeft: "5px" }}
                            />
                          )}
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            component="h2"
                            sx={{ marginBottom: "5px" }}
                          >
                            Phone: {patientProfile.headerEntity.phoneNum}
                          </Typography>
                          {phoneVerified && (
                            <VerifiedIcon
                              sx={{ color: "teal", marginLeft: "5px" }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        bgcolor: red[200],
                        height: "2px",
                        my: 2,
                      }}
                    />

                    <Grid container spacing={2} justifyContent="space-between">
                      {patientProfile.bodyEntity.map((section, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{ marginBottom: "10px" }}
                          >
                            {section.title}
                          </Typography>
                          {section.content.map((item, idx) => (
                            <Box
                              key={idx}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Box
                                component="img"
                                src={item.icon}
                                alt={item.title}
                                sx={{
                                  width: 30, // Set the width you want
                                  height: 30, // Set the height you want
                                  marginRight: "10px",
                                  cursor: linkIconTitles.includes(item.title)
                                    ? "pointer"
                                    : "default",
                                }}
                                onClick={() =>
                                  handleIconClick({
                                    field: item.title,
                                    value: item.value,
                                  })
                                }
                              />
                              <Box>
                                <Typography variant="subtitle2">
                                  {item.title}
                                </Typography>
                                <Typography variant="body3">
                                  {item.value}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default PatientMyProfile;
