/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.HealthData = (function() {

    /**
     * Properties of a HealthData.
     * @exports IHealthData
     * @interface IHealthData
     * @property {HealthData.ISWVersion|null} [swVersion] HealthData swVersion
     * @property {HealthData.IOnlineStatus|null} [onlineStatus] HealthData onlineStatus
     * @property {HealthData.IDescriptorCount|null} [descriptorCount] HealthData descriptorCount
     * @property {HealthData.ITime|null} [time] HealthData time
     * @property {HealthData.ISPO2|null} [spo2] HealthData spo2
     * @property {HealthData.ITemperature|null} [ambientTemperature] HealthData ambientTemperature
     * @property {HealthData.ITemperature|null} [skinTemperature] HealthData skinTemperature
     * @property {HealthData.ISteps|null} [steps] HealthData steps
     * @property {HealthData.IBatteryLevel|null} [batteryLevel] HealthData batteryLevel
     * @property {HealthData.IHeartRate|null} [heartRate] HealthData heartRate
     * @property {HealthData.IIBIBM|null} [ibiBm] HealthData ibiBm
     * @property {HealthData.IPS|null} [ps1] HealthData ps1
     * @property {HealthData.IPS|null} [ps2] HealthData ps2
     * @property {HealthData.IERROR|null} [ec1] HealthData ec1
     * @property {HealthData.IERROR|null} [ec2] HealthData ec2
     */

    /**
     * Constructs a new HealthData.
     * @exports HealthData
     * @classdesc Represents a HealthData.
     * @implements IHealthData
     * @constructor
     * @param {IHealthData=} [properties] Properties to set
     */
    function HealthData(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * HealthData swVersion.
     * @member {HealthData.ISWVersion|null|undefined} swVersion
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.swVersion = null;

    /**
     * HealthData onlineStatus.
     * @member {HealthData.IOnlineStatus|null|undefined} onlineStatus
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.onlineStatus = null;

    /**
     * HealthData descriptorCount.
     * @member {HealthData.IDescriptorCount|null|undefined} descriptorCount
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.descriptorCount = null;

    /**
     * HealthData time.
     * @member {HealthData.ITime|null|undefined} time
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.time = null;

    /**
     * HealthData spo2.
     * @member {HealthData.ISPO2|null|undefined} spo2
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.spo2 = null;

    /**
     * HealthData ambientTemperature.
     * @member {HealthData.ITemperature|null|undefined} ambientTemperature
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.ambientTemperature = null;

    /**
     * HealthData skinTemperature.
     * @member {HealthData.ITemperature|null|undefined} skinTemperature
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.skinTemperature = null;

    /**
     * HealthData steps.
     * @member {HealthData.ISteps|null|undefined} steps
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.steps = null;

    /**
     * HealthData batteryLevel.
     * @member {HealthData.IBatteryLevel|null|undefined} batteryLevel
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.batteryLevel = null;

    /**
     * HealthData heartRate.
     * @member {HealthData.IHeartRate|null|undefined} heartRate
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.heartRate = null;

    /**
     * HealthData ibiBm.
     * @member {HealthData.IIBIBM|null|undefined} ibiBm
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.ibiBm = null;

    /**
     * HealthData ps1.
     * @member {HealthData.IPS|null|undefined} ps1
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.ps1 = null;

    /**
     * HealthData ps2.
     * @member {HealthData.IPS|null|undefined} ps2
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.ps2 = null;

    /**
     * HealthData ec1.
     * @member {HealthData.IERROR|null|undefined} ec1
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.ec1 = null;

    /**
     * HealthData ec2.
     * @member {HealthData.IERROR|null|undefined} ec2
     * @memberof HealthData
     * @instance
     */
    HealthData.prototype.ec2 = null;

    /**
     * Creates a new HealthData instance using the specified properties.
     * @function create
     * @memberof HealthData
     * @static
     * @param {IHealthData=} [properties] Properties to set
     * @returns {HealthData} HealthData instance
     */
    HealthData.create = function create(properties) {
        return new HealthData(properties);
    };

    /**
     * Encodes the specified HealthData message. Does not implicitly {@link HealthData.verify|verify} messages.
     * @function encode
     * @memberof HealthData
     * @static
     * @param {IHealthData} message HealthData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    HealthData.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.swVersion != null && Object.hasOwnProperty.call(message, "swVersion"))
            $root.HealthData.SWVersion.encode(message.swVersion, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.onlineStatus != null && Object.hasOwnProperty.call(message, "onlineStatus"))
            $root.HealthData.OnlineStatus.encode(message.onlineStatus, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.descriptorCount != null && Object.hasOwnProperty.call(message, "descriptorCount"))
            $root.HealthData.DescriptorCount.encode(message.descriptorCount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.time != null && Object.hasOwnProperty.call(message, "time"))
            $root.HealthData.Time.encode(message.time, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.spo2 != null && Object.hasOwnProperty.call(message, "spo2"))
            $root.HealthData.SPO2.encode(message.spo2, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.ambientTemperature != null && Object.hasOwnProperty.call(message, "ambientTemperature"))
            $root.HealthData.Temperature.encode(message.ambientTemperature, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.skinTemperature != null && Object.hasOwnProperty.call(message, "skinTemperature"))
            $root.HealthData.Temperature.encode(message.skinTemperature, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.steps != null && Object.hasOwnProperty.call(message, "steps"))
            $root.HealthData.Steps.encode(message.steps, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.batteryLevel != null && Object.hasOwnProperty.call(message, "batteryLevel"))
            $root.HealthData.BatteryLevel.encode(message.batteryLevel, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.heartRate != null && Object.hasOwnProperty.call(message, "heartRate"))
            $root.HealthData.HeartRate.encode(message.heartRate, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.ibiBm != null && Object.hasOwnProperty.call(message, "ibiBm"))
            $root.HealthData.IBIBM.encode(message.ibiBm, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.ps1 != null && Object.hasOwnProperty.call(message, "ps1"))
            $root.HealthData.PS.encode(message.ps1, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.ps2 != null && Object.hasOwnProperty.call(message, "ps2"))
            $root.HealthData.PS.encode(message.ps2, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.ec1 != null && Object.hasOwnProperty.call(message, "ec1"))
            $root.HealthData.ERROR.encode(message.ec1, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.ec2 != null && Object.hasOwnProperty.call(message, "ec2"))
            $root.HealthData.ERROR.encode(message.ec2, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified HealthData message, length delimited. Does not implicitly {@link HealthData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof HealthData
     * @static
     * @param {IHealthData} message HealthData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    HealthData.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a HealthData message from the specified reader or buffer.
     * @function decode
     * @memberof HealthData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {HealthData} HealthData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    HealthData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.swVersion = $root.HealthData.SWVersion.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.onlineStatus = $root.HealthData.OnlineStatus.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.descriptorCount = $root.HealthData.DescriptorCount.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.time = $root.HealthData.Time.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.spo2 = $root.HealthData.SPO2.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.ambientTemperature = $root.HealthData.Temperature.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.skinTemperature = $root.HealthData.Temperature.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.steps = $root.HealthData.Steps.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.batteryLevel = $root.HealthData.BatteryLevel.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.heartRate = $root.HealthData.HeartRate.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.ibiBm = $root.HealthData.IBIBM.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.ps1 = $root.HealthData.PS.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.ps2 = $root.HealthData.PS.decode(reader, reader.uint32());
                    break;
                }
            case 30: {
                    message.ec1 = $root.HealthData.ERROR.decode(reader, reader.uint32());
                    break;
                }
            case 31: {
                    message.ec2 = $root.HealthData.ERROR.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a HealthData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof HealthData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {HealthData} HealthData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    HealthData.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a HealthData message.
     * @function verify
     * @memberof HealthData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    HealthData.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.swVersion != null && message.hasOwnProperty("swVersion")) {
            var error = $root.HealthData.SWVersion.verify(message.swVersion);
            if (error)
                return "swVersion." + error;
        }
        if (message.onlineStatus != null && message.hasOwnProperty("onlineStatus")) {
            var error = $root.HealthData.OnlineStatus.verify(message.onlineStatus);
            if (error)
                return "onlineStatus." + error;
        }
        if (message.descriptorCount != null && message.hasOwnProperty("descriptorCount")) {
            var error = $root.HealthData.DescriptorCount.verify(message.descriptorCount);
            if (error)
                return "descriptorCount." + error;
        }
        if (message.time != null && message.hasOwnProperty("time")) {
            var error = $root.HealthData.Time.verify(message.time);
            if (error)
                return "time." + error;
        }
        if (message.spo2 != null && message.hasOwnProperty("spo2")) {
            var error = $root.HealthData.SPO2.verify(message.spo2);
            if (error)
                return "spo2." + error;
        }
        if (message.ambientTemperature != null && message.hasOwnProperty("ambientTemperature")) {
            var error = $root.HealthData.Temperature.verify(message.ambientTemperature);
            if (error)
                return "ambientTemperature." + error;
        }
        if (message.skinTemperature != null && message.hasOwnProperty("skinTemperature")) {
            var error = $root.HealthData.Temperature.verify(message.skinTemperature);
            if (error)
                return "skinTemperature." + error;
        }
        if (message.steps != null && message.hasOwnProperty("steps")) {
            var error = $root.HealthData.Steps.verify(message.steps);
            if (error)
                return "steps." + error;
        }
        if (message.batteryLevel != null && message.hasOwnProperty("batteryLevel")) {
            var error = $root.HealthData.BatteryLevel.verify(message.batteryLevel);
            if (error)
                return "batteryLevel." + error;
        }
        if (message.heartRate != null && message.hasOwnProperty("heartRate")) {
            var error = $root.HealthData.HeartRate.verify(message.heartRate);
            if (error)
                return "heartRate." + error;
        }
        if (message.ibiBm != null && message.hasOwnProperty("ibiBm")) {
            var error = $root.HealthData.IBIBM.verify(message.ibiBm);
            if (error)
                return "ibiBm." + error;
        }
        if (message.ps1 != null && message.hasOwnProperty("ps1")) {
            var error = $root.HealthData.PS.verify(message.ps1);
            if (error)
                return "ps1." + error;
        }
        if (message.ps2 != null && message.hasOwnProperty("ps2")) {
            var error = $root.HealthData.PS.verify(message.ps2);
            if (error)
                return "ps2." + error;
        }
        if (message.ec1 != null && message.hasOwnProperty("ec1")) {
            var error = $root.HealthData.ERROR.verify(message.ec1);
            if (error)
                return "ec1." + error;
        }
        if (message.ec2 != null && message.hasOwnProperty("ec2")) {
            var error = $root.HealthData.ERROR.verify(message.ec2);
            if (error)
                return "ec2." + error;
        }
        return null;
    };

    /**
     * Creates a HealthData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof HealthData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {HealthData} HealthData
     */
    HealthData.fromObject = function fromObject(object) {
        if (object instanceof $root.HealthData)
            return object;
        var message = new $root.HealthData();
        if (object.swVersion != null) {
            if (typeof object.swVersion !== "object")
                throw TypeError(".HealthData.swVersion: object expected");
            message.swVersion = $root.HealthData.SWVersion.fromObject(object.swVersion);
        }
        if (object.onlineStatus != null) {
            if (typeof object.onlineStatus !== "object")
                throw TypeError(".HealthData.onlineStatus: object expected");
            message.onlineStatus = $root.HealthData.OnlineStatus.fromObject(object.onlineStatus);
        }
        if (object.descriptorCount != null) {
            if (typeof object.descriptorCount !== "object")
                throw TypeError(".HealthData.descriptorCount: object expected");
            message.descriptorCount = $root.HealthData.DescriptorCount.fromObject(object.descriptorCount);
        }
        if (object.time != null) {
            if (typeof object.time !== "object")
                throw TypeError(".HealthData.time: object expected");
            message.time = $root.HealthData.Time.fromObject(object.time);
        }
        if (object.spo2 != null) {
            if (typeof object.spo2 !== "object")
                throw TypeError(".HealthData.spo2: object expected");
            message.spo2 = $root.HealthData.SPO2.fromObject(object.spo2);
        }
        if (object.ambientTemperature != null) {
            if (typeof object.ambientTemperature !== "object")
                throw TypeError(".HealthData.ambientTemperature: object expected");
            message.ambientTemperature = $root.HealthData.Temperature.fromObject(object.ambientTemperature);
        }
        if (object.skinTemperature != null) {
            if (typeof object.skinTemperature !== "object")
                throw TypeError(".HealthData.skinTemperature: object expected");
            message.skinTemperature = $root.HealthData.Temperature.fromObject(object.skinTemperature);
        }
        if (object.steps != null) {
            if (typeof object.steps !== "object")
                throw TypeError(".HealthData.steps: object expected");
            message.steps = $root.HealthData.Steps.fromObject(object.steps);
        }
        if (object.batteryLevel != null) {
            if (typeof object.batteryLevel !== "object")
                throw TypeError(".HealthData.batteryLevel: object expected");
            message.batteryLevel = $root.HealthData.BatteryLevel.fromObject(object.batteryLevel);
        }
        if (object.heartRate != null) {
            if (typeof object.heartRate !== "object")
                throw TypeError(".HealthData.heartRate: object expected");
            message.heartRate = $root.HealthData.HeartRate.fromObject(object.heartRate);
        }
        if (object.ibiBm != null) {
            if (typeof object.ibiBm !== "object")
                throw TypeError(".HealthData.ibiBm: object expected");
            message.ibiBm = $root.HealthData.IBIBM.fromObject(object.ibiBm);
        }
        if (object.ps1 != null) {
            if (typeof object.ps1 !== "object")
                throw TypeError(".HealthData.ps1: object expected");
            message.ps1 = $root.HealthData.PS.fromObject(object.ps1);
        }
        if (object.ps2 != null) {
            if (typeof object.ps2 !== "object")
                throw TypeError(".HealthData.ps2: object expected");
            message.ps2 = $root.HealthData.PS.fromObject(object.ps2);
        }
        if (object.ec1 != null) {
            if (typeof object.ec1 !== "object")
                throw TypeError(".HealthData.ec1: object expected");
            message.ec1 = $root.HealthData.ERROR.fromObject(object.ec1);
        }
        if (object.ec2 != null) {
            if (typeof object.ec2 !== "object")
                throw TypeError(".HealthData.ec2: object expected");
            message.ec2 = $root.HealthData.ERROR.fromObject(object.ec2);
        }
        return message;
    };

    /**
     * Creates a plain object from a HealthData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof HealthData
     * @static
     * @param {HealthData} message HealthData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    HealthData.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.swVersion = null;
            object.onlineStatus = null;
            object.descriptorCount = null;
            object.time = null;
            object.spo2 = null;
            object.ambientTemperature = null;
            object.skinTemperature = null;
            object.steps = null;
            object.batteryLevel = null;
            object.heartRate = null;
            object.ibiBm = null;
            object.ps1 = null;
            object.ps2 = null;
            object.ec1 = null;
            object.ec2 = null;
        }
        if (message.swVersion != null && message.hasOwnProperty("swVersion"))
            object.swVersion = $root.HealthData.SWVersion.toObject(message.swVersion, options);
        if (message.onlineStatus != null && message.hasOwnProperty("onlineStatus"))
            object.onlineStatus = $root.HealthData.OnlineStatus.toObject(message.onlineStatus, options);
        if (message.descriptorCount != null && message.hasOwnProperty("descriptorCount"))
            object.descriptorCount = $root.HealthData.DescriptorCount.toObject(message.descriptorCount, options);
        if (message.time != null && message.hasOwnProperty("time"))
            object.time = $root.HealthData.Time.toObject(message.time, options);
        if (message.spo2 != null && message.hasOwnProperty("spo2"))
            object.spo2 = $root.HealthData.SPO2.toObject(message.spo2, options);
        if (message.ambientTemperature != null && message.hasOwnProperty("ambientTemperature"))
            object.ambientTemperature = $root.HealthData.Temperature.toObject(message.ambientTemperature, options);
        if (message.skinTemperature != null && message.hasOwnProperty("skinTemperature"))
            object.skinTemperature = $root.HealthData.Temperature.toObject(message.skinTemperature, options);
        if (message.steps != null && message.hasOwnProperty("steps"))
            object.steps = $root.HealthData.Steps.toObject(message.steps, options);
        if (message.batteryLevel != null && message.hasOwnProperty("batteryLevel"))
            object.batteryLevel = $root.HealthData.BatteryLevel.toObject(message.batteryLevel, options);
        if (message.heartRate != null && message.hasOwnProperty("heartRate"))
            object.heartRate = $root.HealthData.HeartRate.toObject(message.heartRate, options);
        if (message.ibiBm != null && message.hasOwnProperty("ibiBm"))
            object.ibiBm = $root.HealthData.IBIBM.toObject(message.ibiBm, options);
        if (message.ps1 != null && message.hasOwnProperty("ps1"))
            object.ps1 = $root.HealthData.PS.toObject(message.ps1, options);
        if (message.ps2 != null && message.hasOwnProperty("ps2"))
            object.ps2 = $root.HealthData.PS.toObject(message.ps2, options);
        if (message.ec1 != null && message.hasOwnProperty("ec1"))
            object.ec1 = $root.HealthData.ERROR.toObject(message.ec1, options);
        if (message.ec2 != null && message.hasOwnProperty("ec2"))
            object.ec2 = $root.HealthData.ERROR.toObject(message.ec2, options);
        return object;
    };

    /**
     * Converts this HealthData to JSON.
     * @function toJSON
     * @memberof HealthData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    HealthData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for HealthData
     * @function getTypeUrl
     * @memberof HealthData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    HealthData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/HealthData";
    };

    HealthData.SWVersion = (function() {

        /**
         * Properties of a SWVersion.
         * @memberof HealthData
         * @interface ISWVersion
         * @property {string|null} [version] SWVersion version
         */

        /**
         * Constructs a new SWVersion.
         * @memberof HealthData
         * @classdesc Represents a SWVersion.
         * @implements ISWVersion
         * @constructor
         * @param {HealthData.ISWVersion=} [properties] Properties to set
         */
        function SWVersion(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SWVersion version.
         * @member {string} version
         * @memberof HealthData.SWVersion
         * @instance
         */
        SWVersion.prototype.version = "";

        /**
         * Creates a new SWVersion instance using the specified properties.
         * @function create
         * @memberof HealthData.SWVersion
         * @static
         * @param {HealthData.ISWVersion=} [properties] Properties to set
         * @returns {HealthData.SWVersion} SWVersion instance
         */
        SWVersion.create = function create(properties) {
            return new SWVersion(properties);
        };

        /**
         * Encodes the specified SWVersion message. Does not implicitly {@link HealthData.SWVersion.verify|verify} messages.
         * @function encode
         * @memberof HealthData.SWVersion
         * @static
         * @param {HealthData.ISWVersion} message SWVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SWVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.version);
            return writer;
        };

        /**
         * Encodes the specified SWVersion message, length delimited. Does not implicitly {@link HealthData.SWVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.SWVersion
         * @static
         * @param {HealthData.ISWVersion} message SWVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SWVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SWVersion message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.SWVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.SWVersion} SWVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SWVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.SWVersion();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.version = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SWVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.SWVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.SWVersion} SWVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SWVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SWVersion message.
         * @function verify
         * @memberof HealthData.SWVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SWVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            return null;
        };

        /**
         * Creates a SWVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.SWVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.SWVersion} SWVersion
         */
        SWVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.SWVersion)
                return object;
            var message = new $root.HealthData.SWVersion();
            if (object.version != null)
                message.version = String(object.version);
            return message;
        };

        /**
         * Creates a plain object from a SWVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.SWVersion
         * @static
         * @param {HealthData.SWVersion} message SWVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SWVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.version = "";
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            return object;
        };

        /**
         * Converts this SWVersion to JSON.
         * @function toJSON
         * @memberof HealthData.SWVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SWVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SWVersion
         * @function getTypeUrl
         * @memberof HealthData.SWVersion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SWVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.SWVersion";
        };

        return SWVersion;
    })();

    HealthData.OnlineStatus = (function() {

        /**
         * Properties of an OnlineStatus.
         * @memberof HealthData
         * @interface IOnlineStatus
         * @property {boolean|null} [isOnlineData] OnlineStatus isOnlineData
         */

        /**
         * Constructs a new OnlineStatus.
         * @memberof HealthData
         * @classdesc Represents an OnlineStatus.
         * @implements IOnlineStatus
         * @constructor
         * @param {HealthData.IOnlineStatus=} [properties] Properties to set
         */
        function OnlineStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OnlineStatus isOnlineData.
         * @member {boolean} isOnlineData
         * @memberof HealthData.OnlineStatus
         * @instance
         */
        OnlineStatus.prototype.isOnlineData = false;

        /**
         * Creates a new OnlineStatus instance using the specified properties.
         * @function create
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {HealthData.IOnlineStatus=} [properties] Properties to set
         * @returns {HealthData.OnlineStatus} OnlineStatus instance
         */
        OnlineStatus.create = function create(properties) {
            return new OnlineStatus(properties);
        };

        /**
         * Encodes the specified OnlineStatus message. Does not implicitly {@link HealthData.OnlineStatus.verify|verify} messages.
         * @function encode
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {HealthData.IOnlineStatus} message OnlineStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OnlineStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isOnlineData != null && Object.hasOwnProperty.call(message, "isOnlineData"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isOnlineData);
            return writer;
        };

        /**
         * Encodes the specified OnlineStatus message, length delimited. Does not implicitly {@link HealthData.OnlineStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {HealthData.IOnlineStatus} message OnlineStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OnlineStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OnlineStatus message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.OnlineStatus} OnlineStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OnlineStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.OnlineStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isOnlineData = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OnlineStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.OnlineStatus} OnlineStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OnlineStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OnlineStatus message.
         * @function verify
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OnlineStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isOnlineData != null && message.hasOwnProperty("isOnlineData"))
                if (typeof message.isOnlineData !== "boolean")
                    return "isOnlineData: boolean expected";
            return null;
        };

        /**
         * Creates an OnlineStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.OnlineStatus} OnlineStatus
         */
        OnlineStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.OnlineStatus)
                return object;
            var message = new $root.HealthData.OnlineStatus();
            if (object.isOnlineData != null)
                message.isOnlineData = Boolean(object.isOnlineData);
            return message;
        };

        /**
         * Creates a plain object from an OnlineStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {HealthData.OnlineStatus} message OnlineStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OnlineStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.isOnlineData = false;
            if (message.isOnlineData != null && message.hasOwnProperty("isOnlineData"))
                object.isOnlineData = message.isOnlineData;
            return object;
        };

        /**
         * Converts this OnlineStatus to JSON.
         * @function toJSON
         * @memberof HealthData.OnlineStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OnlineStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OnlineStatus
         * @function getTypeUrl
         * @memberof HealthData.OnlineStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OnlineStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.OnlineStatus";
        };

        return OnlineStatus;
    })();

    HealthData.DescriptorCount = (function() {

        /**
         * Properties of a DescriptorCount.
         * @memberof HealthData
         * @interface IDescriptorCount
         * @property {number|null} [count] DescriptorCount count
         */

        /**
         * Constructs a new DescriptorCount.
         * @memberof HealthData
         * @classdesc Represents a DescriptorCount.
         * @implements IDescriptorCount
         * @constructor
         * @param {HealthData.IDescriptorCount=} [properties] Properties to set
         */
        function DescriptorCount(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DescriptorCount count.
         * @member {number} count
         * @memberof HealthData.DescriptorCount
         * @instance
         */
        DescriptorCount.prototype.count = 0;

        /**
         * Creates a new DescriptorCount instance using the specified properties.
         * @function create
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {HealthData.IDescriptorCount=} [properties] Properties to set
         * @returns {HealthData.DescriptorCount} DescriptorCount instance
         */
        DescriptorCount.create = function create(properties) {
            return new DescriptorCount(properties);
        };

        /**
         * Encodes the specified DescriptorCount message. Does not implicitly {@link HealthData.DescriptorCount.verify|verify} messages.
         * @function encode
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {HealthData.IDescriptorCount} message DescriptorCount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DescriptorCount.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.count);
            return writer;
        };

        /**
         * Encodes the specified DescriptorCount message, length delimited. Does not implicitly {@link HealthData.DescriptorCount.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {HealthData.IDescriptorCount} message DescriptorCount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DescriptorCount.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DescriptorCount message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.DescriptorCount} DescriptorCount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DescriptorCount.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.DescriptorCount();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.count = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DescriptorCount message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.DescriptorCount} DescriptorCount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DescriptorCount.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DescriptorCount message.
         * @function verify
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DescriptorCount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            return null;
        };

        /**
         * Creates a DescriptorCount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.DescriptorCount} DescriptorCount
         */
        DescriptorCount.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.DescriptorCount)
                return object;
            var message = new $root.HealthData.DescriptorCount();
            if (object.count != null)
                message.count = object.count | 0;
            return message;
        };

        /**
         * Creates a plain object from a DescriptorCount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {HealthData.DescriptorCount} message DescriptorCount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DescriptorCount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.count = 0;
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            return object;
        };

        /**
         * Converts this DescriptorCount to JSON.
         * @function toJSON
         * @memberof HealthData.DescriptorCount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DescriptorCount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DescriptorCount
         * @function getTypeUrl
         * @memberof HealthData.DescriptorCount
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DescriptorCount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.DescriptorCount";
        };

        return DescriptorCount;
    })();

    HealthData.Time = (function() {

        /**
         * Properties of a Time.
         * @memberof HealthData
         * @interface ITime
         * @property {number|Long|null} [customUnixTime] Time customUnixTime
         */

        /**
         * Constructs a new Time.
         * @memberof HealthData
         * @classdesc Represents a Time.
         * @implements ITime
         * @constructor
         * @param {HealthData.ITime=} [properties] Properties to set
         */
        function Time(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Time customUnixTime.
         * @member {number|Long} customUnixTime
         * @memberof HealthData.Time
         * @instance
         */
        Time.prototype.customUnixTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Time instance using the specified properties.
         * @function create
         * @memberof HealthData.Time
         * @static
         * @param {HealthData.ITime=} [properties] Properties to set
         * @returns {HealthData.Time} Time instance
         */
        Time.create = function create(properties) {
            return new Time(properties);
        };

        /**
         * Encodes the specified Time message. Does not implicitly {@link HealthData.Time.verify|verify} messages.
         * @function encode
         * @memberof HealthData.Time
         * @static
         * @param {HealthData.ITime} message Time message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Time.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customUnixTime != null && Object.hasOwnProperty.call(message, "customUnixTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.customUnixTime);
            return writer;
        };

        /**
         * Encodes the specified Time message, length delimited. Does not implicitly {@link HealthData.Time.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.Time
         * @static
         * @param {HealthData.ITime} message Time message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Time.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Time message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.Time
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.Time} Time
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Time.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.Time();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.customUnixTime = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Time message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.Time
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.Time} Time
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Time.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Time message.
         * @function verify
         * @memberof HealthData.Time
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Time.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.customUnixTime != null && message.hasOwnProperty("customUnixTime"))
                if (!$util.isInteger(message.customUnixTime) && !(message.customUnixTime && $util.isInteger(message.customUnixTime.low) && $util.isInteger(message.customUnixTime.high)))
                    return "customUnixTime: integer|Long expected";
            return null;
        };

        /**
         * Creates a Time message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.Time
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.Time} Time
         */
        Time.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.Time)
                return object;
            var message = new $root.HealthData.Time();
            if (object.customUnixTime != null)
                if ($util.Long)
                    (message.customUnixTime = $util.Long.fromValue(object.customUnixTime)).unsigned = false;
                else if (typeof object.customUnixTime === "string")
                    message.customUnixTime = parseInt(object.customUnixTime, 10);
                else if (typeof object.customUnixTime === "number")
                    message.customUnixTime = object.customUnixTime;
                else if (typeof object.customUnixTime === "object")
                    message.customUnixTime = new $util.LongBits(object.customUnixTime.low >>> 0, object.customUnixTime.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Time message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.Time
         * @static
         * @param {HealthData.Time} message Time
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Time.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.customUnixTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.customUnixTime = options.longs === String ? "0" : 0;
            if (message.customUnixTime != null && message.hasOwnProperty("customUnixTime"))
                if (typeof message.customUnixTime === "number")
                    object.customUnixTime = options.longs === String ? String(message.customUnixTime) : message.customUnixTime;
                else
                    object.customUnixTime = options.longs === String ? $util.Long.prototype.toString.call(message.customUnixTime) : options.longs === Number ? new $util.LongBits(message.customUnixTime.low >>> 0, message.customUnixTime.high >>> 0).toNumber() : message.customUnixTime;
            return object;
        };

        /**
         * Converts this Time to JSON.
         * @function toJSON
         * @memberof HealthData.Time
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Time.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Time
         * @function getTypeUrl
         * @memberof HealthData.Time
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Time.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.Time";
        };

        return Time;
    })();

    HealthData.SPO2 = (function() {

        /**
         * Properties of a SPO2.
         * @memberof HealthData
         * @interface ISPO2
         * @property {number|null} [spo2Value] SPO2 spo2Value
         */

        /**
         * Constructs a new SPO2.
         * @memberof HealthData
         * @classdesc Represents a SPO2.
         * @implements ISPO2
         * @constructor
         * @param {HealthData.ISPO2=} [properties] Properties to set
         */
        function SPO2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SPO2 spo2Value.
         * @member {number} spo2Value
         * @memberof HealthData.SPO2
         * @instance
         */
        SPO2.prototype.spo2Value = 0;

        /**
         * Creates a new SPO2 instance using the specified properties.
         * @function create
         * @memberof HealthData.SPO2
         * @static
         * @param {HealthData.ISPO2=} [properties] Properties to set
         * @returns {HealthData.SPO2} SPO2 instance
         */
        SPO2.create = function create(properties) {
            return new SPO2(properties);
        };

        /**
         * Encodes the specified SPO2 message. Does not implicitly {@link HealthData.SPO2.verify|verify} messages.
         * @function encode
         * @memberof HealthData.SPO2
         * @static
         * @param {HealthData.ISPO2} message SPO2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SPO2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.spo2Value != null && Object.hasOwnProperty.call(message, "spo2Value"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.spo2Value);
            return writer;
        };

        /**
         * Encodes the specified SPO2 message, length delimited. Does not implicitly {@link HealthData.SPO2.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.SPO2
         * @static
         * @param {HealthData.ISPO2} message SPO2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SPO2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SPO2 message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.SPO2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.SPO2} SPO2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SPO2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.SPO2();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.spo2Value = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SPO2 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.SPO2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.SPO2} SPO2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SPO2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SPO2 message.
         * @function verify
         * @memberof HealthData.SPO2
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SPO2.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.spo2Value != null && message.hasOwnProperty("spo2Value"))
                if (!$util.isInteger(message.spo2Value))
                    return "spo2Value: integer expected";
            return null;
        };

        /**
         * Creates a SPO2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.SPO2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.SPO2} SPO2
         */
        SPO2.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.SPO2)
                return object;
            var message = new $root.HealthData.SPO2();
            if (object.spo2Value != null)
                message.spo2Value = object.spo2Value | 0;
            return message;
        };

        /**
         * Creates a plain object from a SPO2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.SPO2
         * @static
         * @param {HealthData.SPO2} message SPO2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SPO2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.spo2Value = 0;
            if (message.spo2Value != null && message.hasOwnProperty("spo2Value"))
                object.spo2Value = message.spo2Value;
            return object;
        };

        /**
         * Converts this SPO2 to JSON.
         * @function toJSON
         * @memberof HealthData.SPO2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SPO2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SPO2
         * @function getTypeUrl
         * @memberof HealthData.SPO2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SPO2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.SPO2";
        };

        return SPO2;
    })();

    HealthData.Temperature = (function() {

        /**
         * Properties of a Temperature.
         * @memberof HealthData
         * @interface ITemperature
         * @property {number|null} [temperature] Temperature temperature
         */

        /**
         * Constructs a new Temperature.
         * @memberof HealthData
         * @classdesc Represents a Temperature.
         * @implements ITemperature
         * @constructor
         * @param {HealthData.ITemperature=} [properties] Properties to set
         */
        function Temperature(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Temperature temperature.
         * @member {number} temperature
         * @memberof HealthData.Temperature
         * @instance
         */
        Temperature.prototype.temperature = 0;

        /**
         * Creates a new Temperature instance using the specified properties.
         * @function create
         * @memberof HealthData.Temperature
         * @static
         * @param {HealthData.ITemperature=} [properties] Properties to set
         * @returns {HealthData.Temperature} Temperature instance
         */
        Temperature.create = function create(properties) {
            return new Temperature(properties);
        };

        /**
         * Encodes the specified Temperature message. Does not implicitly {@link HealthData.Temperature.verify|verify} messages.
         * @function encode
         * @memberof HealthData.Temperature
         * @static
         * @param {HealthData.ITemperature} message Temperature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Temperature.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.temperature != null && Object.hasOwnProperty.call(message, "temperature"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.temperature);
            return writer;
        };

        /**
         * Encodes the specified Temperature message, length delimited. Does not implicitly {@link HealthData.Temperature.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.Temperature
         * @static
         * @param {HealthData.ITemperature} message Temperature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Temperature.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Temperature message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.Temperature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.Temperature} Temperature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Temperature.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.Temperature();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.temperature = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Temperature message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.Temperature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.Temperature} Temperature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Temperature.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Temperature message.
         * @function verify
         * @memberof HealthData.Temperature
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Temperature.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.temperature != null && message.hasOwnProperty("temperature"))
                if (!$util.isInteger(message.temperature))
                    return "temperature: integer expected";
            return null;
        };

        /**
         * Creates a Temperature message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.Temperature
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.Temperature} Temperature
         */
        Temperature.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.Temperature)
                return object;
            var message = new $root.HealthData.Temperature();
            if (object.temperature != null)
                message.temperature = object.temperature | 0;
            return message;
        };

        /**
         * Creates a plain object from a Temperature message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.Temperature
         * @static
         * @param {HealthData.Temperature} message Temperature
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Temperature.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.temperature = 0;
            if (message.temperature != null && message.hasOwnProperty("temperature"))
                object.temperature = message.temperature;
            return object;
        };

        /**
         * Converts this Temperature to JSON.
         * @function toJSON
         * @memberof HealthData.Temperature
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Temperature.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Temperature
         * @function getTypeUrl
         * @memberof HealthData.Temperature
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Temperature.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.Temperature";
        };

        return Temperature;
    })();

    HealthData.Steps = (function() {

        /**
         * Properties of a Steps.
         * @memberof HealthData
         * @interface ISteps
         * @property {number|null} [steps] Steps steps
         */

        /**
         * Constructs a new Steps.
         * @memberof HealthData
         * @classdesc Represents a Steps.
         * @implements ISteps
         * @constructor
         * @param {HealthData.ISteps=} [properties] Properties to set
         */
        function Steps(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Steps steps.
         * @member {number} steps
         * @memberof HealthData.Steps
         * @instance
         */
        Steps.prototype.steps = 0;

        /**
         * Creates a new Steps instance using the specified properties.
         * @function create
         * @memberof HealthData.Steps
         * @static
         * @param {HealthData.ISteps=} [properties] Properties to set
         * @returns {HealthData.Steps} Steps instance
         */
        Steps.create = function create(properties) {
            return new Steps(properties);
        };

        /**
         * Encodes the specified Steps message. Does not implicitly {@link HealthData.Steps.verify|verify} messages.
         * @function encode
         * @memberof HealthData.Steps
         * @static
         * @param {HealthData.ISteps} message Steps message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Steps.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.steps != null && Object.hasOwnProperty.call(message, "steps"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.steps);
            return writer;
        };

        /**
         * Encodes the specified Steps message, length delimited. Does not implicitly {@link HealthData.Steps.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.Steps
         * @static
         * @param {HealthData.ISteps} message Steps message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Steps.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Steps message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.Steps
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.Steps} Steps
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Steps.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.Steps();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.steps = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Steps message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.Steps
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.Steps} Steps
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Steps.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Steps message.
         * @function verify
         * @memberof HealthData.Steps
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Steps.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.steps != null && message.hasOwnProperty("steps"))
                if (!$util.isInteger(message.steps))
                    return "steps: integer expected";
            return null;
        };

        /**
         * Creates a Steps message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.Steps
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.Steps} Steps
         */
        Steps.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.Steps)
                return object;
            var message = new $root.HealthData.Steps();
            if (object.steps != null)
                message.steps = object.steps | 0;
            return message;
        };

        /**
         * Creates a plain object from a Steps message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.Steps
         * @static
         * @param {HealthData.Steps} message Steps
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Steps.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.steps = 0;
            if (message.steps != null && message.hasOwnProperty("steps"))
                object.steps = message.steps;
            return object;
        };

        /**
         * Converts this Steps to JSON.
         * @function toJSON
         * @memberof HealthData.Steps
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Steps.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Steps
         * @function getTypeUrl
         * @memberof HealthData.Steps
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Steps.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.Steps";
        };

        return Steps;
    })();

    HealthData.BatteryLevel = (function() {

        /**
         * Properties of a BatteryLevel.
         * @memberof HealthData
         * @interface IBatteryLevel
         * @property {number|null} [batteryLevel] BatteryLevel batteryLevel
         */

        /**
         * Constructs a new BatteryLevel.
         * @memberof HealthData
         * @classdesc Represents a BatteryLevel.
         * @implements IBatteryLevel
         * @constructor
         * @param {HealthData.IBatteryLevel=} [properties] Properties to set
         */
        function BatteryLevel(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BatteryLevel batteryLevel.
         * @member {number} batteryLevel
         * @memberof HealthData.BatteryLevel
         * @instance
         */
        BatteryLevel.prototype.batteryLevel = 0;

        /**
         * Creates a new BatteryLevel instance using the specified properties.
         * @function create
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {HealthData.IBatteryLevel=} [properties] Properties to set
         * @returns {HealthData.BatteryLevel} BatteryLevel instance
         */
        BatteryLevel.create = function create(properties) {
            return new BatteryLevel(properties);
        };

        /**
         * Encodes the specified BatteryLevel message. Does not implicitly {@link HealthData.BatteryLevel.verify|verify} messages.
         * @function encode
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {HealthData.IBatteryLevel} message BatteryLevel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BatteryLevel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.batteryLevel != null && Object.hasOwnProperty.call(message, "batteryLevel"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.batteryLevel);
            return writer;
        };

        /**
         * Encodes the specified BatteryLevel message, length delimited. Does not implicitly {@link HealthData.BatteryLevel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {HealthData.IBatteryLevel} message BatteryLevel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BatteryLevel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BatteryLevel message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.BatteryLevel} BatteryLevel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BatteryLevel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.BatteryLevel();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.batteryLevel = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BatteryLevel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.BatteryLevel} BatteryLevel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BatteryLevel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BatteryLevel message.
         * @function verify
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BatteryLevel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.batteryLevel != null && message.hasOwnProperty("batteryLevel"))
                if (!$util.isInteger(message.batteryLevel))
                    return "batteryLevel: integer expected";
            return null;
        };

        /**
         * Creates a BatteryLevel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.BatteryLevel} BatteryLevel
         */
        BatteryLevel.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.BatteryLevel)
                return object;
            var message = new $root.HealthData.BatteryLevel();
            if (object.batteryLevel != null)
                message.batteryLevel = object.batteryLevel | 0;
            return message;
        };

        /**
         * Creates a plain object from a BatteryLevel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {HealthData.BatteryLevel} message BatteryLevel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BatteryLevel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.batteryLevel = 0;
            if (message.batteryLevel != null && message.hasOwnProperty("batteryLevel"))
                object.batteryLevel = message.batteryLevel;
            return object;
        };

        /**
         * Converts this BatteryLevel to JSON.
         * @function toJSON
         * @memberof HealthData.BatteryLevel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BatteryLevel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BatteryLevel
         * @function getTypeUrl
         * @memberof HealthData.BatteryLevel
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BatteryLevel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.BatteryLevel";
        };

        return BatteryLevel;
    })();

    HealthData.HeartRate = (function() {

        /**
         * Properties of a HeartRate.
         * @memberof HealthData
         * @interface IHeartRate
         * @property {Array.<number>|null} [heartRates] HeartRate heartRates
         */

        /**
         * Constructs a new HeartRate.
         * @memberof HealthData
         * @classdesc Represents a HeartRate.
         * @implements IHeartRate
         * @constructor
         * @param {HealthData.IHeartRate=} [properties] Properties to set
         */
        function HeartRate(properties) {
            this.heartRates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HeartRate heartRates.
         * @member {Array.<number>} heartRates
         * @memberof HealthData.HeartRate
         * @instance
         */
        HeartRate.prototype.heartRates = $util.emptyArray;

        /**
         * Creates a new HeartRate instance using the specified properties.
         * @function create
         * @memberof HealthData.HeartRate
         * @static
         * @param {HealthData.IHeartRate=} [properties] Properties to set
         * @returns {HealthData.HeartRate} HeartRate instance
         */
        HeartRate.create = function create(properties) {
            return new HeartRate(properties);
        };

        /**
         * Encodes the specified HeartRate message. Does not implicitly {@link HealthData.HeartRate.verify|verify} messages.
         * @function encode
         * @memberof HealthData.HeartRate
         * @static
         * @param {HealthData.IHeartRate} message HeartRate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeartRate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heartRates != null && message.heartRates.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.heartRates.length; ++i)
                    writer.int32(message.heartRates[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified HeartRate message, length delimited. Does not implicitly {@link HealthData.HeartRate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.HeartRate
         * @static
         * @param {HealthData.IHeartRate} message HeartRate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeartRate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HeartRate message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.HeartRate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.HeartRate} HeartRate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeartRate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.HeartRate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.heartRates && message.heartRates.length))
                            message.heartRates = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.heartRates.push(reader.int32());
                        } else
                            message.heartRates.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HeartRate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.HeartRate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.HeartRate} HeartRate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeartRate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HeartRate message.
         * @function verify
         * @memberof HealthData.HeartRate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HeartRate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.heartRates != null && message.hasOwnProperty("heartRates")) {
                if (!Array.isArray(message.heartRates))
                    return "heartRates: array expected";
                for (var i = 0; i < message.heartRates.length; ++i)
                    if (!$util.isInteger(message.heartRates[i]))
                        return "heartRates: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a HeartRate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.HeartRate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.HeartRate} HeartRate
         */
        HeartRate.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.HeartRate)
                return object;
            var message = new $root.HealthData.HeartRate();
            if (object.heartRates) {
                if (!Array.isArray(object.heartRates))
                    throw TypeError(".HealthData.HeartRate.heartRates: array expected");
                message.heartRates = [];
                for (var i = 0; i < object.heartRates.length; ++i)
                    message.heartRates[i] = object.heartRates[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a HeartRate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.HeartRate
         * @static
         * @param {HealthData.HeartRate} message HeartRate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HeartRate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.heartRates = [];
            if (message.heartRates && message.heartRates.length) {
                object.heartRates = [];
                for (var j = 0; j < message.heartRates.length; ++j)
                    object.heartRates[j] = message.heartRates[j];
            }
            return object;
        };

        /**
         * Converts this HeartRate to JSON.
         * @function toJSON
         * @memberof HealthData.HeartRate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HeartRate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HeartRate
         * @function getTypeUrl
         * @memberof HealthData.HeartRate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HeartRate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.HeartRate";
        };

        return HeartRate;
    })();

    HealthData.IBIBM = (function() {

        /**
         * Properties of a IBIBM.
         * @memberof HealthData
         * @interface IIBIBM
         * @property {Array.<number>|null} [ibiValues] IBIBM ibiValues
         * @property {Array.<number>|null} [bmValues] IBIBM bmValues
         */

        /**
         * Constructs a new IBIBM.
         * @memberof HealthData
         * @classdesc Represents a IBIBM.
         * @implements IIBIBM
         * @constructor
         * @param {HealthData.IIBIBM=} [properties] Properties to set
         */
        function IBIBM(properties) {
            this.ibiValues = [];
            this.bmValues = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IBIBM ibiValues.
         * @member {Array.<number>} ibiValues
         * @memberof HealthData.IBIBM
         * @instance
         */
        IBIBM.prototype.ibiValues = $util.emptyArray;

        /**
         * IBIBM bmValues.
         * @member {Array.<number>} bmValues
         * @memberof HealthData.IBIBM
         * @instance
         */
        IBIBM.prototype.bmValues = $util.emptyArray;

        /**
         * Creates a new IBIBM instance using the specified properties.
         * @function create
         * @memberof HealthData.IBIBM
         * @static
         * @param {HealthData.IIBIBM=} [properties] Properties to set
         * @returns {HealthData.IBIBM} IBIBM instance
         */
        IBIBM.create = function create(properties) {
            return new IBIBM(properties);
        };

        /**
         * Encodes the specified IBIBM message. Does not implicitly {@link HealthData.IBIBM.verify|verify} messages.
         * @function encode
         * @memberof HealthData.IBIBM
         * @static
         * @param {HealthData.IIBIBM} message IBIBM message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IBIBM.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ibiValues != null && message.ibiValues.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.ibiValues.length; ++i)
                    writer.int32(message.ibiValues[i]);
                writer.ldelim();
            }
            if (message.bmValues != null && message.bmValues.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.bmValues.length; ++i)
                    writer.int32(message.bmValues[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified IBIBM message, length delimited. Does not implicitly {@link HealthData.IBIBM.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.IBIBM
         * @static
         * @param {HealthData.IIBIBM} message IBIBM message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IBIBM.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a IBIBM message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.IBIBM
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.IBIBM} IBIBM
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IBIBM.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.IBIBM();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ibiValues && message.ibiValues.length))
                            message.ibiValues = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.ibiValues.push(reader.int32());
                        } else
                            message.ibiValues.push(reader.int32());
                        break;
                    }
                case 2: {
                        if (!(message.bmValues && message.bmValues.length))
                            message.bmValues = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.bmValues.push(reader.int32());
                        } else
                            message.bmValues.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a IBIBM message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.IBIBM
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.IBIBM} IBIBM
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IBIBM.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a IBIBM message.
         * @function verify
         * @memberof HealthData.IBIBM
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IBIBM.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ibiValues != null && message.hasOwnProperty("ibiValues")) {
                if (!Array.isArray(message.ibiValues))
                    return "ibiValues: array expected";
                for (var i = 0; i < message.ibiValues.length; ++i)
                    if (!$util.isInteger(message.ibiValues[i]))
                        return "ibiValues: integer[] expected";
            }
            if (message.bmValues != null && message.hasOwnProperty("bmValues")) {
                if (!Array.isArray(message.bmValues))
                    return "bmValues: array expected";
                for (var i = 0; i < message.bmValues.length; ++i)
                    if (!$util.isInteger(message.bmValues[i]))
                        return "bmValues: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a IBIBM message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.IBIBM
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.IBIBM} IBIBM
         */
        IBIBM.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.IBIBM)
                return object;
            var message = new $root.HealthData.IBIBM();
            if (object.ibiValues) {
                if (!Array.isArray(object.ibiValues))
                    throw TypeError(".HealthData.IBIBM.ibiValues: array expected");
                message.ibiValues = [];
                for (var i = 0; i < object.ibiValues.length; ++i)
                    message.ibiValues[i] = object.ibiValues[i] | 0;
            }
            if (object.bmValues) {
                if (!Array.isArray(object.bmValues))
                    throw TypeError(".HealthData.IBIBM.bmValues: array expected");
                message.bmValues = [];
                for (var i = 0; i < object.bmValues.length; ++i)
                    message.bmValues[i] = object.bmValues[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a IBIBM message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.IBIBM
         * @static
         * @param {HealthData.IBIBM} message IBIBM
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IBIBM.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.ibiValues = [];
                object.bmValues = [];
            }
            if (message.ibiValues && message.ibiValues.length) {
                object.ibiValues = [];
                for (var j = 0; j < message.ibiValues.length; ++j)
                    object.ibiValues[j] = message.ibiValues[j];
            }
            if (message.bmValues && message.bmValues.length) {
                object.bmValues = [];
                for (var j = 0; j < message.bmValues.length; ++j)
                    object.bmValues[j] = message.bmValues[j];
            }
            return object;
        };

        /**
         * Converts this IBIBM to JSON.
         * @function toJSON
         * @memberof HealthData.IBIBM
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IBIBM.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IBIBM
         * @function getTypeUrl
         * @memberof HealthData.IBIBM
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IBIBM.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.IBIBM";
        };

        return IBIBM;
    })();

    HealthData.PS = (function() {

        /**
         * Properties of a PS.
         * @memberof HealthData
         * @interface IPS
         * @property {Array.<number>|null} [values] PS values
         */

        /**
         * Constructs a new PS.
         * @memberof HealthData
         * @classdesc Represents a PS.
         * @implements IPS
         * @constructor
         * @param {HealthData.IPS=} [properties] Properties to set
         */
        function PS(properties) {
            this.values = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PS values.
         * @member {Array.<number>} values
         * @memberof HealthData.PS
         * @instance
         */
        PS.prototype.values = $util.emptyArray;

        /**
         * Creates a new PS instance using the specified properties.
         * @function create
         * @memberof HealthData.PS
         * @static
         * @param {HealthData.IPS=} [properties] Properties to set
         * @returns {HealthData.PS} PS instance
         */
        PS.create = function create(properties) {
            return new PS(properties);
        };

        /**
         * Encodes the specified PS message. Does not implicitly {@link HealthData.PS.verify|verify} messages.
         * @function encode
         * @memberof HealthData.PS
         * @static
         * @param {HealthData.IPS} message PS message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PS.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.values != null && message.values.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.values.length; ++i)
                    writer.int32(message.values[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified PS message, length delimited. Does not implicitly {@link HealthData.PS.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.PS
         * @static
         * @param {HealthData.IPS} message PS message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PS.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PS message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.PS
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.PS} PS
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PS.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.PS();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.values && message.values.length))
                            message.values = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.values.push(reader.int32());
                        } else
                            message.values.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PS message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.PS
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.PS} PS
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PS.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PS message.
         * @function verify
         * @memberof HealthData.PS
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PS.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (var i = 0; i < message.values.length; ++i)
                    if (!$util.isInteger(message.values[i]))
                        return "values: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a PS message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.PS
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.PS} PS
         */
        PS.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.PS)
                return object;
            var message = new $root.HealthData.PS();
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".HealthData.PS.values: array expected");
                message.values = [];
                for (var i = 0; i < object.values.length; ++i)
                    message.values[i] = object.values[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a PS message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.PS
         * @static
         * @param {HealthData.PS} message PS
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PS.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (message.values && message.values.length) {
                object.values = [];
                for (var j = 0; j < message.values.length; ++j)
                    object.values[j] = message.values[j];
            }
            return object;
        };

        /**
         * Converts this PS to JSON.
         * @function toJSON
         * @memberof HealthData.PS
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PS.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PS
         * @function getTypeUrl
         * @memberof HealthData.PS
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PS.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.PS";
        };

        return PS;
    })();

    HealthData.ERROR = (function() {

        /**
         * Properties of a ERROR.
         * @memberof HealthData
         * @interface IERROR
         * @property {Array.<number>|null} [values] ERROR values
         */

        /**
         * Constructs a new ERROR.
         * @memberof HealthData
         * @classdesc Represents a ERROR.
         * @implements IERROR
         * @constructor
         * @param {HealthData.IERROR=} [properties] Properties to set
         */
        function ERROR(properties) {
            this.values = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ERROR values.
         * @member {Array.<number>} values
         * @memberof HealthData.ERROR
         * @instance
         */
        ERROR.prototype.values = $util.emptyArray;

        /**
         * Creates a new ERROR instance using the specified properties.
         * @function create
         * @memberof HealthData.ERROR
         * @static
         * @param {HealthData.IERROR=} [properties] Properties to set
         * @returns {HealthData.ERROR} ERROR instance
         */
        ERROR.create = function create(properties) {
            return new ERROR(properties);
        };

        /**
         * Encodes the specified ERROR message. Does not implicitly {@link HealthData.ERROR.verify|verify} messages.
         * @function encode
         * @memberof HealthData.ERROR
         * @static
         * @param {HealthData.IERROR} message ERROR message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ERROR.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.values != null && message.values.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.values.length; ++i)
                    writer.int32(message.values[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ERROR message, length delimited. Does not implicitly {@link HealthData.ERROR.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HealthData.ERROR
         * @static
         * @param {HealthData.IERROR} message ERROR message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ERROR.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ERROR message from the specified reader or buffer.
         * @function decode
         * @memberof HealthData.ERROR
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {HealthData.ERROR} ERROR
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ERROR.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.HealthData.ERROR();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.values && message.values.length))
                            message.values = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.values.push(reader.int32());
                        } else
                            message.values.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ERROR message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HealthData.ERROR
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HealthData.ERROR} ERROR
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ERROR.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ERROR message.
         * @function verify
         * @memberof HealthData.ERROR
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ERROR.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (var i = 0; i < message.values.length; ++i)
                    if (!$util.isInteger(message.values[i]))
                        return "values: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a ERROR message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof HealthData.ERROR
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {HealthData.ERROR} ERROR
         */
        ERROR.fromObject = function fromObject(object) {
            if (object instanceof $root.HealthData.ERROR)
                return object;
            var message = new $root.HealthData.ERROR();
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".HealthData.ERROR.values: array expected");
                message.values = [];
                for (var i = 0; i < object.values.length; ++i)
                    message.values[i] = object.values[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a ERROR message. Also converts values to other types if specified.
         * @function toObject
         * @memberof HealthData.ERROR
         * @static
         * @param {HealthData.ERROR} message ERROR
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ERROR.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (message.values && message.values.length) {
                object.values = [];
                for (var j = 0; j < message.values.length; ++j)
                    object.values[j] = message.values[j];
            }
            return object;
        };

        /**
         * Converts this ERROR to JSON.
         * @function toJSON
         * @memberof HealthData.ERROR
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ERROR.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ERROR
         * @function getTypeUrl
         * @memberof HealthData.ERROR
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ERROR.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/HealthData.ERROR";
        };

        return ERROR;
    })();

    return HealthData;
})();

module.exports = $root;
