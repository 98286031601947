import React, { useEffect, useState } from "react";
import {
  BarCharts,
  PieCharts,
  ScatterPlots,
  LineCharts,
  PlotlyHis,
  ScatterPlot,
} from "../charts/charts";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { useFetchPatientStats } from "../common/useFetchComponentInfo";

const preprocessHealthData = (healthData) => {
  const conditions = [
    "Cardiac Issues",
    "Chest Pain",
    "Syncope",
    "Palpitation",
    "Shortness of Breath",
  ];

  const keyMap = {
    "Cardiac Issues": "cardiacIssues",
    "Chest Pain": "chestPain",
    Syncope: "syncope",
    Palpitation: "palpitation",
    "Shortness of Breath": "shortnessOfBreath",
  };

  const groupedData = {
    male: [],
    female: [],
    others: [],
  };

  conditions.forEach((condition) => {
    const key = keyMap[condition];
    const conditionData = healthData[key] || { male: 0, female: 0, others: 0 };
    groupedData.male.push(conditionData.male);
    groupedData.female.push(conditionData.female);
    groupedData.others.push(conditionData.others);
  });

  return {
    xAxisData: conditions,
    seriesData: [
      {
        data: groupedData.male,
        label: "Male",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
      {
        data: groupedData.female,
        label: "Female",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
      {
        data: groupedData.others,
        label: "Others",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
    ],
  };
};

const preprocessHabitsData = (habitsData) => {
  const habits = ["Smoking", "Alcohol"];

  const keyMap = {
    Smoking: "smoking",
    Alcohol: "alcohol",
  };

  const groupedData = {
    male: [],
    female: [],
    others: [],
  };

  habits.forEach((habit) => {
    const key = keyMap[habit];
    const habitData = habitsData[key] || { male: 0, female: 0, others: 0 };
    groupedData.male.push(habitData.male);
    groupedData.female.push(habitData.female);
    groupedData.others.push(habitData.others);
  });

  return {
    xAxisData: habits,
    seriesData: [
      {
        data: groupedData.male,
        label: "Male",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
      {
        data: groupedData.female,
        label: "Female",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
      {
        data: groupedData.others,
        label: "Others",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
    ],
  };
};

const preprocessRiskFactorsData = (riskFactorsData) => {
  const riskFactors = ["Diabetes", "Hypertension"];

  const keyMap = {
    Diabetes: "diabetes",
    Hypertension: "hypertension",
  };

  const groupedData = {
    male: [],
    female: [],
    others: [],
  };

  riskFactors.forEach((riskFactor) => {
    const key = keyMap[riskFactor];
    const riskFactorData = riskFactorsData[key] || {
      male: 0,
      female: 0,
      others: 0,
    };
    groupedData.male.push(riskFactorData.male);
    groupedData.female.push(riskFactorData.female);
    groupedData.others.push(riskFactorData.others);
  });

  return {
    xAxisData: riskFactors,
    seriesData: [
      {
        data: groupedData.male,
        label: "Male",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
      {
        data: groupedData.female,
        label: "Female",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
      {
        data: groupedData.others,
        label: "Others",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
    ],
  };
};

const preprocessSummaryStatistics = (summaryStats) => {
  const statistics = ["Age", "BMI", "Dyslipidemia", "Ejection Fraction"];

  const keyMap = {
    Age: "averageAge",
    BMI: "averageBMI",
    Dyslipidemia: "averageDyslipidemia",
    "Ejection Fraction": "averageEjectionFraction",
  };

  const groupedData = {
    male: [],
    female: [],
    others: [],
  };

  statistics.forEach((stat) => {
    const key = keyMap[stat];
    const statData = summaryStats[key] || { male: 0, female: 0, others: 0 };
    groupedData.male.push(statData.male || 0);
    groupedData.female.push(statData.female || 0);
    groupedData.others.push(statData.others || 0);
  });

  return {
    xAxisData: statistics,
    seriesData: [
      {
        data: groupedData.male,
        label: "Male",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
      {
        data: groupedData.female,
        label: "Female",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
      {
        data: groupedData.others,
        label: "Others",
        outerRadius: 80,
        highlighted: { additionalRadius: 10 },
      },
    ],
  };
};

const PatientStats = ({ id = null }) => {
  const { data, error, isLoading } = useFetchPatientStats(id);
  const [healthData, setHealthData] = useState(null);
  const [habitsData, setHabitsData] = useState(null);
  const [riskFactorsData, setRiskFactorsData] = useState(null);
  const [summaryStatsData, setSummaryStatsData] = useState(null);
  const [bmiCardiacData, setBmiCardiacData] = useState({
    xData: [],
    yData: [],
  });
  const [alcoholCardiacData, setAlcoholCardiacData] = useState({
    xData: [],
    yData: [],
  });
  const [smokingRespiratoryData, setSmokingRespiratoryata] = useState({
    xData: [],
    yData: [],
  });

  useEffect(() => {
    if (data && data.healthConditions) {
      setHealthData(preprocessHealthData(data.healthConditions));
    }
    if (data && data.habits) {
      setHabitsData(preprocessHabitsData(data.habits));
    }
    if (data && data.riskFactors) {
      setRiskFactorsData(preprocessRiskFactorsData(data.riskFactors));
    }
    if (data && data.summaryStatistics) {
      setSummaryStatsData(preprocessSummaryStatistics(data.summaryStatistics));
    }
    if (data && data.correlations && data.correlations.bmiCardiacCorrelation) {
      const xData = data.correlations.bmiCardiacCorrelation.map((item) =>
        parseFloat(item.bmi)
      );
      const yData = data.correlations.bmiCardiacCorrelation.map(
        (item) => item.cardiac_issue
      );
      setBmiCardiacData({ xData, yData });
    }
    if (
      data &&
      data.correlations &&
      data.correlations.alcoholCardiacCorrelation
    ) {
      const xData = data.correlations.alcoholCardiacCorrelation.map((item) =>
        parseFloat(item.alcohol)
      );
      const yData = data.correlations.alcoholCardiacCorrelation.map(
        (item) => item.cardiac_issue
      );
      setAlcoholCardiacData({ xData, yData });
    }
    if (
      data &&
      data.correlations &&
      data.correlations.smokingRespiratoryCorrelation
    ) {
      const xData = data.correlations.smokingRespiratoryCorrelation.map(
        (item) => parseFloat(item.smoking)
      );
      const yData = data.correlations.smokingRespiratoryCorrelation.map(
        (item) => item.shortness_of_breath
      );
      setSmokingRespiratoryata({ xData, yData });
    }
  }, [data]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <h1>Patient Statistics</h1>
      </Box>
      <Grid container spacing={2}>
        {/* Gender Distribution */}
        <Grid item xs={12} md={6} lg={6}>
          <PieCharts
            data={[
              { id: "Male", value: data.gender.male },
              { id: "Female", value: data.gender.female },
              { id: "Others", value: data.gender.others },
            ]}
            title="Gender Distribution"
          />
        </Grid>

        {/* Age Distribution*/}
        <Grid item xs={12} md={6} lg={6}>
          {data && data.distributions && data.distributions.ageDistribution ? (
            <PlotlyHis
              data={data.distributions.ageDistribution}
              title="Age Distribution"
              xAxisTitle="Age"
              pColor={1}
              sColor={1}
            />
          ) : (
            <div>Loading age distribution data...</div>
          )}
        </Grid>

        {/* Height Distribution */}
        <Grid item xs={12} md={6} lg={6}>
          {data &&
          data.distributions &&
          data.distributions.heightDistribution ? (
            <PlotlyHis
              data={data.distributions.heightDistribution}
              title="Height Distribution"
              xAxisTitle="Height in cm"
            />
          ) : (
            <div>Loading height distribution data...</div>
          )}
        </Grid>

        {/* Weight Distribution */}
        <Grid item xs={12} md={6} lg={6}>
          {data &&
          data.distributions &&
          data.distributions.weightDistribution ? (
            <PlotlyHis
              data={data.distributions.weightDistribution}
              title="Weight Distribution"
              xAxisTitle="Weight in Kgs"
              pColor={2}
              sColor={2}
            />
          ) : (
            <div>Loading weight distribution data...</div>
          )}
        </Grid>

        {/* BMI Distribution */}
        <Grid item xs={12} md={6} lg={6}>
          {data && data.distributions && data.distributions.bmiDistribution ? (
            <PlotlyHis
              data={data.distributions.bmiDistribution}
              title="BMI Distribution"
              xAxisTitle="BMI"
            />
          ) : (
            <div>Loading BMI distribution data...</div>
          )}
        </Grid>
        {/* Dyslipidemia Distribution */}
        <Grid item xs={12} md={6} lg={6}>
          {data &&
          data.distributions &&
          data.distributions.dyslipidemiaDistribution ? (
            <PlotlyHis
              data={data.distributions.dyslipidemiaDistribution}
              title="Dyslipidemia Distribution"
              xAxisTitle="Dyslipidemia"
              pColor={2}
              sColor={2}
            />
          ) : (
            <div>Loading Dyslipidemia distribution data...</div>
          )}
        </Grid>

        {/* EjectionFraction Distribution */}
        <Grid item xs={12} md={6} lg={6}>
          {data &&
          data.distributions &&
          data.distributions.ejectionFractionDistribution ? (
            <PlotlyHis
              data={data.distributions.ejectionFractionDistribution}
              title="EjectionFraction Distribution"
              xAxisTitle="EjectionFraction"
            />
          ) : (
            <div>Loading Dyslipidemia distribution data...</div>
          )}
        </Grid>

        {/* Health Conditions */}
        <Grid item xs={12} md={6} lg={6}>
          {healthData ? (
            <BarCharts
              xAxisData={healthData.xAxisData}
              seriesData={healthData.seriesData}
              title="Health Conditions"
            />
          ) : (
            <div>Loading chart data...</div>
          )}
        </Grid>

        {/* Habits */}
        <Grid item xs={12} md={6} lg={6}>
          {habitsData ? (
            <BarCharts
              xAxisData={habitsData.xAxisData}
              seriesData={habitsData.seriesData}
              title="Habits"
            />
          ) : (
            <div>Loading habits data...</div>
          )}
        </Grid>

        {/* Risk Factors */}
        <Grid item xs={12} md={6} lg={6}>
          {riskFactorsData ? (
            <BarCharts
              xAxisData={riskFactorsData.xAxisData}
              seriesData={riskFactorsData.seriesData}
              title="Risk Factors"
            />
          ) : (
            <div>Loading risk factors data...</div>
          )}
        </Grid>

        {/* Summary Statistics */}
        <Grid item xs={12} md={6} lg={6}>
          {summaryStatsData ? (
            <BarCharts
              xAxisData={summaryStatsData.xAxisData}
              seriesData={summaryStatsData.seriesData}
              title="Summary Statistics"
            />
          ) : (
            <div>Loading summary statistics data...</div>
          )}
        </Grid>

        {/* Correlations */}
        {/* Scatter Plot for BMI vs Cardiac Issues */}
        <Grid item xs={12} md={6} lg={6}>
          {bmiCardiacData ? (
            <ScatterPlot
              xData={bmiCardiacData.xData}
              yData={bmiCardiacData.yData}
              xAxisTitle="BMI"
              yAxisTitle="Cardiac Issues"
              title="BMI vs Cardiac Issues"
              width={600}
              height={400}
            />
          ) : (
            <div>Loading summary statistics data...</div>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {alcoholCardiacData ? (
              <ScatterPlot
                xData={alcoholCardiacData.xData}
                yData={alcoholCardiacData.yData}
                xAxisTitle="Alcohol"
                yAxisTitle="Cardiac Issues"
                title="Alcohol vs Cardiac Issues"
                width={1200}
                height={400}
                pColor={1}
              />
            ) : (
              <div>Loading summary statistics data...</div>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {smokingRespiratoryData ? (
              <ScatterPlot
                xData={smokingRespiratoryData.xData}
                yData={smokingRespiratoryData.yData}
                xAxisTitle="Smoking"
                yAxisTitle="Shortness of Breath"
                title="Smoking vs Shortness of Breath"
                width={1200}
                height={400}
              />
            ) : (
              <div>Loading summary statistics data...</div>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientStats;
