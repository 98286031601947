import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import PatientMyProfile from "./PatientMyProfile";
import CenteredProgress from "../global/CenteredProgress";
import Vitals from "./PatientVitals";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import PatientRPM from "./PatientRPM";
import TenantInfo from "../tenants/TenantInfo";
import LocalHospitalIcon from "@mui/icons-material/LocalHospitalOutlined";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupiedOutlined";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import CastIcon from "@mui/icons-material/CastOutlined";
import EditNoteIcon from "@mui/icons-material/EditNoteOutlined";
import ReportList from "./ReportsList";
import LatestReport from "./LatestReport";
import { useFetchHealthProfile } from "../../components/patients/useFetchPatientInfo";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PatientDash() {
  const location = useLocation();
  const getTabValueFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam !== null && !isNaN(Number(tabParam))) {
      return parseInt(tabParam, 10);
    }
    return null; // Return null if the 'tab' parameter doesn't exist or is not a number
  };

  const [value, setValue] = useState(() => {
    // Try to get the tab value from URL first
    const tabValueFromURL = getTabValueFromURL();
    if (tabValueFromURL !== null) {
      return tabValueFromURL;
    }
    // Fallback to localStorage or default to 0
    return parseInt(localStorage.getItem("myDash") || "0", 10);
  });

  useEffect(() => {
    // Update state when URL changes
    const tabValueFromURL = getTabValueFromURL();
    if (tabValueFromURL !== null) {
      setValue(tabValueFromURL);
    }
  }, [location.search]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("myDash", newValue.toString());
  };
  const {
    data: patientProfile,
    isLoading: isPatientProfileLoading,
    isError: isPatientProfileError,
    error: patientProfileError,
    refetch: refetchPatientProfile,
  } = useFetchHealthProfile();

  return (
    <Box sx={{ width: "100%" }}>
      {isPatientProfileLoading && <CenteredProgress />}
      {patientProfile && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 5,
              borderBottom: 1,
              borderColor: colors.pastelColor.blue,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Reports"
              TabIndicatorProps={{
                style: {
                  backgroundColor: colors.pastelColor.blue,
                },
              }}
            >
              <Tab
                icon={<HealthAndSafetyIcon />}
                label="Health Profile"
                {...a11yProps(0)}
                sx={{
                  color: colors.primary,
                  fontSize: "1.0rem",
                  "&.Mui-selected": {
                    color: colors.greenAccent[400],
                  },
                  "&:hover": {
                    color: colors.pastelColor.teal,
                  },
                }}
              />
              <Tab
                icon={<SensorOccupiedIcon />}
                label="My Vitals"
                {...a11yProps(1)}
                sx={{
                  color: colors.primary,
                  fontSize: "1.0rem",
                  "&.Mui-selected": {
                    color: colors.greenAccent[400],
                  },
                  "&:hover": {
                    color: colors.pastelColor.teal,
                  },
                }}
              />
              <Tab
                icon={<CastIcon />}
                label="RPM"
                {...a11yProps(2)}
                sx={{
                  color: colors.primary,
                  fontSize: "1.0rem",
                  "&.Mui-selected": {
                    color: colors.greenAccent[400],
                  },
                  "&:hover": {
                    color: colors.pastelColor.teal,
                  },
                }}
              />
              <Tab
                icon={<LocalHospitalIcon />}
                label="My Hospital"
                {...a11yProps(3)}
                sx={{
                  color: colors.primary,
                  fontSize: "1.0rem",
                  "&.Mui-selected": {
                    color: colors.greenAccent[400],
                  },
                  "&:hover": {
                    color: colors.pastelColor.teal,
                  },
                }}
              />
              <Tab
                icon={<EditNoteIcon />}
                label="Latest Report"
                {...a11yProps(4)}
                sx={{
                  color: colors.primary,
                  fontSize: "1.0rem",
                  "&.Mui-selected": {
                    color: colors.greenAccent[400],
                  },
                  "&:hover": {
                    color: colors.pastelColor.teal,
                  },
                }}
              />
              <Tab
                icon={<EditNoteIcon />}
                label="My Reports"
                {...a11yProps(5)}
                sx={{
                  color: colors.primary,
                  fontSize: "1.0rem",
                  "&.Mui-selected": {
                    color: colors.greenAccent[400],
                  },
                  "&:hover": {
                    color: colors.pastelColor.teal,
                  },
                }}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <PatientMyProfile initialProfile={patientProfile} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Vitals key="selfvitals" initialProfile={patientProfile} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PatientRPM key="selfrpm" initialProfile={patientProfile} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TenantInfo />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Container
              maxWidth="lg"
              sx={{
                border: `1px solid ${colors.greenAccent[400]}`,
                backgroundColor: colors.primary[400],
              }}
            >
              <LatestReport />
            </Container>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Container
              maxWidth="xl"
              sx={{
                border: `1px solid ${colors.greenAccent[400]}`,
              }}
            >
              <ReportList />
            </Container>
          </TabPanel>
        </>
      )}
    </Box>
  );
}
