import { useGetDataSummaryByEmail } from "../../components/patients/useFetchPatientInfo";
import CenteredProgress from "../global/CenteredProgress";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO } from "date-fns";
import { de, enGB, zhCN, enUS } from "date-fns/locale";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import moment from "moment-timezone";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  IconButton,
  Avatar,
  Typography,
  Grid,
  Tooltip,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { getLocalStorageItem } from "../../components/auth/storage";
import Plot from "react-plotly.js";

const locales = { "en-gb": enGB, "en-us": enUS, "zh-cn": zhCN, de };

const Vitals = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const primaryMainColor = theme.palette.background.default;
  const location = useLocation();
  const navigate = useNavigate();
  const initialProfileFromLocation = location.state?.initialProfile;
  const [initialProfile, setInitialProfile] = useState(
    props.initialProfile || initialProfileFromLocation
  );
  const [locale, setLocale] = React.useState("en-gb");
  const emailVerified = initialProfile?.isEmailVerified;
  const phoneVerified = initialProfile?.isPhoneNoVerified;
  const [initialEmail, setInitialEmail] = useState("");
  const [initialTimeZone, setInitialTimeZone] = useState("");
  const [plotConfig, setPlotConfig] = useState({});
  const vitalsList = [
    { key: "HRV", value: "Heart Rate" },
    { key: "SPO2", value: "Blood Oxygen Saturation" },
    { key: "IBI", value: "Interbeat Interval" },
    { key: "SKIN_TEMP", value: "Skin Temperature" },
    { key: "BM", value: "Beats Missed" },
    { key: "STEPS", value: "Pedometer" },
  ];
  const [selectedVital, setSelectedVital] = useState("SPO2");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedTitle2, setSelectedTitle2] = useState("");
  const [selectedX, setSelectedX] = useState("Time");
  const [selectedX2] = useState("Date");
  const [selectedY, setSelectedY] = useState("");
  const [selectedColor, setSelectedColor] = useState("#FF6D6A");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [optionsMinute, setOptionsMinute] = useState({});
  const [optionsDaily, setOptionsDaily] = useState({});
  const currentUserEmail = getLocalStorageItem("username");
  const [userEmail, setUserEmail] = useState("");
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!initialProfile) {
      setUserEmail(currentUserEmail);
    } else {
      setInitialEmail(initialProfile.headerEntity.email);
      setInitialTimeZone(initialProfile.timeZone);
    }
    if (initialProfile.timeZone) {
      const dateInTimeZone = moment
        .tz(new Date(), initialProfile.timeZone)
        .format("YYYY-MM-DD");
      setSelectedDate(dateInTimeZone);
      setSelectedX("Time at : " + initialProfile.timeZone);
    }
  }, [initialProfile]);

  useEffect(() => {
    const timeZone = initialTimeZone;
    const fromDate = moment.tz(selectedDate, timeZone).startOf("day").toDate();
    const toDate = moment.tz(selectedDate, timeZone).endOf("day").toDate();
    const weekBefore = moment
      .tz(selectedDate, timeZone)
      .subtract(6, "days")
      .startOf("day")
      .toDate();

    if (selectedVital === "HRV") {
      setSelectedTitle("Heart Rate Minute Average");
      setSelectedTitle2("Heart Rate Daily Average");
      setSelectedY("Heart Rate (bpm)");
      setSelectedColor(colors.pastelColor.red);
    } else if (selectedVital === "SPO2") {
      setSelectedTitle("SpO2 Minute Average");
      setSelectedTitle2("SpO2 Daily Average");
      setSelectedY("SpO2 (%)");
      setSelectedColor(colors.pastelColor.green);
    } else if (selectedVital === "IBI") {
      setSelectedTitle("Inter Beat Intervals Minute Average");
      setSelectedTitle2("Inter Beat Intervals Daily Average");
      setSelectedY("Inter Beat Intervals (ms)");
      setSelectedColor(colors.pastelColor.orange);
    } else if (selectedVital === "SKIN_TEMP") {
      setSelectedTitle("Body temperature Minute Average");
      setSelectedTitle2("Body temperature Daily Average");
      setSelectedY("Body temperature (C)");
      setSelectedColor(colors.pastelColor.yellow);
    } else if (selectedVital === "BM") {
      setSelectedTitle("Beats Missed Minute Count");
      setSelectedTitle2("Beats Missed Per Day");
      setSelectedY("Beats Missed");
      setSelectedColor(colors.pastelColor.red);
    } else if (selectedVital === "STEPS") {
      setSelectedTitle("Steps Minute Count");
      setSelectedTitle2("Daily Step Count");
      setSelectedY("Steps");
      setSelectedColor(colors.pastelColor.brown);
    }
    if (selectedVital === "BM" || selectedVital === "STEPS") {
      setPlotConfig({
        type: "bar",
        marker: { color: selectedColor },
      });
    } else {
      setPlotConfig({
        type: "scatter",
        mode: "lines",
        marker: { color: selectedColor },
        line: {
          shape: "spline",
          smoothing: 1.3,
          width: 1.8,
        },
      });
    }

    const aggForVital =
      selectedVital === "BM" || selectedVital === "STEPS" ? "sum" : "avg";

    setOptionsMinute({
      fromDate: fromDate.getTime(),
      toDate: toDate.getTime(),
      email: initialEmail,
      vital: selectedVital,
      agg: aggForVital,
      interval: "Minute",
      summary: true,
      timezone: initialTimeZone,
    });

    setOptionsDaily({
      fromDate: weekBefore.getTime(),
      toDate: toDate.getTime(),
      email: initialEmail,
      vital: selectedVital,
      agg: aggForVital,
      interval: "Daily",
      summary: false,
      timezone: initialTimeZone,
    });
  }, [
    selectedVital,
    selectedDate,
    initialEmail,
    initialTimeZone,
    colors.pastelColor.brown,
    colors.pastelColor.green,
    colors.pastelColor.orange,
    colors.pastelColor.red,
    colors.pastelColor.yellow,
  ]);

  const { data, isLoading, isError, error, refetch } =
    useGetDataSummaryByEmail(optionsMinute);
  const {
    data: dailyData,
    isLoading: isDailyLoading,
    isError: isDailyError,
    error: dailyError,
    refetch: refetchDaily,
  } = useGetDataSummaryByEmail(optionsDaily);
  const [plotData, setPlotData] = useState({ x: [], y: [] });
  const [dailyPlotData, setDailyPlotData] = useState({ x: [], y: [] });
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    if (data && data.data) {
      const timeZone = initialTimeZone;
      if (data.data.length === 0) {
        const nextDay = moment.tz(selectedDate, timeZone).add(1, "days");
        const startOfNextDay = nextDay.clone().startOf("day");
        const startDayStr = startOfNextDay.format("YYYY-MM-DD");
        const endOfNextDay = nextDay.clone().endOf("day");
        const endDayStr = endOfNextDay.format("YYYY-MM-DD");

        setPlotData({ x: [startDayStr, endDayStr], y: [null, null] });
        toast.info(
          "No day data available for the selected patient / parameters."
        );
      } else {
        // Transform the data into a format suitable for Plotly
        const transformedData = {
          x: data.data.map((d) =>
            moment.tz(d.UnixTime * 1000, timeZone).format("YYYY-MM-DD HH:mm:ss")
          ),
          y: data.data.map((d) => d.Aggval),
        };
        setPlotData(transformedData);
      }
    }
    if (data && data.summary) {
      setSummary(data.summary[0]);
    }
  }, [data, initialTimeZone]);

  useEffect(() => {
    const convertToLocalDateString = (unixTimestamp, timeZone) => {
      return moment.unix(unixTimestamp).tz(timeZone).format("YYYY-MM-DD");
    };

    const timeZone = initialTimeZone;

    const startDate = moment.tz(selectedDate, timeZone).subtract(6, "days");

    const dates = [];
    for (let i = 0; i <= 6; i++) {
      const date = startDate.clone().add(i, "days");
      dates.push(date.format("YYYY-MM-DD"));
    }

    const y = new Array(7).fill(null);

    if (dailyData && dailyData.data) {
      if (dailyData.data.length === 0) {
        // No data is available
        setDailyPlotData({ x: dates, y });
        toast.info(
          "No weekly data available for the selected patient / parameters."
        );
      } else {
        // Data is available, map it to the respective dates
        dailyData.data.forEach((dataPoint) => {
          const dataDate = convertToLocalDateString(
            dataPoint.UnixTime,
            timeZone
          );
          const index = dates.findIndex((date) => date === dataDate);
          if (index !== -1) {
            y[index] = dataPoint.Aggval;
          }
        });
        setDailyPlotData({ x: dates, y });
      }
    }
  }, [dailyData, initialTimeZone, selectedDate]);

  const handleVitalChange = (event) => {
    setSelectedVital(event.target.value);
  };

  const handleDateChange = (newValue) => {
    const fixedDate = format(newValue, "yyyy-MM-dd");
    setSelectedDate(fixedDate);
  };

  useEffect(() => {
    // Additionally, handle errors from React Query
    if (isError && error) {
      // Display a more specific error message if available, or a generic message
      toast.error(error.message || "An error occurred while fetching data.");
    }

    if (isDailyError && dailyError) {
      // Display a more specific error message if available, or a generic message
      toast.error(
        dailyError.message || "An error occurred while fetching data."
      );
    }
  }, [isError, error, isDailyError, dailyError]);

  if (isLoading || isDailyLoading) return <CenteredProgress />;

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Vitals" subtitle="Omnyk patient's vitals." />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Container
          maxWidth="lg"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
          }}
        >
          {initialProfile && (
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {/* First column for Avatar, Email, and Phone */}
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  src={initialProfile.headerEntity.avatar}
                  alt="avatar"
                  sx={{
                    width: 80,
                    height: 80,
                    marginRight: "20px",
                  }}
                />

                <Box sx={{ textAlign: "left" }}>
                  <Typography variant="h5" component="h2">
                    {initialProfile.headerEntity.name}
                    {emailVerified && phoneVerified && (
                      <VerifiedUserIcon
                        sx={{ color: "teal", marginLeft: "5px" }}
                      />
                    )}
                  </Typography>
                  <Typography variant="body1">
                    Email: {initialProfile.headerEntity.email}
                    {emailVerified && (
                      <VerifiedIcon sx={{ color: "teal", marginLeft: "5px" }} />
                    )}
                  </Typography>
                  <Typography variant="body2">
                    Phone: {initialProfile.headerEntity.phoneNum}
                    {phoneVerified && (
                      <VerifiedIcon sx={{ color: "teal", marginLeft: "5px" }} />
                    )}
                  </Typography>
                  {initialProfile.bodyEntity
                    .filter((section) => section.title === "About Me")
                    .flatMap((section) => section.content)
                    .filter(
                      (item) =>
                        item.title === "Tenant ID" || item.title === "Ring ID"
                    )
                    .map((item, idx) => (
                      <Typography
                        key={idx}
                        variant="body2"
                        sx={{ marginTop: "5px" }}
                      >
                        {item.title}: {item.value}
                      </Typography>
                    ))}
                </Box>
              </Grid>

              {/* Second and Third columns for About Me and Emergency Contact */}
              {initialProfile.bodyEntity
                .filter(
                  (section) =>
                    section.title === "About Me" ||
                    section.title === "Emergency Contact"
                )
                .map((section, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{ marginBottom: "10px" }}
                    >
                      {section.title}
                    </Typography>
                    {section.content.map((item, idx) => {
                      const aboutMeCriteria = [
                        "Gender",
                        "Height",
                        "Weight",
                        "BMI",
                      ];
                      const shouldDisplay =
                        section.title === "About Me"
                          ? aboutMeCriteria.includes(item.title)
                          : true;

                      return (
                        shouldDisplay && (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <Box
                              component="img"
                              src={item.icon}
                              alt={item.title}
                              sx={{
                                width: 15,
                                height: 15,
                                marginRight: "10px",
                              }}
                            />
                            <Box>
                              <Typography variant="subtitle2">
                                {item.title}
                              </Typography>
                              <Typography variant="body2">
                                {item.value}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      );
                    })}
                  </Grid>
                ))}
            </Grid>
          )}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            m={2}
            sx={{
              border: `1px solid ${colors.greenAccent[800]}`,
              backgroundColor: colors.primary[400],
            }}
          >
            <FormControl variant="outlined" sx={{ margin: 2 }}>
              <InputLabel id="vital-select-label">Vital</InputLabel>
              <Select
                labelId="vital-select-label"
                id="vital-select"
                value={selectedVital}
                onChange={handleVitalChange}
                label="Vital"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: primaryMainColor,
                    },
                  },
                }}
              >
                {vitalsList.map((vital) => (
                  <MenuItem key={vital.key} value={vital.key}>
                    {vital.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Date Picker */}
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locales[locale]}
            >
              <DatePicker
                label="Select Date"
                format="dd-MMM-yyyy"
                defaultValue={parseISO(selectedDate)}
                onChange={(newValue) => handleDateChange(newValue)}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
                views={["year", "month", "day"]}
                minDate={new Date("2022-01-01")}
                maxDate={new Date("2026-01-01")}
              />
            </LocalizationProvider>
          </Box>
          {summary && selectedVital !== "STEPS" && selectedVital !== "BM" && (
            <TableContainer
              component={Paper}
              sx={{
                marginBottom: "20px",
                overflowX: "auto",
                backgroundColor: colors.primary[400],
              }}
            >
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Vital</TableCell>
                    <TableCell align="right">Average</TableCell>
                    <TableCell align="right">Max</TableCell>
                    <TableCell align="right">Min</TableCell>
                    <TableCell align="right">Standard Deviation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={summary.vital}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {summary.vital}
                    </TableCell>
                    <TableCell align="right">{summary.avg}</TableCell>
                    <TableCell align="right">{summary.max}</TableCell>
                    <TableCell align="right">{summary.min}</TableCell>
                    <TableCell align="right">
                      {summary.stddev.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {summary && (selectedVital === "STEPS" || selectedVital === "BM") && (
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  marginBottom: "20px",
                  overflowX: "auto",
                  backgroundColor: colors.primary[400],
                  maxWidth: "fit-content", // Adjust the table width to its content
                }}
              >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Vital</TableCell>
                      <TableCell align="right">Count Since Midnight</TableCell>
                      {selectedVital === "STEPS" && (
                        <TableCell align="right">
                          Distance Covered (km)
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={summary.vital}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {summary.vital}
                      </TableCell>
                      <TableCell align="right">{summary.sum}</TableCell>
                      {selectedVital === "STEPS" && (
                        <TableCell align="right">
                          {(summary.sum / 1250).toFixed(2)}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <Grid container justifyContent="flex-end" alignItems="center" mt={2}>
            <Grid item>
              <Tooltip title={`Refresh vitals`} placement="top">
                <span>
                  <IconButton onClick={() => {refetch(); refetchDaily();}} aria-label="refetch">
                    <RefreshIcon
                      sx={{
                        color: colors.pastelColor.blue,
                      }}
                      fontSize="large"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
          <Plot
            data={[
              {
                x: plotData.x,
                y: plotData.y,
                ...plotConfig, // Spread the plotConfig to apply the type and other properties
              },
            ]}
            layout={{
              paper_bgcolor: colors.primary[400],
              plot_bgcolor: colors.primary[400],
              xaxis: {
                title: selectedX,
                type: "date",
                color: colors.grey[100],
                gridcolor: colors.grey[100],
                tickformat: '%d %b %Y\n%H:%M:%S',
              },
              yaxis: {
                title: selectedY,
                color: colors.grey[100],
                gridcolor: colors.grey[100],
              },
              font: {
                color: colors.grey[100],
              },
              title: {
                text: selectedTitle,
                font: {
                  color: colors.grey[100],
                },
              },
            }}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
          />
          <Box mt={2}>
            <Plot
              data={[
                {
                  x: dailyPlotData.x,
                  y: dailyPlotData.y,
                  type: "bar",
                  marker: { color: selectedColor },
                },
              ]}
              layout={{
                paper_bgcolor: colors.primary[400], // Set the background color of the paper to black
                plot_bgcolor: colors.primary[400], // Set the background color of the plot to black
                xaxis: {
                  title: selectedX2,
                  type: "date",
                  color: colors.grey[100], // Set the color of x-axis labels to white
                  gridcolor: colors.grey[100], // Set the color of grid lines to grey
                  tickformat: '%d %b \n %Y'
                },
                yaxis: {
                  title: selectedY,
                  color: colors.grey[100], // Set the color of y-axis labels to white
                  gridcolor: colors.grey[100], // Set the color of grid lines to grey
                },
                font: {
                  color: colors.grey[100], // Set the color of all text to white
                },
                title: {
                  text: selectedTitle2,
                  font: {
                    color: colors.grey[100], // Set the title color to white
                  },
                },
              }}
              useResizeHandler={true}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Vitals;
