import React from "react";
import { Typography, Box, Grid, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StressAnalysisDisplay from "./reports/stressAnalysis";
import ActivityAnalysisDisplay from "./reports/activityAnalysis";
import HeartHealthAnalysisDisplay from "./reports/heartHealthAnalysis";
import HRVAnalysisDisplay from "./reports/hrvAnalysis";
import SleepAnalysisDisplay from "./reports/sleepAnalysis";
import VitalsDataCard from "./reports/vitalsDataCard";
import {
  AssessmentOutlined,
  AvTimerOutlined,
  DescriptionOutlined,
  HeatPumpRounded,
  LocalHospitalOutlined,
  PermIdentityOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { formatDate } from "../forms/utilities";

const groupVitalsData = (vitalsData) => {
  const groupedData = {
    temperature: { current: vitalsData.temperature, unit: "°C" },
    bloodPressure: {
      systolic: vitalsData.bloodPressureSystolic,
      diastolic: vitalsData.bloodPressureDiastolic,
      unit: "mmHg",
    },
    heartRate: {
      min: vitalsData.hrMin,
      max: vitalsData.hrMax,
      avg: vitalsData.hrAvg,
      unit: "bpm",
    },
    spo2: {
      min: vitalsData.spo2Min,
      max: vitalsData.spo2Max,
      avg: vitalsData.spo2Avg,
      unit: "%",
    },
    ibi: {
      min: vitalsData.ibiMin,
      max: vitalsData.ibiMax,
      avg: vitalsData.ibiAvg,
      processedMin: vitalsData.ibiProcessedMin,
      processedMax: vitalsData.ibiProcessedMax,
      processedAvg: vitalsData.ibiProcessedAvg,
      unit: "ms",
    },
    nnclean: {
      min: vitalsData.nncleanMin,
      max: vitalsData.nncleanMax,
      avg: vitalsData.nncleanAvg,
      unit: "ms",
    },
    ringUsage: { current: vitalsData.ringUsage, unit: "ms" },
  };
  return groupedData;
};

const PatientReportPage = ({ data }) => {
  const VitalsData = groupVitalsData(data?.vitalsData);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom component="div">
        Patient Report
      </Typography>

      {/* Patient Info */}
      <Paper
        sx={{
          p: 2,
          mb: 3,
          backgroundColor: colors.primary[400],
          border: 2,
          borderColor: colors.pastelColor.blue,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <PermIdentityOutlined sx={{ mr: 1 }} />
              <Typography variant="h6">{data.patientName}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <LocalHospitalOutlined sx={{ mr: 1 }} />
              <Typography color="textSecondary">
                {data.tenantName} ({data.tenantCode})
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <TodayOutlined sx={{ mr: 1 }} />
              <Typography color="textSecondary">
                Age: {data.patientAge}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <HeatPumpRounded sx={{ mr: 1 }} />
              <Typography>Health Score: {data.healthScore}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <AssessmentOutlined sx={{ mr: 1 }} />
              <Typography> {data.summary}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <AvTimerOutlined sx={{ mr: 1 }} />
              <Typography>
                Ring Usage:{" "}
                {data?.vitalsData?.ringUsage !== undefined
                  ? `${Math.floor(data.vitalsData.ringUsage / 60)}h ${
                      data.vitalsData.ringUsage % 60
                    }m`
                  : "No Data!"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <DescriptionOutlined sx={{ mr: 1 }} />
              <Typography>
                Report Type:{" "}
                {data.reportType.charAt(0).toUpperCase() +
                  data.reportType.slice(1)}
              </Typography>
            </Box>
            {data.reportType === "custom" && (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <AccessTimeIcon sx={{ mr: 1 }} />
                <Typography>
                  Start Date: {formatDate(data.startDate)}
                </Typography>
                <Typography ml={2}>
                  End Date: {formatDate(data.endDate)}
                </Typography>
              </Box>
            )}
            {data.reportType === "daily" && (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <AccessTimeIcon sx={{ mr: 1 }} />
                <Typography>
                  Report Date: {formatDate(data.reportDate)}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Typography variant="h5" gutterBottom component="div">
        Vitals Overview
      </Typography>
      {VitalsData && (
        <Box display="flex" alignItems="center" ml={5} justifyContent="center">
          <Grid container spacing={3} mt={3}>
            <VitalsDataCard
              title="Heart Rate"
              value={VitalsData.heartRate.avg}
              unit={VitalsData.heartRate.unit}
              min={VitalsData.heartRate.min}
              max={VitalsData.heartRate.max}
              avg={VitalsData.heartRate.avg}
              lpcolor={colors.pastelColor.red}
              sx={{ ml: 2 }}
            />
            <VitalsDataCard
              title="SpO2"
              value={VitalsData.spo2.avg}
              unit={VitalsData.spo2.unit}
              min={VitalsData.spo2.min}
              max={VitalsData.spo2.max}
              avg={VitalsData.spo2.avg}
              lpcolor={colors.pastelColor.green}
              sx={{ ml: 2 }}
            />
            <VitalsDataCard
              title="IBI"
              value={VitalsData.ibi.avg}
              unit={VitalsData.ibi.unit}
              min={VitalsData.ibi.min}
              max={VitalsData.ibi.max}
              avg={VitalsData.ibi.avg}
              lpcolor={colors.pastelColor.blue}
              sx={{ ml: 2 }}
            />
            <VitalsDataCard
              title="NN-Clean"
              value={VitalsData.nnclean.avg}
              unit={VitalsData.nnclean.unit}
              min={VitalsData.nnclean.min}
              max={VitalsData.nnclean.max}
              avg={VitalsData.nnclean.avg}
              lpcolor={colors.pastelColor.blue}
              sx={{ ml: 2 }}
            />
          </Grid>
        </Box>
      )}
      <Typography variant="h5" gutterBottom component="div">
        Heart Health Analysis
      </Typography>
      {data.heartHealthAnalysis && (
        <HeartHealthAnalysisDisplay data={data.heartHealthAnalysis} />
      )}
      <Typography variant="h5" gutterBottom component="div" mt={2}>
        Activity Analysis
      </Typography>
      {data.activityAnalysis && (
        <ActivityAnalysisDisplay activityAnalysis={data.activityAnalysis} />
      )}
      <Typography variant="h5" gutterBottom component="div" mt={2}>
        HRV Analysis
      </Typography>
      {data.hrvAnalysis && <HRVAnalysisDisplay data={data.hrvAnalysis} />}
      {data.sleepStressAnalysis && (
        <>
          <SleepAnalysisDisplay data={data.sleepStressAnalysis} />
          <StressAnalysisDisplay stressData={data.sleepStressAnalysis} />
        </>
      )}
    </Box>
  );
};

export default PatientReportPage;
