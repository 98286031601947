import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";
import { useFetchTenantStats } from "../../components/tenant/useFetchTenantInfo";
import { useTheme } from "@mui/material/styles";
import { useNavigateWithFilters } from "../../components/common/navigationFilters";
import { tokens } from "../../theme";
import DevicesOtherIcon from "@mui/icons-material/DevicesOtherOutlined";
import AirlineSeatFlatAngledIcon from "@mui/icons-material/AirlineSeatFlatAngledOutlined";
import { teal } from "@mui/material/colors";

const TenantStats = ({ tenantCode }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigateWithFilters = useNavigateWithFilters();
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalDevices, setTotalDevices] = useState(0);
  const [totalPatientsAcrossGender, setTotalPatientsAcrossGender] = useState(
    {}
  );
  const [totalDevicesAcrossSize, setTotalDevicesAcrossSize] = useState({});
  const [totalDevicesAcrossStatus, setTotalDevicesAcrossStatus] = useState({});

  const {
    data: statsData,
    isLoading,
    isError,
  } = useFetchTenantStats(tenantCode);

  const onDevicesSelected = (type = null, value = null) => {
    let localFilters = {
      tenantCode: tenantCode,
    };
    if (type) {
      localFilters[type] = value;
    }
    navigateWithFilters("devices", localFilters);
  };

  const onPatientsSelected = (type = null, value = null) => {
    let localFilters = {
      tenantCode: tenantCode,
    };
    if (type) {
      localFilters[type] = value;
    }
    navigateWithFilters("patients", localFilters);
  };

  useEffect(() => {
    if (!isLoading && !isError && statsData) {
      setTotalPatients(statsData.patient.total);
      setTotalDevices(statsData.device.total);
      setTotalPatientsAcrossGender(statsData.patient.gender);
      setTotalDevicesAcrossSize(statsData.device.size);
      setTotalDevicesAcrossStatus(statsData.device.status);
    }
  }, [isLoading, isError, statsData]);

  return (
    <Grid container spacing={2} alignItems="center">
      {/* Patients VCard */}
      {statsData !== null && (
        <Grid item xs={4} md={4}>
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              margin: 2,
              backgroundColor: colors.primary[400],
            }}
          >
            <Tooltip title="Tenant patient list">
              <IconButton
                onClick={onPatientsSelected}
                sx={{ margin: "auto", size: "large" }}
              >
                <AirlineSeatFlatAngledIcon
                  fontSize="large"
                  sx={{ color: teal[400] }}
                />
              </IconButton>
            </Tooltip>
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                sx={{ color: colors.greenAccent[400] }}
              >
                Total Patients: {totalPatients || 0}
              </Typography>
              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onPatientsSelected("gender", "Male")}
              >
                Male: {totalPatientsAcrossGender.male || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onPatientsSelected("gender", "Female")}
              >
                Female: {totalPatientsAcrossGender.female || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onPatientsSelected("gender", "Others")}
              >
                Others: {totalPatientsAcrossGender.others || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onPatientsSelected("gender", "Unknown")}
              >
                Unknown: {totalPatientsAcrossGender.unknown || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}

      {/* Devices VCard */}
      {statsData !== null && (
        <Grid item xs={4} md={4}>
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              margin: 2,
              backgroundColor: colors.primary[400],
            }}
          >
            <Tooltip title="Tenant device list">
              <IconButton onClick={onDevicesSelected} sx={{ margin: "auto" }}>
                <DevicesOtherIcon
                  fontSize="large"
                  sx={{ color: colors.other.blue }}
                />
              </IconButton>
            </Tooltip>
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                sx={{ color: colors.greenAccent[400] }}
              >
                Total Devices: {totalDevices || 0}
              </Typography>

              {/* Making each line clickable like the first CardContent */}
              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onDevicesSelected("size", "ExtraSmall")}
              >
                Extra Small: {totalDevicesAcrossSize.extraSmall || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onDevicesSelected("size", "Small")}
              >
                Small: {totalDevicesAcrossSize.small || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onDevicesSelected("size", "Medium")}
              >
                Medium: {totalDevicesAcrossSize.medium || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onDevicesSelected("size", "Large")}
              >
                Large: {totalDevicesAcrossSize.large || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      {statsData !== null && (
        <Grid item xs={4} md={4}>
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              margin: 2,
              backgroundColor: colors.primary[400],
            }}
          >
            <Tooltip title="Tenant device list">
              <IconButton onClick={onDevicesSelected} sx={{ margin: "auto" }}>
                <DevicesOtherIcon
                  fontSize="large"
                  sx={{ color: colors.other.blue }}
                />
              </IconButton>
            </Tooltip>
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                className="clickable"
                onClick={() => onDevicesSelected("status", "AssignedPatient")}
              >
                Assigned Patient:{" "}
                {totalDevicesAcrossStatus.assignedPatient || 0}
              </Typography>

              <Typography
                className="clickable"
                variant="body2"
                onClick={() => onDevicesSelected("status", "HospitalReceived")}
              >
                Received: {totalDevicesAcrossStatus.hospitalReceived || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onDevicesSelected("status", "Allocated")}
              >
                Allocated: {totalDevicesAcrossStatus.allocated || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onDevicesSelected("status", "Shipping")}
              >
                Shipping: {totalDevicesAcrossStatus.shipping || 0}
              </Typography>

              <Typography
                variant="body2"
                className="clickable"
                onClick={() => onDevicesSelected("status", "Unallocated")}
              >
                Unallocated: {totalDevicesAcrossStatus.unAllocated || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
export default TenantStats;
