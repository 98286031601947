import React, { useEffect } from "react";
import { get, API_URL } from "../../services/api";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";

const InvoicePaymentUpdate = () => {
  const [searchParams] = useSearchParams();
  const urlOrderId = searchParams.get("order_id");
  const navigate = useNavigate();

  const updatePaymentOrder = async () => {
    const url = `${API_URL}/v2/Order/GetLinkOrderCF?InvoiceUid=${urlOrderId}&type=Tenant`;
    try {
      const response = await get(url, true);
      if (response.statusCode === "OK" && response.data) {
        navigate("/adminDash");
      } else {
        navigate("/adminDash")
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (urlOrderId) {
      updatePaymentOrder();
    }
  }, [urlOrderId]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Invoice" subtitle="Invoice Payment update" />
        </Box>
      </Box>
    </Box>
  );
};

export default InvoicePaymentUpdate;
