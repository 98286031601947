import React from "react";
import { Typography, Grid, Box, Avatar, Chip, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {
  Phone,
  Email,
  LocalHospital,
  Code,
  AssignmentIndRounded,
  DeviceHubOutlined,
  Groups,
  Workspaces,
  FiberSmartRecord,
  Devices,
  MedicalInformation,
} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import DnsIcon from "@mui/icons-material/Dns";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import DetailsFooter from "../../components/common/DetailsFooter";

const UserDetails = ({ selectedUser }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!selectedUser) return null;
  const {
    email,
    mobileNumber,
    roles,
    userName,
    tenantCode,
    tenant,
    tenantPhoto,
    tenantEmail,
    deviceIds,
    tenantId,
    userId,
    firstName,
    lastName,
    enabled,
    roleId,
    pendingNotificationExists,
    id,
    createdOn,
    lastActionOn,
    lastActionBy,
  } = selectedUser;

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom={2}
        >
          <Avatar
            sx={{
              bgcolor: tenantPhoto ? "transparent" : "",
              width: 112,
              height: 112,
            }}
            src={tenantPhoto || ""}
            alt={`${firstName} ${lastName}`}
          >
            {!tenantPhoto && <LocalHospital />}
          </Avatar>
          <Typography variant="h5" gutterBottom>
            {firstName} {lastName}
          </Typography>
          {mobileNumber && (
            <Box display="flex" alignItems="center">
              <Phone style={{ color: colors.other.blue, mr: 1 }} />
              <Typography variant="body1">{mobileNumber}</Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <Email style={{ color: colors.other.blue, mr: 1 }} />
            <Typography variant="body1">{email.toLowerCase()}</Typography>
          </Box>
        </Box>
        <Box
          p={2}
          borderRadius={2}
          mb={1}
          sx={{
            border: "1px solid #1976d2",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Tooltip title={enabled ? "Account Active" : "Account disabled"}>
            {enabled ? (
              <CheckCircleIcon sx={{ color: "green" }} />
            ) : (
              <CancelIcon sx={{ color: "red" }} />
            )}
          </Tooltip>
          <Tooltip
            title={
              pendingNotificationExists
                ? "Pending Notifications"
                : "No pending notifications"
            }
          >
            {pendingNotificationExists ? (
              <NotificationsActiveIcon sx={{ color: "green" }} />
            ) : (
              <NotificationsNoneIcon sx={{ color: "grey" }} />
            )}
          </Tooltip>
          <Tooltip
            title={
              deviceIds.length > 0
                ? `Devices Assigned: ${deviceIds.length}`
                : "No Devices Assigned"
            }
          >
            <DeviceHubOutlined
              sx={{ color: deviceIds.length > 0 ? "teal" : "grey" }}
            />
          </Tooltip>
          <Tooltip title={`Tenant ID: ${tenantId ?? "Not Assigned"}`}>
            <MedicalInformation sx={{ color: tenantId ? "teal" : "grey" }} />
          </Tooltip>
          <Tooltip title={`Role ID: ${roleId ?? "Not Assigned"}`}>
            <Groups sx={{ color: roleId ? "#1976d2" : "grey" }} />
          </Tooltip>
          <Tooltip title={`User ID: ${userId}`}>
            <FingerprintIcon sx={{ color: "#1976d2" }} />
          </Tooltip>
          <Tooltip title={`System ID: ${id}`}>
            <DnsIcon sx={{ color: "#1976d2" }} />
          </Tooltip>
        </Box>
        <Box
          p={2}
          borderRadius={2}
          sx={{
            border: "1px solid #1976d2",
            display: "flex",
            alignItems: "left",
            gap: 2,
          }}
        >
          <ListItem>
            <Tooltip title={"Roles"}>
              <ListItemIcon>
                <Workspaces fontSize="medium" sx={{ color: "#1976d2" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {roles.length > 0 ? (
                    roles.map((role, index) => (
                      <Chip
                        key={index} // Use index as key if role is not unique, otherwise use role
                        label={role}
                        sx={{
                          bgcolor: colors.other.blue, // The color you want for the Chip
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />
                    ))
                  ) : (
                    <Typography variant="body1">No Roles</Typography>
                  )}
                </Box>
              }
            />
          </ListItem>
        </Box>
      </Grid>
      {/* Section 1: Basic Information */}
      <Grid item xs={12}>
        <Box
          p={2}
          mb={2}
          borderRadius={2}
          style={{ border: "1px solid #1976d2" }}
        >
          <List>
            <ListItem>
              <Tooltip title={"Tenant Code"}>
                <ListItemIcon>
                  <Code fontSize="large" style={{ color: "lightcoral" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{tenantCode}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Tenant"}>
                <ListItemIcon>
                  <LocalHospital
                    fontSize="large"
                    style={{ color: "lightcoral" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{tenant}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Tenant Email"}>
                <ListItemIcon>
                  <Email fontSize="large" style={{ color: "lightcoral" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{tenantEmail}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"User Name"}>
                <ListItemIcon>
                  <AssignmentIndRounded
                    fontSize="large"
                    style={{ color: "#1976d2" }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h7">{userName}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Assigned Deviceids"}>
                <ListItemIcon>
                  <Devices fontSize="large" style={{ color: "#1976d2" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {deviceIds.length > 0 ? (
                      deviceIds.map((device, index) => (
                        <Chip
                          key={device} // It's better to use device as key
                          label={device}
                          sx={{
                            bgcolor: colors.other.blue,
                            color: "white",
                            fontWeight: "bold",
                            "& .MuiChip-icon": {
                              color: "white", // Icon color
                            },
                          }}
                          icon={<FiberSmartRecord />}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No devices</Typography>
                    )}
                  </Box>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Grid>
      {/* Section 3: Additional Information */}
      <Grid item xs={12}>
        <Box sx={{ width: "100%" }}>
          <DetailsFooter
            createdOn={createdOn}
            lastActionOn={lastActionOn}
            lastActionBy={lastActionBy}
            customColor={colors.pastelColor.blue}
            dividerColor={"#1976d2"}
          />
        </Box>
      </Grid>

      {/* Images */}
      <Grid item xs={12}>
        {tenantPhoto && (
          <img
            src={tenantPhoto}
            alt="Tenant"
            style={{ maxWidth: "100%", borderRadius: "8px" }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default UserDetails;
