import React, { useState, useEffect } from "react";
import CenteredProgress from "../global/CenteredProgress";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useFetchTenantDetails } from "../../components/tenant/useFetchTenantInfo";
import TenantDetails from "../../components/tenant/TenantDetails";
import { Container, Box, IconButton, Tooltip } from "@mui/material";
import EditTenant from "../../components/tenant/editTenant";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteTenant from "../../components/tenant/deleteTenant";
import { useTimedMessage } from "../../components/forms/utilities";
import ActionToolbar from "../../components/common/ActionToolbar";
import MovePatient from "../../components/common/movePatient";
import ItemSearch from "../../components/search/itemSearch";
import ProfileCompletenessBar from "../../components/tenant/profileStatus";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useAuth } from "../../AuthProvider";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../components/auth/storage";
import { useQueryClient } from "@tanstack/react-query";
import PatientStats from "../../components/patients/PatientStats";
import ToggleOffIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnIcon from "@mui/icons-material/ToggleOnOutlined";

const TenantInfo = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const queryClient = useQueryClient();
  const { userDetails } = useAuth();
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantUpdateSuccess, setTenantUpdateSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [assignPatientMode, setAssignPatientMode] = useState(false);
  const [isSuccess, setIsSuccess] = React.useState(true);
  const [editMode, setEditMode] = useState(false);
  const [patientStatsMode, setPatientStatsMode] = useState(false);
  const [editableTenant, setEditableTenant] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isTenantDeleted, setIsTenantDeleted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMyHospitalPage = location.pathname === "/my-dashboard";
  const isAdmin = userDetails.roles.some(
    (role) => role === "Admin" || role === "HospitalAdmin"
  );
  const isHospitalAdmin = userDetails?.roles.some(
    (role) => role === "HospitalAdmin"
  );
  const isSearchBarEnabled = !isMyHospitalPage && isAdmin && !isHospitalAdmin;
  const isActionToolBarEnabled = isAdmin;
  const queryParams = new URLSearchParams(location.search);
  const urlinitialTenantCode = queryParams.get("tcode");
  const initialTenantId = location.state?.initialTenantId;
  const initialId = location.state?.initialId;
  const initialTenantCode =
    isMyHospitalPage || isHospitalAdmin
      ? userDetails.tenantCode
      : urlinitialTenantCode || location.state?.initialTenantCode;
  const [initialTenant, setInitialTenant] = useState(
    location.state?.initialTenant
  );
  const [selectedTenantUid, setSelectedTenantUid] = useState(initialTenantId);
  const [selectedTenantId, setSelectedTenantId] = useState(initialId); // confusing fix later
  const [selectedTenantCode, setSelectedTenantCode] =
    useState(initialTenantCode);

  useEffect(() => {
    if (
      !selectedTenantUid &&
      !selectedTenantId &&
      !initialTenantCode &&
      !initialTenant
    ) {
      setSelectedTenantCode(getLocalStorageItem("tenantInfo"));
    }
  }, []);
  const [status] = useState({
    add: { disabled: false, tooltip: "Add new tenant" },
    edit: { disabled: false, tooltip: "Edit selected tenant" },
    delete: { disabled: false, tooltip: "Delete selected tenant" },
    assignPatient: { disabled: false, tooltip: "Move patient to tenant" },
    stats: { disabled: false, tooltip: "Patient Statistics" },
  });
  const handleBack = () => {
    navigate(-1);
  };
  const {
    data: tenantDetails,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchTenantDetails(
    selectedTenantUid,
    selectedTenantCode,
    selectedTenantId,
    initialTenant
  );

  useEffect(() => {
    if (initialTenant) {
      setSelectedTenantCode(null);
      setSelectedTenantUid(initialTenant.uid);
    }
  }, [initialTenant]);

  useEffect(() => {
    if (initialTenantCode) {
      setInitialTenant(null);
      setSelectedTenantUid(null);
      setSelectedTenantId(null);
      setSelectedTenantCode(initialTenantCode);
    }
  }, [initialTenantCode]);

  useEffect(() => {
    if (tenantDetails) {
      setSelectedTenant(tenantDetails);
      setLocalStorageItem("tenantInfo", tenantDetails.code);
    }
  }, [tenantDetails]);

  useEffect(() => {
    if (tenantUpdateSuccess) {
      refetch().then((updatedData) => {
        if (updatedData.data) {
          setSelectedTenant(updatedData.data);
        }
        setTenantUpdateSuccess(false);
      });
    }
  }, [tenantUpdateSuccess, refetch]);

  useEffect(() => {
    if (isTenantDeleted) {
      queryClient.removeQueries({
        predicate: (query) =>
          query.queryKey[0] === "tenantDetails" &&
          query.queryKey[1] === selectedTenant.uid,
      });
      queryClient.removeQueries({
        predicate: (query) =>
          query.queryKey[0] === "tenantDetails" &&
          query.queryKey[2] === selectedTenant.code,
      });
      setSelectedTenant(null);
      setSelectedTenantUid(null);
      setSelectedTenantId(null);
      setSelectedTenantCode(null);
      removeLocalStorageItem("tenantInfo");
    }
  }, [isTenantDeleted]);

  const handleTenantUpdateSuccess = (success) => {
    setTenantUpdateSuccess(success);
  };

  const handleTenantSelected = (tenant) => {
    setSelectedTenantCode(null);
    setInitialTenant(null);
    handleGetTenantDetails(tenant);
  };

  const handleAdd = () => {
    navigate("/addtenant");
    setEditMode(false);
  };
  const handleEdit = () => {
    setEditableTenant({ ...selectedTenant }); // Make a copy of the device to edit
    setEditMode(true);
    setAssignPatientMode(false);
  };

  const handleStatsMode = () => {
    setPatientStatsMode(true);
    setEditMode(false);
    setAssignPatientMode(false);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const enableAssignPatient = () => {
    setAssignPatientMode(true);
    setEditMode(false);
  };

  const handleGetTenantDetails = async (tenant) => {
    setSelectedTenantUid(tenant.uid);
  };

  useEffect(() => {
    // Show success or custom error messages from responseMessage
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }

    // Additionally, handle errors from React Query
    if (isError && error) {
      // Display a more specific error message if available, or a generic message
      toast.error(error.message || "An error occurred while fetching data.");
    }
  }, [responseMessage, isSuccess, isError, error]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Tenants" subtitle="Omnyk tenant's profile." />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        {!patientStatsMode && (
          <>
            <Container
              maxWidth="md"
              sx={{
                border: `1px solid ${colors.greenAccent[400]}`,
                backgroundColor: colors.primary[400],
              }}
            >
              <Box mb={2}>
                {(isLoading || isloading) && <CenteredProgress />}
              </Box>
              <DeleteTenant
                selectedTenant={selectedTenant}
                setResponseMessage={setResponseMessage}
                openDeleteDialog={openDeleteDialog}
                setOpenDeleteDialog={setOpenDeleteDialog}
                setIsSuccess={setIsSuccess}
                setIsLoading={setIsLoading}
                setSelectedTenant={setSelectedTenant}
                setIsTenantDeleted={setIsTenantDeleted}
              />
              <Box my={2}>
                {!editMode && isSearchBarEnabled && (
                  <ItemSearch
                    onItemSelected={handleTenantSelected}
                    component="Tenant"
                  />
                )}
              </Box>
              {selectedTenant && (
                <ProfileCompletenessBar selectedTenant={selectedTenant} />
              )}
              {editMode && editableTenant && (
                <EditTenant
                  editableTenant={editableTenant}
                  selectedTenant={selectedTenant}
                  setResponseMessage={setResponseMessage}
                  setIsSuccess={setIsSuccess}
                  setEditMode={setEditMode}
                  onTenantUpdateSuccess={handleTenantUpdateSuccess}
                />
              )}
              {selectedTenant &&
                assignPatientMode &&
                !editMode &&
                !patientStatsMode && (
                  <MovePatient
                    tenantMode={true}
                    selectedTenant={selectedTenant}
                    setResponseMessage={setResponseMessage}
                    setIsSuccess={setIsSuccess}
                    setAssignMode={setAssignPatientMode}
                    onUpdateSuccess={handleTenantUpdateSuccess}
                  />
                )}
              {selectedTenant && !editMode && (
                <>
                  {isActionToolBarEnabled && (
                    <ActionToolbar
                      status={status}
                      onAdd={handleAdd}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      onAssignPatient={enableAssignPatient}
                      onStats={handleStatsMode}
                    />
                  )}
                  <TenantDetails data={selectedTenant} isAdmin={isAdmin} />
                </>
              )}
            </Container>
          </>
        )}
        {patientStatsMode && (
          <>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip
                title={patientStatsMode ? "Patient Statistics" : "TenantInfo"}
                placement="top"
                arrow
              >
                <IconButton
                  onClick={() => setPatientStatsMode(false)}
                  sx={{ fontSize: "32px" }}
                >
                  {patientStatsMode ? (
                    <ToggleOnIcon
                      sx={{
                        color: colors.pastelColor.c1,
                        fontSize: "inherit",
                      }}
                    />
                  ) : (
                    <ToggleOffIcon
                      sx={{
                        color: colors.pastelColor.c2,
                        fontSize: "inherit",
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <PatientStats id={selectedTenant.id} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default TenantInfo;
