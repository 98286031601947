import React from "react";
import { Typography, Grid, Box, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {
  AccountCircle,
  Phone,
  Email,
  Public,
  LocationCity,
  MarkunreadMailbox,
  Money,
  AdminPanelSettings,
  AccessTime,
  Place,
  Business,
  LocalHospital,
  Code,
  PriceChange,
} from "@mui/icons-material";
import DetailsFooter from "../../components/common/DetailsFooter";
import TenantStats from "./tenantStats";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";

const TenantDetails = ({
  data,
  isAdmin,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) return null;

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom={2}
        >
          <LocalHospital style={{ color: "red", fontSize: "2.5rem" }} />
          <Typography variant="h4" gutterBottom>
            {data.name}
          </Typography>
        </Box>
      </Grid>
      {isAdmin && (
        <Grid item xs={12}>
          <TenantStats
            tenantCode={data.code}
          />
        </Grid>
      )}
      {/* Section 1: Basic Information */}
      <Grid item xs={12}>
        <Box
          p={2}
          mb={2}
          borderRadius={2}
          style={{ border: `2px solid ${colors.pastelColor.blue}` }}
        >
          <List>
            <ListItem>
              <Tooltip title={"Tenant Code"}>
                <ListItemIcon>
                  <Code
                    fontSize="large"
                    style={{ color: colors.pastelColor.red }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{data.code}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Contact Name"}>
                <ListItemIcon>
                  <AccountCircle
                    fontSize="large"
                    style={{ color: colors.pastelColor.red }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h6">{data.contactName}</Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Contact Mobile Number"}>
                <ListItemIcon>
                  <Phone
                    fontSize="large"
                    style={{ color: colors.pastelColor.red }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h6">{data.mobileNumber}</Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"User Email"}>
                <ListItemIcon>
                  <Email
                    fontSize="large"
                    style={{ color: colors.pastelColor.red }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h6">{data.email}</Typography>}
              />
            </ListItem>
          </List>
        </Box>
      </Grid>

      {/* Section 2: Address */}
      <Grid item xs={12}>
        <Box
          p={2}
          mb={2}
          borderRadius={2}
          style={{ border: `2px solid ${colors.pastelColor.blue}` }}
        >
          <List>
            <ListItem>
              <Tooltip title={"Address"}>
                <ListItemIcon>
                  <Place
                    fontSize="medium"
                    style={{ color: colors.pastelColor.brown }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h7">{data.address}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"City & State"}>
                <ListItemIcon>
                  <LocationCity
                    fontSize="medium"
                    style={{ color: colors.pastelColor.brown }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h7">
                    {data.city}, {data.state}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Country"}>
                <ListItemIcon>
                  <Public
                    fontSize="medium"
                    style={{ color: colors.pastelColor.brown }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h7">{data.country}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"ZipCode"}>
                <ListItemIcon>
                  <MarkunreadMailbox
                    fontSize="medium"
                    style={{ color: colors.pastelColor.brown }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h7">{data.zipCode}</Typography>}
              />
            </ListItem>
          </List>
        </Box>
      </Grid>

      {/* Section 3: Additional Information */}
      <Grid item xs={12}>
        <Box
          p={2}
          borderRadius={2}
          style={{ border: `2px solid ${colors.pastelColor.blue}` }}
        >
          <List>
            <ListItem>
              <Tooltip title={"Is Integrated GST ?"}>
                <ListItemIcon>
                  <Business
                    fontSize="medium"
                    style={{ color: colors.pastelColor.teal }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h7">
                    IGST: {data.isIgst ? "Yes" : "No"}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Credit Days"}>
                <ListItemIcon>
                  <Money
                    fontSize="medium"
                    style={{ color: colors.pastelColor.teal }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h7">
                    Credit Days: {data.creditDays}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"GST Number"}>
                <ListItemIcon>
                  <PriceChange
                    fontSize="medium"
                    style={{ color: colors.pastelColor.teal }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h7">{data.gstNo}</Typography>}
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Is System tenant ?"}>
                <ListItemIcon>
                  <AdminPanelSettings
                    fontSize="medium"
                    style={{ color: colors.pastelColor.teal }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  <Typography variant="h7">
                    System Tenant: {data.isSystem ? "Yes" : "No"}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <Tooltip title={"Time Zone"}>
                <ListItemIcon>
                  <AccessTime
                    fontSize="medium"
                    style={{ color: colors.pastelColor.teal }}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={<Typography variant="h7">{data.timeZone}</Typography>}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ width: "100%", mt: 4 }}>
          <DetailsFooter
            createdOn={data.createdOn}
            lastActionOn={data.lastActionOn}
            lastActionBy={data.lastActionBy}
            customColor={"teal"}
            dividerColor={"grey.300"}
          />
        </Box>
      </Grid>

      {/* Images */}
      <Grid item xs={12}>
        {data.imageUrl && (
          <img
            src={data.imageUrl}
            alt="Tenant"
            style={{
              maxWidth: "100%",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          />
        )}
        {data.photo && (
          <img
            src={data.photo}
            alt="Tenant"
            style={{ maxWidth: "100%", borderRadius: "8px" }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TenantDetails;
