import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function ImageUrlDialog({ open, onClose, onConfirm }) {
  const [url, setUrl] = useState('');

  const handleClose = () => {
    onClose();
    setUrl('');
  };

  const handleConfirm = () => {
    onConfirm(url);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add an Image</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the URL of the image you want to add.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Image URL"
          type="url"
          fullWidth
          variant="standard"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Add Image</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImageUrlDialog;