import { post, API_URL, get } from "../../services/api";
import { Container, Grid, Button, Typography, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import isEmail from "validator/lib/isEmail";
import React, { useState } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import { NotAccessibleOutlined, SearchOutlined } from "@mui/icons-material";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { toast } from "react-toastify";

const DeallocatePatient = ({
  selectedDevice,
  setdeallocatePatientMode,
  setSearchTerm,
  onDeviceUpdateSuccess,
}) => {
  const [patientEmail, setPatientEmail] = useState("");
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setPatientEmail(emailValue);
  };

  const handleDeallocatePatient = async () => {
    if (!patientEmail.trim()) {
      toast.info("Email Address cannot be empty!");
      return;
    }

    if (!isEmail) {
      toast.error("Invalid Email Address!");
      return;
    }

    if (!selectedDevice) {
      toast.info("Please select a device!");
      return;
    }
    setIsLoading(true);
    try {
      // Directly fetch user profile here
      const userData = await get(
        `${API_URL}/v2/Patient/GetPatientProfile?email=${patientEmail}`,
        true
      );
      if (userData.data && userData.data.uid) {
        let tenantCode = "";
        const bodyEntityContent = userData.data.bodyEntity;
        for (const entity of bodyEntityContent) {
          for (const contentItem of entity.content) {
            if (contentItem.title === "Tenant ID") {
              tenantCode = contentItem.value;
              break;
            }
          }
          if (tenantCode) break;
        }
        // Check tenantCode against selectedDevice.tenantCode
        if (tenantCode !== selectedDevice.tenantCode) {
          toast.error(
            "Patient is not in the same Tenant, as the device being deallocated!"
          );
          return; // Exit the function since tenant codes don't match
        }
        const requestId = uuidv4();
        const sendData = {
          requestId,
          data: {
            patientId: userData.data.uid,
            deviceID: selectedDevice.uid,
          },
        };
        const response = await post(
          `${API_URL}/v2/Patient/RingDeallocation`,
          sendData,
          true
        );
        if (response.statusCode === "OK") {
          const allMessages = response.messages.join("\n");
          toast.success(allMessages);
          setPatientEmail("");
          setSearchTerm("");
          const updateWasSuccessful = true;
          onDeviceUpdateSuccess(updateWasSuccessful);
          setdeallocatePatientMode(false);
        } else {
          toast.error("Error Deallocating ring from patient.");
        }
      } else {
        const errorMessage =
          userData.errors && userData.errors.length > 0
            ? userData.errors[0]
            : "Error fetching patient details.";

        toast.error(errorMessage);
        // Clear the patient email and searchTerm if there was an error
        setPatientEmail("");
        setSearchTerm("");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        // If it's a 400 error and has error messages, display the first error message
        const allErrors = error.response.data.errors.join("\n");
        toast.error(allErrors);
      } else {
        // For other errors
        toast.error("Error Deallocating ring.!!!!");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAutoFindPatient = async (macaddress) => {
    try {
      const data = await get(
        `${API_URL}/v2/Device/DevicePatientList?pageNo=1&recordsPerPage=10&Filter=${macaddress}`
      );
      if (data && data.items && data.items.length > 0) {
        const patient = data.items[0];
        setPatientEmail(patient.email);
        setPatientFirstName(patient.firstName);
        setPatientLastName(patient.lastName);
        toast.success("Patient Found!");
      } else {
        setPatientEmail("");
        setPatientFirstName("");
        setPatientLastName("");
        toast.error("No patient found");
      }
    } catch (error) {
      toast.error("Error fetching patient email.");
    }
  };

  return (
    <Container>
      <div>
        <TextField
          fullWidth
          label="Patient Email"
          type="email"
          placeholder="Enter Patient's email"
          value={patientEmail}
          onChange={handleEmailChange}
          error={!isEmail}
          helperText={!isEmail ? "Invalid email format" : ""}
          required
          style={{ marginRight: "12px" }}
        />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginTop: "8px",
          }}
          onClick={() => handleAutoFindPatient(selectedDevice.deviceId)}
        >
          <SearchOutlined />
          Auto Find Patient
        </Button>
        {patientFirstName && patientLastName && (
          <div style={{ marginTop: "8px" }}>
            <Typography variant="body2">
              Patient Name: {patientFirstName} {patientLastName}
            </Typography>
          </div>
        )}
        {isLoading && <CenteredProgress />}
        {/* Deallocate Device to patient button */}
        <Grid container justifyContent="center" style={{ marginTop: "16px" }}>
          <Grid item xs={12} md={6}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  marginRight: "8px",
                }}
                onClick={handleDeallocatePatient}
                disabled={!isEmail(patientEmail) || isLoading}
              >
                <NotAccessibleOutlined />
                Deallocate Patient
              </Button>
              <Button
                onClick={() => {
                  setdeallocatePatientMode(false);
                  setPatientEmail("");
                }}
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default DeallocatePatient;
