import { post, API_URL } from "../../services/api";
import { Container, Typography, Grid, Button, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ItemSearch from "../search/itemSearch";
import React, { useState } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import { LocalHospitalOutlined } from "@mui/icons-material";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { toast } from "react-toastify";

const AllocateTenant = ({
  selectedDevice,
  setAssignTenantMode,
  setSearchTerm,
  onDeviceUpdateSuccess,
}) => {
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const clearSelectedTenant = () => {
    setSelectedTenant(null);
  };

  const handleTenantSelection = (tenant) => {
    if (tenant) {
      setSelectedTenant(tenant);
    }
  };
  const handleDeviceAssign = async () => {
    if (!selectedDevice) {
      toast.info("Please select a device!");
      return;
    }

    if (!selectedTenant) {
      toast.info("Please select a tenant.");
      return;
    }
    setIsLoading(true);
    try {
      if (selectedDevice && selectedDevice.uid) {
        const requestId = uuidv4();
        const sendData = {
          requestId,
          data: {
            deviceId: selectedDevice.uid,
            tenantId: selectedTenant.uid,
          },
        };
        const response = await post(
          `${API_URL}/v2/Device/AssignDevice`,
          sendData,
          true
        );
        if (response.statusCode === "OK") {
          const allMessages = response.messages.join("\n");
          toast.success(allMessages);
          setSearchTerm("");
          setAssignTenantMode(false);
          const updateWasSuccessful = true;
          onDeviceUpdateSuccess(updateWasSuccessful);
        } else {
          if (
            response.statusCode === "BadRequest" &&
            response.errors &&
            response.errors.length > 0
          ) {
            const allErrors = response.errors.join("\n");
            toast.error(allErrors);
          } else {
            toast.error("Unknown error occurred."); // Handle other unexpected cases
          }
        }
      } else {
        toast.error("Error Getting Ring UID");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        const allErrors = error.response.data.errors.join("\n");
        toast.error(allErrors);
      } else {
        // For other errors
        toast.error("Error Allocating Ring.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <div>
        <ItemSearch onItemSelected={handleTenantSelection} component="Tenant"/>
      </div>
      <Box mb={2} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h6" component="h2" mr={1}>
          Selected Tenant:
        </Typography>
        <Typography variant="h7" component="h2">
          {selectedTenant ? selectedTenant.name : "None"}
        </Typography>
      </Box>
      <Grid container justifyContent="center" style={{ marginTop: "16px" }}>
        {isLoading && <CenteredProgress />}
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              onClick={handleDeviceAssign}
              disabled={
                !selectedTenant || selectedTenant.name === "None" || isLoading
              }
            >
              <LocalHospitalOutlined sx={{ mr: "10px" }} />
              Assign Tenant
            </Button>
            <Button
              onClick={() => {
                setAssignTenantMode(false);
                clearSelectedTenant();
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AllocateTenant;
