import {
  Button,
  Typography,
  Grid,
  Alert,
  InputAdornment,
  TextField,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { post, API_URL } from "../../services/api";
import { v4 as uuidv4 } from "uuid";
import { useTimedMessage } from "../forms/utilities";
import { useForm, FormProvider } from "react-hook-form";
import PasswordInput from "../forms/PasswordInput";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import CenteredProgress from "../../scenes/global/CenteredProgress";

const ChangePassword = ({ onUpdateSuccess, setChangePasswordMode }) => {
  const methods = useForm();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [showPassword, setShowPassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePasswordSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      const requestId = uuidv4();
      const sendData = {
        requestId,
        data: {
          currentPassword: data.currentPassword,
          newPassword: data.password,
          confirmPassword: data.passwordConfirm,
        },
      };
      const response = await post(
        `${API_URL}/v2/FastballUser/ChangePassword`,
        sendData,
        true
      );

      if (response && response.data) {
        setIsSuccess(true);
        const successMessage =
          response.messages.length > 0
            ? response.messages[0]
            : "You have , sucessfully changed your password!";
        setResponseMessage(successMessage);
        onUpdateSuccess(successMessage);
      } else {
        setIsSuccess(false);
        setResponseMessage(
          response.errors.length > 0
            ? response.errors[0]
            : "Sorry, couldnt change the password, please retry!"
        );
      }
    } catch (e) {
      const errorMessage =
        e.response?.errors?.length > 0
          ? e.response.errors[0]
          : "Sorry, could not change the password, please retry!";
      setIsSuccess(false);
      setResponseMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <FormProvider {...methods}>
      <Typography className="heading" variant="h6" align="center">
        Change Password
      </Typography>
      <form onSubmit={handleChangePasswordSubmit}>
        <Grid container spacing={2}>
          {" "}
          {/* Grid container starts here */}
          <Grid item xs={12}>
            <TextField
              {...register("currentPassword", {
                required: "Current Password is required",
              })}
              type={showPassword ? "text" : "password"}
              label="Current Password"
              fullWidth
              required
              variant="outlined"
              error={!!errors.currentPassword}
              helperText={errors.currentPassword?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: colors.other.blue }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput />
          </Grid>
          <Grid item xs={12}>
            {responseMessage && (
              <Alert
                severity={isSuccess ? "success" : "error"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "1em",
                }}
              >
                {responseMessage}
              </Alert>
            )}
          </Grid>
          {isLoading && <CenteredProgress />}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
              width: "100%",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginRight: "8px" }}
              disabled={isLoading}
            >
              Change Password
            </Button>
            <Button
              onClick={() => {
                setChangePasswordMode(false);
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ChangePassword;
