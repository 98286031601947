import React, { useState, useEffect } from "react";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useFetchDeviceLocations } from "./useFetchDeviceInfo";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const MapContainer = ({ deviceId, setOpenLocation }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const { data, isLoading, error } = useFetchDeviceLocations(deviceId);
  const [defaultCenter, setDefaultCenter] = useState(null);
  const formatDate = (isoString) => {
    return new Date(isoString).toLocaleString(); // Adjust date format as needed
  };
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  useEffect(() => {
    if (data) {
      setLocations(data);
    }
  }, [data]);

  useEffect(() => {
    if (locations && locations.length > 0) {
      // Sort the data by date
      locations.sort((a, b) => new Date(b.date) - new Date(a.date));
      setDefaultCenter({
        lat: locations[0].lat,
        lng: locations[0].lng,
      });
    } else if (locations && locations.length === 0) {
      // If no data or empty array
      toast.info("No location data available.");
      setOpenLocation(false);
      setDefaultCenter({ lat: 0, lng: 0 });
    }

    if (error) {
      toast.error("Error fetching location data: " + error.message);
      setOpenLocation(false);
      setDefaultCenter({ lat: 0, lng: 0 });
    }
  }, [locations, error, setOpenLocation]);

  return isLoaded ? (
    <Box style={{ position: "relative" }}>
      <Box mb={2}>{isLoading && <CenteredProgress />}</Box>
      <Box>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={16}
          center={defaultCenter}
          onLoad={() => {
            setIsMapLoaded(true);
          }}
        >
          {isMapLoaded &&
            locations &&
            locations.map((item) => (
              <MarkerF
                key={item.date}
                position={{ lat: item.lat, lng: item.lng }}
                onClick={() => setSelectedLocation(item)}
                aria-label={`Location marker for ${item.deviceId}`}
              >
                {selectedLocation === item && (
                  <InfoWindowF onCloseClick={() => setSelectedLocation(null)}>
                    <Typography
                      variant="h6"
                      style={{ color: colors.blueAccent[600] }}
                    >
                      Avida Ring Location on {formatDate(item.date)}
                    </Typography>
                  </InfoWindowF>
                )}
              </MarkerF>
            ))}
        </GoogleMap>
      </Box>
      <button
        onClick={() => setOpenLocation(false)}
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
        }}
      >
        <CloseIcon />
      </button>
    </Box>
  ) : (
    <></>
  );
};

export default MapContainer;
