import React from "react";
import { Box, useTheme, Typography, Tooltip, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import { ErrorOutlineOutlined, RefreshOutlined } from "@mui/icons-material";

const ErrorsRefetch = ({ message, subTitle, refetch }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box textAlign="center" sx={{ color: colors.pastelColor.red }} m={2}>
      <ErrorOutlineOutlined fontSize="large" />
      <Typography variant="h5" component="p">
        {message}
      </Typography>
      <Tooltip title={subTitle}>
        <IconButton onClick={() => refetch()} color="secondary" size="large">
          <RefreshOutlined fontSize="large" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ErrorsRefetch;
