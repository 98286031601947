import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  DialogContentText,
  LinearProgress,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Checkbox,
  Box,
} from "@mui/material";
import { post, API_URL, put } from "../../services/api";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import ItemSearch from "../search/itemSearch";
import PatientForm from "../forms/BulkPatientForm";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import { useQueryClient } from "@tanstack/react-query";
import { removeLocalStorageItem } from "../../components/auth/storage";

const BulkUpdateDialog = ({ open, onClose, selectedItems, refetch }) => {
  const queryClient = useQueryClient();
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [status, setStatus] = useState("Pending");
  const [remarks, setRemarks] = useState("Auto Updates");
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [updateSuccessCount, setUpdateSuccessCount] = useState(0);
  const [updateFailCount, setUpdateFailCount] = useState(0);
  const [startUpdating, setStartUpdating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [action, setAction] = useState("");
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidEmergencyPhone, setIsValidEmergencyPhone] = useState(true);
  const [isNotValidData, setIsNotValidData] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isUpdatesComplete, setIsUpdatesComplete] = useState(false);
  const handlePhoneValidChange = (isValid) => {
    setIsValidPhone(isValid);
  };

  const handleEmergencyPhoneValidChange = (isValid) => {
    setIsValidEmergencyPhone(isValid);
  };

  const handleClearTenant = () => {
    setSelectedTenant(null);
  };

  const onFormSubmit = async (data) => {
    const validData = preprocessFormData(data);
    console.log("Data", data);
    console.log("ValidData", validData);
    if (Object.keys(validData).length > 0) {
      setIsNotValidData(false);
      setFormData(validData);
    } else {
      setIsNotValidData(true);
    }
  };

  useEffect(() => {
    // Whenever currentItemIndex changes and we are in update mode, perform an update
    const performUpdate = async () => {
      if (startUpdating && currentItemIndex < selectedItems.length) {
        await handleUpdateStatus();
      } else if (currentItemIndex >= selectedItems.length) {
        setStartUpdating(false); // Stop updating when all items have been processed
      }
    };

    performUpdate();
  }, [currentItemIndex, startUpdating]);

  useEffect(() => {
    if (open) {
      // Initialize the state when the dialog opens
      setUpdateSuccessCount(0);
      setUpdateFailCount(0);
      setCurrentItemIndex(0);
      setStartUpdating(false);
      setIsSuccess(true);
      setResponseMessage("");
      setProgress(0);
    }
  }, [open]);

  const handleStartUpdates = () => {
    if (!startUpdating) {
      resetDialog();
      setStartUpdating(true); // Start the update process
    }
  };

  useEffect(() => {
    if (
      startUpdating &&
      updateSuccessCount + updateFailCount === selectedItems.length
    ) {
      // All items have been processed, so we can now show the final progress
      setStartUpdating(false); // Stops any further updates
      if (updateFailCount === 0) {
        refetch();
        queryClient.removeQueries({
          queryKey: ["patientProfile"],
        });
        removeLocalStorageItem("patientInfo");
        toast.success("All updates successfully completed.");
        setIsUpdatesComplete(true);
      } else if (updateSuccessCount === 0) {
        toast.error("All updates failed. Please review.");
      } else {
        refetch();
        queryClient.removeQueries({
          queryKey: ["patientProfile"],
        });
        removeLocalStorageItem("patientInfo");
        toast.info(`There are ${updateFailCount} failures. Please review.`);
        setIsUpdatesComplete(true);
      }
    }
  }, [
    updateSuccessCount,
    updateFailCount,
    selectedItems.length,
    startUpdating,
    selectedTenant,
  ]);

  function preprocessFormData(formData) {
    const checkLengthKeys = ["mobileNumber", "emergencyContactNo"];
    let validData = {};

    Object.entries(formData).forEach(([key, value]) => {
      if (value === "no change") {
        // Skip "no change" values
        return;
      }

      if (value !== undefined && value !== "") {
        if (checkLengthKeys.includes(key) && value.length < 4) {
          // Skip values that are too short
          return;
        }

        // Convert "true" and "false" string values to booleans for specific fields
        validData[key] =
          value === "true" ? true : value === "false" ? false : value;
      }
      // No need to log undefined or empty values; they're simply not added to validData
    });

    return validData;
  }

  useEffect(() => {
    const newProgress =
      ((updateSuccessCount + updateFailCount) / selectedItems.length) * 100;
    setProgress(newProgress);
  }, [updateSuccessCount, updateFailCount, selectedItems.length]);

  const handleUpdateStatus = async () => {
    if (open && startUpdating && selectedItems.length > currentItemIndex) {
      const item = selectedItems[currentItemIndex];

      let payload, url;
      if (action === "move") {
        if (!selectedTenant) {
          setIsSuccess(false);
          setResponseMessage("No tenant selected for moving patient.");
          setUpdateFailCount((prevCount) => prevCount + 1);
          setCurrentItemIndex((prevIndex) => prevIndex + 1);
          return;
        }
        payload = {
          requestId: uuidv4(),
          data: {
            patientId: item.uid,
            tenantId: selectedTenant.uid,
          },
        };
        url = `${API_URL}/v2/Patient/MovePatient`;
      } else if (action === "delete") {
        payload = {
          requestId: uuidv4(),
          data: item.email,
        };
        url = `${API_URL}/v2/FastballUser/ObfuscateUserByEmail`;
      } else if (action === "edit") {
        let updatedData = {
          ...item,
        };
        // Now, update `updatedData` with `validData`
        Object.keys(formData).forEach((key) => {
          updatedData[key] = formData[key];
        });
        console.log("formData", formData);
        payload = {
          requestId: uuidv4(),
          data: updatedData,
        };
        url = `${API_URL}/v2/Patient/Update`;
      }

      try {
        let response;
        if (action === "edit") {
          response = await put(url, payload, true);
        } else {
          response = await post(url, payload, true);
        }
        if (response && response.statusCode === "OK") {
          setIsSuccess(true);
          setResponseMessage(
            response.messages ? response.messages[0] : "Operation successful"
          );
          setUpdateSuccessCount((prevCount) => prevCount + 1);
        } else {
          setIsSuccess(false);
          setResponseMessage(response.errors[0]);
          setUpdateFailCount((prevCount) => prevCount + 1);
        }
      } catch (error) {
        setIsSuccess(false);
        setResponseMessage(
          error.response?.data?.errors[0] || "Error in operation"
        );
        setUpdateFailCount((prevCount) => prevCount + 1);
      } finally {
        if (currentItemIndex === selectedItems.length - 1) {
          setProgress(100);
        }
        setCurrentItemIndex((prevIndex) => prevIndex + 1);
      }
    } else if (currentItemIndex >= selectedItems.length) {
      setStartUpdating(false);
      toast.info("All updates complete.");
    }
  };

  const resetDialog = () => {
    setUpdateSuccessCount(0);
    setUpdateFailCount(0);
    setCurrentItemIndex(0);
    setStartUpdating(false);
    setIsSuccess(true);
    setResponseMessage("");
    setProgress(0);
  };

  const handleClose = () => {
    setStatus("Pending");
    setRemarks("Auto Updates");
    setIsAcknowledged(false);
    setIsNotValidData(false);
    setFormData(null);
    setIsUpdatesComplete(false);
    resetDialog();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: colors.primary[400],
        },
      }}
      aria-labelledby="bulk-update-dialog-title"
      aria-describedby="bulk-update-dialog-description"
    >
      <DialogTitle
        id="bulk-update-dialog-title"
        sx={{ color: colors.greenAccent[400], typography: "h5" }}
      >
        Update Patients
      </DialogTitle>
      <DialogContent
        dividers={action === "edit"} // Adds dividers to DialogContent, useful for indicating scrollable content
        sx={{
          overflowY: "auto", // Makes the content scrollable
          maxHeight: "100vh", // Adjust this value to control the visible area before scrolling
        }}
      >
        <DialogContentText
          id="bulk-update-dialog-description"
          sx={{ marginBottom: 2 }}
        >
          Choose an action for the selected patients:
        </DialogContentText>
        <RadioGroup
          row
          value={action}
          onChange={(e) => setAction(e.target.value)}
        >
          <FormControlLabel
            value="move"
            control={
              <Radio
                sx={{
                  color: colors.greenAccent[200],
                  "&.Mui-checked": {
                    color: colors.greenAccent[400],
                  },
                }}
              />
            }
            label="Move Patient"
          />
          <FormControlLabel
            value="edit"
            control={
              <Radio
                sx={{
                  color: colors.greenAccent[200],
                  "&.Mui-checked": {
                    color: colors.greenAccent[400],
                  },
                }}
              />
            }
            label="Edit Patient"
          />
          <FormControlLabel
            value="delete"
            control={
              <Radio
                sx={{
                  color: colors.redAccent[200],
                  "&.Mui-checked": {
                    color: colors.redAccent[400],
                  },
                }}
              />
            }
            label="Delete Patient"
          />
        </RadioGroup>

        {action === "move" && (
          <Grid container spacing={2} mt={2}>
            <ItemSearch onItemSelected={setSelectedTenant} component="Tenant"/>
            {selectedTenant && (
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1">{`Selected Tenant: ${selectedTenant.name} | ${selectedTenant.code}`}</Typography>
                <IconButton
                  onClick={handleClearTenant}
                  aria-label="clear tenant"
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}
        {action === "delete" && (
          // Render delete confirmation UI here
          <Typography>
            Are you sure you want to delete the selected patients?
          </Typography>
        )}
        {action === "move" && (
          <>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    margin: "8px",
                  }}
                >
                  Close
                </Button>
              </Grid>
              {!isUpdatesComplete && (
                <Grid item>
                  <Button
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={handleStartUpdates}
                    disabled={startUpdating || !selectedTenant}
                  >
                    Move Patients
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {action === "edit" && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px", // Adjust padding as needed
              }}
            >
              <Grid container spacing={2} mt={2}>
                <PatientForm
                  onPhoneValidChange={handlePhoneValidChange}
                  onEmergencyPhoneValidChange={handleEmergencyPhoneValidChange}
                  onFormSubmit={onFormSubmit}
                />
              </Grid>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAcknowledged}
                        onChange={(e) => setIsAcknowledged(e.target.checked)}
                        name="acknowledged"
                      />
                    }
                    label="I am aware of my actions of bulk edits to patient data."
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  {formData && (
                    <>
                      <Grid item>
                        <Typography>Patient update data is set!</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => setFormData(null)}
                          aria-label="clear data"
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                  {isNotValidData && (
                    <>
                      <Grid item>
                        <Typography sx={{ color: colors.redAccent[400] }}>
                          No valid data to update!
                        </Typography>
                      </Grid>
                      <Grid item></Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        margin: "8px",
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                  {!isUpdatesComplete && (
                    <Grid item>
                      <Button
                        sx={{
                          backgroundColor: colors.blueAccent[700],
                          color: colors.grey[100],
                          fontSize: "12px",
                          fontWeight: "bold",
                          padding: "10px 20px",
                          margin: "8px",
                        }}
                        onClick={handleStartUpdates}
                        disabled={startUpdating || !isAcknowledged || !formData}
                      >
                        Edit Patients
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        {action === "delete" && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAcknowledged}
                  onChange={(e) => setIsAcknowledged(e.target.checked)}
                  name="acknowledged"
                />
              }
              label="I am aware of my actions of bulk delete"
            />
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    margin: "8px",
                  }}
                >
                  Close
                </Button>
              </Grid>
              {!isUpdatesComplete && (
                <Grid item>
                  <Button
                    sx={{
                      backgroundColor: colors.redAccent[700],
                      color: colors.grey[100],
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={handleStartUpdates}
                    disabled={startUpdating || !isAcknowledged}
                  >
                    Delete Patient
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <LinearProgress variant="determinate" value={progress} />
      <Typography variant="body1">
        {`Progress: ${progress.toFixed(
          0
        )}%, Successes: ${updateSuccessCount}, Failures: ${updateFailCount}`}
      </Typography>
    </Dialog>
  );
};

export default BulkUpdateDialog;
