import { useForm, FormProvider, Controller } from "react-hook-form";
import React, { useState, Suspense } from "react";
import { post, API_URL, securePost } from "../../services/api";
import { Grid, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotosOutlined";
import ItemSearch from "../search/itemSearch";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { handleLoginSuccess, handleIndependentAuth } from "../auth/authUtils";
import { useAuth } from "../../AuthProvider";
const PatientForm = React.lazy(() => import("../forms/PatientForm"));

const AddPatient = ({
  setResponseMessage,
  setIsSuccess,
  setNewPatientEmail,
  isSignUp = false,
}) => {
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { loginUser, userDetails } = useAuth();
  const isAdmin = userDetails?.roles.some(
    (role) => role === "Admin" || role === "HospitalAdmin"
  );
  const isHospitalAdmin = userDetails?.roles.some(
    (role) => role === "HospitalAdmin"
  );
  const navigate = useNavigate();

  const handleTenantSelected = (tenant, field) => {
    field.onChange(tenant.id);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    if (formData.isCaretaker) {
      Object.keys(formData).forEach((key) => {
        if (
          ![
            "firstName",
            "lastName",
            "email",
            "mobileNumber",
            "dob",
            "password",
          ].includes(key)
        ) {
          delete formData[key];
        }
      });
    }
    if (isHospitalAdmin) {
      formData.currentTenantId = userDetails.tenantId;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };
    let url = `${API_URL}/v2/Patient/Add`;
    let response;
    if (isSignUp) {
      url = `${API_URL}/v2/Patient/SignUp`;
    }

    try {
      if (isSignUp) {
        const token = await handleIndependentAuth();
        response = await securePost(token, url, payload, true);
      } else {
        response = await post(url, payload, true);
      }
      if (response.statusCode === "OK") {
        if (isSignUp) {
          const {
            token: accessToken,
            refreshToken,
            username,
            createdTime,
            expiresIn,
            twoFactorEnabled,
            patient,
          } = response.data;

          const credentialsData = {
            accessToken,
            refreshToken,
            username,
            createdTime,
            expiresIn,
            twoFactorEnabled,
            patient,
            isNewPatient: true,
          };
          handleLoginSuccess({
            credentialsData,
            loginUser,
            navigate,
            setIsLoading,
          });
        }
        setResponseMessage(response.messages[0]);
        setIsSuccess(true);
        setNewPatientEmail(formData?.email);
        methods.reset();
      } else {
        setIsSuccess(false);
        setResponseMessage("Error adding Patient.");
        toast.error("Error adding Patient.");
      }
    } catch (error) {
      if (error.response.data && error.response.data.errors.length > 0) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        setIsSuccess(false);
        setResponseMessage(validationErrorMessages);
        const errorMessage =
          "Error adding patient " +
          formData?.email +
          ".\n" +
          validationErrorMessages.join("\n");
        toast.error(errorMessage);
      } else {
        // Handle other errors
        setIsSuccess(false);
        setResponseMessage("Error adding Patient.");
        toast.error("Error adding patient.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {!isSignUp && !isHospitalAdmin && (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Controller
              name="currentTenantId"
              control={methods.control}
              rules={{ required: "Tenant is required" }}
              render={({ field, fieldState: { error } }) => (
                <Box width="100%">
                  {" "}
                  {/* Ensure the Box takes the full width */}
                  <ItemSearch
                    onItemSelected={(tenant) =>
                      handleTenantSelected(tenant, field)
                    }
                    component="Tenant"
                    {...field}
                  />
                  {error && (
                    <Typography
                      color="error"
                      variant="caption"
                      display="block"
                      mt={1}
                    >
                      {error.message}
                    </Typography>
                  )}
                </Box>
              )}
            />
          </Box>
        )}
        <Suspense fallback={<CenteredProgress />}>
          <PatientForm isSignUp={isSignUp} isAdmin={isAdmin} />
        </Suspense>
        {isLoading && <CenteredProgress />}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              disabled={isLoading}
              type="submit"
            >
              <AddToPhotosIcon sx={{ mr: "10px" }} />
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AddPatient;
