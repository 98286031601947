import { Grid, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { countries } from '../../common/constants';

const CountrySelectForm = ({handleCountryChange}) => {
  const { register, control, formState: { errors } } = useFormContext();

  return (
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="country-select-label">Country</InputLabel>
          <Select
            {...register("countryId", { required: "Country selection is required" })}
            labelId="country-select-label"
            label="Country"
            defaultValue=""
            onChange={handleCountryChange}
            error={!!errors.countryId}
          >
            {countries.map((country) => (
              <MenuItem key={country.country_id} value={country.country_id}>
                {country.country_name}
              </MenuItem>
            ))}
          </Select>
          {errors.countryId && <Typography color="error">{errors.countryId.message}</Typography>}
        </FormControl>
      </Grid>
  );
};

export default CountrySelectForm;
