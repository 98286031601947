import { ColorModeContext, useMode } from "./theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CssBaseline, ThemeProvider } from "@mui/material";
import CenteredProgress from "./scenes/global/CenteredProgress";
import Omnybar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/TopBar";
import AuthProvider from "./AuthProvider";
import { ToastContainer } from "react-toastify";
import React, { useState, Suspense, lazy } from "react";
import { useAuth } from "./AuthProvider";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ProtectedRoute from "./ProtectedRoute";
import AdminDash from "./scenes/dashboard";
import PatientDash from "./scenes/patients/PatientDash";
import PatientProfile from "./scenes/patients/PatientInfo";
import PatientMyProfile from "./scenes/patients/PatientMyProfile";
import PatientReport from "./scenes/patients/PatientReport";
import AddPatient from "./scenes/patients/AddPatient";
import SignUp from "./scenes/patients/SignUp";
import DeviceInfo from "./scenes/devices/DeviceInfo";
import AddDevice from "./scenes/devices/AddDevice";
import MyProfile from "./scenes/users/UserInfo";
import UserProfile from "./scenes/users/UserProfile";
import AddUser from "./scenes/users/AddUser";
import AddTenantAdmin from "./scenes/users/AddTenantAdmin";
import Information from "./scenes/global/Info";
import AppBadges from "./scenes/global/DownloadApp";
import NotAuthorised from "./scenes/global/NotAuthorised";
import AddItem from "./scenes/product/AddItem";
import InvoicePaymentUpdate from "./scenes/invoice/InvoicePaid";
import ProductDetails from "./scenes/product/ItemDetails";
import AppSettings from "./scenes/global/AppSettings";

const Vitals = lazy(() => import("./scenes/patients/PatientVitals"));
const AdminLogin = lazy(() => import("./components/AdminLogin"));
const ResetPassword = lazy(() => import("./scenes/global/ResetPassword"));
const ForgotPassword = lazy(() => import("./scenes/global/ForgotPassword"));
const ProductPurchase = lazy(() => import("./scenes/global/ProductPurchase"));
const ValidateRPM = lazy(() => import("./scenes/global/ValidateRPM"));
const ValidateEmail = lazy(() => import("./scenes/global/ValidateEmail"));
const AddTenant = lazy(() => import("./scenes/tenants/AddTenant"));
const TenantInfo = lazy(() => import("./scenes/tenants/TenantInfo"));
const PatientList = lazy(() => import("./scenes/patients/PatientList"));
const ReportsList = lazy(() => import("./scenes/patients/ReportsList"));
const CoreList = lazy(() => import("./scenes/product/CoreList"));
const AnonymousList = lazy(() => import("./scenes/patients/AnonymousList"));
const DeviceList = lazy(() => import("./scenes/devices/DeviceList"));
const TenantList = lazy(() => import("./scenes/tenants/TenantList"));
const TenantPatientList = lazy(() =>
  import("./scenes/tenants/TenantPatientList")
);
const TenantDeviceList = lazy(() =>
  import("./scenes/tenants/TenantDeviceList")
);
const UserList = lazy(() => import("./scenes/users/UserList"));
const PatientRPM = lazy(() => import("./scenes/patients/PatientRPM"));
const PatientMonitor = lazy(() => import("./scenes/patients/PatientMonitor"));
const ProductList = lazy(() => import("./scenes/product/ProductList"));
const SKUList = lazy(() => import("./scenes/product/SKUList"));
const InvoiceList = lazy(() => import("./scenes/invoice/InvoiceList"));
const ConsumerInvoiceList = lazy(() =>
  import("./scenes/invoice/ConsumerInvoiceList")
);
const InvoiceDetails = lazy(() => import("./scenes/invoice/InvoiceDetails"));
const AddInvoice = lazy(() => import("./scenes/invoice/AddInvoice"));
const FirmwareInfo = lazy(() => import("./scenes/global/Firmware"));
const BTScan = lazy(() => import("./scenes/global/DeviceScan"));
const DeviceReturn = lazy(() => import("./scenes/devices/DeviceReturn"));
const DeviceReturnDetails = lazy(() =>
  import("./scenes/devices/DeviceReturnDetails")
);
const DeviceRequest = lazy(() => import("./scenes/devices/DeviceRequest"));
const DeviceRequestDetails = lazy(() =>
  import("./scenes/devices/DeviceRequestDetails")
);

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_KEY}>
      <AppContent />
    </GoogleOAuthProvider>
  );
}

function AppContent() {
  const [theme, colorMode] = useMode();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <CssBaseline />
            <AppLayout />
          </AuthProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </QueryClientProvider>
  );
}

function AppLayout() {
  const [isSidebar, setIsSidebar] = useState(true);
  const { isAuthenticated } = useAuth();

  return (
    <div className="app">
      {isAuthenticated && <Omnybar isSidebar={isSidebar} />}
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Routes>
          {/* Public Routes */}
          <Route
            path="/adminlogin"
            element={
              <Suspense fallback={<CenteredProgress />}>
                <AdminLogin />
              </Suspense>
            }
          />
          <Route path="/download-app" element={<AppBadges />} />
          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<CenteredProgress />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path="/confirmemail"
            element={
              <Suspense fallback={<CenteredProgress />}>
                <ValidateEmail />
              </Suspense>
            }
          />
          <Route path="/invoice-payment" element={<InvoicePaymentUpdate />} />
          <Route
            path="/forgotpass"
            element={
              <Suspense fallback={<CenteredProgress />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="/grantrpm"
            element={
              <Suspense fallback={<CenteredProgress />}>
                <ValidateRPM />
              </Suspense>
            }
          />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/notauthorized" element={<NotAuthorised />} />
          {isAuthenticated && (
            <>
              <Route
                path="/adminDash"
                element={
                  <ProtectedRoute
                    element={<AdminDash />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/adddevice"
                element={
                  <ProtectedRoute
                    element={<AddDevice />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/addpatient"
                element={
                  <ProtectedRoute
                    element={<AddPatient />}
                    rolesAllowed={["Admin", "HospitalAdmin"]}
                  />
                }
              />
              <Route
                path="/patientprofile"
                element={
                  <ProtectedRoute
                    element={<PatientProfile key="patientprofile" />}
                    rolesAllowed={["Admin", "ReportUser", "HospitalAdmin"]}
                  />
                }
              />
              <Route
                path="/patient-report"
                element={
                  <ProtectedRoute
                    element={<PatientReport key="patientreport" />}
                    rolesAllowed={[
                      "Admin",
                      "ReportUser",
                      "Patient",
                      "HospitalAdmin",
                    ]}
                  />
                }
              />
              <Route
                path="/my-dashboard"
                element={<ProtectedRoute element={<PatientDash />} />}
              />
              <Route
                path="/healthprofile"
                element={
                  <ProtectedRoute
                    element={<PatientMyProfile key="healthprofile" />}
                  />
                }
              />
              <Route
                path="/deviceinfo"
                element={
                  <ProtectedRoute
                    element={<DeviceInfo />}
                    rolesAllowed={["Admin", "HospitalAdmin"]}
                  />
                }
              />
              <Route
                path="/adduser"
                element={
                  <ProtectedRoute
                    element={<AddUser />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/addtenantadmin"
                element={
                  <ProtectedRoute
                    element={<AddTenantAdmin />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/myprofile"
                element={<ProtectedRoute element={<MyProfile />} />}
              />
              <Route
                path="/userprofile"
                element={
                  <ProtectedRoute
                    element={<UserProfile />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/info"
                element={<ProtectedRoute element={<Information />} />}
              />
              <Route
                path="/add-SKU"
                element={
                  <ProtectedRoute
                    element={<AddItem key="SKU" component={"SKU"} />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/add-product"
                element={
                  <ProtectedRoute
                    element={<AddItem key="product" component={"Product"} />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/add-productcore"
                element={
                  <ProtectedRoute
                    element={
                      <AddItem key="productcore" component={"ProductCore"} />
                    }
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/add-category"
                element={
                  <ProtectedRoute
                    element={<AddItem key="category" component={"Category"} />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/add-subcategory"
                element={
                  <ProtectedRoute
                    element={
                      <AddItem key="subcategory" component={"SubCategory"} />
                    }
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/SKU-details"
                element={
                  <ProtectedRoute
                    element={<ProductDetails key="SKU" component={"SKU"} />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/product-details"
                element={
                  <ProtectedRoute
                    element={
                      <ProductDetails key="product" component={"Product"} />
                    }
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/productcore-details"
                element={
                  <ProtectedRoute
                    element={
                      <ProductDetails
                        key="productcore"
                        component={"ProductCore"}
                      />
                    }
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/category-details"
                element={
                  <ProtectedRoute
                    element={
                      <ProductDetails key="category" component={"Category"} />
                    }
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/subcategory-details"
                element={
                  <ProtectedRoute
                    element={
                      <ProductDetails
                        key="subcategory"
                        component={"SubCategory"}
                      />
                    }
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/app-settings"
                element={
                  <ProtectedRoute
                    element={<AppSettings />}
                    rolesAllowed={["Admin"]}
                  />
                }
              />
              <Route
                path="/vitals"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<Vitals key="patientvitals" />}
                      rolesAllowed={["Admin", "HospitalAdmin", "Patient"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/buy-omnykare"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<ProductPurchase />}
                      rolesAllowed={[
                        "Admin",
                        "HospitalAdmin",
                        "Patient",
                        "ReportUser",
                      ]}
                    />
                  </Suspense>
                }
              />

              <Route
                path="/addtenant"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<AddTenant />}
                      rolesAllowed={["Admin"]}
                    />
                  </Suspense>
                }
              />

              <Route
                path="/tenantinfo"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<TenantInfo key="tenantinfo" />}
                      rolesAllowed={["Admin", "HospitalAdmin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/myhospital"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute element={<TenantInfo key="myhospital" />} />
                  </Suspense>
                }
              />
              <Route
                path="/patientlist"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<PatientList />}
                      rolesAllowed={["Admin", "HospitalAdmin", "ReportUser"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/reports"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<ReportsList />}
                      rolesAllowed={[
                        "Admin",
                        "Patient",
                        "HospitalAdmin",
                        "ReportUser",
                      ]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/core-list"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<CoreList />}
                      rolesAllowed={["Admin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/anonymouslist"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<AnonymousList />}
                      rolesAllowed={["Admin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/devicelist"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<DeviceList />}
                      rolesAllowed={["Admin", "HospitalAdmin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/tenantlist"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<TenantList />}
                      rolesAllowed={["Admin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/tenant-patient-list"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<TenantPatientList />}
                      rolesAllowed={["Admin", "HospitalAdmin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/tenant-device-list"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<TenantDeviceList />}
                      rolesAllowed={["Admin", "HospitalAdmin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/userlist"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<UserList />}
                      rolesAllowed={["Admin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/patientrpm"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<PatientRPM key="patientrpm" />}
                      rolesAllowed={["Admin", "HospitalAdmin", "Patient"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/patientmonitor"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<PatientMonitor />}
                      rolesAllowed={["Admin", "HospitalAdmin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/productlist"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<ProductList />}
                      rolesAllowed={["Admin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/sku-list"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<SKUList />}
                      rolesAllowed={["Admin"]}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/consumer-invoicelist"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<ConsumerInvoiceList />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/invoicelist"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<InvoiceList />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/invoice-details"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<InvoiceDetails />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/consumer-invoice-details"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={
                        <InvoiceDetails
                          key="cinvoice"
                          component="ConsumerInvoice"
                        />
                      }
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/addinvoice"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<AddInvoice />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/firmware"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<FirmwareInfo />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/deviceconnect"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute element={<BTScan />}></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/devicereturnrequest"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<DeviceReturn />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/device-request-details"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<DeviceRequestDetails />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/device-return-details"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<DeviceReturnDetails />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
              <Route
                path="/devicerequest"
                element={
                  <Suspense fallback={<CenteredProgress />}>
                    <ProtectedRoute
                      element={<DeviceRequest />}
                      rolesAllowed={["Admin"]}
                    ></ProtectedRoute>
                  </Suspense>
                }
              />
            </>
          )}
          {isAuthenticated ? (
            <Route path="*" element={<Navigate to="/adminDash" replace />} />
          ) : (
            <Route path="*" element={<Navigate to="/adminlogin" replace />} />
          )}
        </Routes>
        <ToastContainer />
      </main>
    </div>
  );
}

export default App;
