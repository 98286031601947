import { get, API_URL, login } from "../../services/api";
import { clearLocalStorage, setLocalStorageItem } from "./storage.js";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export const handleLoginSuccess = async ({
  credentialsData,
  loginUser,
  navigate,
  setIsLoading = () => {},
}) => {
  const allowedRoles = ["Admin", "Patient", "Caretaker", "HospitalAdmin"];
  setLocalStorageItem("accessToken", credentialsData.accessToken);
  try {
    const userDetails = await get(
      `${API_URL}/v2/FastballUser/CurrentUserDetails`
    );
    if (
      userDetails.enabled &&
      userDetails.roles &&
      userDetails.roles.some((role) => allowedRoles.includes(role))
    ) {
      let patientDetails, isNewPatient;
      if (credentialsData.patient) {
        patientDetails = credentialsData.patient;
        isNewPatient = !!credentialsData.isNewPatient;
      }
      loginUser(userDetails, credentialsData, patientDetails, isNewPatient);
      navigate("/adminDash");
    } else {
      toast.warning("You are not authorized to access OmnyManage.");
      clearLocalStorage();
    }
  } catch (error) {
    toast.error("User Role Authentication Failed:", error);
  } finally {
    if (typeof setIsLoading === "function") {
      setIsLoading(true);
    }
  }
};

export const handleIndependentAuth = async () => {
  const requestId = uuidv4();
  const loginData = {
    requestId,
    data: {
      username: process.env.REACT_APP_ADMIN_USERNAME,
      password: process.env.REACT_APP_ADMIN_PASSWORD,
    },
  };

  try {
    const credentialsData = await login(loginData);
    return credentialsData.accessToken;
  } catch (error) {
    toast.error(error);
  } finally {
    console.log("OK");
  }
};


