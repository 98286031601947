import { Typography, Box, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import SpaIcon from "@mui/icons-material/Spa";
import { BarChart } from "@mui/x-charts/BarChart";

const StressAnalysisDisplay = ({ stressData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartSettings = {
    width: 500,
    height: 300,
  };

  const dataset = [
    { stressLevel: "Level 30", value: stressData.stressLevel30 },
    { stressLevel: "Level 60", value: stressData.stressLevel60 },
    { stressLevel: "Level 90", value: stressData.stressLevel90 },
    { stressLevel: "Average", value: stressData.avgStress },
  ];

  const valueFormatter = (value) => `${value}`;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        backgroundColor: colors.primary[400],
        my: 2,
        borderRadius: 2,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
      >
        <SpaIcon color="action" />
        Stress Analysis
      </Typography>

      {/* Stress Levels */}
      <Box display="flex" flexDirection="column" alignItems="left" ml={15}>
        <BarChart
          dataset={dataset}
          yAxis={[{ scaleType: "band", dataKey: "stressLevel" }]}
          series={[
            { dataKey: "value", label: "Stress Levels", valueFormatter },
          ]}
          layout="horizontal"
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 10,
              itemMarkHeight: 5,
            },
          }}
          {...chartSettings}
        />
      </Box>
    </Paper>
  );
};

export default StressAnalysisDisplay;
