import React, { useState, useEffect } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

export function TablePaginationActions(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { count, page, rowsPerPage, onPageChange } = props;
  const [inputPage, setInputPage] = useState(page + 1);

  useEffect(() => {
    setInputPage(page + 1);
  }, [page]);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleInputChange = (event) => {
    setInputPage(event.target.value);
  };

  const handleInputEnter = (event) => {
    if (event.key === "Enter") {
      const newPage =
        Math.min(
          Math.max(1, Number(event.target.value)),
          Math.ceil(count / rowsPerPage)
        ) - 1;
      onPageChange(event, newPage);
      setInputPage(newPage + 1); // Adjust for user-friendly page numbering
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5, display: "flex", alignItems: "center" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        sx={{ color: colors.greenAccent[400] }}
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        sx={{ color: colors.greenAccent[200] }}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <TextField
        label="Page"
        size="small"
        value={inputPage}
        onChange={handleInputChange}
        onKeyDown={handleInputEnter}
        inputProps={{
          style: { textAlign: "center" },
          step: 1,
          min: 1,
          max: Math.ceil(count / rowsPerPage),
          type: "number",
          "aria-label": "Page",
        }}
        sx={{ maxWidth: "60px", mx: 1 }}
      />
      <IconButton
        onClick={handleNextButtonClick}
        sx={{ color: colors.greenAccent[200] }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        sx={{ color: colors.greenAccent[400] }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
