import { post, API_URL } from "../../services/api";
import {
  Button,
  MenuItem,
  Grid,
  Container,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturnOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { toast } from "react-toastify";

const DeallocateTenant = ({
  selectedDevice,
  setDeallocateTenantMode,
  setSearchTerm,
  onDeviceUpdateSuccess,
}) => {
  const [reasonId, setReasonId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeSize, setExchangeSize] = useState("");
  const [remarks, setRemarks] = useState("");
  const [exchangeSizeError, setExchangeSizeError] = useState("");
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleReasonChange = (event) => {
    const { value } = event.target;
    setReasonId(value);
    if (value !== "3") {
      // If reason is not 'SizeChange'
      setExchangeSize(""); // Reset exchangeSize
    }
  };

  const handleExchangeSizeChange = (event) => {
    const { value } = event.target;
    if (selectedDevice && value === selectedDevice.size) {
      setExchangeSizeError(
        "Exchange size cannot be the same as the current size."
      );
      setExchangeSize("");
    } else {
      setExchangeSizeError("");
      setExchangeSize(value);
    }
  };

  const handleReturnRequest = async () => {
    if (!selectedDevice) {
      toast.info("Please select a device!");
      return;
    }

    setIsLoading(true);
    try {
      if (selectedDevice && selectedDevice.uid) {
        const requestId = uuidv4();
        // Construct payload conditionally based on reasonId
        const dataPayload = {
          requestNo: uuidv4(),
          tenantId: selectedDevice.currentTenantId,
          reasonId: parseInt(reasonId, 10),
          remarks: remarks,
          statusId: 0,
        };

        if (reasonId === "3") {
          // If reason is SizeChange
          dataPayload.sizeExchangeData = [
            {
              deviceId: selectedDevice.id,
              device: selectedDevice.deviceId,
              currentSize: selectedDevice.size,
              exchangeSize: exchangeSize,
            },
          ];
        } else {
          dataPayload.deviceData = [
            {
              deviceId: selectedDevice.id,
              device: selectedDevice.deviceId,
              currentSize: selectedDevice.size,
            },
          ];
        }
        const sendData = {
          requestId,
          data: dataPayload,
        };
        const response = await post(
          `${API_URL}/v2/DeviceReturnRequest/Add`,
          sendData,
          true
        );
        if (response.statusCode === "OK") {
          const allMessages = response.messages.join("\n");
          toast.success(allMessages);
          setSearchTerm("");
          const updateWasSuccessful = true;
          onDeviceUpdateSuccess(updateWasSuccessful);
          setDeallocateTenantMode(false);
        } else {
          if (
            response.statusCode === "BadRequest" &&
            response.errors &&
            response.errors.length > 0
          ) {
            const allErrors = response.errors.join("\n");
            toast.error(allErrors);
          } else {
            toast.error("Unknown error occurred."); // Handle other unexpected cases
          }
        }
      } else {
        toast.error("Error Getting Ring UID");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.messages &&
        error.response.data.messages.length > 0
      ) {
        // If it's a 400 error and has error messages, display the first error message
        const allErrors = error.response.data.messages.join("\n");
        toast.error(allErrors);
      } else {
        // For other errors
        toast.error("Error Allocating Ring.");
      }
    } finally {
      setIsLoading(false);
      setOpenReturnDialog(false);
    }
  };

  return (
    <Container maxWidth="xl">
      <Box mb={2}>
        {" "}
        {/* Box with bottom margin */}
        {/* Reason ID dropdown */}
        <TextField
          select
          fullWidth
          label="Reason for Return"
          value={reasonId}
          onChange={handleReasonChange}
          required
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  bgcolor: colors.primary[400],
                },
              },
            },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="1">Return</MenuItem>
          <MenuItem value="2">Replacement</MenuItem>
          <MenuItem value="3">Size Change</MenuItem>
        </TextField>
      </Box>

      {/* Conditional exchangeSize dropdown */}
      {reasonId === "3" && (
        <Box mb={2}>
          {" "}
          {/* Box with bottom margin */}
          <TextField
            select
            fullWidth
            label="Exchange Size"
            value={exchangeSize}
            onChange={handleExchangeSizeChange}
            error={!!exchangeSizeError}
            helperText={exchangeSizeError}
            required
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    bgcolor: colors.primary[400],
                  },
                },
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Large">Large</MenuItem>
            <MenuItem value="ExtraSmall">Extra Small</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Small">Small</MenuItem>
          </TextField>
        </Box>
      )}

      <Box mb={2}>
        {" "}
        {/* Box with bottom margin */}
        {/* Remarks input box */}
        <TextField
          fullWidth
          label="Remarks"
          value={remarks}
          onChange={handleRemarksChange}
          multiline
        />
      </Box>
      <Grid container justifyContent="center" style={{ marginTop: "16px" }}>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              onClick={() => {
                if (!reasonId) {
                  toast.info("Please select a reason for return.");
                  return;
                }
                // If reasonId is '3' (Size Change), check if exchangeSize is also selected
                if (reasonId === "3" && !exchangeSize) {
                  toast.info("Please select an exchange size.");
                  return;
                }
                setOpenReturnDialog(true);
              }}
            >
              <KeyboardReturnIcon sx={{ mr: "10px" }} />
              Return Device
            </Button>
            <Button
              onClick={() => {
                setDeallocateTenantMode(false);
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>

      {/* The Dialog JSX */}
      <Dialog
        open={openReturnDialog}
        onClose={() => setOpenReturnDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: colors.primary[400],
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: colors.greenAccent[400], typography: "h5" }}
        >
          {"Confirm Device Return"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to return this device from currrent Tenant?
            This action will require approval.
          </DialogContentText>
          {isLoading && <CenteredProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReturnDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            disabled={isLoading}
            onClick={() => {
              handleReturnRequest();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DeallocateTenant;
