import { useForm, FormProvider, Controller } from "react-hook-form";
import React, { useState } from "react";
import { post, API_URL } from "../../services/api";
import { Grid, Button, Box, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import UserForm from "../forms/UserForm";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import ItemSearch from "../search/itemSearch";
import AddIcon from "@mui/icons-material/AddOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";

const AddTenantAdmin = ({ setResponseMessage, setIsSuccess }) => {
  const methods = useForm();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  const handleTenantSelected = (tenant, field) => {
    field.onChange(tenant.uid);
  };

  const onSubmit = async (formData) => {
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };
    setIsLoading(true);
    try {
      const response = await post(
        `${API_URL}/v2/TenantAdmin/Add`,
        payload,
        true
      );
      if (response.statusCode === "OK" && response.errors.length === 0) {
        setResponseMessage(response.messages[0]);
        setIsSuccess(true);
        methods.reset();
      } else if (response.statusCode === "OK" && response.errors.length > 0) {
        setIsSuccess(false);
        setResponseMessage(response.errors[0]);
      } else {
        setIsSuccess(false);
        setResponseMessage("Error adding Tenant Admin.");
      }
    } catch (error) {
      if (error.response.data && error.response.data.errors.length > 0) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        setIsSuccess(false);
        setResponseMessage(validationErrorMessages);
      } else {
        // Handle other errors
        setIsSuccess(false);
        setResponseMessage("Error adding Tenant Admin.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="center" mt={2} mb={2}>
          <Controller
            name="tenantUId"
            control={methods.control}
            rules={{ required: "Tenant is required" }}
            render={({ field, fieldState: { error } }) => (
              <Box width="100%">
                {" "}
                {/* Ensure the Box takes the full width */}
                <ItemSearch
                  onItemSelected={(tenant) =>
                    handleTenantSelected(tenant, field)
                  }
                  {...field}
                  component="Tenant"
                />
                {error && (
                  <Typography
                    color="error"
                    variant="caption"
                    display="block"
                    mt={1}
                  >
                    {error.message}
                  </Typography>
                )}
              </Box>
            )}
          />
        </Box>
        <UserForm />
        {isLoading && <CenteredProgress />}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              disabled={isLoading}
              type="submit"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
            >
              <AddIcon sx={{ mr: "10px" }} />
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AddTenantAdmin;
