import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

// const API_URL = 'https://avida.dev.omnyk.com';
const API_URL = process.env.REACT_APP_API_URL;

// Create axios instance
const service = axios.create({
  // baseURL: 'https://avida.omnyk.com',
  timeout: 10000, // Request timeout
});

function isJWT(token) {
  if (!token || token.split(".").length !== 3) {
    return false;
  }
  try {
    jwtDecode(token); // Decoding will fail if it's not a valid JWT
    return true;
  } catch (error) {
    return false;
  }
}

// Request interceptor
service.interceptors.request.use(
  (config) => {
    // Check if Authorization header is already set in the request
    if (!config.headers["Authorization"]) {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error("Server Error"));
    } else {
      return response.data;
    }
  },
  (error) => {
    if (error.response && error.response.status === 404) {
      return Promise.reject(new Error("Not Found"));
    }
    if (
      error.code === "ECONNABORTED" &&
      error.message.indexOf("timeout") !== -1
    ) {
      return Promise.reject(new Error("API Timeout"));
    }
    return Promise.reject(error);
  }
);

export async function securePost(
  token,
  path,
  data = null,
  fullResponse = false
) {
  const config = {
    headers: { Authorization: "Bearer " + token },
  };
  try {
    const response = data
      ? await service.post(path, data, config)
      : await service.post(path, {}, config);
    if (fullResponse) {
      return response; // return the entire response object
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function to handle GET requests
async function get(path, fullResponse = false) {
  try {
    const response = await service.get(path);
    if (fullResponse) {
      return response; // return the entire response object
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function to handle POST requests
async function post(path, data = null, fullResponse = false) {
  try {
    const response = data
      ? await service.post(path, data)
      : await service.post(path);
    if (fullResponse) {
      return response; // return the entire response object
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function refreshToken() {
  const path = `${API_URL}/v2/Token/refresh`;
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!isJWT(accessToken) || !isJWT(refreshToken)) {
    // Handle invalid token case here (e.g., redirect to login)
    return;
  }

  const payload = {
    requestId: uuidv4(),
    data: {
      accessToken: accessToken,
      refreshToken: refreshToken,
      setCookie: false,
    },
  };
  try {
    const response = await service.post(path, payload);
    if (response.statusCode === "OK") {
      const credentialsData = response.data;
      // Map 'token' to 'accessToken'
      credentialsData.accessToken = credentialsData.token;
      delete credentialsData.token;
      return credentialsData;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}

// Function to handle PUT requests
async function put(path, data = null, fullResponse = false) {
  try {
    const response = data
      ? await service.put(path, data)
      : await service.put(path);
    if (fullResponse) {
      return response; // return the entire response object
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}
// Function to handle DELETE requests
async function deleteRequest(path, data = null, fullResponse = false) {
  try {
    let response;
    if (data) {
      response = await service.delete(path, { data: data });
    } else {
      response = await service.delete(path);
    }

    if (fullResponse) {
      return response; // return the entire response object
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function to handle Login requests
async function login(data) {
  try {
    const response = await service.post(
      `${API_URL}/v2/Token/GenerateToken`,
      data
    );
    const {
      token: accessToken,
      refreshToken,
      username,
      createdTime,
      expiresIn,
      twoFactorEnabled,
      patient,
    } = response.data;
    const { messages } = response;
    let isNewPatient = false;
    if (
      messages.includes(
        "Email confirmation mail sent.. Please check it and confirm"
      )
    ) {
      isNewPatient = true;
    }
    return {
      accessToken,
      refreshToken,
      username,
      createdTime,
      expiresIn,
      twoFactorEnabled,
      patient,
      isNewPatient,
    };
  } catch (error) {
    throw error;
  }
}

async function externalAuth(data) {
  try {
    const response = await axios.post(`${API_URL}/ExternalAuthToken`, data);
    const {
      token: accessToken,
      refreshToken,
      username,
      createdTime,
      expiresIn,
      twoFactorEnabled,
      patient,
    } = response.data.data;
    const { messages } = response.data;
    let isNewPatient = false;
    if (
      messages.includes(
        "Email confirmation mail sent.. Please check it and confirm"
      )
    ) {
      isNewPatient = true;
    }
    return {
      accessToken,
      refreshToken,
      username,
      createdTime,
      expiresIn,
      twoFactorEnabled,
      patient,
      isNewPatient,
    };
  } catch (error) {
    throw error;
  }
}

export default service;
export {
  get,
  post,
  put,
  deleteRequest,
  login,
  externalAuth,
  refreshToken,
  API_URL,
};
