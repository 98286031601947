import { Typography, Box, Paper, LinearProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const HeartHealthAnalysisDisplay = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  return (
    <Paper elevation={3} sx={{ p: 2, backgroundColor: colors.primary[400] }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="h6" gutterBottom>
          Heart Health Analysis
        </Typography>

        <Box display="flex" alignItems="center">
          <FavoriteIcon color="error" sx={{ mr: 1 }} />
          <Typography variant="body1">
            Resting Heart Rate: {data.restingHeartRate} bpm
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <FitnessCenterIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body1">
            HR Variance: {data.hrVariance}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <HeartBrokenIcon color="warning" sx={{ mr: 1 }} />
          <Typography variant="body1">
            Beats Missed: {data.beatsMissed}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <DirectionsRunIcon color="action" sx={{ mr: 1 }} />
          <Typography variant="body1">Steps: {data.steps}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <AccessTimeIcon color="secondary" sx={{ mr: 1 }} />
          <Typography variant="body1">
            Activity Duration: {formatDuration(data.activityDuration)}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="body1" sx={{ mb: 1 }}>
            Heart Health Score
          </Typography>
          <LinearProgress
            variant="determinate"
            value={data.heartHealthScore}
            sx={{
              width: "100%",
              "& .MuiLinearProgress-bar": {
                backgroundColor: colors.pastelColor.pink,
              },
            }}
          />
          <Typography variant="caption">{data.heartHealthScore}%</Typography>
        </Box>
      </Box>
    </Paper>
  );
};


export default HeartHealthAnalysisDisplay;
