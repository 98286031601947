import React, { useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { get, API_URL } from "../../services/api";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import BatteryLevelDisplay from "../../components/common/batteryIndicator.js";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  SvgIcon,
  Divider,
  Tooltip,
  IconButton,
  Avatar,
} from "@mui/material";
import { format } from "date-fns";
import NotInterestedIcon from "@mui/icons-material/NotInterestedOutlined";
import { ReactComponent as SpO2Icon } from "../../assets/spo2.svg";
import { ReactComponent as BMIcon } from "../../assets/bm.svg";
import { ReactComponent as HRIcon } from "../../assets/hr.svg";
import { ReactComponent as StepsIcon } from "../../assets/steps.svg";
import { ReactComponent as TempIcon } from "../../assets/temp.svg";
import { ReactComponent as IBIIcon } from "../../assets/ibi.svg";
import { HRAnimation } from "../../components/animations/animations";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreenOutlined";
import OpenInFullIcon from "@mui/icons-material/OpenInFullOutlined";
import UpdateIcon from "@mui/icons-material/UpdateOutlined";
import Header from "../../components/Header";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../../components/auth/storage";

const RPMLive = ({
  viewId,
  userEmail,
  userData,
  startMonitoring,
  stopMonitoring,
  setOpenRPMView,
  patientDataRefetch,
  careTakerRefetch,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [storedTime, setStoredTime] = useState("");
  const [rpmError, setRPMError] = useState("");
  const intervalRef = useRef(null);
  const previousEmailRef = useRef(null);
  const previousTzRef = useRef(null);
  const fetchIntervalRef = useRef(15000);
  const [intervalTrigger, setIntervalTrigger] = useState(0);
  const sameStoredTimeCountRef = useRef(0);
  const previousStoredTimeRef = useRef("");
  const [careTakerCount, setCareTakerCount] = useState(0);
  const [shouldPause, setShouldPause] = useState(false);
  const [minView, setMinView] = useState(false);
  const [savedProtoData, setSavedProtoData] = useState(null);
  const HealthData =
    require("../../components/patients/processedData.js").HealthData;
  const [vitals, setVitals] = useState({
    SpO2: { value: "_ _", subtitle: "Blood O2 Saturation", icon: SpO2Icon },
    HR: { value: "_ _", subtitle: "Heart Rate", icon: HRIcon },
    IBI: { value: "_ _", subtitle: "Interbeat interval", icon: IBIIcon },
    BM: { value: "_ _", subtitle: "Beats Missed Since Midnight", icon: BMIcon },
    Skin: { value: "_ _", subtitle: "Body Temperature", icon: TempIcon },
    Pedometer: {
      value: "_ _",
      subtitle: "Steps Since Midnight",
      icon: StepsIcon,
    },
  });
  const [knownGoodHeartRate, setKnownGoodHeartRate] = useState("_ _");
  const [knownGoodSpO2, setKnownGoodSpO2] = useState("_ _");
  const storageKey = `rpmView_${viewId}`;

  const [userDeviceData, setUserDeviceData] = useState({
    swVersion: { version: "_ _" },
    onlineStatus: { isOnlineData: false },
    time: { customUnixTime: 0 },
    batteryLevel: { batteryLevel: "_ _" },
  });
  const { swVersion, onlineStatus, time, batteryLevel } = userDeviceData;
  const customUnixTimeToDate = (customUnixTime) => {
    if (customUnixTime === 0) {
      return "No Data yet";
    } else {
      const unixTimestampAdjusted = parseInt(customUnixTime) + 1672531200000;
      return format(new Date(unixTimestampAdjusted), "do MMMM, yyyy 'at' pp");
    }
  };
  const tooltipMessage = rpmError
    ? `Error: ${rpmError}`
    : fetchIntervalRef.current >= 45000
    ? "Request user to wear the ring firmly & confirm network connectivity."
    : careTakerCount > 1
    ? `You and ${careTakerCount - 1} others are actively monitoring`
    : careTakerCount === 1
    ? "You are actively monitoring"
    : "You are not actively monitoring yet.";

  const avatarColor = rpmError
    ? colors.pastelColor.red
    : fetchIntervalRef.current >= 45000
    ? colors.pastelColor.orange
    : colors.pastelColor.blue;

  const resetToDefaultValues = () => {
    setShouldPause(false);
    setCareTakerCount(0);
    sameStoredTimeCountRef.current = 0;
    fetchIntervalRef.current = 15000;
    setStoredTime("");
    setRPMError("");
    setVitals({
      SpO2: { value: "_ _", subtitle: "Blood O2 Saturation", icon: SpO2Icon },
      HR: { value: "_ _", subtitle: "Heart Rate", icon: HRIcon },
      IBI: { value: "_ _", subtitle: "Interbeat interval", icon: IBIIcon },
      BM: {
        value: "_ _",
        subtitle: "Beats Missed Since Midnight",
        icon: BMIcon,
      },
      Skin: { value: "_ _", subtitle: "Body Temperature", icon: TempIcon },
      Pedometer: {
        value: "_ _",
        subtitle: "Steps Since Midnight",
        icon: StepsIcon,
      },
    });

    setKnownGoodHeartRate("_ _");
    setKnownGoodSpO2("_ _");

    setUserDeviceData({
      swVersion: { version: "_ _" },
      onlineStatus: { isOnlineData: false },
      time: { customUnixTime: 0 },
      batteryLevel: { batteryLevel: "_ _" },
    });
  };

  useEffect(() => {
    try {
      const savedData = getLocalStorageItem(storageKey);
      if (savedData) {
        setMinView(savedData.minView ?? false);
        setSavedProtoData(savedData.responseData ?? null);
      }
    } catch (error) {
      console.error("Failed to load and parse local storage data:", error);
    }
  }, [viewId]);

  const processHealthData = (message, steps, beats) => {
    // Calculate average heart rate
    const heartRates = message.heartRate.heartRates || [];
    let averageHeartRate =
      heartRates.length > 0
        ? Math.round(
            heartRates.reduce((acc, cur) => acc + cur, 0) / heartRates.length
          )
        : null;
    let validHeartRate =
      averageHeartRate && averageHeartRate >= 40 && averageHeartRate <= 200
        ? averageHeartRate.toString()
        : knownGoodHeartRate;

    // Update known good heart rate if a new valid value is found
    if (validHeartRate !== knownGoodHeartRate && validHeartRate !== "_ _") {
      setKnownGoodHeartRate(validHeartRate);
    }

    // Calculate IBI from the valid average heart rate
    let ibi =
      validHeartRate !== "_ _"
        ? Math.round(60000 / parseInt(validHeartRate))
        : "_ _";

    // Validate SpO2
    const spo2Value = message.spo2.spo2Value;
    let validSpO2 =
      spo2Value >= 75 && spo2Value <= 99 ? spo2Value.toString() : knownGoodSpO2;

    // Update known good SpO2 if a new valid value is found
    if (validSpO2 !== knownGoodSpO2 && validSpO2 !== "_ _") {
      setKnownGoodSpO2(validSpO2);
    }

    const stepsValue = steps || "_ _";

    const bmValue = beats || "_ _";

    const skinTempCelsius = message.skinTemperature.temperature / 100;
    const skinTempFahrenheit = ((skinTempCelsius * 9) / 5 + 32).toFixed(1);

    setVitals((prevVitals) => ({
      ...prevVitals,
      HR: {
        ...prevVitals.HR,
        value: validHeartRate !== "_ _" ? `${validHeartRate} bpm` : "_ _",
      },
      IBI: { ...prevVitals.IBI, value: ibi !== "_ _" ? `${ibi} ms` : "_ _" },
      SpO2: {
        ...prevVitals.SpO2,
        value: validSpO2 !== "_ _" ? `${validSpO2}%` : "_ _",
      },
      BM: { ...prevVitals.BM, value: bmValue },
      Skin: { ...prevVitals.Skin, value: `${skinTempFahrenheit}°F` },
      Pedometer: { ...prevVitals.Pedometer, value: stepsValue },
    }));
  };

  const processProtoData = (protoData, steps, beats) => {
    try {
      // Convert to regular Base64 before decoding
      const base64String = protoData.replace(/-/g, "+").replace(/_/g, "/");
      const decodedArrayBuffer = atob(base64String);
      const byteArray = new Uint8Array(decodedArrayBuffer.length);
      for (let i = 0; i < decodedArrayBuffer.length; i++) {
        byteArray[i] = decodedArrayBuffer.charCodeAt(i);
      }
      const message = HealthData.decode(byteArray);
      setUserDeviceData({
        swVersion: { version: message.swVersion?.version || "_ _" },
        onlineStatus: {
          isOnlineData: message.onlineStatus?.isOnlineData ?? false,
        },
        time: { customUnixTime: message.time?.customUnixTime || 0 },
        batteryLevel: {
          batteryLevel: message.batteryLevel?.batteryLevel || "_ _",
        },
      });

      const formattedTime = customUnixTimeToDate(
        userDeviceData.time.customUnixTime
      );
      processHealthData(message, steps, beats);
    } catch (error) {
      console.error("Error decoding the string:", error.message);
    }
  };

  const saveResponseData = (responseData) => {
    const existingData = getLocalStorageItem(storageKey) || {};
    existingData.responseData = responseData;
    setLocalStorageItem(storageKey, existingData);
  };

  // Function to fetch RPM vitals data
  const fetchRPMVitals = async () => {
    const url = `${API_URL}/v2/Vitals/GetRPMVitals?email=${encodeURIComponent(
      userEmail
    )}`;
    try {
      const response = await get(url, true);
      if (response.statusCode === "OK" && response.data) {
        const storedTimeUtc = new Date(response.data.storedTime);
        const storedTimeLocal = storedTimeUtc.toLocaleString();
        setStoredTime(storedTimeLocal);
        setCareTakerCount(response.data.careTakers.length);
        if (response.data.protoData) {
          setShouldPause(false);
        }
        if (response.data.storedTime === previousStoredTimeRef.current) {
          sameStoredTimeCountRef.current += 1;
        } else {
          if (response.data.protoData) {
            saveResponseData(response.data);
            processProtoData(
              response.data.protoData,
              response.data.steps,
              response.data.beats
            );
          }
          sameStoredTimeCountRef.current = 0;
          if (fetchIntervalRef.current !== 15000) {
            fetchIntervalRef.current = 15000;
            setIntervalTrigger((prev) => prev + 1);
            setShouldPause(false);
            console.log("Resetting Interval:", fetchIntervalRef.current);
          }
        }
        previousStoredTimeRef.current = response.data.storedTime;

        // Adjust the fetch interval based on successive checks
        if (sameStoredTimeCountRef.current >= 5) {
          const newInterval = Math.min(
            fetchIntervalRef.current + 30000,
            1800000
          );
          fetchIntervalRef.current = newInterval;
          setIntervalTrigger((prev) => prev + 1);
          setShouldPause(true);
          console.log("Increasing Interval:", newInterval);
          sameStoredTimeCountRef.current = 0;
        }
      } else {
        console.error(
          "Failed to fetch RPM vitals:",
          response.errors || "Unknown error"
        );
      }
    } catch (error) {
      patientDataRefetch();
      setCareTakerCount(0);
      if (intervalRef.current) clearInterval(intervalRef.current);
      setShouldPause(true);
      console.error("Error fetching RPM vitals:", error);
      if (
        error.response &&
        error.response.data ===
          "Vitals data not found, please restart the monitoring!!"
      ) {
        if (
          previousEmailRef.current &&
          previousEmailRef.current === userEmail
        ) {
          toast.error("Monitoring needs to be restarted");
          setRPMError("Monitoring needs to be restarted");
        }
      } else if (
        error.response &&
        error.response.data ===
          "Invalid patient or patient stopped monitoring request!!"
      ) {
        if (
          previousEmailRef.current &&
          previousEmailRef.current === userEmail
        ) {
          toast.error("Please check patient rpm status & monitoring status!");
          setRPMError("Please check patient rpm status & monitoring status!");
        }
      }
    }
  };

  const setupFetchInterval = () => {
    clearInterval(intervalRef.current); // Clear any existing interval
    intervalRef.current = setInterval(fetchRPMVitals, fetchIntervalRef.current); // Set up a new interval
  };

  useEffect(() => {
    const manageMonitoring = async () => {
      if (previousEmailRef.current !== userEmail) {
        if (previousEmailRef.current) {
          await stopMonitoring(previousEmailRef.current, previousTzRef.current);
        }
        resetToDefaultValues();
        previousEmailRef.current = userEmail;
        previousTzRef.current = userData.timeZone;
        await startMonitoring(userEmail, userData.timeZone);
        if (savedProtoData) {
          const storedTimeUtc = new Date(savedProtoData.storedTime);
          const storedTimeLocal = storedTimeUtc.toLocaleString();
          setStoredTime(storedTimeLocal);
          setCareTakerCount(savedProtoData.careTakers.length);
          if (savedProtoData.protoData) {
            setShouldPause(false);
            processProtoData(
              savedProtoData.protoData,
              savedProtoData.steps,
              savedProtoData.beats
            );
          }
        }
        await fetchRPMVitals();
      }
      setupFetchInterval();
    };

    manageMonitoring();

    return () => clearInterval(intervalRef.current);
  }, [userEmail, intervalTrigger]);

  const formattedStoredTime = storedTime
    ? format(new Date(storedTime), "do MMM, yyyy 'at' pp")
    : "";

  const handleMinView = () => {
    const newMinViewState = !minView;
    setMinView(newMinViewState);
    const existingData = getLocalStorageItem(storageKey) || {};
    existingData.minView = newMinViewState;
    setLocalStorageItem(storageKey, existingData);
  };

  return (
    <div>
      <Grid container justifyContent="flex-end" alignItems="center" mt={2}>
        <Grid item>
          <Tooltip title={minView ? "Maximize" : "Minimize"} placement="top">
            <span>
              <IconButton
                onClick={handleMinView}
                aria-label={minView ? "maximize" : "minimize"}
              >
                {minView ? (
                  <OpenInFullIcon
                    sx={{
                      color: colors.pastelColor.blue,
                    }}
                    fontSize="medium"
                  />
                ) : (
                  <CloseFullscreenIcon
                    sx={{
                      color: colors.pastelColor.blue,
                    }}
                    fontSize="medium"
                  />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      {!minView ? (
        <>
          <Header
            title="Live"
            subtitle={`Monitoring ${userData.patientName}`}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Grid item xs={12} container justifyContent="center">
              <HRAnimation
                style={{ width: 150, height: 150 }}
                shouldPause={shouldPause}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" align="left" component="h2">
                  Patient : {userData.patientName}
                </Typography>
                <Typography variant="h6" align="left" component="h2">
                  Email : {userEmail}
                </Typography>
                <Typography variant="h6" align="left" component="h2">
                  Contact : {userData.contactNumber}
                </Typography>
                <Typography variant="body1" align="left">
                  Last Updated : {formattedStoredTime}
                </Typography>
                <Typography variant="body1" align="left">
                  Monitoring Interval :{" "}
                  {(fetchIntervalRef.current / 60000).toFixed(2)} minutes
                </Typography>
                <Typography variant="body1" align="left">
                  Monitoring :{" "}
                  {careTakerCount > 1
                    ? `You and ${
                        careTakerCount - 1
                      } others are actively monitoring`
                    : careTakerCount === 1
                    ? "You are actively monitoring"
                    : "You are not actively monitoring yet."}
                </Typography>
                {fetchIntervalRef.current >= 45000 && (
                  <Typography
                    variant="body1"
                    align="left"
                    sx={{ color: colors.pastelColor.red }}
                  >
                    Request user to wear the ring firmly & confirm network
                    connectivity.
                  </Typography>
                )}
                {rpmError && (
                  <Typography
                    variant="body1"
                    align="left"
                    sx={{ color: colors.pastelColor.red }}
                  >
                    Error: {rpmError}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <BatteryLevelDisplay batteryLevel={batteryLevel.batteryLevel} />
                <Typography variant="body1" align="right">
                  Firmware Version : {swVersion.version}
                </Typography>
                <Typography variant="body1" align="right">
                  Online Status :{" "}
                  {onlineStatus.isOnlineData ? "Online" : "Offline"}
                </Typography>
                <Typography variant="body1" align="right">
                  Last Data Time : {customUnixTimeToDate(time.customUnixTime)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2} mt={2} mb={2}>
            {Object.entries(vitals).map(
              ([key, { value, subtitle, icon: Icon }], index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ backgroundColor: colors.primary[400] }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {Icon && (
                          <SvgIcon
                            component={Icon}
                            sx={{
                              color: colors.greenAccent[500],
                              marginRight: 2,
                              width: 40,
                              height: 40,
                            }}
                          />
                        )}
                        <Box>
                          <Typography
                            variant="h4"
                            component="div"
                            gutterBottom
                            sx={{ color: colors.grey[100] }}
                          >
                            {key}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: colors.greenAccent[500] }}
                          >
                            {subtitle}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ color: colors.grey[100], fontSize: "1.5rem" }}
                      >
                        {value}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            )}
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              onClick={() => {
                stopMonitoring(userEmail, userData.timeZone);
                setOpenRPMView(false);
              }}
            >
              <NotInterestedIcon
                sx={{ color: colors.pastelColor.red, mr: "10px" }}
              />
              {userEmail}
            </Button>
          </div>
        </>
      ) : (
        <Grid container spacing={0.5} alignItems="center" mt={1} mb={1}>
          {/* Patient Name and Avatar */}
          <Grid item xs={3} md={2}>
            <Tooltip title={tooltipMessage} placement="top">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: avatarColor,
                  }}
                >
                  {userData.patientName.charAt(0)}
                </Avatar>
                <Typography variant="body2" sx={{ color: colors.grey[100] }}>
                  {userData.patientName}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
          {/* Vitals Information */}
          {Object.entries(vitals).map(([key, { value, icon: Icon }], index) => (
            <Grid item xs key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {Icon && (
                  <SvgIcon
                    component={Icon}
                    sx={{
                      color: colors.greenAccent[500],
                      marginBottom: 1,
                      width: 24,
                      height: 24,
                    }}
                  />
                )}
                <Typography variant="caption" sx={{ color: colors.grey[100] }}>
                  {key}
                </Typography>
                <Typography variant="body2" sx={{ color: colors.grey[100] }}>
                  {value}
                </Typography>
              </Box>
            </Grid>
          ))}

          {/* Last Updated Information */}
          <Grid item xs={3} md={2}>
            <Tooltip
              title={`Last Updated: ${formattedStoredTime}`}
              placement="top"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <SvgIcon
                  component={UpdateIcon}
                  sx={{ color: colors.greenAccent[500], marginBottom: 1 }}
                />
                <BatteryLevelDisplay batteryLevel={batteryLevel.batteryLevel} />
              </Box>
            </Tooltip>
          </Grid>
          {/* Close Button */}
          <Grid item xs={3} md={2}>
            <Tooltip title="Stop Monitoring" placement="top">
              <IconButton
                onClick={() => {
                  stopMonitoring(userEmail, userData.timeZone);
                  setOpenRPMView(false);
                }}
                aria-label="close"
              >
                <NotInterestedIcon
                  sx={{ color: colors.pastelColor.red }}
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <Divider
        style={{ backgroundColor: colors.greenAccent[400], height: "1px" }}
      />
    </div>
  );
};

export default RPMLive;
