import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { ScatterChart } from "@mui/x-charts/ScatterChart";
import Plot from "react-plotly.js";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";

const chartSettings = {
  width: 500,
  height: 300,
};

export const ScatterPlot = ({
  xData,
  yData,
  xAxisTitle,
  yAxisTitle,
  title,
  pColor = 2,
  width = 600,
  height = 400,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div>
      <Plot
        data={[
          {
            x: xData,
            y: yData,
            mode: "markers",
            marker: {
              color:
                pColor === 1 ? colors.pastelColor.c1 : colors.pastelColor.c2,
            },
          },
        ]}
        layout={{
          title: {
            text: title,
            font: {
              color: colors.grey[100],
            },
          },
          xaxis: {
            title: {
              text: xAxisTitle,
              font: {
                color: colors.grey[100],
              },
            },
            tickfont: {
              color: colors.grey[100],
            },
          },
          yaxis: {
            title: {
              text: yAxisTitle,
              font: {
                color: colors.grey[100],
              },
            },
            tickfont: {
              color: colors.grey[100],
            },
          },
          paper_bgcolor: "rgba(0,0,0,0)", // Transparent background
          plot_bgcolor: "rgba(0,0,0,0)", // Transparent background
          width: width,
          height: height,
        }}
        config={{ responsive: true }}
      />
    </div>
  );
};

export const PlotlyHis = ({
  xAxisTitle,
  data,
  title,
  pColor = 1,
  sColor = 1,
  width = 600,
  height = 400,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const filteredData = data.filter((value) => value !== 0);
  return (
    <div>
      <Plot
        data={[
          {
            x: filteredData,
            type: "histogram",
            marker: {
              color:
                pColor === 1 ? colors.pastelColor.c1 : colors.pastelColor.c2,
              line: {
                color:
                  sColor === 1
                    ? colors.pastelColor.c1d
                    : colors.pastelColor.c2d,
                width: 1,
              },
            },
          },
        ]}
        layout={{
          title: {
            text: title,
            font: {
              color: colors.grey[100],
            },
          },
          xaxis: {
            title: {
              text: xAxisTitle,
              font: {
                color: colors.grey[100],
              },
            },
            tickfont: {
              color: colors.grey[100],
            },
          },
          yaxis: {
            title: {
              text: "Count",
              font: {
                color: colors.grey[100],
              },
            },
            tickfont: {
              color: colors.grey[100],
            },
          },
          paper_bgcolor: "rgba(0,0,0,0)", // Transparent background
          plot_bgcolor: "rgba(0,0,0,0)", // Transparent background
          width: width,
          height: height,
        }}
        config={{ responsive: true }}
      />
    </div>
  );
};

export const ScatterPlots = ({ data, title, xAxis, yAxis }) => {
  return (
    <div>
      <h3>{title}</h3>
      <ScatterChart
        series={[{ data }]}
        xAxis={[{ dataKey: xAxis }]}
        yAxis={[{ dataKey: yAxis }]}
      />
    </div>
  );
};

export const PieCharts = ({ data, title }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const seriesData = data.map((item) => ({
    id: item.id,
    value: item.value,
    label: item.id,
  }));

  return (
    <div>
     <h2 style={{ color: colors.grey[100], textAlign: 'center', fontWeight: 'normal' }}>{title}</h2>
      <PieChart
        series={[
          {
            data: seriesData,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: colors.primary[200],
            },
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -90,
            endAngle: 210,
          },
        ]}
        width={400}
        height={300}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "middle" },
            padding: 10,
            itemMarkHeight: 5,
          },
        }}
      />
    </div>
  );
};

export const BarCharts = ({ xAxisData, seriesData, title }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartSettings = {
    width: 600,
    height: 300,
  };

  return (
    <div>
     <h2 style={{ color: colors.grey[100], textAlign: 'center', fontWeight: 'normal' }}>{title}</h2>
      <BarChart
        xAxis={[{ scaleType: "band", data: xAxisData }]} // Categories for each group
        series={seriesData.map((series) => ({
          data: series.data,
          label: series.label,
          highlightScope: { faded: "global", highlighted: "series" },
        }))}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "middle" },
            padding: 0,
            itemMarkHeight: 5,
          },
        }}
        {...chartSettings}
      />
    </div>
  );
};

export const LineCharts = ({ data, title, xAxis, yAxis }) => {
  return (
    <div>
      <h3>{title}</h3>
      <LineChart
        series={[{ data }]}
        xAxis={[{ scaleType: "time", dataKey: xAxis }]}
        yAxis={[{ dataKey: yAxis }]}
      />
    </div>
  );
};
