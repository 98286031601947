import React, { useState, useEffect } from "react";
import CenteredProgress from "../global/CenteredProgress";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import {
  useFetchDeviceDetails,
  useFetchDeviceDetailsMId,
} from "../../components/device/useFetchDeviceInfo";
import DeviceDetails from "./DeviceDetails";
import { Container, Box, IconButton } from "@mui/material";
import ActionToolbar from "../../components/common/ActionToolbar";
import { useNavigate, useLocation } from "react-router-dom";
import EditDevice from "../../components/device/editDevice";
import AllocateTenant from "../../components/device/allocateTenant";
import AllocatePatient from "../../components/device/allocatePatient";
import DeallocatePatient from "../../components/device/deAllocatePatient";
import DeallocateTenant from "../../components/device/deAllocateTenant";
import DeleteDevice from "../../components/device/deleteDevice";
import MapContainer from "../../components/device/location";
import DeviceSearch from "../../components/device/deviceSearch";
import ProgressBarWithMilestones from "../../components/device/status";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useAuth } from "../../AuthProvider";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../components/auth/storage";
import { useQueryClient } from "@tanstack/react-query";

const DeviceInfo = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const homePath = "/deviceinfo";
  const { isAuthenticated, userDetails } = useAuth();
  const queryClient = useQueryClient();
  const [deviceUpdateSuccess, setDeviceUpdateSuccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [assignTenantMode, setAssignTenantMode] = useState(false);
  const [assignPatientMode, setAssignPatientMode] = useState(false);
  const [deallocatePatientMode, setdeallocatePatientMode] = useState(false);
  const [deallocateTenantMode, setDeallocateTenantMode] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [editableDevice, setEditableDevice] = useState(null);
  const [deviceStatus, setDeviceStatus] = useState("Unallocated");
  const [isloading, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const isAdmin = userDetails.roles.some(
    (role) => role === "Admin" || role === "HospitalAdmin"
  );
  const isSearchBarEnabled = isAdmin;
  const navigate = useNavigate();
  const location = useLocation();
  const handleBack = () => {
    navigate(-1);
  };
  const queryParams = new URLSearchParams(location.search);
  const urlInitialDeviceMId = queryParams.get("macadd");
  const initialDevice = location.state?.initialDevice;
  const initialDeviceMId =
    urlInitialDeviceMId || location.state?.initialDeviceMId;
  const [selectedDeviceMId, setSelectedDeviceMId] = useState(initialDeviceMId);
  const [selectedDeviceUid, setSelectedDeviceUid] = useState(null);

  const {
    data: deviceDetails,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchDeviceDetails(selectedDeviceUid, initialDevice);
  const {
    data: deviceDetailsMId,
    isLoading: midIsLoading,
    isError: midIsError,
    error: midError,
    refetch: refetchMid,
  } = useFetchDeviceDetailsMId(selectedDeviceMId, initialDevice);
  const [status, setStatus] = useState({
    add: { disabled: false, tooltip: "Add new device" },
    edit: { disabled: true, tooltip: "Edit selected device" },
    delete: { disabled: true, tooltip: "Delete selected device" },
    assignPatient: { disabled: true, tooltip: "Assign patient" },
    assignTenant: { disabled: true, tooltip: "Assign tenant" },
    deallocatePatient: { disabled: true, tooltip: "Deallocate patient" },
    deallocateTenant: { disabled: true, tooltip: "Deallocate tenant" },
    location: { disabled: false, tooltip: "Device location." },
  });

  useEffect(() => {
    return () => {
      setSelectedDevice(null);
      setSelectedDeviceUid(null);
      setSelectedDeviceMId(null);
    };
  }, []);

  useEffect(() => {
    if (!selectedDeviceMId && !initialDeviceMId && !initialDevice && !selectedDeviceUid) {
      setSelectedDeviceUid(getLocalStorageItem("deviceInfo"));
    }
  }, []);

  useEffect(() => {
    if (initialDevice) {
      setSelectedDeviceUid(initialDevice.uid);
    }
  }, [initialDevice]);

  useEffect(() => {
    if (deviceDetails) {
      setSelectedDevice(deviceDetails);
      setLocalStorageItem("deviceInfo", deviceDetails.uid);
    }
  }, [deviceDetails]);

  useEffect(() => {
    if (deviceDetailsMId) {
      setSelectedDevice(deviceDetailsMId);
      setLocalStorageItem("deviceInfo", deviceDetailsMId.uid);
    }
  }, [deviceDetailsMId]);

  useEffect(() => {
    if (initialDeviceMId) {
      setSelectedDeviceUid(null);
      setSelectedDeviceMId(initialDeviceMId);
    }
  }, [initialDeviceMId]);

  const handleDeviceSelected = (device) => {
    setSelectedDeviceMId(null);
    setSelectedDeviceUid(device.uid);
  };

  const handleDeviceUpdateSuccess = (success) => {
    if (selectedDeviceUid) {
      refetch();
    } else {
      refetchMid();
    }
    setDeviceUpdateSuccess(success);
  };

  useEffect(() => {
    if (selectedDevice) {
      setStatus((prevStatus) => {
        // Create a shallow copy of prevStatus for simple objects
        const newStatus = { ...prevStatus };

        Object.keys(newStatus).forEach((key) => {
          if (!["add", "location"].includes(key)) {
            newStatus[key].disabled = true;
          }
        });

        const { tenant, status, lastAssignedOn } = selectedDevice;
        newStatus.location.disabled = !lastAssignedOn;

        if (status === "UnAllocated") {
          newStatus.delete.disabled = false;
          newStatus.edit.disabled = false;
          newStatus.assignTenant.disabled = false;
          setDeviceStatus("UnAllocated");
        } else if (status === "Allocated") {
          setDeviceStatus("Tenant Allocated");
        } else if (status === "Shipping") {
          setDeviceStatus("Shipping");
        } else if (status === "HospitalReceived") {
          newStatus.assignPatient.disabled = false;
          newStatus.deallocateTenant.disabled = false;
          setDeviceStatus("Hospital Received");
        } else if (status === "AssignedPatient") {
          newStatus.deallocatePatient.disabled = false;
          setDeviceStatus("Patient Allocated");
        }
        return newStatus;
      });
    }
  }, [selectedDevice]);

  const handleAdd = (device) => {
    navigate("/adddevice");
    setEditMode(false);
  };
  const handleEdit = () => {
    setEditableDevice({ ...selectedDevice }); // Make a copy of the device to edit
    setEditMode(true);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleAssignTenant = () => {
    setAssignTenantMode(true);
  };

  const enableAssignPatient = () => {
    setAssignPatientMode(true);
  };

  const enableDeallocatePatient = () => {
    setdeallocatePatientMode(true);
  };

  const enableDeallocateTenant = () => {
    setDeallocateTenantMode(true);
  };

  const handleLocation = () => {
    setOpenLocation(true);
  };

  const handleDeviceDeleted = () => {
    queryClient.removeQueries({
      queryKey: ["deviceDetails", selectedDevice.uid],
    });
    queryClient.removeQueries({
      queryKey: ["deviceDetailsMId", selectedDevice.deviceId],
    });
    removeLocalStorageItem("deviceInfo");
    setSelectedDevice(null);
    setSelectedDeviceUid(null);
    setSelectedDeviceMId(null);
  };

  const handleIconClick = (params) => {
    if (params.field === "Tenant") {
      const code = params.value;
      navigate("/tenantinfo", { state: { initialTenantCode: code } });
    } else if (params.field === "Patient") {
      const patientUid = params.value;
      navigate("/patientprofile", { state: { initialPatientUid: patientUid } });
    }
  };

  useEffect(() => {
    // Additionally, handle errors from React Query
    if (isError && error) {
      // Display a more specific error message if available, or a generic message
      toast.error(error.message || "An error occurred while fetching data.");
    }
    if (midIsError && midError) {
      // Display a more specific error message if available, or a generic message
      toast.error(
        midError.message || "An error occurred while fetching MacID data."
      );
    }
  }, [isError, error, midIsError, midError]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Devices" subtitle="Omnyk device details." />
        </Box>
        {location.pathname === homePath && (
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
        )}
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
          }}
        >
          <DeleteDevice
            selectedDevice={selectedDevice}
            openDeleteDialog={openDeleteDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            setIsLoading={setIsLoading}
            setSelectedDevice={setSelectedDevice}
            onDeviceDeleted={handleDeviceDeleted}
          />
          <Box my={2}>
            {!editMode && isSearchBarEnabled && (
              <DeviceSearch onDeviceSelected={handleDeviceSelected} />
            )}
          </Box>
          {selectedDevice && (
            <>
              <ProgressBarWithMilestones currentStatus={deviceStatus} />
              {assignTenantMode && (
                <AllocateTenant
                  selectedDevice={selectedDevice}
                  setAssignTenantMode={setAssignTenantMode}
                  setSearchTerm={setSearchTerm}
                  onDeviceUpdateSuccess={handleDeviceUpdateSuccess}
                />
              )}
              {assignPatientMode && (
                <AllocatePatient
                  selectedDevice={selectedDevice}
                  setAssignPatientMode={setAssignPatientMode}
                  setSearchTerm={setSearchTerm}
                  onDeviceUpdateSuccess={handleDeviceUpdateSuccess}
                  tenantId={selectedDevice.currentTenantId}
                />
              )}
              {deallocateTenantMode && (
                <DeallocateTenant
                  selectedDevice={selectedDevice}
                  setDeallocateTenantMode={setDeallocateTenantMode}
                  setSearchTerm={setSearchTerm}
                  onDeviceUpdateSuccess={handleDeviceUpdateSuccess}
                />
              )}
              {deallocatePatientMode && (
                <DeallocatePatient
                  selectedDevice={selectedDevice}
                  setdeallocatePatientMode={setdeallocatePatientMode}
                  setSearchTerm={setSearchTerm}
                  onDeviceUpdateSuccess={handleDeviceUpdateSuccess}
                />
              )}
              {openLocation && (
                <MapContainer
                  deviceId={selectedDevice.deviceId}
                  setOpenLocation={setOpenLocation}
                />
              )}

              <div>
                {!editMode && !openLocation && (
                  <>
                    <ActionToolbar
                      status={status}
                      onAdd={handleAdd}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      onAssignTenant={handleAssignTenant}
                      onLocation={handleLocation}
                      onAssignPatient={enableAssignPatient}
                      onDeallocateTenant={enableDeallocateTenant}
                      onDeallocatePatient={enableDeallocatePatient}
                    />

                    <Box mb={2}>
                      {(isLoading || midIsLoading) && <CenteredProgress />}
                      <DeviceDetails
                        data={selectedDevice}
                        handleIconClick={handleIconClick}
                      />
                    </Box>
                  </>
                )}
              </div>
              <div>
                {editMode && editableDevice && (
                  <EditDevice
                    editableDevice={editableDevice}
                    selectedDevice={selectedDevice}
                    setEditMode={setEditMode}
                    onDeviceUpdateSuccess={handleDeviceUpdateSuccess}
                  />
                )}
              </div>
            </>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default DeviceInfo;
