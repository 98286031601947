import {
  Typography,
  Box,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";

const VitalsDataCard = ({ title, value, unit, min, max, avg, lpcolor, sx }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Card
      sx={{ mb: 2, maxWidth: 345, backgroundColor: colors.primary[400], ...sx }}
    >
      <CardContent>
        <Typography color={colors.grey[100]} gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="h2">
          {value} {unit}
        </Typography>
        <LinearProgress
          value={((value - min) / (max - min)) * 100}
          sx={{
            flexGrow: 1,
            mx: 2,
            "& .MuiLinearProgress-bar": {
              backgroundColor: lpcolor,
            },
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Typography variant="body2" color={colors.pastelColor.green}>
            Min: {min} {unit}
          </Typography>
          <Typography
            variant="body2"
            color={colors.pastelColor.orange}
            sx={{ ml: 3 }}
          >
            Max: {max} {unit}
          </Typography>
        </Box>
        <Typography
          sx={{ mt: 2 }}
          variant="body2"
          color={colors.pastelColor.blue}
        >
          Avg: {avg} {unit}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VitalsDataCard;
