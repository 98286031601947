import { Typography, Box, Paper, LinearProgress } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import HotelIcon from "@mui/icons-material/Hotel";
import SunsetIcon from "@mui/icons-material/Brightness3"; // For representing sleep time
import SunriseIcon from "@mui/icons-material/WbSunny"; // For sunrise time
import BedtimeIcon from "@mui/icons-material/Bedtime";

const formatDuration = (seconds) =>
  `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}m`;

const calculateTimeDelta = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  return Math.abs(end - start) / (1000 * 60); // Delta in minutes
};

const SleepAnalysisDisplay = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const sunsetToSleepStartDelta = calculateTimeDelta(
    data.sunset,
    data.sleepStartTime
  );
  const sleepEndToSunriseDelta = calculateTimeDelta(
    data.sleepEndTime,
    data.sunrise
  );
  function SleepStagesPie() {
    const seriesData = [
      { id: "Wake", value: data.wakeStage, label: "Wake" },
      { id: "Light Sleep", value: data.lightSleep, label: "Light Sleep" },
      { id: "Deep Sleep", value: data.deepSleep, label: "Deep Sleep" },
      { id: "REM Sleep", value: data.remSleep, label: "REM Sleep" },
    ];

    return (
      <PieChart
        series={[
          {
            data: seriesData,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: colors.primary[200],
            },
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -90,
            endAngle: 210,
          },
        ]}
        width={400}
        height={300}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "middle" },
            padding: 10,
            itemMarkHeight: 5,
          },
        }}
      ></PieChart>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{ p: 3, backgroundColor: colors.primary[400], my: 2 }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
      >
        <HotelIcon color="brown" />
        Sleep Analysis
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        my={2}
      >
        <Typography>
          Total Sleep: {formatDuration(data.sleepDuration)}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={(data.sleepQuality / 100) * 100}
          sx={{
            width: "100%",
            mx: 2,
            height: 10,
            borderRadius: 5,
            "& .MuiLinearProgress-bar": {
              backgroundColor: colors.pastelColor.blue,
            },
          }}
        />
        <Typography>Sleep Quality: {data.sleepQuality}%</Typography>
      </Box>

      <Box display="flex" justifyContent="space-around" my={2}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SunsetIcon sx={{ color: colors.pastelColor.pink }} />
          <Typography variant="caption">
            Sunset: {new Date(data.sunset).toLocaleTimeString()}
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            {formatDuration(sunsetToSleepStartDelta * 60)} until sleep starts
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <BedtimeIcon sx={{ color: colors.pastelColor.brown }} />
          <Typography variant="caption">
            Sleep Start: {new Date(data.sleepStartTime).toLocaleTimeString()}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SunriseIcon color="warning" />
          <Typography variant="caption">
            Sunrise: {new Date(data.sunrise).toLocaleTimeString()}
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            {formatDuration(sleepEndToSunriseDelta * 60)} after sleep ends
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SunriseIcon />
          <Typography variant="caption">
            Sleep End: {new Date(data.sleepEndTime).toLocaleTimeString()}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="left" mt={5}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <BedtimeIcon color="brown" />
          Sleep Stages
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SleepStagesPie />
        </Box>
      </Box>
    </Paper>
  );
};

export default SleepAnalysisDisplay;
