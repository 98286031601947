import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import Typography from "@mui/material/Typography";
import emailVerificationAnimation from "./emailSent.json";
import successAnimation from "./success.json";
import exclamationAnimation from "./exclamation.json";
import failureAnimation from "./fail.json";
import hrAnimation from "./hb.json";

export const EmailVerificationAnimation = ({ style }) => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Lottie
        animationData={emailVerificationAnimation}
        style={{ width: "100%", height: "auto" }}
      />
      <Typography variant="h6" color="primary">
        Email Sent!
      </Typography>
    </div>
  );
};

export const SuccessAnimation = ({ style }) => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Lottie
        animationData={successAnimation}
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export const ExclamationAnimation = ({ style }) => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Lottie
        animationData={exclamationAnimation}
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export const FailureAnimation = ({ style }) => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Lottie
        animationData={failureAnimation}
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export const HRAnimation = ({ style, shouldPause }) => {
  const lottieRef = useRef(null);

  useEffect(() => {
    if (shouldPause) {
      lottieRef.current.pause();
    } else {
      lottieRef.current.play();
    }
  }, [shouldPause]);

  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Lottie
        animationData={hrAnimation}
        lottieRef={lottieRef}
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};
