import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { tokens } from "../../theme";
import { UpdateOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { post, API_URL } from "../../services/api";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const DynamicField = ({ control, name, label }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <TextField
        {...field}
        fullWidth
        label={label}
        variant="outlined"
        margin="normal"
      />
    )}
  />
);

const DynamicEdit = ({ data, setEditMode, setIsUpdated }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { control, handleSubmit } = useForm({
    defaultValues: data,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (formData) => {
    const changedValues = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== data[key]) {
        acc[key] = formData[key]; // Only add to acc if the value has changed
      }
      return acc;
    }, {});
    if (Object.keys(changedValues).length === 0) {
      toast.info("No changes made to app data");
      return;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };
    setIsLoading(true);
    try {
      const response = await post(
        `${API_URL}/v2/AppSettings/Update`,
        payload,
        true
      );
      if (response.statusCode === "OK") {
        toast.success("App Settings updated successfully!");
        setIsUpdated(true);
        setEditMode(false);
      } else if (response.statusCode === "InternalServerError") {
        // Construct an error message string if there are validation errors
        let errorMessage = "Error updating App Settings";
        if (response.validationErrors && response.validationErrors.length > 0) {
          // Map each validation error to a readable string, then join them
          const validationMessages = response.validationErrors
            .map((error) => `${error.property}: ${error.error}`)
            .join(", ");
          errorMessage += `. Validation Errors: ${validationMessages}`;
        }

        toast.error(errorMessage);
      } else {
        toast.error("Error updating App Settings");
      }
    } catch (error) {
      if (
        error.response.data.validationErrors &&
        error.response.data.validationErrors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.validationErrors
          .map((err) => `${err.property}: ${err.error}`)
          .join(" ");
        toast.error(validationErrorMessages);
      } else if (error.response.data.errors) {
        toast.error("A required field has validation errors")

      }else {
        toast.error("Error updating App settings.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderFormFields = (data, parentKey = "") => {
    return Object.entries(data).map(([key, value]) => {
      const fieldName = parentKey ? `${parentKey}.${key}` : key;
      const label =
        key.charAt(0).toUpperCase() +
        key
          .slice(1)
          .replace(/([A-Z])/g, " $1")
          .trim();

      if (typeof value === "object" && value !== null) {
        return (
          <div key={fieldName}>
            <Typography variant="h6" gutterBottom>
              {label}
            </Typography>
            {renderFormFields(value, fieldName)}
          </div>
        );
      }

      return (
        <DynamicField
          key={fieldName}
          control={control}
          name={fieldName}
          label={label}
        />
      );
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        variant="outlined"
        sx={{ mb: 2, backgroundColor: colors.primary[400] }}
      >
        <CardContent>
          {renderFormFields(data)}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              disabled={isLoading}
              type="submit"
            >
              <UpdateOutlined sx={{ mr: "10px" }} />
              Update
            </Button>
            <Button
              onClick={() => {
                setEditMode(false);
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </CardContent>
      </Card>
    </form>
  );
};

export default DynamicEdit;
