import React from "react";
import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import {
  HighlightOnHoverCell,
  AvatarCell,
  DgWrapper,
  getSlotProps,
} from "../common/customDataGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FmdBadIcon from "@mui/icons-material/FmdBadOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";

const CareTakerList = ({
  isLoading,
  patientRequests,
  selectionModel,
  setSelectionModel,
  handleCellClick,
  authorizeOveride,
  statusOveride,
  deleteRPM,
  selfService,
  isAdmin,
  reverseStopMonitoring,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const slotProps = getSlotProps(theme);
  const columns = [
    {
      field: "avatar",
      headerName: "",
      width: 80,
      filterable: false,
      renderCell: (params) => (
        <AvatarCell
          row={params.row}
          animation={params.row.isCurrentlyMonitoring}
          name={params.row.careTakerName}
        />
      ),
    },
    {
      field: "careTakerName",
      headerName: "Care Taker",
      width: 150,
      cellClassName: "fn-column--cell",
      filterable: false,
      renderCell: (params) => (
        <HighlightOnHoverCell value={params.value} customCN="fn-column--cell" />
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      cellClassName: "email-column--cell",
      filterable: false,
    },
    {
      field: "contactNumber",
      headerName: "Contact",
      width: 150,
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      filterable: false,
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "isCurrentlyMonitoring",
      headerName: "Monitoring",
      width: 150,
      type: "boolean",
      filterable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      type: "actions",
      getActions: (params) => {
        const actions = [];
        if (isAdmin || selfService) {
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Authorise Override" placement="top" arrow>
                  <EditIcon
                    sx={{
                      color:
                        params.row.status === "Pending" ||
                        params.row.status === "Rejected"
                          ? colors.pastelColor.orange
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="Authorise Override"
              onClick={() => authorizeOveride(params.id)}
              disabled={
                !(
                  params.row.status === "Pending" ||
                  params.row.status === "Rejected"
                )
              }
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Status Override" placement="top" arrow>
                  <FmdBadIcon
                    sx={{
                      color:
                        params.row.status !== "Disabled"
                          ? colors.pastelColor.red
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="Status Override"
              onClick={() => statusOveride(params.id)}
              disabled={params.row.status === "Disabled"}
            />
          );
        }
        if (selfService) {
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Force Stop Monitoring" placement="top" arrow>
                  <HighlightOffIcon
                    sx={{
                      color:
                        params.row.isCurrentlyMonitoring &&
                        params.row.status === "Authorised"
                          ? colors.pastelColor.pink
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="Stop"
              onClick={() =>
                reverseStopMonitoring(params.row.email, "Asia/Kolkata", true)
              }
              disabled={
                !params.row.isCurrentlyMonitoring ||
                params.row.status !== "Authorised"
              }
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete Request" placement="top" arrow>
                  <DeleteIcon
                    sx={{
                      color: colors.pastelColor.red,
                    }}
                  />
                </Tooltip>
              }
              label="Delete Request"
              onClick={() => deleteRPM(params.row.email)}
              disabled={!selfService}
            />
          );
        }
        return actions;
      },
    },
  ];

  const hiddenColumns = [];
  const initialVisibilityModel = hiddenColumns.reduce((model, columnName) => {
    model[columnName] = false;
    return model;
  }, {});

  const rows = patientRequests.map((item) => {
    return {
      id: item.uid,
      ...item,
    };
  });
  const initialSortModel = [{ field: "status", sort: "asc" }];

  return (
    <DgWrapper>
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelection) =>
          setSelectionModel(newSelection)
        }
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectionModel(newRowSelectionModel);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: initialVisibilityModel,
          },
          sorting: {
            sortModel: initialSortModel,
          },
        }}
        rowSelectionModel={selectionModel}
        rowCount={rows.length}
        checkboxSelection
        filterDebounceMs={300}
        onCellClick={handleCellClick}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={slotProps}
      />
    </DgWrapper>
  );
};

export default CareTakerList;
