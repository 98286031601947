import React, { useState, useEffect } from "react";
import { Box, Container, IconButton } from "@mui/material";
import AddDevice from "../../components/device/addDevice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const AddDeviceForm = () => {
  const [newDeviceId, setNewDeviceId] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (newDeviceId) {
      toast.success("Added new device.");
      navigate("/deviceinfo", { state: { initialDeviceId: newDeviceId } });
    }
  }, [newDeviceId, navigate]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Devices" subtitle="Add new device." />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="5px"
        >
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
        </Box>
        <Container
          maxWidth="md"
          sx={{
            backgroundColor: colors.primary[400],
            border: `1px solid ${colors.greenAccent[400]}`,
            padding: "15px",
          }}
        >
          <AddDevice setNewDeviceId={setNewDeviceId} />
        </Container>
      </Box>
    </Box>
  );
};

export default AddDeviceForm;
