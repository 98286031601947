import { useCallback, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { get, post, API_URL } from "../../services/api";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../AuthProvider";

const API_BASE_URL = `${API_URL}/v2/FastballUser`;

export const useFetchSuggestedUsers = (searchTerm = "") => {
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchSuggestedUsersFull = async ({ queryKey }) => {
    const [_key, term] = queryKey;
    if (!term.trim()) return []; // Avoid fetching with empty or whitespace-only query
    const encodedTerm = encodeURIComponent(term);
    try {
      const response = await get(
        `${API_BASE_URL}/list?Page=1&PerPage=20&filter=${encodedTerm}`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data.items || [];
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching suggested patients"
        );
      }
    } catch (error) {
      // Log the error or handle it as needed
      throw error; // Rethrowing the error so React Query can catch it
    }
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["suggestedUsers", debouncedTerm],
    queryFn: fetchSuggestedUsersFull,
    enabled: !!debouncedTerm,
  });

  return { data, isLoading, isError, error };
};

export const useFetchSuggestedUsersQuick = (searchTerm = "") => {
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchSuggestedUsers = async ({ queryKey }) => {
    const [_key, term] = queryKey;
    if (!term.trim()) return []; // Avoid fetching with empty or whitespace-only query
    const encodedTerm = encodeURIComponent(term);
    try {
      const response = await get(
        `${API_BASE_URL}/AutoComplete?contains=${encodedTerm}`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data || [];
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching suggested users"
        );
      }
    } catch (error) {
      // Log the error or handle it as needed
      throw error; // Rethrowing the error so React Query can catch it
    }
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["suggestedUsers", debouncedTerm],
    queryFn: fetchSuggestedUsers,
    enabled: !!debouncedTerm,
  });

  return { data, isLoading, isError, error };
};

export const useFetchUserDetails = (uid, initialData) => {
  return useQuery({
    queryKey: ["userDetails", uid],
    queryFn: async () => {
      if (!uid) return; // Ensure uid is present
      const response = await get(`${API_BASE_URL}/GetByUid?id=${uid}`, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching device details"
        );
      }
    },
    enabled: !!uid && !initialData, // Only run the query if the uid is truthy
    initialData: initialData,
  });
};

export const useFetchUserDetailsQuick = (userid, initialData) => {
  return useQuery({
    queryKey: ["userDetails", userid],
    queryFn: async () => {
      if (!userid) return; // Ensure uid is present
      const response = await get(`${API_BASE_URL}/AutoComplete?userId=${userid}`, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || "Error fetching user details"
        );
      }
    },
    enabled: !!userid && !initialData, // Only run the query if the uid is truthy
    initialData: initialData,
  });
};

export const useFetchUserByEmail = (email, initialData) => {
  const fetchUser = async () => {
    if (!email) return; // Do not run if email is not provided
    const encodedFilter = encodeURIComponent(email);
    const response = await get(
      `${API_BASE_URL}/list?filter=${encodedFilter}`,
      true
    );
    if (response.statusCode === "OK") {
      if (response.data.totalCount === 1) {
        return response.data.items[0];
      } else {
        if (response.data.totalCount === 0) {
          throw new Error(
            "User does not exist. Please check the entered email."
          );
        } else {
          throw new Error("Multiple users found.");
        }
      }
    } else {
      throw new Error(response.errors?.[0] || "Error fetching user details");
    }
  };

  return useQuery({
    queryKey: ["userByEmail", email],
    queryFn: fetchUser,
    enabled: !!email && !initialData,
    initialData: initialData,
  });
};

export const useFetchUserList = (
  page,
  PerPage,
  filtersArray = [],
  shouldFetch = true
) => {
  // Create a query key that includes all relevant parameters
  const queryKey = ["userList", page, PerPage, filtersArray];

  // Define the query function
  const queryFn = async () => {
    // Start with page and PerPage parameters
    let queryStringParts = [
      `page=${encodeURIComponent(page)}`,
      `PerPage=${encodeURIComponent(PerPage)}`,
    ];

    // Process filtersArray to handle both single values and arrays
    filtersArray.forEach(({ type, value }) => {
      if (Array.isArray(value)) {
        // For arrays, add each value under the same key
        value.forEach((val) => {
          queryStringParts.push(
            `${encodeURIComponent(type)}=${encodeURIComponent(val)}`
          );
        });
      } else if (value) {
        // For single values, add them directly
        queryStringParts.push(
          `${encodeURIComponent(type)}=${encodeURIComponent(value)}`
        );
      }
    });

    // Join all parts of the query string
    const queryString = queryStringParts.join("&");

    const response = await get(`${API_BASE_URL}/list?${queryString}`, true);
    if (response.statusCode !== "OK") {
      throw new Error("Error fetching patient list");
    }
    return response.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    enabled: shouldFetch,
    // Add any additional options you need, like staleTime or cacheTime
  });
};

export const useFetchUserInfo = (
  setResponseMessage,
  setIsSuccess,
  setIsLoading,
  setSelectedUser,
  setPagination,
  setUserRequests
) => {
  const { updateUserDetails } = useAuth();

  const handleResponse = (response, successMessage) => {
    if (response.statusCode === "OK") {
      setIsSuccess(true);
      setResponseMessage(successMessage);
      return response.data;
    } else {
      throw new Error(
        response.errors?.[0] || "An error occurred while fetching data."
      );
    }
  };

  const handleError = (error) => {
    setIsSuccess(false);
    setResponseMessage(
      error?.response?.data?.errors?.[0] ||
        error.message ||
        "Error occurred during the API call."
    );
  };

  const deleteUser = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        const requestId = uuidv4();
        const response = await post(
          `${API_BASE_URL}/ObfuscateUser`,
          { requestId, data: id },
          true
        );
        return handleResponse(response, "User deleted successfully.");
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setIsSuccess, setResponseMessage]
  );

  const fetchUserDetails = useCallback(
    async (uid = null, currentUser = false) => {
      setIsLoading(true);
      try {
        const url = currentUser
          ? `${API_BASE_URL}/CurrentUserDetails`
          : `${API_BASE_URL}/GetByUid?id=${uid}`;
        const response = await get(url, true);
        if (currentUser) {
          const newDetails = response.data;
          updateUserDetails(newDetails);
        }
        return handleResponse(response, "User details fetched successfully.");
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setIsSuccess, setResponseMessage, updateUserDetails]
  );

  const fetchUserList = useCallback(
    async (page, PerPage, filtersArray = []) => {
      setIsLoading(true);
      const filtersObject = filtersArray.reduce((obj, filter) => {
        if (filter.value) {
          obj[filter.type] = filter.value;
        }
        return obj;
      }, {});
      const params = { page, PerPage, ...filtersObject };
      const queryString = Object.entries(params)
        .filter(([, value]) => value != null) // Exclude null or undefined values
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      try {
        const response = await get(`${API_BASE_URL}/list?${queryString}`, true);
        setUserRequests(response.data.items);
        setPagination({
          totalPages: response.data.totalPages,
          totalCount: response.data.totalCount,
          recordsPerPage: response.data.recordsPerPage,
        });
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, setIsLoading, setUserRequests, setPagination]
  );

  const fetchUserByEmail = useCallback(
    async (email) => {
      setIsLoading(true);
      try {
        const encodedFilter = encodeURIComponent(email);
        const response = await get(
          `${API_BASE_URL}/list?filter=${encodedFilter}`,
          true
        );
        const data = handleResponse(
          response,
          "User details fetched successfully."
        );
        if (data.totalCount === 1) {
          setSelectedUser(data.items[0]);
          return data.items[0];
        } else {
          if (data.totalCount === 0) {
            throw new Error(
              "User does not exist. Please check the entered email."
            );
          } else {
            throw new Error("Multiple users found.");
          }
        }
      } catch (error) {
        handleError(error);
        setSelectedUser(null);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setIsSuccess, setResponseMessage, setSelectedUser]
  );

  return {
    fetchUserDetails,
    fetchUserByEmail,
    fetchUserList,
    deleteUser,
  };
};
