import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  FormHelperText,
  Grid,
  Typography,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  TextField,
  InputLabel,
  Button,
  Radio,
  RadioGroup,
  IconButton,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { MuiPhone } from "./MuiPhone";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import {
  capitalizeWords,
  validateName,
  validateAndNormalizeEmail,
  isPhoneValid,
} from "./utilities";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import CodeOffIcon from "@mui/icons-material/CodeOffOutlined";
import MaleIcon from "@mui/icons-material/MaleOutlined";
import FemaleIcon from "@mui/icons-material/FemaleOutlined";
import TransgenderIcon from "@mui/icons-material/TransgenderOutlined";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO } from "date-fns";

const PatientForm = ({ onFormSubmit }) => {
  const methods = useForm();
  const {
    register,
    control,
    errors,
    setValue,
    setError,
    clearErrors,
  } = methods;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const primaryMainColor = theme.palette.background.default;
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  const [userInteracted, setUserInteracted] = useState(false);
  const [userInteractedEP, setUserInteractedEP] = useState(false);
  const settings = [
    { label: "RPM", name: "isRpmEnabled" },
    { label: "FW Upgrade", name: "firmwareUpgrade" },
    { label: "Auto FW Upgrade", name: "autoFirmwareUpgrade" },
    { label: "Maintenance", name: "maintenance" },
  ];

  const handlePhoneChange = (value, onChange, component) => {
    onChange(value); // Use the passed onChange directly

    const isValid = isPhoneValid(value);
    if (
      !isValid &&
      ((component === "mobileNumber" && userInteracted) ||
        (component === "emergencyContactNo" && userInteractedEP))
    ) {
      setError(component, {
        type: "manual",
        message: "Invalid phone number",
      });
    } else {
      clearErrors(component);
    }
  };

  return (
    <FormProvider {...methods}>
      <form id="editPatientsForm" onSubmit={methods.handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="mobileNumber"
              rules={{
                validate: {
                  isValidPhone: (value) =>
                    !userInteracted ||
                    isPhoneValid(value) ||
                    "Phone is not valid",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <MuiPhone
                  {...field}
                  label="Mobile Number"
                  onChange={(e) =>
                    handlePhoneChange(
                      e.target.value,
                      field.onChange,
                      "mobileNumber"
                    )
                  }
                  error={!!error}
                  helperText={error ? error.message : null}
                  onBlur={() => setUserInteracted(true)} // Set to true on blur
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          setUserInteracted(false);
                          setValue("mobileNumber", "");
                        }}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="dob"
                control={control}
                rules={{
                  validate: {
                    isAtLeast18: (value) => {
                      // If there's no value, consider it valid (making the field optional)
                      if (!value) return true;

                      // If there's a value, validate it
                      const date = parseISO(value);
                      const eighteenYearsAgo = new Date();
                      eighteenYearsAgo.setFullYear(
                        eighteenYearsAgo.getFullYear() - 18
                      );

                      return (
                        date <= eighteenYearsAgo ||
                        `Patient must be born on or before ${format(
                          eighteenYearsAgo,
                          "yyyy-MM-dd"
                        )}`
                      );
                    },
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="Date of Birth"
                    value={value ? parseISO(value) : null}
                    onChange={(newValue) => {
                      // Only call onChange with formatted date if newValue is not null
                      if (newValue !== null) {
                        onChange(format(newValue, "yyyy-MM-dd"));
                      } else {
                        onChange("");
                      }
                    }}
                    slotProps={{
                      actionBar: { actions: ["clear", "accept"] },
                      popper: {
                        sx: {
                          "& .MuiDialogActions-root .MuiButton-text": {
                            color: "white",
                            backgroundColor: colors.greenAccent[700],
                          },
                        },
                      },
                    }}
                    maxDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 18)
                      )
                    }
                    openTo="year"
                    views={["year", "month", "day"]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!error}
                        helperText={error ? error.message : null}
                        readOnly // Make sure to adjust based on your requirements
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          {/* Emergency Contact */}
          <Grid item xs={6}>
            <TextField
              {...register("emergencyContactName", {
                validate: (value) => {
                  // Only validate if there's a value
                  return !value || validateName(value) ? true : "Invalid name"; // Adjust the error message as needed
                },
              })}
              label="Emergency Contact Name"
              fullWidth
              variant="outlined"
              error={!!errors?.emergencyContactName}
              helperText={errors?.emergencyContactName?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ConnectWithoutContactIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              onBlur={(e) => {
                const capitalizedValue = capitalizeWords(e.target.value);
                setValue("emergencyContactName", capitalizedValue, {
                  shouldValidate: true,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="emergencyContactNo"
              rules={{
                validate: {
                  isValidPhone: (value) =>
                    !userInteractedEP ||
                    isPhoneValid(value) ||
                    "Emergency Contact Phone is not valid",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <MuiPhone
                  {...field}
                  label="Emergency Contact"
                  onChange={(e) =>
                    handlePhoneChange(
                      e.target.value,
                      field.onChange,
                      "emergencyContactNo"
                    )
                  }
                  error={!!error}
                  helperText={error ? error.message : null}
                  onBlur={() => setUserInteractedEP(true)} // Set to true on blur
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          setUserInteractedEP(false);
                          setValue("emergencyContactNo", "");
                        }}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("emergencyEmail", {
                validate: {
                  validEmail: (value) => {
                    // If there's no value, consider it valid (making the field optional)
                    if (!value) return true;

                    // If there's a value, validate it
                    const { isValid, errorMessage } =
                      validateAndNormalizeEmail(value);
                    return isValid || errorMessage;
                  },
                },
              })}
              label="Emergency Email"
              type="email"
              fullWidth
              variant="outlined"
              error={!!errors?.emergencyEmail}
              helperText={errors?.emergencyEmail?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              onBlur={(e) => {
                const { isValid, normalizedEmail } = validateAndNormalizeEmail(
                  e.target.value
                );
                if (isValid) {
                  setValue("emergencyEmail", normalizedEmail, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </Grid>
          {/* Other Details */}

          <Grid item xs={6}>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors?.gender}
                >
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    {...field}
                    labelId="gender-label"
                    label="Gender"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: primaryMainColor,
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Male">
                      <MaleIcon sx={{ mr: 1, color: colors.other.blue }} />
                      Male
                    </MenuItem>
                    <MenuItem value="Female">
                      <FemaleIcon sx={{ mr: 1, color: colors.other.blue }} />
                      Female
                    </MenuItem>
                    <MenuItem value="Others">
                      <TransgenderIcon
                        sx={{ mr: 1, color: colors.other.blue }}
                      />
                      Others
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.gender ? errors.gender?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid container spacing={1} justifyContent="left" ml={1.2}>
            {settings.map((setting, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Typography
                  variant="subtitle1"
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  {setting.label}
                </Typography>

                <FormControl component="fieldset" fullWidth>
                  <Controller
                    name={setting.name}
                    control={control}
                    defaultValue="no change"
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        sx={{ justifyContent: "left", display: "flex" }}
                      >
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio
                              sx={{
                                color: colors.greenAccent[200],
                                "&.Mui-checked": {
                                  color: colors.greenAccent[400],
                                },
                              }}
                            />
                          }
                          label="True"
                          sx={{ marginRight: 2 }} // Adjust spacing as needed
                        />
                        <FormControlLabel
                          value="false"
                          control={
                            <Radio
                              sx={{
                                color: colors.greenAccent[200],
                                "&.Mui-checked": {
                                  color: colors.greenAccent[400],
                                },
                              }}
                            />
                          }
                          label="False"
                          sx={{ marginRight: 2 }} // Adjust spacing as needed
                        />
                        <FormControlLabel
                          value="no change"
                          control={
                            <Radio
                              sx={{
                                color: colors.greenAccent[200],
                                "&.Mui-checked": {
                                  color: colors.greenAccent[400],
                                },
                              }}
                            />
                          }
                          label="No Change"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register("firmwareVersion", {
                required: false,
                pattern: /^(?:\d+\.)?(?:\d+\.)?\d+$/,
              })}
              label="Firmware Version"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CodeOffIcon sx={{ color: colors.other.blue }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              variant="outlined"
            />
            {errors?.firmwareVersion && (
              <span>Firmware Version is invalid</span>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Button
              form="editPatientsForm" // Ensure this matches your form's id
              type="submit" // Make the button submit the form
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "12px",
                fontWeight: "bold",
                padding: "10px 20px",
                margin: "8px",
              }}
            >
              Set Data
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default PatientForm;
