import React, { useRef, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Button, Box, Stack, Typography } from "@mui/material";
import ImageUrlDialog from "./ImageURL";
import StarterKit from "@tiptap/starter-kit";
import { Underline } from "@tiptap/extension-underline";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import {
  MenuButton,
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuButtonStrikethrough,
  MenuButtonUnderline,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonEditLink,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonUndo,
  MenuSelectTextAlign,
  MenuButtonHorizontalRule,
  MenuButtonAddTable,
  MenuButtonTaskList,
  MenuButtonAddImage,
  LinkBubbleMenuHandler,
  ResizableImage,
  TableImproved,
  RichTextEditor,
} from "mui-tiptap";
import { TextFields, Lock, LockOpen } from "@mui/icons-material";
import { LinkBubbleMenu, TableBubbleMenu } from "mui-tiptap";

function RTE({ name, setIsContentSaved, content = "" }) {
  const { setValue, getValues } = useFormContext();
  const rteRef = useRef(null);
  const [isEditable, setIsEditable] = useState(true);
  const [showMenuBar, setShowMenuBar] = useState(true);
  const isPreviousChangeRef = useRef(true);
  const [editorReady, setEditorReady] = useState(false);
  const [isLocalSaved, setIsLocalSaved] = useState(true);
  const [localCount, setLocalCount] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAddImage = (url) => {
    rteRef.current?.editor?.chain().focus().setImage({ src: url }).run();
  };

  function isContentEffectivelyEmpty(html) {
    // Remove HTML tags and check if there's any text left
    const textContent = html.replace(/<[^>]*>/g, "").trim();
    return textContent === "" || textContent === "&nbsp;";
  }

  const saveContent = () => {
    if (rteRef.current && rteRef.current.editor) {
      const htmlContent = rteRef.current.editor.getHTML();
      const count = htmlContent.length;
      if (htmlContent.trim() && !isContentEffectivelyEmpty(htmlContent)) {
        setValue(name, htmlContent, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setIsContentSaved(true);
        isPreviousChangeRef.current = true;
        setIsLocalSaved(true);
        setLocalCount(count);
      } else {
        console.log("No content to save");
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const editor = rteRef.current?.editor;
      if (editor) {
        clearInterval(interval);
        setEditorReady(true);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (editorReady) {
      const editor = rteRef.current?.editor;
      const unsubscribe = editor.on("update", () => {
        const currentHtml = editor.getHTML();
        const savedHtml = getValues(name);
        // Use ref's current value to check if logging should happen
        if (currentHtml !== savedHtml && isPreviousChangeRef.current) {
          setIsContentSaved(false);
          isPreviousChangeRef.current = false; // Set the ref to false directly
          setIsLocalSaved(false);
        }
      });

      return () => {
        if (typeof unsubscribe === "function") {
          unsubscribe();
        }
      };
    }
  }, [editorReady, getValues, name]);

  const CustomLinkExtension = Link.extend({
    inclusive: false,
  });

  return (
    <Box alignItems="center" sx={{backgroundcolor: "transparent"}}>
      <RichTextEditor
        ref={rteRef}
        content={content}
        extensions={[
          StarterKit,
          TableCell,
          TableHeader,
          TableRow,
          Underline,
          Subscript,
          Superscript,
          TaskList,
          TaskItem,
          Image,
          ResizableImage,
          Dropcursor,
          TextAlign.configure({
            types: ["heading", "paragraph", "image"],
          }),
          CustomLinkExtension.configure({
            autolink: true,
            linkOnPaste: true,
            openOnClick: false,
          }),
          TableImproved.configure({
            resizable: true,
          }),
          LinkBubbleMenuHandler,
        ]}
        renderControls={() => (
          <MenuControlsContainer>
            <MenuSelectHeading />
            <MenuDivider />
            <MenuButtonRedo />
            <MenuButtonUndo />
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuButtonUnderline />
            <MenuButtonStrikethrough />
            <MenuDivider />
            <MenuSelectTextAlign />
            <MenuDivider />
            <MenuButtonEditLink />
            <MenuButtonSuperscript />
            <MenuButtonSubscript />
            <MenuDivider />
            <MenuButtonAddImage onClick={handleOpenDialog} />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
            <MenuButtonTaskList />
            <MenuButtonAddTable />
            <MenuButtonHorizontalRule />
          </MenuControlsContainer>
        )}
        RichTextFieldProps={{
          variant: "outlined",
          MenuBarProps: {
            hide: !showMenuBar,
          },
          footer: (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: (theme) => theme.palette.divider,
                py: 1,
                px: 1.5,
              }}
            >
              <MenuButton
                value="formatting"
                tooltipLabel={
                  showMenuBar ? "Hide formatting" : "Show formatting"
                }
                size="small"
                onClick={() => setShowMenuBar((currentState) => !currentState)}
                selected={showMenuBar}
                IconComponent={TextFields}
              />

              <MenuButton
                value="formatting"
                tooltipLabel={
                  isEditable
                    ? "Prevent edits (use read-only mode)"
                    : "Allow edits"
                }
                size="small"
                onClick={() => setIsEditable((currentState) => !currentState)}
                selected={!isEditable}
                IconComponent={isEditable ? Lock : LockOpen}
              />

              <Button
                variant="contained"
                size="small"
                disabled={isLocalSaved}
                onClick={saveContent}
              >
                Save
              </Button>
              {localCount && (
                <Typography style={{ fontSize: "12px" }} color="primary">
                  Count: {localCount}
                </Typography>
              )}
            </Stack>
          ),
        }}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            <TableBubbleMenu />
          </>
        )}
      </RichTextEditor>
      <ImageUrlDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleAddImage}
      />
    </Box>
  );
}

export default RTE;
