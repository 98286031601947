import React, { useState } from "react";
import ListHandler from "../../components/common/ListHandler";
import config from "../../components/common/filterSettings.json";
import { useFetchList } from "../../components/common/useFetchListInfo";
import BulkUpdateDialog from "../../components/patients/BulkUpdateDialog";
import ReportTable from "../../components/patients/reportTable";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportList() {
  const { headers, defaultFilters, dgFilterTypes, genericFilterTypes } =
    config.dailyReports;
  const {
    headers: customHeaders,
    defaultFilters: customDefaultFilters,
    dgFilterTypes: customDgFilterTypes,
    genericFilterTypes: customGenericFilterTypes,
  } = config.customReports;
  const [value, setValue] = useState(
    parseInt(localStorage.getItem("reportsCurrentTab") || "0", 10)
  );
  const extended = { queryChoice: "daily" };
  const extendedCustom = { queryChoice: "custom" };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("reportsCurrentTab", newValue.toString());
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ m: 5, borderBottom: 1, borderColor: colors.pastelColor.blue }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Reports"
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.pastelColor.blue,
            },
          }}
        >
          <Tab
            label="Daily Reports"
            {...a11yProps(0)}
            sx={{
              color: colors.primary,
              fontSize: "1.0rem",
              "&.Mui-selected": {
                color: colors.greenAccent[400],
              },
              "&:hover": {
                color: colors.pastelColor.teal,
              },
            }}
          />
          <Tab
            label="Custom Reports"
            {...a11yProps(1)}
            sx={{
              color: colors.primary,
              fontSize: "1.0rem",
              "&.Mui-selected": {
                color: colors.greenAccent[400],
              },
              "&:hover": {
                color: colors.pastelColor.teal,
              },
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {config && headers && (
          <ListHandler
            headers={headers}
            defaultFilters={defaultFilters}
            dgFilterTypes={dgFilterTypes}
            genericFilterTypes={genericFilterTypes}
            useFetchList={useFetchList}
            ItemTable={ReportTable}
            BulkUpdateDialog={BulkUpdateDialog}
            extended={extended}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {config && customHeaders && (
          <>
            <ListHandler
              headers={customHeaders}
              defaultFilters={customDefaultFilters}
              dgFilterTypes={customDgFilterTypes}
              genericFilterTypes={customGenericFilterTypes}
              useFetchList={useFetchList}
              ItemTable={ReportTable}
              BulkUpdateDialog={BulkUpdateDialog}
              extended={extendedCustom}
            />
          </>
        )}
      </TabPanel>
    </Box>
  );
}
