import {
  useGetPatientRPMList,
  useGetCareTakerList,
} from "../../components/patients/useFetchPatientInfo";
import { post, API_URL, deleteRequest } from "../../services/api";
import PatientRPMList from "../../components/patients/patientRPMList";
import CareTakerList from "../../components/patients/careTakerList";
import AddPatientRPM from "../../components/patients/addPatientRPM";
import RpmStatusOveride from "../../components/patients/statusOveride";
import CenteredProgress from "../global/CenteredProgress";
import RPMLive from "./RPMView.js";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgentOutlined";
import AssistWalkerIcon from "@mui/icons-material/AssistWalkerOutlined";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Tooltip,
  Container,
  IconButton,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useAuth } from "../../AuthProvider";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../components/auth/storage";

const PatientRPM = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isAuthenticated, userDetails } = useAuth();
  const primaryMainColor = theme.palette.background.default;
  const location = useLocation();
  const navigate = useNavigate();
  const initialProfileFromLocation = location.state?.initialProfile;
  const [initialProfile, setInitialProfile] = useState(
    props.initialProfile || initialProfileFromLocation
  );
  const currentUserEmail = userDetails.email.toLowerCase();
  const emailVerified = initialProfile?.isEmailVerified;
  const phoneVerified = initialProfile?.isPhoneNoVerified;
  const [initialEmail, setInitialEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [ctSelectionModel, setCTSelectionModel] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [patientRequests, setPatientRequests] = useState([]);
  const [careTakerRequests, setCareTakerRequests] = useState([]);
  const [openAddRPM, setOpenAddRPM] = useState(false);
  const [openStatusOveride, setOpenStatusOveride] = useState(false);
  const [rpmUid, setRpmUid] = useState("");
  const [selfService, setSelfService] = useState(
    initialProfile &&
      initialProfile.headerEntity.email.toLowerCase() === currentUserEmail
  );
  const [rpmViews, setRpmViews] = useState(() => {
    // Only attempt to load rpmViews from local storage if selfService is true
    if (selfService) {
      const savedRpmViews = getLocalStorageItem("rpmViews");
      return savedRpmViews ? savedRpmViews : [];
    }
    return []; // Return an empty array if selfService is not true
  });
  const [highlightedIds, setHighlightedIds] = useState(() => {
    // Only attempt to load highlightedIds from local storage if selfService is true
    if (selfService) {
      const savedHighlightedIds = getLocalStorageItem("highlightedIds");
      return savedHighlightedIds ? savedHighlightedIds : [];
    }
    return []; // Return an empty array if selfService is not true
  });
  const maxRPMViews = 3;
  const API_RPM_URL = `${API_URL}/v2/RpmRequest`;
  const isAdmin = userDetails?.roles.some(
    (role) => role === "Admin" || role === "HospitalAdmin"
  );

  const handleBack = () => {
    navigate(-1);
  };

  const handleCellClick = (params) => {
    if (params.field === "careTakerName" || params.field === "patientName") {
      const email = params.row.email;
      navigate("/healthprofile", {
        state: {
          relatedEmail: email,
          isDependent: true,
          dependentType: params.field,
          isAdmin: isAdmin,
        },
      });
    } else if (params.field === "deviceId") {
      if (params.value) {
        const deviceId = params.value;
        navigate("/deviceinfo", { state: { initialDeviceMId: deviceId } });
      }
    } else if (params.field === "hospital") {
      if (params.value) {
        const code = params.row.hospitalCode;
        navigate("/tenantinfo", { state: { initialTenantCode: code } });
      }
    }
  };

  useEffect(() => {
    // Determine selfService status based on initialProfile and currentUserEmail
    const isSelfService =
      initialProfile &&
      initialProfile.headerEntity.email.toLowerCase() === currentUserEmail;

    // Update state based on the new determination of selfService
    setSelfService(isSelfService);

    if (!initialProfile) {
      // Default to currentUserEmail if no initialProfile is provided
      setUserEmail(currentUserEmail);
      // Assuming we clear or reset states when there's no initialProfile
      setHighlightedIds(
        isSelfService ? getLocalStorageItem("highlightedIds") || [] : []
      );
      setRpmViews(isSelfService ? getLocalStorageItem("rpmViews") || [] : []);
    } else {
      // Set initialEmail from the initialProfile
      setInitialEmail(initialProfile.headerEntity.email.toLowerCase());

      // Conditionally load or clear highlightedIds and rpmViews based on selfService
      if (isSelfService) {
        const savedHighlightedIds = getLocalStorageItem("highlightedIds");
        setHighlightedIds(savedHighlightedIds ? savedHighlightedIds : []);

        const savedRpmViews = getLocalStorageItem("rpmViews");
        setRpmViews(savedRpmViews ? savedRpmViews : []);
      } else {
        // Clear states if not selfService
        setHighlightedIds([]);
        setRpmViews([]);
      }
    }
  }, [initialProfile, currentUserEmail]);

  const {
    data: careTakerData,
    isLoading,
    isError,
    error,
    refetch: careTakerRefetch,
  } = useGetCareTakerList(initialEmail);
  const {
    data: patientData,
    isPatientLoading,
    isPatientError,
    patientError,
    refetch: patientDataRefetch,
  } = useGetPatientRPMList(initialEmail);

  const handleRowSelection = (selectedIds) => {
    setSelectionModel(selectedIds);
    setSelectedItems(
      patientData.filter((item) => selectedIds.includes(item.id))
    );
  };

  const ctHandleRowSelection = (selectedIds) => {
    setCTSelectionModel(selectedIds);
    setSelectedItems(
      patientData.filter((item) => selectedIds.includes(item.id))
    );
  };

  const startMonitoring = async (email, timezone) => {
    try {
      const urlEncodedEmail = encodeURIComponent(email);
      const response = await post(
        `${API_RPM_URL}/StartMonitoring?email=${urlEncodedEmail}&timeZone=${timezone}`,
        null,
        true
      );
      if (response.statusCode === "OK") {
        patientDataRefetch();
        if (response.messages && response.messages.length > 0) {
          toast.success(response.messages.join("\n"));
        } else {
          toast.success("Started Monitoring " + email);
        }
      } else {
        toast.error("Error starting RPM");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage = `Error starting RPM for ${email}.\n${validationErrorMessages.join(
          "\n"
        )}`;
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const stopMonitoring = async (email, timezone, reverse = false) => {
    try {
      const urlEncodedEmail = encodeURIComponent(email);
      let url = `${API_RPM_URL}/StopMonitoring?email=${urlEncodedEmail}&timeZone=${timezone}`;
      if (reverse) {
        url = `${API_RPM_URL}/ReverseStopMonitoring?email=${urlEncodedEmail}&timeZone=${timezone}`;
      }
      const response = await post(url, null, true);
      if (response.statusCode === "OK") {
        if (reverse) {
          careTakerRefetch();
        } else {
          patientDataRefetch();
        }
        if (response.messages && response.messages.length > 0) {
          toast.info(response.messages.join("\n"));
        } else {
          if (reverse) {
            toast.info("Stopped caretaker " + email + " from monitoring");
          } else {
            toast.info("Stopped Monitoring " + email);
          }
        }
      } else {
        toast.error("Error stopping RPM");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage = `Error stopping rpm for ${email}.\n${validationErrorMessages.join(
          "\n"
        )}`;
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
    }
  };

  // CareTaker Removes Patient
  const onStopRPM = async (email) => {
    try {
      const urlEncodedEmail = encodeURIComponent(email);
      const response = await post(
        `${API_RPM_URL}/StopRpm?email=${urlEncodedEmail}`,
        null,
        true
      );
      if (response.statusCode === "OK") {
        patientDataRefetch();
        toast.success(response.messages.join("\n"));
      } else {
        toast.error("Error Removing RPM Request");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage = `Error Removing RPM for ${email}.\n${validationErrorMessages.join(
          "\n"
        )}`;
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
    }
  };

  // Patient Removes CareTaker
  const deleteRPM = async (email) => {
    try {
      const urlEncodedEmail = encodeURIComponent(email);
      const response = await deleteRequest(
        `${API_RPM_URL}/Delete?email=${urlEncodedEmail}`,
        null,
        true
      );
      if (response.statusCode === "OK") {
        careTakerRefetch();
        toast.success(response.messages.join("\n"));
      } else {
        toast.error("Error Removing RPM Request");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage = `Error Removing RPM for ${email}.\n${validationErrorMessages.join(
          "\n"
        )}`;
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const reSendEmail = async (email) => {
    try {
      const urlEncodedEmail = encodeURIComponent(email);
      const response = await post(
        `${API_RPM_URL}/ResendMail?email=${urlEncodedEmail}`,
        null,
        true
      );
      if (response.statusCode === "OK") {
        // Assuming response.messages is an array; join messages or pick the first one as needed
        toast.success(response.messages.join("\n"));
      } else if (response.statusCode === "BadRequest") {
        toast.error(response.errors.join("\n"));
      } else {
        toast.error("Error requesting email to be sent");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage = `Error requesting email for ${email}.\n${validationErrorMessages.join(
          "\n"
        )}`;
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const addPatient = async (email) => {
    try {
      const urlEncodedEmail = encodeURIComponent(email);
      const response = await post(
        `${API_RPM_URL}/Add?PatientEmail=${urlEncodedEmail}`,
        null,
        true
      );
      if (response.statusCode === "OK") {
        patientDataRefetch();
        toast.success(response.messages.join("\n"));
      } else {
        toast.error("Error adding patient for RPM");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage = `Error adding patient for RPM ${email}.\n${validationErrorMessages.join(
          "\n"
        )}`;
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const authorizeOveride = async (uid) => {
    try {
      const response = await post(
        `${API_RPM_URL}/RequestAccepted?uid=${uid}`,
        null,
        true
      );
      if (response.statusCode === "OK") {
        patientDataRefetch();
        careTakerRefetch();
        toast.success(response.messages.join("\n"));
      } else if (response.statusCode === "BadRequest") {
        toast.error(response.errors.join("\n"));
      } else {
        toast.error("Error requesting email to be sent");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage = `Error requesting email for ${uid}.\n${validationErrorMessages.join(
          "\n"
        )}`;
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
    }
  };
  const onStatusOveride = async (status) => {
    try {
      const requestId = uuidv4();
      const response = await post(
        `${API_RPM_URL}/UpdateStatus`,
        { requestId, data: { id: rpmUid, status: status } },
        true
      );
      if (response.statusCode === "OK") {
        patientDataRefetch();
        careTakerRefetch();
        toast.success(response.messages.join("\n"));
      } else {
        toast.error("Error overiding status");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors) &&
        error.response.data.errors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage = `Error overriding ${rpmUid}.\n${validationErrorMessages.join(
          "\n"
        )}`;
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
    }
  };
  const statusOveride = (uid) => {
    setOpenStatusOveride(true);
    setRpmUid(uid);
  };

  const handleRPMView = (userData) => {
    setRpmViews((prevViews) => {
      const newView = {
        id: userData.id,
        email: userData.email,
        userData: userData,
      };

      let updatedViews;
      if (prevViews.length >= maxRPMViews) {
        const oldestEntry = prevViews[0];
        stopMonitoring(oldestEntry.email, oldestEntry.timezone);
        updatedViews = [...prevViews.slice(1), newView];
      } else {
        updatedViews = [...prevViews, newView];
      }

      // Save updated rpmViews to local storage
      setLocalStorageItem("rpmViews", updatedViews);
      return updatedViews;
    });

    setHighlightedIds((prevIds) => {
      const updatedIds =
        prevIds.length >= maxRPMViews
          ? [...prevIds.slice(1), userData.id]
          : [...prevIds, userData.id];

      // Save updated highlightedIds to local storage
      setLocalStorageItem("highlightedIds", updatedIds);
      return updatedIds;
    });
  };

  const removeRPMView = (id) => {
    setRpmViews((prevViews) => {
      const updatedViews = prevViews.filter((view) => view.id !== id);
      // Update local storage with the new rpmViews state
      setLocalStorageItem("rpmViews", updatedViews);
      removeLocalStorageItem(`rpmResponseData_${id}`);
      return updatedViews;
    });

    setHighlightedIds((prevIds) => {
      const updatedIds = prevIds.filter(
        (highlightedId) => highlightedId !== id
      );
      // Update local storage with the new highlightedIds state
      setLocalStorageItem("highlightedIds", updatedIds);
      return updatedIds;
    });
  };

  useEffect(() => {
    if (patientData) {
      setPatientRequests(patientData);
    }
  }, [patientData]);

  useEffect(() => {
    if (careTakerData) {
      setCareTakerRequests(careTakerData);
    }
  }, [careTakerData]);

  useEffect(() => {
    // Additionally, handle errors from React Query
    if (isError && error) {
      // Display a more specific error message if available, or a generic message
      toast.error(error.message || "An error occurred while fetching data.");
    }

    if (isPatientError && patientError) {
      // Display a more specific error message if available, or a generic message
      toast.error(
        patientError.message || "An error occurred while fetching data."
      );
    }
  }, [isError, error, isPatientError, patientError]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header
            title="RPM"
            subtitle="Omnyk's Remote Patient Monitoring status."
          />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Container
          maxWidth="xl"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
          }}
        >
          {isLoading && <CenteredProgress />}
          {initialProfile && (
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {/* First column for Avatar, Email, and Phone */}
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  src={initialProfile.headerEntity.avatar}
                  alt="avatar"
                  sx={{
                    width: 80,
                    height: 80,
                    marginRight: "20px",
                  }}
                />

                <Box sx={{ textAlign: "left" }}>
                  <Typography variant="h5" component="h2">
                    {initialProfile.headerEntity.name}
                    {emailVerified && phoneVerified && (
                      <VerifiedUserIcon
                        sx={{ color: "teal", marginLeft: "5px" }}
                      />
                    )}
                  </Typography>
                  <Typography variant="body1">
                    Email: {initialProfile.headerEntity.email.toLowerCase()}
                    {emailVerified && (
                      <VerifiedIcon sx={{ color: "teal", marginLeft: "5px" }} />
                    )}
                  </Typography>
                  <Typography variant="body2">
                    Phone: {initialProfile.headerEntity.phoneNum}
                    {phoneVerified && (
                      <VerifiedIcon sx={{ color: "teal", marginLeft: "5px" }} />
                    )}
                  </Typography>
                  {initialProfile.bodyEntity
                    .filter((section) => section.title === "About Me")
                    .flatMap((section) => section.content)
                    .filter(
                      (item) =>
                        item.title === "Tenant ID" || item.title === "Ring ID"
                    )
                    .map((item, idx) => (
                      <Typography
                        key={idx}
                        variant="body2"
                        sx={{ marginTop: "5px" }}
                      >
                        {item.title}: {item.value}
                      </Typography>
                    ))}
                </Box>
              </Grid>

              {/* Second and Third columns for About Me and Emergency Contact */}
              {initialProfile.bodyEntity
                .filter(
                  (section) =>
                    section.title === "About Me" ||
                    section.title === "Emergency Contact"
                )
                .map((section, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{ marginBottom: "10px" }}
                    >
                      {section.title}
                    </Typography>
                    {section.content.map((item, idx) => {
                      const aboutMeCriteria = [
                        "Gender",
                        "Height",
                        "Weight",
                        "BMI",
                      ];
                      const shouldDisplay =
                        section.title === "About Me"
                          ? aboutMeCriteria.includes(item.title)
                          : true;

                      return (
                        shouldDisplay && (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <Box
                              component="img"
                              src={item.icon}
                              alt={item.title}
                              sx={{
                                width: 15,
                                height: 15,
                                marginRight: "10px",
                              }}
                            />
                            <Box>
                              <Typography variant="subtitle2">
                                {item.title}
                              </Typography>
                              <Typography variant="body2">
                                {item.value}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      );
                    })}
                  </Grid>
                ))}
            </Grid>
          )}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            m={2}
            sx={{
              border: `1px solid ${colors.greenAccent[800]}`,
              backgroundColor: colors.primary[400],
            }}
          ></Box>
          <Box mt={2}>
            {rpmViews.map((view) => (
              <RPMLive
                key={view.id}
                viewId={view.id}
                userEmail={view.email}
                userData={view.userData}
                startMonitoring={startMonitoring}
                stopMonitoring={stopMonitoring}
                setOpenRPMView={() => removeRPMView(view.id)}
                patientDataRefetch={patientDataRefetch}
                careTakerRefetch={careTakerRefetch}
              />
            ))}
          </Box>
          <Box mt={2}>
            <Header
              title="Patients"
              subtitle="List of patients being monitored"
            />
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Tooltip title="Add a patient to monitor" placement="top">
                  <span>
                    <IconButton
                      onClick={() => setOpenAddRPM(true)}
                      disabled={!selfService}
                      aria-label="add"
                    >
                      <AddCircleOutlineIcon
                        sx={{
                          color: selfService ? colors.greenAccent[600] : "grey",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Refresh Patient RPM List" placement="top">
                  <span>
                    <IconButton
                      onClick={() => patientDataRefetch()}
                      aria-label="add"
                    >
                      <RefreshIcon
                        sx={{
                          color: selfService ? colors.pastelColor.blue : "grey",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            {patientRequests && patientRequests.length > 0 ? (
              <>
                <PatientRPMList
                  isLoading={isPatientLoading}
                  patientRequests={patientRequests}
                  selectionModel={selectionModel}
                  setSelectionModel={handleRowSelection}
                  handleCellClick={handleCellClick}
                  startMonitoring={startMonitoring}
                  stopMonitoring={stopMonitoring}
                  reSendEmail={reSendEmail}
                  authorizeOveride={authorizeOveride}
                  onStopRPM={onStopRPM}
                  statusOveride={statusOveride}
                  selfService={selfService}
                  handleRPMView={handleRPMView}
                  highlightedIds={highlightedIds}
                  isAdmin={isAdmin}
                />
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                marginTop={4}
              >
                <AssistWalkerIcon
                  color="action"
                  sx={{ fontSize: 60, color: colors.redAccent[300] }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ color: colors.redAccent[300] }}
                >
                  No Patients being monitored. Add one now!
                </Typography>
              </Box>
            )}
            <AddPatientRPM
              open={openAddRPM}
              setOpen={setOpenAddRPM}
              addPatient={addPatient}
            />
            <RpmStatusOveride
              open={openStatusOveride}
              setOpen={setOpenStatusOveride}
              onStatusOveride={onStatusOveride}
            />
          </Box>
          <Box mt={2}>
            <Header
              title="Caretakers"
              subtitle="List of caretakers monitoring"
            />
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Tooltip title="Refresh Caretaker List" placement="top">
                  <span>
                    <IconButton
                      onClick={() => careTakerRefetch()}
                      aria-label="add"
                    >
                      <RefreshIcon
                        sx={{
                          color: selfService ? colors.pastelColor.blue : "grey",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            {careTakerRequests && careTakerRequests.length > 0 ? (
              <CareTakerList
                isLoading={isLoading}
                patientRequests={careTakerRequests}
                selectionModel={ctSelectionModel}
                setSelectionModel={ctHandleRowSelection}
                handleCellClick={handleCellClick}
                deleteRPM={deleteRPM}
                selfService={selfService}
                statusOveride={statusOveride}
                authorizeOveride={authorizeOveride}
                isAdmin={isAdmin}
                reverseStopMonitoring={stopMonitoring}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                marginTop={4}
              >
                <SupportAgentIcon
                  color="action"
                  sx={{ fontSize: 60, color: colors.redAccent[300] }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ color: colors.redAccent[300] }}
                >
                  No Caretakers yet. Ask for one!
                </Typography>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default PatientRPM;
