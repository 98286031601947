import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import isStrongPassword from "validator/lib/isStrongPassword";
import {
  Grid,
  InputAdornment,
  IconButton,
  FormHelperText,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { green } from "@mui/material/colors";
import LockIcon from "@mui/icons-material/Lock";
import SyncLockIcon from "@mui/icons-material/SyncLock";

const PasswordInput = () => {
  const {
    register,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const password = watch("password", "");
  const [passwordStrengthLabel, setPasswordStrengthLabel] = useState("");
  const [showMinimumRequirements, setShowMinimumRequirements] = useState(true);

  const passwordOptions = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
    returnScore: true, // Return a score instead of a boolean
  };

  // Update the password strength label based on the score
  const updatePasswordStrengthLabel = (score) => {
    if (score > 30) {
      setPasswordStrengthLabel("Strong");
    } else if (score > 20) {
      setPasswordStrengthLabel("Medium");
    } else if (password.length > 0) {
      // Only show 'Weak' if the user has started typing a password
      setPasswordStrengthLabel("Weak");
    } else {
      setPasswordStrengthLabel("");
    }
  };

  // Call this function when the password input changes
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    const score = isStrongPassword(value, passwordOptions);
    updatePasswordStrengthLabel(score);

    const isPasswordStrong = isStrongPassword(value, {
      ...passwordOptions,
      returnScore: false,
    });
    if (!isPasswordStrong) {
      setError("password", {
        type: "manual",
        message: "Password is not strong enough",
      });
    } else {
      clearErrors("password");
    }

    // Set the display of the minimum requirements based on the password strength
    setShowMinimumRequirements(!isPasswordStrong);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          {...register("password", {
            required: "Password is required",
            validate: (value) =>
              isStrongPassword(value, {
                ...passwordOptions,
                returnScore: false,
              }),
          })}
          type={showPassword ? "text" : "password"}
          label="Password"
          fullWidth
          required
          variant="outlined"
          autoComplete="new-password"
          error={!!errors.password}
          helperText={
            errors.password
              ? errors.password.message
              : `Password strength: ${passwordStrengthLabel}`
          }
          FormHelperTextProps={{
            sx: {
              color:
                passwordStrengthLabel === "Strong" ? green[500] : "inherit", // Use green color from Material-UI colors when password is strong
              fontWeight:
                passwordStrengthLabel === "Strong" ? "bold" : "normal",
            },
          }}
          onChange={handlePasswordChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color="secondary" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {showMinimumRequirements && (
          <FormHelperText>
            Minimum 8 characters, with at least one uppercase, one lowercase,
            one number, and one symbol.
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          {...register("passwordConfirm", {
            required: "Confirm Password is required",
            validate: (value) =>
              value === getValues("password") || "Passwords do not match",
          })}
          type="password"
          label="Confirm Password"
          fullWidth
          required
          variant="outlined"
          autoComplete="new-password"
          error={!!errors.passwordConfirm}
          helperText={
            errors.passwordConfirm ? errors.passwordConfirm.message : ""
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SyncLockIcon color="secondary" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PasswordInput;
