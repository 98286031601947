import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { put, API_URL } from "../../services/api";
import { Button, Alert, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CategoryForm from "../forms/CategoryForm";
import ProductForm from "../forms/ProductForm";
import { useTimedMessage } from "../forms/utilities";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import { UpdateOutlined } from "@mui/icons-material";
import CenteredProgress from "../../scenes/global/CenteredProgress";

const EditItem = ({
  component,
  selectedItem,
  setResponseMessage,
  setIsSuccess,
  setEditMode,
  refetch,
}) => {
  const methods = useForm();
  const [localResponseMessage, setLocalResponseMessage] = useTimedMessage();
  const [isLocalSuccess, setIsLocalSuccess] = React.useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [isContentSaved, setIsContentSaved] = useState(true);

  useEffect(() => {
    if (selectedItem) {
      methods.reset(selectedItem);
    }
  }, [selectedItem, methods]);

  const onSubmit = async (formData) => {
    const changedValues = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== selectedItem[key]) {
        acc[key] = formData[key]; // Only add to acc if the value has changed
      }
      return acc;
    }, {});
    if (Object.keys(changedValues).length === 0) {
      setIsLocalSuccess(false);
      setLocalResponseMessage("No changes made to product data");
      return;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };
    setIsLoading(true);
    try {
      const response = await put(`${API_URL}/v2/${component}/Update`, payload, true);
      if (response.statusCode === "OK") {
        setResponseMessage(response.messages[0]);
        setIsSuccess(true);
        setEditMode(false);
        methods.reset();
        refetch();
      } else if (response.statusCode === "InternalServerError") {
        if (response.validationErrors && response.validationErrors.length > 0) {
          // Check for validation errors in the response
          const validationErrorMessages = response.validationErrors
            .map((err) => `${err.property}: ${err.error}`)
            .join(" ");
          setIsLocalSuccess(false);
          setLocalResponseMessage(validationErrorMessages);
        }
      } else {
        setIsLocalSuccess(false);
        setLocalResponseMessage("Error updating Product.");
      }
    } catch (error) {
      if (
        error.response.data.validationErrors &&
        error.response.data.validationErrors.length > 0
      ) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.validationErrors
          .map((err) => `${err.property}: ${err.error}`)
          .join(" ");
        setIsLocalSuccess(false);
        setLocalResponseMessage(validationErrorMessages);
      } else {
        // Handle other errors
        setIsLocalSuccess(false);
        setLocalResponseMessage("Error updating Product.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
      <CategoryForm
          component={`${component}`}
          setIsContentSaved={setIsContentSaved}
          isEdit={true} selectedItem={selectedItem}
        />
        {component === "ProductCore" && (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <ProductForm isEdit={true} selectedItem={selectedItem}/>
          </Box>
        )}
        {localResponseMessage && (
          <Alert
            severity={isLocalSuccess ? "success" : "error"}
            style={{ display: "flex", justifyContent: "center", margin: "1em" }}
          >
            {localResponseMessage}
          </Alert>
        )}
        {isLoading && <CenteredProgress />}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            type="submit"
            disabled={isLoading || !isContentSaved}
          >
            <UpdateOutlined sx={{ mr: "10px" }} />
            Update
          </Button>
          <Button
            onClick={() => {
              setEditMode(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditItem;
