import { useForm, FormProvider } from "react-hook-form";
import React, { useState, Suspense } from "react";
import { post, API_URL } from "../../services/api";
import { Grid, Button, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotosOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";
const UserForm = React.lazy(() => import("../forms/UserForm"));

const AddUser = ({ setResponseMessage, setIsSuccess, setNewUserEmail }) => {
  const methods = useForm();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };

    try {
      const response = await post(
        `${API_URL}/v2/FastballUser/AddUser`,
        payload,
        true
      );
      if (response.statusCode === "OK") {
        setResponseMessage(response.messages[0]);
        setIsSuccess(true);
        setNewUserEmail(formData?.email);
        methods.reset();
      } else if (
        response.statusCode === "BadRequest" &&
        response.errors?.length > 0
      ) {
        toast.error(
          "Error adding user " + formData?.email + "." + response.errors
        );
      } else {
        setIsSuccess(false);
        setResponseMessage("Error adding User.");
        toast.error("Error adding user.");
      }
    } catch (error) {
      if (error.response?.data && error.response?.data.errors.length > 0) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        setIsSuccess(false);
        setResponseMessage(validationErrorMessages);
        toast.error(
          "Error adding user " + formData?.email + "." + validationErrorMessages
        );
      } else {
        // Handle other errors
        setIsSuccess(false);
        setResponseMessage("Error adding User.");
        toast.error("Error adding user.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Suspense fallback={<CenteredProgress />}>
          <UserForm />
        </Suspense>
        {isLoading && <CenteredProgress />}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              disabled={isLoading}
              type="submit"
            >
              <AddToPhotosIcon sx={{ mr: "10px" }} />
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AddUser;
