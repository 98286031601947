import React, { useState, useEffect } from "react";
import CenteredProgress from "../global/CenteredProgress";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import {
  useFetchPatientDetails,
  useFetchPatientProfile,
} from "../../components/patients/useFetchPatientInfo";
import {
  Typography,
  Container,
  Box,
  Grid,
  Avatar,
  IconButton,
  Divider,
} from "@mui/material";
import DeletePatient from "../../components/patients/deletePatient";
import EditPatient from "../../components/patients/editPatient";
import MovePatient from "../../components/common/movePatient";
import { useTimedMessage } from "../../components/forms/utilities";
import ActionToolbar from "../../components/common/ActionToolbar";
import { useNavigate, useLocation } from "react-router-dom";
import { red } from "@mui/material/colors";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ProfileCompletenessBar from "../../components/patients/profileStatus";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ItemSearch from "../../components/search/itemSearch";
import { useAuth } from "../../AuthProvider";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../components/auth/storage";
import { useQueryClient } from "@tanstack/react-query";

const PatientProfile = ({
  initialEmail: propsInitialEmail,
  initialPatient: propsInitialPatient,
  onPatientUpdated,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const queryClient = useQueryClient();
  const { isAuthenticated, userDetails } = useAuth();
  const linkIconTitles = ["Tenant ID", "Ring ID"];
  const [currentTenant, setCurrentTenant] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [patientUpdateSuccess, setPatientUpdateSuccess] = useState(false);
  const [patientEmail, setPatientEmail] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientProfile, setSelectedPatientProfile] = useState(null);
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [isSuccess, setIsSuccess] = React.useState(true);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAdmin = userDetails?.roles.some(
    (role) => role === "Admin" || role === "HospitalAdmin"
  );
  const isHospitalAdmin = userDetails?.roles.some(
    (role) => role === "HospitalAdmin"
  );
  const isSearchBarEnabled = isAdmin;
  const initialPatientState =
    location.state?.initialPatient || propsInitialPatient;
  const [initialPatient, setInitialPatient] = useState(initialPatientState);
  const initialPatientUid = location.state?.initialPatientUid;
  const urlInitialEmail = queryParams.get("email");
  const [assignTenantMode, setAssignTenantMode] = useState(false);
  const emailVerified = selectedPatientProfile?.isEmailVerified;
  const phoneVerified = selectedPatientProfile?.isPhoneNoVerified;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedPatientUid, setSelectedPatientUid] = useState(null);
  const determineCacheEmail = () => {
    if (!initialPatientUid && !initialPatient) {
      return getLocalStorageItem("patientInfo");
    } else {
      return "";
    }
  };
  const cacheEmail = determineCacheEmail();
  const initialEmail =
    urlInitialEmail ||
    propsInitialEmail ||
    location.state?.initialEmail ||
    cacheEmail;
  const handleBack = () => {
    navigate(-1);
  };
  const {
    data: patientProfile,
    isLoading: isPatientProfileLoading,
    isError: isPatientProfileError,
    error: patientProfileError,
    refetch: refetchPatientProfile,
  } = useFetchPatientProfile(patientEmail);

  const {
    data: patientDetails,
    isLoading: isPatientDetailsLoading,
    isError: isPatientDetailsError,
    error: patientDetailsError,
    refetch: refetchPatientDetails,
  } = useFetchPatientDetails(selectedPatientUid, initialPatient, true);

  const [status] = useState({
    add: { disabled: !isAdmin, tooltip: "Add new patient" },
    edit: {
      disabled: !isAdmin,
      tooltip: "Edit selected patient",
    },
    delete: {
      disabled: !isAdmin,
      tooltip: "Delete selected patient",
    },
    assignTenant: {
      disabled: !isAdmin || isHospitalAdmin,
      tooltip: "Move patient to tenant",
    },
    vitals: {
      disabled: !isAdmin,
      tooltip: "View patient vitals.",
    },
    rpm: { disabled: !isAdmin, tooltip: "View RPM status." },
  });

  useEffect(() => {
    return () => {
      setSelectedPatient(null);
      setSelectedPatientProfile(null);
      setSelectedPatientUid(null);
      setEditMode(false);
      setPatientEmail("");
    };
  }, []);

  useEffect(() => {
    if (patientProfile) {
      if (patientProfile.bodyEntity) {
        patientProfile.bodyEntity.forEach((section) => {
          const tenantItem = section.content.find(
            (item) => item.title === "Tenant ID"
          );
          if (tenantItem && tenantItem.value) {
            setCurrentTenant(tenantItem.value);
            setIsAnonymous(tenantItem.value === "ANONYMOUS");
          }
        });
      }
      setSelectedPatientProfile(patientProfile);
      setSelectedPatientUid(patientProfile.uid);
      setLocalStorageItem(
        "patientInfo",
        JSON.stringify(patientProfile.headerEntity.email)
      );
    }
  }, [patientProfile]);

  useEffect(() => {
    if (initialPatientUid) {
      setSelectedPatientUid(initialPatientUid);
    }
  }, [initialPatientUid]);

  useEffect(() => {
    if (patientDetails) {
      setSelectedPatient(patientDetails);
    }
    if (patientDetails && !patientProfile && !patientEmail) {
      setPatientEmail(patientDetails.email);
    }
  }, [patientDetails]);

  const handleAdd = () => {
    navigate("/addpatient");
    setEditMode(false);
  };

  const handleVitals = () => {
    if (selectedPatientProfile) {
      navigate("/vitals", {
        state: { initialProfile: selectedPatientProfile },
      });
    }
  };

  const handleRPM = () => {
    if (selectedPatientProfile) {
      navigate("/patientrpm", {
        state: { initialProfile: selectedPatientProfile },
      });
    }
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleAssignTenant = () => {
    setAssignTenantMode(true);
  };

  const handleGetPatientProfile = async (email) => {
    setPatientEmail(email);
  };

  const handleGetPatientDetails = async (uid) => {
    setSelectedPatientUid(uid);
  };

  useEffect(() => {
    if (initialEmail) {
      handleGetPatientProfile(initialEmail);
    }
    // eslint-disable-next-line
  }, [initialEmail]);

  useEffect(() => {
    if (propsInitialPatient) {
      setSelectedPatient(propsInitialPatient);
    }
    // eslint-disable-next-line
  }, [propsInitialPatient]);

  useEffect(() => {
    if (patientUpdateSuccess) {
      refetchPatientProfile();
      refetchPatientDetails().then((updatedData) => {
        if (updatedData.data) {
          setSelectedPatient(updatedData.data);
        }
        if (typeof onPatientUpdated === "function") {
          // Use the data from the resolved promise of refetch
          onPatientUpdated(updatedData.data);
        }
        setPatientUpdateSuccess(false);
      });
    }
  }, [patientUpdateSuccess, refetchPatientProfile, onPatientUpdated]);

  const handlePatientUpdateSuccess = (success) => {
    setPatientUpdateSuccess(success);
  };

  const handleEdit = async () => {
    try {
      await handleGetPatientDetails(selectedPatientProfile.uid);
    } catch (err) {
      throw err;
    }
    setEditMode(true);
  };

  const handleIconClick = (params) => {
    if (params.field === "Tenant ID") {
      const code = params.value;
      navigate("/tenantinfo", { state: { initialTenantCode: code } });
    } else if (params.field === "Ring ID") {
      if (params.value) {
        const deviceId = params.value;
        navigate("/deviceinfo", { state: { initialDeviceMId: deviceId } });
      }
    }
  };

  const handlePatientSelected = (patient) => {
    setInitialPatient(null);
    setSelectedPatientUid(null);
    handleGetPatientProfile(patient.email);
  };

  const handlePatientDelete = () => {
    queryClient.removeQueries({
      queryKey: ["patientProfile", selectedPatientProfile.headerEntity.email],
    });
    setSelectedPatient(null);
    setSelectedPatientProfile(null);
    setSelectedPatientUid(null);
    setPatientEmail("");
    removeLocalStorageItem("patientInfo");
  };

  useEffect(() => {
    // Show success or custom error messages from responseMessage
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }

    // Additionally, handle errors from React Query
    if (isPatientProfileError && patientProfileError) {
      if (patientProfileError.message?.includes("Patient not found")) {
        removeLocalStorageItem("patientInfo");
      }
      // Display a more specific error message if available, or a generic message
      toast.error(
        patientProfileError.message ||
          "An error occurred while fetching patient profile."
      );
    }

    if (isPatientDetailsError && patientDetailsError) {
      // Display a more specific error message if available, or a generic message
      toast.error(
        patientDetailsError.message ||
          "An error occurred while fetching patient details."
      );
    }
  }, [
    responseMessage,
    isSuccess,
    isPatientProfileError,
    patientProfileError,
    isPatientDetailsError,
    patientDetailsError,
  ]);
  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Patients" subtitle="Omnyk patient's profile." />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
          }}
        >
          <DeletePatient
            selectedPatientProfile={selectedPatientProfile}
            setResponseMessage={setResponseMessage}
            setIsSuccess={setIsSuccess}
            onPatientUpdated={onPatientUpdated}
            setIsLoading={setIsLoading}
            openDeleteDialog={openDeleteDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            onPatientDelete={handlePatientDelete}
          />
          {isSearchBarEnabled && !editMode && (
            <Box my={2}>
              <ItemSearch onItemSelected={handlePatientSelected} component="Patient"/>
            </Box>
          )}
          {assignTenantMode && (
            <MovePatient
              patientMode={true}
              patientEmail={selectedPatientProfile.headerEntity.email}
              setResponseMessage={setResponseMessage}
              setIsSuccess={setIsSuccess}
              setAssignMode={setAssignTenantMode}
              onUpdateSuccess={handlePatientUpdateSuccess}
            />
          )}
          {selectedPatientProfile && (
            <ProfileCompletenessBar patient={selectedPatientProfile} />
          )}
          {editMode && selectedPatient && (
            <EditPatient
              selectedPatientProfile={selectedPatientProfile}
              selectedPatient={selectedPatient}
              setResponseMessage={setResponseMessage}
              setPatientEmail={setPatientEmail}
              setIsSuccess={setIsSuccess}
              setEditMode={setEditMode}
              onPatientUpdateSuccess={handlePatientUpdateSuccess}
            />
          )}
          {!editMode && (
            <>
              {(isPatientProfileLoading ||
                isloading ||
                isPatientDetailsLoading) && <CenteredProgress />}
              {selectedPatientProfile && !editMode && (
                <>
                  {!isAnonymous && (
                    <ActionToolbar
                      status={status}
                      onAdd={handleAdd}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      onAssignTenant={handleAssignTenant}
                      onVitals={handleVitals}
                      onRPM={handleRPM}
                    />
                  )}

                  <Box
                    sx={{
                      marginTop: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <Avatar
                        src={selectedPatientProfile.headerEntity.avatar}
                        alt="avatar"
                        sx={{
                          width: 80, // Adjust the width as needed
                          height: 80, // Adjust the height as needed
                          marginRight: "20px",
                        }}
                      />
                      <Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{ marginBottom: "10px" }}
                          >
                            {selectedPatientProfile.headerEntity.name}
                          </Typography>
                          {emailVerified && phoneVerified && (
                            <VerifiedUserIcon
                              sx={{ color: "teal", marginLeft: "5px" }}
                            />
                          )}
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body1"
                            component="h2"
                            sx={{ marginBottom: "5px" }}
                          >
                            Email: {selectedPatientProfile.headerEntity.email}
                          </Typography>
                          {emailVerified && (
                            <VerifiedIcon
                              sx={{ color: "teal", marginLeft: "5px" }}
                            />
                          )}
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            component="h2"
                            sx={{ marginBottom: "5px" }}
                          >
                            Phone:{" "}
                            {selectedPatientProfile.headerEntity.phoneNum}
                          </Typography>
                          {phoneVerified && (
                            <VerifiedIcon
                              sx={{ color: "teal", marginLeft: "5px" }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        bgcolor: red[200],
                        height: "2px",
                        my: 2,
                      }}
                    />

                    <Grid container spacing={2} justifyContent="space-between">
                      {selectedPatientProfile.bodyEntity.map(
                        (section, index) => (
                          <Grid item xs={12} sm={6} md={6} key={index}>
                            <Typography
                              variant="h5"
                              component="h2"
                              sx={{ marginBottom: "10px" }}
                            >
                              {section.title}
                            </Typography>
                            {section.content.map((item, idx) => (
                              <Box
                                key={idx}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <Box
                                  component="img"
                                  src={item.icon}
                                  alt={item.title}
                                  sx={{
                                    width: 30, // Set the width you want
                                    height: 30, // Set the height you want
                                    marginRight: "10px",
                                    cursor: linkIconTitles.includes(item.title)
                                      ? "pointer"
                                      : "default",
                                  }}
                                  onClick={() =>
                                    handleIconClick({
                                      field: item.title,
                                      value: item.value,
                                    })
                                  }
                                />
                                <Box>
                                  <Typography variant="subtitle2">
                                    {item.title}
                                  </Typography>
                                  <Typography variant="body3">
                                    {item.value}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default PatientProfile;
