import React, { useState } from 'react';
import { LinearProgress, Box, Typography, Tooltip, ClickAwayListener } from '@mui/material';
import { isPhoneValid } from '../forms/utilities';
import isEmail from 'validator/lib/isEmail';

const ProfileCompletenessBar = ({ selectedTenant }) => {
    const [open, setOpen] = useState(false);
    // Define the fields and their weights
    const fields = {
        imageUrl: 3,
        photo: 4,
        name: 10,
        code: 0,
        address: 10,
        city: 15,
        state: 10,
        country: 10,
        zipCode: 10,
        contactName: 15,
        creditDays: 5,
        email: isEmail(selectedTenant.email) ? 15 : 0, // Check if email is valid and assign weight
        mobileNumber: isPhoneValid(selectedTenant.mobileNumber) ? 15 : 0, // Check if phone is valid and assign weight
        isIgst: 0,
        gstNo: 15,
        isSystem: 0,
        timeZone: 5
    };
    const getFieldsToImprove = () => {
        const fieldsToImprove = [];
        const fieldNames = {
            imageUrl: 'Image URL',
            photo: 'Photo',
            name: 'Name',
            address: 'Address',
            city: 'City',
            state: 'State',
            country: 'Country',
            zipCode: 'Zip Code',
            contactName: 'Contact Name',
            email: 'Email',
            mobileNumber: 'Mobile Number',
            gstNo: 'GST Number',
            timeZone: 'Time Zone',
            creditDays: 'Credit Days'
        };

        // Iterate over the fields to determine which ones need improvement
        Object.keys(fields).forEach(key => {
            const weight = fields[key];
            const value = selectedTenant[key];
            const isComplete = value !== null && value !== '';

            // Check if the field has a non-zero weight and is not complete or not valid
            if (weight > 0 && (!isComplete || (key === 'email' && !isEmail(value)) || (key === 'mobileNumber' && !isPhoneValid(value)))) {
                fieldsToImprove.push(fieldNames[key] || key);
            }
        });

        return fieldsToImprove.join(', ');
    };
    // Calculate the completeness score
    const completenessScore = Object.keys(fields).reduce((total, key) => {
        const value = selectedTenant[key];
        const isComplete = value !== null && value !== '';
        return total + (isComplete ? fields[key] : 0);
    }, 0);

    // Calculate the maximum score
    const maxScore = Object.values(fields).reduce((sum, weight) => sum + weight, 0);

    // Calculate the completeness percentage
    const completenessPercentage = (completenessScore / maxScore) * 100;

    // Determine color based on completeness percentage
    const color = completenessPercentage < 60 ? 'error' : completenessPercentage < 85 ? 'warning' : 'success';

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };


    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Box textAlign="center" mb={2}>
                <Typography variant="h6" gutterBottom>
                    Tenant Profile Completeness
                </Typography>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={getFieldsToImprove()}
                >
                    <Box onClick={handleTooltipOpen}>
                        <LinearProgress
                            variant="determinate"
                            value={completenessPercentage}
                            color={color}
                            sx={{ height: 15, borderRadius: 5, cursor: 'pointer' }} // Added cursor style for better UX
                        />
                    </Box>
                </Tooltip>
                <Typography
                    variant="body1"
                    color={color}
                    mt={2}
                >
                    {completenessPercentage.toFixed(2)}%
                </Typography>
            </Box>
        </ClickAwayListener>
    );
};

export default ProfileCompletenessBar;
