import React, { useEffect, useState, Suspense } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { put, API_URL } from "../../services/api";
import { Button, Alert } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useTimedMessage } from "../forms/utilities";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import UpgradeIcon from "@mui/icons-material/UpgradeOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";
const TenantForm = React.lazy(() => import('../forms/TenantForm'));

const EditTenant = ({
  editableTenant,
  selectedTenant,
  setResponseMessage,
  setIsSuccess,
  setEditMode,
  onTenantUpdateSuccess,
}) => {
  const methods = useForm();
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [localResponseMessage, setLocalResponseMessage] = useTimedMessage();
  const [isLocalSuccess, setIsLocalSuccess] = React.useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editableTenant) {
      methods.reset(editableTenant);
    }
  }, [editableTenant, methods]);

  const handlePhoneValidChange = (isValid) => {
    setIsValidPhone(isValid);
  };

  const onSubmit = async (formData) => {
    const changedValues = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== selectedTenant[key]) {
        acc[key] = formData[key]; // Only add to acc if the value has changed
      }
      return acc;
    }, {});

    if (Object.keys(changedValues).length === 0) {
      setIsLocalSuccess(false);
      setLocalResponseMessage("No changes made to tenant data");
      return;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };
    setIsLoading(true);
    try {
      const response = await put(`${API_URL}/v2/Tenant/Update`, payload, true);
      if (response.statusCode === "OK") {
        setResponseMessage(response.messages[0]);
        setIsSuccess(true);
        setEditMode(false);
        methods.reset(editableTenant);
        const updateWasSuccessful = true;
        onTenantUpdateSuccess(updateWasSuccessful);
      } else {
        setLocalResponseMessage(response.errors[0]);
        setIsLocalSuccess(false);
      }
    } catch (error) {
      setLocalResponseMessage("Failed to update tenant.");
      setIsLocalSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Suspense fallback={<CenteredProgress />}>
        <TenantForm
          isEdit={true}
          editableTenant={editableTenant}
          onPhoneValidChange={handlePhoneValidChange}
        />
        </Suspense>
        {localResponseMessage && (
          <Alert
            severity={isLocalSuccess ? "success" : "error"}
            style={{ display: "flex", justifyContent: "center", margin: "1em" }}
          >
            {localResponseMessage}
          </Alert>
        )}
        {isLoading && <CenteredProgress />}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            type="submit"
            disabled={!isValidPhone || isLoading}
          >
            <UpgradeIcon sx={{ mr: "10px" }} />
            Update
          </Button>
          <Button
            onClick={() => {
              setEditMode(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditTenant;
