import { useForm, FormProvider, Controller } from "react-hook-form";
import React, { useState } from "react";
import { post, API_URL } from "../../services/api";
import { Grid, Button, Box, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CategoryForm from "../forms/CategoryForm";
import ProductForm from "../forms/ProductForm";
import SearchComponent from "../forms/components/SearchComponent";
import CascadingSearch from "../forms/components/CascadePsc";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotosOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";

const AddItemForm = ({ component, setNewId }) => {
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isContentSaved, setIsContentSaved] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const onItemSelected = (item, field) => {
    field.onChange(item.id);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };

    try {
      const response = await post(
        `${API_URL}/v2/${component}/Add`,
        payload,
        true
      );
      if (response.statusCode === "OK") {
        toast.success(response.messages[0]);
        if (response.data && response.data.id) {
          setNewId(response.data.id);
        }
        methods.reset();
      } else if (response.statusCode === "BadRequest") {
        toast.error(response.errors[0]);
      } else {
        toast.error(`Error adding ${component}.`);
      }
    } catch (error) {
      if (error.response.data && error.response.data.errors.length > 0) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage =
          `Error adding ${component}.` +
          ".\n" +
          validationErrorMessages.join("\n");
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error(`Error adding ${component}.`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {component === "SubCategory" && (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <SearchComponent
              control={methods.control}
              name="categoryId"
              component="Category"
              onItemSelected={onItemSelected}
              rules={{ required: "Category Name is required" }}
            />
          </Box>
        )}
        {component === "SKU" && (
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={2}
            mb={2}
            width="100%"
          >
            <CascadingSearch
              control={methods.control}
              onItemSelected={onItemSelected}
            />
          </Box>
        )}
        {component === "Product" && (
          <>
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
              <SearchComponent
                control={methods.control}
                name="productId"
                component="ProductCore"
                onItemSelected={onItemSelected}
                rules={{ required: "ProductCore Name is required" }}
              />
            </Box>
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
              <SearchComponent
                control={methods.control}
                name="subCategoryId"
                component="SubCategory"
                onItemSelected={onItemSelected}
                rules={{ required: "SubCategory Name is required" }}
              />
            </Box>
          </>
        )}
        {component !== "Product" && (
          <CategoryForm
            component={`${component}`}
            setIsContentSaved={setIsContentSaved}
          />
        )}
        {component === "ProductCore" && (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <ProductForm />
          </Box>
        )}
        {isLoading && <CenteredProgress />}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              disabled={
                isLoading || (!isContentSaved && component !== "Product")
              }
              type="submit"
            >
              <AddToPhotosIcon sx={{ mr: "10px" }} />
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AddItemForm;
