import React from "react";
import {
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  TextField,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import {
  validateMacAddress,
  validateSerialNumber,
  handleSerialInputChange,
  handleMacInputChange,
} from "./utilities";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";

const DeviceForm = ({ isEdit = false, editableDevice = null }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid container spacing={2}>
      {/* Name */}
      <Grid item xs={12}>
        <TextField
          {...register("serialNo", {
            required: "Serial Number is required",
            validate: {
              correctFormat: (value) =>
                validateSerialNumber(value) ||
                "Serial Number must start with OCFA followed by 5 digits",
            },
          })}
          label="Serial Number"
          required
          defaultValue="OCFA"
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleSerialInputChange}
          inputProps={{ maxLength: 9 }}
          error={!!errors.serialNo}
          helperText={errors.serialNo?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("deviceId", {
            required: "Device ID (MAC Address) is required",
            validate: {
              validMac: (value) =>
                validateMacAddress(value) || "Enter a valid MAC Address",
            },
          })}
          label="Device ID (MAC Address)"
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          error={!!errors.deviceId}
          helperText={errors.deviceId?.message}
          onChange={handleMacInputChange}
          inputProps={{ maxLength: 17 }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="size"
          control={control}
          defaultValue={isEdit && editableDevice ? editableDevice.size : ""}
          rules={{ required: "Size is required" }}
          render={({ field }) => (
            <FormControl fullWidth variant="outlined" error={!!errors.size}>
              <InputLabel id="size-label" required>
                Size
              </InputLabel>
              <Select
                {...field}
                labelId="size-label"
                label="Size"
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: colors.primary[400],
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="Large">Large</MenuItem>
                <MenuItem value="ExtraSmall">Extra Small</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Small">Small</MenuItem>
              </Select>
              <FormHelperText>
                {errors.size ? errors.size.message : ""}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      {[{ label: "Is Imported?", name: "isImported" }].map((item, index) => (
        <Grid item xs={3} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isEdit ? editableDevice?.[item.name] ?? false : false}
                disabled={true}
              />
            }
            label={item.label}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DeviceForm;
