import { useQuery } from "@tanstack/react-query";
import { get, API_URL } from "../../services/api";

const version = "v2";

export const useFetchByGuid = (component, uid, initialData) => {
  return useQuery({
    queryKey: [`${component}Details`, uid],
    queryFn: async () => {
      if (!uid) {
        return {};
      }
      let url = `${API_URL}/${version}/${component}/GetByUid?id=${uid}`;
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || `Error fetching + ${component}+ details`
        );
      }
    },
    enabled: !!uid && !initialData,
    initialData: initialData,
  });
};

export const useFetchById = (component, id, initialData) => {
  return useQuery({
    queryKey: [`${component}Details`, id],
    queryFn: async () => {
      if (!id) {
        return {};
      }
      let url = `${API_URL}/${version}/${component}/GetById?id=${id}`;
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] || `Error fetching + ${component}+ details`
        );
      }
    },
    enabled: !!id && !initialData,
    initialData: initialData,
  });
};

export const useFetchByDependentId = (component, subComponent, id) => {
  return useQuery({
    queryKey: [`${component}${subComponent}Details`, id],
    queryFn: async () => {
      if (!id) {
        return {};
      }
      let url = `${API_URL}/${version}/${subComponent}/LinksBy${component}Id?id=${id}`;
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(
          response.errors?.[0] ||
            `Error fetching + ${component}_${subComponent}+ details`
        );
      }
    },
    enabled: !!id,
  });
};

export const useFetchAppSettings = (isUpdated = false) => {
  return useQuery({
    queryKey: ["appSettings", isUpdated],
    queryFn: async () => {
      const response = await get(
        `${API_URL}/${version}/AppSettings/GetSettings`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(response.errors?.[0] || "Error fetching App settings");
      }
    },
  });
};

export const useFetchPatientStats = (id = null) => {
  return useQuery({
    queryKey: ["patientStats", id],
    queryFn: async () => {
      const url = id 
        ? `${API_URL}/${version}/Patient/GetPatientStats?id=${id}` 
        : `${API_URL}/${version}/Patient/GetPatientStats`;

      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(response.errors?.[0] || "Error fetching patient stats");
      }
    },
  });
};


export const useFetchMyProduct = (isUpdated = false) => {
  return useQuery({
    queryKey: ["myProduct", isUpdated],
    queryFn: async () => {
      const response = await get(
        `${API_URL}/${version}/SubCategory/MyProducts`,
        true
      );
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(response.errors?.[0] || "Error fetching My products");
      }
    },
  });
};