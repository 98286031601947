import React from "react";
import { Typography, Box, IconButton } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import LockResetIcon from "@mui/icons-material/LockResetOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useNavigate, useLocation } from "react-router-dom";

const NotAuthorised = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = location.state?.userDetails;

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Access Denied"
          subtitle={`Dear ${userDetails?.firstName}, you don't have access to the requested page.`}
        />
      </Box>
      <IconButton onClick={handleBack} aria-label="back">
        <ArrowBackIosNewOutlinedIcon />
      </IconButton>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        marginTop={4}
      >
        <LockResetIcon
          color="action"
          sx={{ fontSize: 200, color: colors.redAccent[300] }}
        />
        <Typography variant="subtitle1" sx={{ color: colors.redAccent[300] }}>
          Access restricted to authroised personel only.
        </Typography>
      </Box>
    </Box>
  );
};

export default NotAuthorised;
