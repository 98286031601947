import React from "react";
import { Typography, Box } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import WarningIcon from "@mui/icons-material/Warning";
import Header from "../../components/Header";
import { useAuth } from "../../AuthProvider";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import { AppDownloadButtons } from "../../components/common/AppDownloadButtons";

const AppBadges = () => {
  const { isAuthenticated, userDetails, patientDetails } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isPatient =
    patientDetails !== undefined &&
    patientDetails !== null &&
    patientDetails !== "undefined"
      ? true
      : false;
  const currentUserDetails = isPatient ? patientDetails : userDetails;

  return (
    <Box m="20px">
      <Header
        title="OmnyTraq™"
        subtitle="Download our OmnyTraq™ app. Available now on your favorite device."
      />
      <Box
        m="20px"
        sx={{
          border: 2, // sets the border thickness
          borderColor: colors.greenAccent[400],
        }}
      >
        {isAuthenticated && currentUserDetails && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Keeps the vertical layout
                justifyContent: "center", // Vertically centers the Box in its container
                alignItems: "center", // Horizontally centers the Box in its container
              }}
              mt={3}
            >
              <Box
                sx={{
                  textAlign: "left",
                  maxWidth: "600px",
                  width: "100%",
                }}
              >
                <Typography variant="h3" gutterBottom>
                  Welcome, {currentUserDetails.firstName}{" "}
                  {currentUserDetails.lastName}!
                </Typography>
                {patientDetails && (
                  <>
                    {currentUserDetails.mobileNumber ? (
                      currentUserDetails.isPhoneNoVerified ? (
                        <Typography
                          variant="h5"
                          sx={{ display: "flex", alignItems: "center", mt: 2 }}
                        >
                          <VerifiedUserIcon
                            sx={{ mr: 1, color: colors.pastelColor.green }}
                          />
                          Your phone number is verified.
                        </Typography>
                      ) : (
                        <Typography
                          variant="h5"
                          sx={{ display: "flex", alignItems: "center", mt: 2 }}
                        >
                          <WarningIcon
                            sx={{ mr: 1, color: colors.pastelColor.orange }}
                          />
                          Please verify your phone number.
                        </Typography>
                      )
                    ) : (
                      <Typography
                        variant="h5"
                        sx={{ display: "flex", alignItems: "center", mt: 2 }}
                      >
                        <WarningIcon
                          sx={{ mr: 1, color: colors.pastelColor.red }}
                        />
                        Please update your profile with your mobile number.
                      </Typography>
                    )}

                    {currentUserDetails.isEmailVerified ? (
                      <Typography
                        variant="h5"
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <VerifiedUserIcon
                          sx={{ mr: 1, color: colors.pastelColor.green }}
                        />
                        Thank you for verifying your email.
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <WarningIcon
                          sx={{ mr: 1, color: colors.pastelColor.orange }}
                        />
                        Please verify your email address.
                      </Typography>
                    )}

                    {currentUserDetails.emergencyContactName &&
                    currentUserDetails.emergencyContactNo ? (
                      <Typography
                        variant="h5"
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <CheckCircleIcon
                          sx={{ mr: 1, color: colors.pastelColor.green }}
                        />
                        Thank you for adding your emergency contact details. It
                        helps us serve you better.
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <WarningIcon
                          sx={{ mr: 1, color: colors.pastelColor.orange }}
                        />
                        Please update your emergency contact name and phone
                        number.
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </>
        )}
        <AppDownloadButtons />
      </Box>
    </Box>
  );
};

export default AppBadges;
