import React, { useState } from "react";
import { TextField, InputAdornment, IconButton, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const GenericSearch = ({ onGenericSelected }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    onGenericSelected("");
  };

  const handleSearchButtonClick = () => {
    onGenericSelected(searchTerm);
    setSearchTerm("");
  };

  const handleKeyDown = (event) => {
    // Check if the Enter key was pressed
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action to avoid form submission in some cases
      onGenericSelected(searchTerm);
      setSearchTerm("");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        label="Search"
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <IconButton onClick={handleClearSearch}>
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton onClick={handleSearchButtonClick}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default GenericSearch;
