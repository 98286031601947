import React from "react";
import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { HighlightOnHoverCell, AvatarCell, DgWrapper, getSlotProps } from "../common/customDataGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/SendOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import FmdBadIcon from "@mui/icons-material/FmdBadOutlined";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeartOutlined";
import "./HighlightRow.css";

const PatientRPMList = ({
  isLoading,
  patientRequests,
  selectionModel,
  setSelectionModel,
  handleCellClick,
  startMonitoring,
  stopMonitoring,
  reSendEmail,
  authorizeOveride,
  onStopRPM,
  statusOveride,
  selfService,
  handleRPMView,
  highlightedIds,
  isAdmin,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const slotProps = getSlotProps(theme);
  const columns = [
    {
      field: "avatar",
      headerName: "",
      width: 80,
      filterable: false,
      renderCell: (params) => (
        <AvatarCell
          row={params.row}
          animation={params.row.isCurrentlyMonitored}
          name={params.row.patientName}
        />
      ),
    },
    {
      field: "patientName",
      headerName: "Patient name",
      width: 150,
      cellClassName: "fn-column--cell",
      filterable: false,
      renderCell: (params) => (
        <HighlightOnHoverCell value={params.value} customCN="fn-column--cell" />
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      cellClassName: "email-column--cell",
      filterable: false,
    },
    {
      field: "contactNumber",
      headerName: "Contact",
      width: 150,
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      filterable: false,
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "isRpmEnabled",
      headerName: "RPM",
      width: 100,
      type: "boolean",
      filterable: false,
    },
    {
      field: "isCurrentlyMonitored",
      headerName: "Monitored",
      width: 100,
      type: "boolean",
      filterable: false,
    },
    {
      field: "timeZone",
      headerName: "Timezone",
      width: 150,
      filterable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      type: "actions",
      getActions: (params) => {
        const actions = [];
        if (isAdmin) {
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Authorise Override" placement="top" arrow>
                  <EditIcon
                    sx={{
                      color:
                        params.row.status === "Pending" ||
                        params.row.status === "Rejected"
                          ? colors.pastelColor.orange
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="Authorise Override"
              onClick={() => authorizeOveride(params.id)}
              disabled={
                !(
                  params.row.status === "Pending" ||
                  params.row.status === "Rejected"
                )
              }
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Status Override" placement="top" arrow>
                  <FmdBadIcon
                    sx={{
                      color:
                        params.row.status !== "Disabled"
                          ? colors.pastelColor.red
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="Status Override"
              onClick={() => statusOveride(params.id)}
              disabled={params.row.status === "Disabled"}
            />
          );
        }
        if (selfService) {
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Start Monitoring" placement="top" arrow>
                  <VisibilityIcon
                    sx={{
                      color:
                        !params.row.isCurrentlyMonitored &&
                        params.row.status === "Authorised"
                          ? colors.pastelColor.teal
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="View"
              onClick={() =>
                startMonitoring(params.row.email, params.row.timeZone)
              }
              disabled={
                params.row.isCurrentlyMonitored ||
                params.row.status !== "Authorised"
              }
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Stop Monitoring" placement="top" arrow>
                  <HighlightOffIcon
                    sx={{
                      color:
                        params.row.isCurrentlyMonitored &&
                        params.row.status === "Authorised"
                          ? colors.pastelColor.pink
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="Stop"
              onClick={() =>
                stopMonitoring(params.row.email, params.row.timeZone)
              }
              disabled={
                !params.row.isCurrentlyMonitored ||
                params.row.status !== "Authorised"
              }
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="RPM View" placement="top" arrow>
                  <MonitorHeartIcon
                    sx={{
                      color:
                        selfService &&
                        params.row.status === "Authorised" &&
                        !highlightedIds.includes(params.row.id)
                          ? colors.pastelColor.green
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="RPM View"
              onClick={() => handleRPMView(params.row)}
              disabled={
                !selfService ||
                params.row.status !== "Authorised" ||
                highlightedIds.includes(params.row.id)
              }
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Resend Email" placement="top" arrow>
                  <SendIcon
                    sx={{
                      color:
                        params.row.status === "Pending"
                          ? colors.greenAccent[400]
                          : "grey",
                    }}
                  />
                </Tooltip>
              }
              label="Resend Email"
              onClick={() => reSendEmail(params.row.email)}
              disabled={!selfService || params.row.status !== "Pending"}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Remove Request" placement="top" arrow>
                  <DeleteIcon
                    sx={{
                      color: colors.pastelColor.red,
                    }}
                  />
                </Tooltip>
              }
              label="Remove Request"
              onClick={() => onStopRPM(params.row.email)}
              disabled={!selfService}
            />
          );
        }
        return actions;
      },
    },
  ];
  const hiddenColumns = [];
  const initialVisibilityModel = hiddenColumns.reduce((model, columnName) => {
    model[columnName] = false;
    return model;
  }, {});

  const rows = patientRequests.map((item) => {
    return {
      id: item.uid,
      ...item,
    };
  });
  const initialSortModel = [{ field: "status", sort: "asc" }];

  return (
    <DgWrapper>
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        selectionModel={selectionModel}
        getRowClassName={(params) =>
          highlightedIds.includes(params.id) ? "highlight-row" : ""
        }
        onSelectionModelChange={(newSelection) =>
          setSelectionModel(newSelection)
        }
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectionModel(newRowSelectionModel);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: initialVisibilityModel,
          },
          sorting: {
            sortModel: initialSortModel,
          },
        }}
        rowSelectionModel={selectionModel}
        rowCount={rows.length}
        checkboxSelection
        filterDebounceMs={300}
        onCellClick={handleCellClick}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={slotProps}
      />
    </DgWrapper>
  );
};

export default PatientRPMList;
