import { useNavigate } from 'react-router-dom';
import filterSettings from './filterSettings.json';

export const useNavigateWithFilters = () => {
  const navigate = useNavigate();

  const navigateWithFilters = (section = 'patients', updates = {}) => {
    const sectionData = filterSettings[section];

    // Generate updated filters based on the provided updates
    const updatedFilters = sectionData.defaultFilters.map(filter => {
      if (updates[filter.type] !== undefined) {
        return { ...filter, value: updates[filter.type] };
      }
      return filter;
    });

    // Navigate with the updated filters as state
    if (sectionData.headers.url) {
      navigate(sectionData.headers.url, { state: { initialFilters: updatedFilters } });
    } else {
      console.error(`URL not defined for section "${section}" in filter settings.`);
    }
  };

  return navigateWithFilters;
};
