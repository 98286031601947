import React from "react";
import "react-international-phone/style.css";
import {
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";

export const MuiPhone = React.forwardRef(
  (
    { value, onChange, name, InputProps: parentInputProps, ...restProps },
    ref
  ) => {
    const {
      inputValue,
      handlePhoneValueChange,
      country,
      setCountry,
    } = usePhoneInput({
      defaultCountry: "in",
      value,
      countries: defaultCountries,
      onChange: (data) => {
        const event = { target: { value: data.phone, name: name } };
        onChange(event);
      },
    });

    const startAdornment = (
      <InputAdornment
        position="start"
        style={{ marginRight: "2px", marginLeft: "-8px" }}
      >
        <Select
          MenuProps={{
            style: {
              maxHeight: "300px",
              width: "360px",
              marginTop: "10px",
              marginLeft: "-34px",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          sx={{
            width: "max-content",
            fieldset: { display: "none" },
            '&.Mui-focused:has(div[aria-expanded="false"])': {
              fieldset: { display: "block" },
            },
            ".MuiSelect-select": {
              padding: "8px",
              paddingRight: "24px !important",
            },
            svg: { right: 0 },
          }}
          value={country.iso2}
          onChange={(e) => setCountry(e.target.value)}
          renderValue={(value) => (
            <FlagImage iso2={value} style={{ display: "flex" }} />
          )}
        >
          {defaultCountries.map((c) => {
            const country = parseCountry(c);
            return (
              <MenuItem key={country.iso2} value={country.iso2}>
                <FlagImage iso2={country.iso2} style={{ marginRight: "8px" }} />
                <Typography marginRight="8px">{country.name}</Typography>
                <Typography color="text.secondary">
                  +{country.dialCode}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </InputAdornment>
    );

    return (
      <TextField
        variant="outlined"
        label="Phone number"
        placeholder="Phone number"
        value={inputValue}
        onChange={handlePhoneValueChange}
        type="tel"
        inputRef={ref}
        InputProps={{
          ...parentInputProps,
          startAdornment,
        }}
        {...restProps}
      />
    );
  }
);
