import React, { useState, forwardRef, useEffect } from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import format from "date-fns/format";

export const CustomDatePicker = forwardRef(
  (
    {
      onDateSelected,
      legendLabel = "Filter Date",
      availablePickerTypes = ["date", "date-time"],
      clearSearch = false,
      clearSelectedItem,
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = useState(null);
    const [pickerType, setPickerType] = useState("date"); // Default to 'date'
    const [key, setKey] = useState(0);

    let PickerComponent = DatePicker; // Default to DatePicker

    const pickerOptions = [
      { value: "date", label: "Date" },
      { value: "date-time", label: "Date & Time" },
      { value: "year", label: "Year" },
      { value: "month-year", label: "Month & Year" },
    ];

    switch (pickerType) {
      case "date":
        PickerComponent = DatePicker;
        break;
      case "date-time":
        PickerComponent = DateTimePicker;
        break;
      case "year":
        PickerComponent = (props) => <DatePicker views={["year"]} {...props} />;
        break;
      case "month-year":
        PickerComponent = (props) => (
          <DatePicker views={["year", "month"]} {...props} />
        );
        break;
      default:
        PickerComponent = DatePicker; // Default case
    }
    const clearDate = () => {
      if (clearSearch) {
        setTimeout(() => {
          setValue(null);
          setKey((prevKey) => prevKey + 1);
        }, 3000);
      }
    };

    useEffect(() => {
      if (clearSelectedItem) {
        setValue(null);
      }
    }, [clearSelectedItem]);

    const handlePickerTypeChange = (event) => {
      setValue(null);
      setPickerType(event.target.value);
    };

    const handleDateChange = (newValue) => {
      setValue(newValue);
      if (onDateSelected && newValue != null) {
        // Here you can format the date based on the picker type
        let formattedDate;
        switch (pickerType) {
          case "year":
            formattedDate = format(newValue, "yyyy");
            break;
          case "month-year":
            formattedDate = format(newValue, "yyyy-MM");
            break;
          case "date":
            formattedDate = format(newValue, "yyyy-MM-dd");
            break;
          case "date-time":
            formattedDate = newValue.toISOString().substring(0, 16);
            break;
          default:
            formattedDate = format(newValue, "Pp");
            break;
        }
        onDateSelected(formattedDate, legendLabel);
        clearDate();
      }
    };

    return (
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">{legendLabel}</FormLabel>
          <RadioGroup row value={pickerType} onChange={handlePickerTypeChange}>
            {pickerOptions.map((option) => {
              // Check if the option.value is included in the availablePickerTypes array
              if (availablePickerTypes.includes(option.value)) {
                return (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                      <Radio
                        sx={{
                          color: colors.greenAccent[200],
                          "&.Mui-checked": {
                            color: colors.greenAccent[400],
                          },
                        }}
                      />
                    }
                    label={option.label}
                  />
                );
              }
              return null; // Do not render the option if it's not included
            })}
          </RadioGroup>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <PickerComponent
            key={key}
            label="Select"
            value={value}
            onChange={handleDateChange}
            slotProps={{
              actionBar: { actions: ["clear", "accept"] },
              textField: {
                required: true,
                readOnly: true,
              },
              popper: {
                sx: {
                  "& .MuiDialogActions-root .MuiButton-text": {
                    color: "white",
                    backgroundColor: colors.greenAccent[700],
                  },
                },
              },
            }}
            renderInput={(params) => <TextField {...params} ref={ref} />} // Forward the ref here
          />
        </LocalizationProvider>
      </div>
    );
  }
);
