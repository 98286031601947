import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import SearchComponent from './SearchComponent';
import { Box } from '@mui/material';

const CascadingSearch = ({externalControl, onItemSelected}) => {
  const { control } = useForm();
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [subCategoryFilter, setSubCategoryFilter] = useState(null);
  
  return (
    <Box width="100%">
      <SearchComponent
        control={control}
        name="category"
        component="Category"
        onItemSelected={(item) => setCategoryFilter({ categoryId: item.id })}
        rules={{ required: "Category Name is required" }}
      />
      {categoryFilter && (
      <SearchComponent
        control={control}
        name="subcategory"
        component="SubCategory"
        filter={categoryFilter}
        onItemSelected={(item) => setSubCategoryFilter({ subCategoryId: item.id })}
        rules={{ required: "Sub Category Name is required" }}
      />
      )}
      {subCategoryFilter && (
      <SearchComponent
        control={externalControl}
        name="productsubcategoryId"
        component="Product"
        filter={subCategoryFilter}
        onItemSelected={onItemSelected}
        rules={{ required: "Product Sub Category Name is required" }}
      />
      )}
    </Box>
  );
};

export default CascadingSearch;