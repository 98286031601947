import React, { useState, useEffect } from "react";
import { Container, Box, IconButton, Typography } from "@mui/material";
import AddPatient from "../../components/patients/addPatient";
import { useAuth } from "../../AuthProvider";
import { useTimedMessage } from "../../components/forms/utilities";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const SignUp = () => {
  const { isAuthenticated, userDetails } = useAuth();
  const [responseMessage, setResponseMessage] = useTimedMessage();
  const [isSuccess, setIsSuccess] = useState(true);
  const [newPatientEmail, setNewPatientEmail] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (newPatientEmail) {
      toast.success("Added new patient.");
      navigate("/patientprofile", { state: { initialEmail: newPatientEmail } });
    }
  }, [newPatientEmail, navigate]);

  useEffect(() => {
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }
  }, [responseMessage, isSuccess]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header
            title="Omnykare Signup"
            subtitle="Signup as a caretaker or patient."
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="5px"
        >
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
        </Box>
        <Container
          maxWidth="md"
          sx={{
            backgroundColor: colors.primary[400],
            border: `1px solid ${colors.greenAccent[400]}`,
            padding: "15px",
          }}
        >
          {!isAuthenticated && !userDetails ? (
            <AddPatient
              setResponseMessage={setResponseMessage}
              setIsSuccess={setIsSuccess}
              setNewPatientEmail={setNewPatientEmail}
              isSignUp={true}
            />
          ) : (
            <>
              <Typography variant="h3" gutterBottom>
                Hello, {userDetails?.firstName} {userDetails?.lastName}.
              </Typography>
              <Typography variant="h4">
                You are already signed into OmnyManage.
              </Typography>
            </>
          )}
        </Container>
      </Box>
    </Box>
  );
};
export default SignUp;
