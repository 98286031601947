import React from "react";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useFetchPatientReport } from "../../components/patients/useFetchPatientInfo";
import {
  Container,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import PatientReportPage from "../../components/patients/reportView";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const PatientReport = ({}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();

  const initialReportUid = location.state?.initialReportUid;
  const handleBack = () => {
    navigate(-1);
  };
  const { data, isLoading, isError, error, refetch } =
    useFetchPatientReport(initialReportUid);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Report details" subtitle="Omnyk patient's report." />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
          }}
        >
          {data && <PatientReportPage data={data} />}
        </Container>
      </Box>
    </Box>
  );
};

export default PatientReport;
