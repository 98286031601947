import React, { useState, useEffect } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { TablePaginationActions } from "./customPaginationActions";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { setLocalStorageItem, getLocalStorageItem } from "../auth/storage";

const HeaderWithIconRoot = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(0.5),
  },
}));

export function HeaderWithIcon(props) {
  const { icon, ...params } = props;

  return (
    <HeaderWithIconRoot>
      <span>{params.headerName ?? params.groupId}</span> {icon}
    </HeaderWithIconRoot>
  );
}

const CustomNoRowsOverlay = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: 2, // spacing between icon and text
      }}
    >
      <InfoIcon sx={{ fontSize: 40, color: colors.pastelColor.red }} />
      <Typography variant="subtitle1">OmnyManage data not available</Typography>
    </Box>
  );
};

export const DgWrapper = ({ children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      m="8px 0 0 0"
      width="100%"
      sx={{
        "& .MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-withBorderColor": {
          borderBottomColor: `${colors.pastelColor.blue} !important`,
        },
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .device-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-filler": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      {children}
    </Box>
  );
};

export const getSlotProps = (theme) => {
  const colors = tokens(theme.palette.mode);
  const primaryMainColor = theme.palette.background.default;
  return {
    toolbar: { printOptions: { disableToolbarButton: true } },
    pagination: {
      ActionsComponent: TablePaginationActions,
    },
    columnMenu: {
      sx: {},
    },
    basePopper: {
      sx: {
        "& .MuiPaper-root": { backgroundColor: primaryMainColor },
        "& .MuiButton-root": {
          backgroundColor: colors.greenAccent[600],
          color: colors.grey[100],
          padding: "10px 20px",
        },
        "& .MuiInputBase-input:focus": {},
        "& .MuiInputLabel-formControl.Mui-focused": {
          color: colors.greenAccent[400],
        },
        "& .MuiSwitch-switchBase": {
          color: "primary", // This will be the color when the switch is off
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: colors.other.teal, // This will be the color when the switch is on
        },
      },
    },
  };
};

export const AvatarCell = ({ row, animation, name }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let borderColor = "transparent";
  switch (row.status) {
    case "Authorised":
      borderColor = colors.greenAccent[600];
      break;
    case "Pending":
      borderColor = colors.pastelColor.orange;
      break;
    case "DND":
      borderColor = colors.pastelColor.pink;
      break;
    default:
      borderColor = colors.pastelColor.red;
  }

  return (
    <Box
      className={animation ? "avatar-ring" : ""}
      sx={{
        "--ring-color": borderColor, // Using a CSS variable to dynamically set the ring color
      }}
    >
      <Avatar
        alt={name}
        src={row.avatarUrl}
        sx={{
          bgcolor: borderColor, // Set the background color based on status
          ".MuiAvatar-img": {
            // Ensuring the image fits well within the animated ring
            width: "100%",
            height: "100%",
          },
        }}
      >
        {row.avatarUrl ? "" : name?.charAt(0) || ""}
      </Avatar>
    </Box>
  );
};

const getTwoDigits = (value, first) => {
  if (first) {
    // Return the first two characters
    return value.substring(0, 2);
  } else {
    // Return the last two characters
    return value.substring(value.length - 2);
  }
};

export const HoverChip = ({ value, field, handleChipClick, first = false }) => {
  const handleClick = (event) => {
    event.stopPropagation();
    handleChipClick(value, field);
  };
  const [hover, setHover] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Tooltip title={value} placement="top">
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
        style={{
          cursor: "pointer",
          display: "inline-block",
          marginRight: "8px",
        }}
      >
        <Chip
          label={getTwoDigits(value, first)}
          size="small"
          style={{
            backgroundColor: hover
              ? value === "Admin" || value === "HospitalAdmin"
                ? colors.pastelColor.red
                : colors.greenAccent[700]
              : "transparent",
            color: hover ? colors.grey[100] : colors.grey[100],
            border: hover
              ? "none"
              : value === "Admin" || value === "HospitalAdmin"
              ? `2px solid ${colors.pastelColor.red}`
              : `2px solid ${colors.greenAccent[400]}`,
          }}
        />
      </div>
    </Tooltip>
  );
};

export const HighlightOnHoverClickCell = ({ value, customCN, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      className={customCN}
      style={{
        cursor: "pointer",
        color: isHovered ? colors.greenAccent[400] : "inherit",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(e) => {
        e.stopPropagation(); // Stop the event from propagating to the DataGrid's handleCellClick
        onClick();
      }}
    >
      {value || ""}
    </div>
  );
};

export const HighlightOnHoverCell = ({ value, customCN }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      className={customCN}
      style={{
        cursor: "pointer",
        color: isHovered ? colors.greenAccent[400] : "inherit",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {value || ""}
    </div>
  );
};

const CustomDataGrid = ({
  rows,
  columns,
  isLoading,
  selectionModel,
  setSelectionModel,
  paginationModel,
  onPaginationModelChange,
  onPageChange,
  rowCount,
  onFilterModelChange,
  onSortModelChange,
  filterModel,
  sortModel,
  handleCellClick,
  initialVisibilityModel,
  initialSortModel,
  headers,
  columnGroupingModel,
}) => {
  const theme = useTheme();
  const slotProps = getSlotProps(theme);
  const storageFileCW = `${headers.storagePrefix}CW`;
  const storageFileCVM = `${headers.storagePrefix}CVM`;
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialVisibilityModel
  );
  const [columnWidths, setColumnWidths] = useState(
    columns.reduce((acc, column) => {
      acc[column.field] = column.width || 100; // Default width if not specified
      return acc;
    }, {})
  );

  useEffect(() => {
    const savedColumnWidths = getLocalStorageItem(storageFileCW);
    if (savedColumnWidths) {
      setColumnWidths(savedColumnWidths);
    }
  });

  useEffect(() => {
    const savedColumnVisibility = getLocalStorageItem(storageFileCVM);
    if (savedColumnVisibility) {
      setColumnVisibilityModel(savedColumnVisibility);
    } else {
      setColumnVisibilityModel(initialVisibilityModel);
    }
  }, [initialVisibilityModel]);

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
    setLocalStorageItem(storageFileCVM, newModel);
  };

  const handleColumnWidthChange = (params) => {
    const { field } = params.colDef; // Extract the field identifier of the column
    const { width } = params; // Extract the new width

    setColumnWidths((prevWidths) => {
      const newWidths = { ...prevWidths, [field]: width };
      setLocalStorageItem(storageFileCW, newWidths);
      return newWidths;
    });
  };

  const adjustedColumns = columns.map((column) => ({
    ...column,
    width: columnWidths[column.field] || column.width || 100,
  }));

  return (
    <DgWrapper>
      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        loading={isLoading}
        rows={rows}
        columns={adjustedColumns}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelection) =>
          setSelectionModel(newSelection)
        }
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectionModel(newRowSelectionModel);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: initialVisibilityModel,
          },
          sorting: {
            sortModel: initialSortModel,
          },
        }}
        rowSelectionModel={selectionModel}
        onPageChange={(params) => onPageChange(params.page + 1)}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        sortModel={sortModel}
        columnGroupingModel={columnGroupingModel}
        onSortModelChange={onSortModelChange}
        rowCount={rowCount}
        checkboxSelection
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationMode="server"
        filterMode="server"
        pagination
        filterDebounceMs={250}
        onCellClick={handleCellClick}
        onColumnWidthChange={handleColumnWidthChange}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: GridToolbar,
        }}
        slotProps={slotProps}
      />
    </DgWrapper>
  );
};

export default CustomDataGrid;
