import React, { useState, forwardRef, useEffect } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  useTheme,
  TextField,
  CircularProgress,
} from "@mui/material";
import SensorsIcon from "@mui/icons-material/SensorsOutlined";
import { useFetchAutoComplete } from "../common/useFetchListInfo";
import { toast } from "react-toastify";

const DeviceSearch = forwardRef(
  ({ onDeviceSelected, clearSearch = false }, ref) => {
    const theme = useTheme();
    const primaryMainColor = theme.palette.background.default;
    const [searchTerm, setSearchTerm] = useState("");
    const {
      data: suggestedProducts,
      isLoading,
      isError,
      error,
    } = useFetchAutoComplete({
      component: "Device",
      searchTerm: searchTerm,
    });

    // Handle error state
    useEffect(() => {
      if (isError) {
        toast.error(error.message);
      }
    }, [isError, error]);

    const formatMacAddress = (value) => {
      value = value.replace(/[^0-9A-F:]/gi, ""); // Allow hex characters and colons
      const parts = value
        .split(":")
        .join("")
        .match(/.{1,2}/g);
      return parts ? parts.join(":") : "";
    };

    const handleSearchChange = (event, newValue) => {
      let value = newValue.toUpperCase();
      value = formatMacAddress(value);
      setSearchTerm(value);
    };

    const handleOptionSelected = (event, item) => {
      if (item) {
        onDeviceSelected(item);
        if (clearSearch) {
          setTimeout(() => {
            setSearchTerm("");
          }, 3000);
        }
      }
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Autocomplete
          freeSolo
          inputValue={searchTerm}
          onInputChange={handleSearchChange}
          onChange={handleOptionSelected}
          options={suggestedProducts || []}
          getOptionLabel={(option) => {
            if (!searchTerm) {
              return "";
            }
            return option.deviceId || "";
          }}
          loading={isLoading}
          ListboxProps={{
            sx: {
              bgcolor: primaryMainColor,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Device ID"
              placeholder="Select a device..."
              variant="outlined"
              fullWidth
              required
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SensorsIcon sx={{ color: "lightcoral" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              // Forward the ref to the TextField
              ref={ref}
            />
          )}
        />
      </Box>
    );
  }
);

export default DeviceSearch;
