import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const ProtectedRoute = ({ element, rolesAllowed }) => {
  const { isAuthenticated, userDetails } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      if (location.pathname !== "/adminlogin") {
        navigate("/adminlogin");
      }
    } else {
      // Ensure userDetails is not null and roles are defined
      if (userDetails && userDetails.roles) {
        if (
          userDetails.roles.length === 1 &&
          userDetails.roles.includes("Patient") &&
          location.pathname === "/adminDash"
        ) {
          navigate("/my-dashboard", { replace: true });
        } else if (
          rolesAllowed &&
          !rolesAllowed.some((role) => userDetails.roles.includes(role))
        ) {
          if (location.pathname === "/adminDash") {
            navigate("/download-app", {
              replace: true,
              state: { userDetails: userDetails },
            });
          } else {
            navigate("/notauthorized", {
              replace: true,
              state: { userDetails: userDetails },
            });
          }
        }
      }
    }
  }, [isAuthenticated, userDetails, rolesAllowed, navigate, location.pathname]);

  if (
    !isAuthenticated ||
    !userDetails || // Check if userDetails is null
    (rolesAllowed && userDetails.roles &&
      !rolesAllowed.some((role) => userDetails.roles.includes(role)))
  ) {
    // Render nothing if not authorized (the useEffect will handle navigation)
    return null;
  }

  // Return the element if authenticated and authorized
  return <>{element}</>;
};

export default ProtectedRoute;