import {
  Box,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useAuth } from "../../AuthProvider";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccountsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import NotificationsComponent, {
  useFetchNotifications,
  displayNotification,
} from "./notifications";
import SdStorageIcon from "@mui/icons-material/SdStorageOutlined";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import { useLocation } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartOutlined";

const Topbar = () => {
  const { isAuthenticated, userDetails, logoutUser } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorSl, setAnchorSl] = React.useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationCount, setUnReadNotificationsCount] =
    React.useState(0);
  const navigate = useNavigate();
  const isPatient =
    userDetails && userDetails.roles && userDetails.roles.includes("Patient");
  const [notificationsMenuAnchorEl, setNotificationsMenuAnchorEl] =
    useState(null);
  const iconColor =
    unreadNotificationCount > 0 ? colors.pastelColor.red : "inherit";

  const handleNotificationsMenuOpen = (event) => {
    setNotificationsMenuAnchorEl(event.currentTarget);
  };

  const { data, error, isLoading, refetch } =
    useFetchNotifications(isAuthenticated);

  useEffect(() => {
    if (Array.isArray(data)) {
      setUnReadNotificationsCount(data.length);
      setNotifications(data);
      data.forEach((notification) => {
        if (notification.type === "RPM" || notification.type === "RPMAUTH") {
          displayNotification(
            notification,
            colors,
            notifications,
            setNotifications,
            refetch,
            navigate
          );
        }
      });
    } else {
      setUnReadNotificationsCount(0);
      setNotifications([]);
    }
  }, [data]);

  useEffect(() => {
    if (
      location.pathname === "/my-dashboard" ||
      location.pathname === "/adminDash"
    ) {
      refetch();
    }
  }, [location]);

  const handleNotificationsMenuClose = () => {
    setNotificationsMenuAnchorEl(null);
  };

  useEffect(() => {}, [userDetails]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsMenu = (event) => {
    setAnchorSl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSlClose = () => {
    setAnchorSl(false);
  };

  const handleLogout = () => {
    logoutUser();
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setAnchorEl(null);
  };

  function handleStorageClear() {
    // Loop through all keys in localStorage
    Object.keys(localStorage).forEach((key) => {
      // Check if the key ends with 'ListFilters'
      if (
        key.endsWith("ListFilters") ||
        key.endsWith("ListPm") ||
        key.endsWith("ListCVM") ||
        key.endsWith("ListSM") ||
        key.endsWith("ListCW") ||
        key.endsWith("Info")
      ) {
        // Remove the item from localStorage
        localStorage.removeItem(key);
      }
    });
  }

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>
      {/* Icons  */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {isAuthenticated && userDetails ? (
          <>
            <Badge badgeContent={unreadNotificationCount} color="error">
              <IconButton
                style={{ color: iconColor }}
                onClick={handleNotificationsMenuOpen}
                title={
                  unreadNotificationCount > 0
                    ? "You have unread notifications"
                    : "No unread notifications"
                }
              >
                <NotificationsOutlinedIcon />
              </IconButton>
            </Badge>
            <Menu
              id="notifications-menu"
              anchorEl={notificationsMenuAnchorEl}
              open={Boolean(notificationsMenuAnchorEl)}
              onClose={handleNotificationsMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              slotProps={{
                paper: {
                  sx: {
                    backgroundColor: theme.palette.primary[400],
                  },
                },
              }}
            >
              <NotificationsComponent
                onClose={handleNotificationsMenuClose}
                notifications={notifications}
                setNotifications={setNotifications}
                refetchCount={refetch}
              />
            </Menu>
            <IconButton
              onClick={handleSettingsMenu}
              edge="end"
              aria-label="settings of current user"
              aria-controls="menu-setbar"
              aria-haspopup="true"
              color="inherit"
              title="Settings"
            >
              <SettingsOutlinedIcon />
            </IconButton>
            <Menu
              id="menu-setbar"
              anchorEl={anchorSl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorSl)}
              onClose={handleSlClose}
              slotProps={{
                paper: {
                  sx: {
                    backgroundColor: colors.primary[400],
                  },
                },
              }}
            >
              <MenuItem onClick={() => handleStorageClear()}>
                <ListItemIcon>
                  <SdStorageIcon sx={{ color: colors.redAccent[400] }} />
                </ListItemIcon>
                <ListItemText primary="Clear storage" />
              </MenuItem>
            </Menu>
            <IconButton
              onClick={handleMenu}
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              title={`${userDetails.firstName} ${userDetails.lastName}`}
            >
              <PersonOutlinedIcon fontSize="default" />{" "}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              slotProps={{
                paper: {
                  sx: {
                    backgroundColor: colors.primary[400],
                  },
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("/myprofile")}>
                <ListItemIcon>
                  <ManageAccountsIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="My Profile" />
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("/buy-omnykare")}>
                <ListItemIcon>
                  <ShoppingCartIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Buy OmnyKare" />
              </MenuItem>
              {isPatient && (
                <MenuItem onClick={() => handleMenuItemClick("/my-dashboard")}>
                  <ListItemIcon>
                    <DashboardIcon style={{ color: colors.pastelColor.blue }} />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </MenuItem>
              )}
              <MenuItem
                key="downloads"
                onClick={() => handleMenuItemClick("/download-app")}
              >
                <ListItemIcon>
                  <DownloadForOfflineIcon
                    style={{ color: colors.pastelColor.blue }}
                  />
                </ListItemIcon>
                <ListItemText primary="App Download" />
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon style={{ color: "lightcoral" }} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </>
        ) : (
          <MenuItem onClick={() => handleMenuItemClick("/adminLogin")}>
            <ListItemIcon>
              <LoginIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </MenuItem>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
