/**
 * Save a key-value pair to localStorage.
 * @param {string} key 
 * @param {string} value 
 */
export const setLocalStorageItem = (key, value) => {
    // Check if the value is a string, if not, stringify it.
    const valueToStore = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, valueToStore);
};

/**
 * Retrieve a value from localStorage by key.
 * @param {string} key 
 * @returns {any}
 */
export const getLocalStorageItem = (key) => {
    const item = localStorage.getItem(key);
    try {
        // Attempt to parse the item as JSON. If it's not JSON, an error will be thrown.
        return JSON.parse(item);
    } catch (error) {
        // If an error is thrown, it means the item isn't JSON, so just return the plain string value.
        return item;
    }
};

/**
 * Remove an item from localStorage by key.
 * @param {string} key 
 */
export const removeLocalStorageItem = (key) => {
    localStorage.removeItem(key);
};

/**
 * Clear all data from localStorage.
 */
export const clearLocalStorage = () => {
    localStorage.clear();
};

/**
 * Save credentials to localStorage.
 * @param {Object} loginData - Contains accessToken, refreshToken, etc.
 */
export const saveCredentials = (loginData) => {
    setLocalStorageItem('accessToken', loginData.accessToken);
    setLocalStorageItem('refreshToken', loginData.refreshToken);
    setLocalStorageItem('username', loginData.username);
    setLocalStorageItem('createdTime', loginData.createdTime);
    setLocalStorageItem('expiresIn', loginData.expiresIn);
    setLocalStorageItem('role', loginData.role);
};

/**
 * Clear all authentication-related information from localStorage.
 */
export const clearCredentials = () => {
    removeLocalStorageItem('accessToken');
    removeLocalStorageItem('refreshToken');
    removeLocalStorageItem('username');
    removeLocalStorageItem('createdTime');
    removeLocalStorageItem('expiresIn');
    removeLocalStorageItem('role');
};
