import React, { useState } from "react";
import { Grid, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";

export const SearchTermDisplay = ({ filters, onClearFilter }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Check if there are any filters to display
  if (!filters || filters.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={1} alignItems="center">
      {filters.map((filter, index) => {
        // Determine the label to display based on the existence of valueSecondary
        const label = filter.valueSecondary
          ? `${filter.label}: ${filter.valueSecondary}`
          : `${filter.label}: ${filter.value}`;

        return (
          // Only display chips for filters with a value or valueSecondary
          (filter.value || filter.valueSecondary) && (
            <Grid item key={index}>
              <Chip
                label={label}
                onDelete={() => onClearFilter(filter.type)}
                sx={{
                  bgcolor: colors.primary[400],
                  border: `2px solid ${colors.greenAccent[400]}`,
                  color: colors.grey[100],
                  fontSize: "0.8rem",
                }}
              />
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export const useFilters = (initialFilters) => {
  const [filters, setFilters] = useState(initialFilters);

  const updateFilter = (updatedFilter) => {
    setFilters((currentFilters) =>
      currentFilters.map((filter) =>
        filter.type === updatedFilter.type
          ? { ...filter, ...updatedFilter }
          : filter
      )
    );
  };

  const updateFilters = (updatedFilters) => {
    setFilters((currentFilters) =>
      currentFilters.map((filter) => {
        const foundUpdate = updatedFilters.find(
          (update) => update.type === filter.type
        );
        return foundUpdate ? { ...filter, ...foundUpdate } : filter;
      })
    );
  };

  const clearFilter = (type) => {
    setFilters((currentFilters) =>
      currentFilters.map((filter) =>
        filter.type === type
          ? { ...filter, value: "", valueSecondary: "" }
          : filter
      )
    );
  };

  const clearAllFilters = () => {
    setFilters((currentFilters) =>
      currentFilters.map((filter) => ({
        ...filter,
        value: "",
        valueSecondary: "",
      }))
    );
  };

  const getFilterValue = (type) => {
    const filter = filters.find((filter) => filter.type === type);
    return filter
      ? { value: filter.value, valueSecondary: filter.valueSecondary }
      : { value: "", valueSecondary: "" };
  };

  return {
    filters,
    updateFilter,
    updateFilters,
    clearFilter,
    clearAllFilters,
    getFilterValue,
  };
};
