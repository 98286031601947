import React, { useState, useEffect } from "react";
import { Box, Container, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import {
  useFetchById,
  useFetchByDependentId,
} from "../../components/common/useFetchComponentInfo";
import ActionToolbar from "../../components/common/ActionToolbar";
import EditItem from "../../components/product/editItem";
import DeleteItem from "../../components/product/deleteItem";
import LinkItemForm from "../../components/product/linkItem";
import AddPermissionForm from "../../components/product/addRolePermission";
import LinkSkuForm from "../../components/product/linkSku";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import Header from "../../components/Header";
import ItemSearch from "../../components/search/itemSearch";
import ItemView from "./ItemView";
import SkuView from "./SkuView";
import { useAuth } from "../../AuthProvider";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../components/auth/storage";
import { useQueryClient } from "@tanstack/react-query";

const ItemDetails = ({ component }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { isAuthenticated, userDetails } = useAuth();
  const queryClient = useQueryClient();
  const [initialId, setInitialId] = useState(location.state?.initialId);
  const [initialItem, setInitialItem] = useState(
    location.state?.selectedItem || ""
  );
  const [selectedItem, setSelectedItem] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [linkMode, setLinkMode] = useState(false);
  const [permissionMode, setPermissionMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const initialProductUid = location.state?.initialProductUid;
  const [selectedItemUid, setSelectedItemUid] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const isAdmin = userDetails.roles.some((role) => role === "Admin");
  const isSearchBarEnabled = isAdmin;
  const storageFile = `${component}Info`;
  const navigate = useNavigate();
  const [subComponent, setSubComponent] = useState("");
  const [status, setStatus] = useState({
    add: { disabled: false, tooltip: `Add new ${component}` },
    edit: { disabled: false, tooltip: `Edit selected ${component}` },
    delete: { disabled: false, tooltip: `Delete selected ${component}` },
    link: {
      disabled: component !== "ProductCore" && component !== "SubCategory",
      tooltip: `Link ${component}`,
    },
  });
  const { data, isLoading, isError, error, refetch } = useFetchById(
    component,
    selectedItemId,
    initialItem
  );

  const {
    data: dependentData,
    isLoading: isDependentLoading,
    isError: isDependentError,
    error: Dependenterror,
    refetch: refetchDependent,
  } = useFetchByDependentId(component, subComponent, selectedItemId);
  const handleBack = () => {
    navigate(-1);
  };

  const updateSubComponent = () => {
    if (component === "Category") {
      setSubComponent("SubCategory");
    } else if (component === "SubCategory" || component === "ProductCore") {
      setSubComponent("Product");
    } else if (component === "Product") {
      setSubComponent("SKU");
    }
  };

  useEffect(() => {
    const newState = {
      add: { ...status.add, tooltip: `Add new ${component}` },
      delete: {
        ...status.delete,
        disabled: dependentData?.length > 0,
        tooltip: `Delete selected ${component}`,
      },
    };

    // Only add 'link' if component is 'ProductCore' or 'SubCategory'
    if (
      component === "ProductCore" ||
      component === "SubCategory" ||
      component === "Product"
    ) {
      const linkName =
        component === "Product"
          ? "SKU"
          : "ProductCore"
          ? "SubCategory"
          : "ProductCore";
      newState.link = {
        disabled: false, // link should not be disabled if it's shown
        tooltip: `Link ${linkName}`,
      };
    }

    if (component === "SubCategory") {
      newState.assignRole = {
        disabled: false,
        tooltip: "Assign UserRole permission",
      };
    }

    if (component !== "Product") {
      newState.edit = {
        disabled: false,
        tooltip: `Edit selected ${component}`,
      };
    }

    setStatus(newState);
  }, [component, dependentData]);

  useEffect(() => {
    if (!initialId && !initialProductUid && !initialItem) {
      setSelectedItemId(getLocalStorageItem(storageFile));
    }
  }, []);

  const handleAdd = () => {
    navigate(`/add-${component.toLocaleLowerCase()}`);
    setEditMode(false);
  };

  const handleEdit = () => {
    if (selectedItem && selectedItem.id) {
      setSelectedItemId(selectedItem.id);
    }
    setEditMode(true);
  };

  const handleLink = () => {
    setLinkMode(true);
  };

  const handleAssignRole = () => {
    setPermissionMode(true);
  };
  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleProductSelected = (item) => {
    setSelectedItemId(item.id);
  };

  const handleItemDeleted = () => {
    queryClient.removeQueries({
      queryKey: [`${component}Details`, selectedItem.id],
    });
    queryClient.removeQueries({
      queryKey: [`${component}List`],
    });
    removeLocalStorageItem(storageFile);
    setSelectedItemId(null);
    setSelectedItemUid(null);
    setSelectedItem("");
  };

  useEffect(() => {
    if (data) {
      updateSubComponent();
      setSelectedItem(data);
    }
  }, [data]);

  useEffect(() => {
    if (selectedItem) {
      setLocalStorageItem(storageFile, selectedItem.id);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (initialId) {
      setSelectedItemId(initialId);
      setInitialId(null);
    }
  }, [initialId]);

  useEffect(() => {
    if (initialItem) {
      setSelectedItem(initialItem);
    }
  }, [initialItem]);

  useEffect(() => {
    if (component) {
      updateSubComponent();
    }
  }, [component]);

  useEffect(() => {
    if (responseMessage) {
      if (isSuccess) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage);
      }
    }
    // Additionally, handle errors from React Query
    if (isError && error) {
      // Display a more specific error message if available, or a generic message
      toast.error(error.message || "An error occurred while fetching data.");
    }
  }, [isError, error, isSuccess, responseMessage]);

  return (
    <Box>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header
            title={`${component}`}
            subtitle={`Omnyk ${component} details.`}
          />
        </Box>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
        <Container
          maxWidth="md"
          sx={{
            border: `1px solid ${colors.greenAccent[400]}`,
            backgroundColor: colors.primary[400],
            padding: "15px",
          }}
        >
          <Box my={2}>
            {!editMode && !linkMode && isSearchBarEnabled && (
              <ItemSearch
                component={component}
                onItemSelected={handleProductSelected}
              />
            )}
          </Box>
          {!editMode && selectedItem && dependentData && (
            <>
              <ActionToolbar
                status={status}
                onLink={handleLink}
                onAssignRole={handleAssignRole}
                onAdd={handleAdd}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
              {linkMode &&
                (component === "ProductCore" ||
                  component === "SubCategory") && (
                  <LinkItemForm
                    component={component}
                    selectedItem={selectedItem}
                    setLinkMode={setLinkMode}
                    refetchDependent={refetchDependent}
                  />
                )}
              {permissionMode && component === "SubCategory" && (
                <AddPermissionForm
                  component={"UserRole"}
                  selectedItem={selectedItem}
                  setPermissionMode={setPermissionMode}
                  refetchDependent={refetchDependent}
                />
              )}

              {linkMode && component === "Product" && (
                <LinkSkuForm
                  component="SKU"
                  selectedItem={selectedItem}
                  setLinkMode={setLinkMode}
                  refetch={refetchDependent}
                />
              )}
              {component === "SKU" || component === "Product" ? (
                <SkuView
                  component={component}
                  selectedItem={selectedItem}
                  colors={colors}
                  dependentData={dependentData}
                  subComponent={subComponent}
                />
              ) : (
                <ItemView
                  component={component}
                  selectedItem={selectedItem}
                  colors={colors}
                  dependentData={dependentData}
                  subComponent={subComponent}
                />
              )}
            </>
          )}
          {editMode && selectedItem && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header
                  title={`Edit ${component}`}
                  subtitle={selectedItem.name}
                />
              </Box>
              <EditItem
                component={component}
                selectedItem={selectedItem}
                setResponseMessage={setResponseMessage}
                setIsSuccess={setIsSuccess}
                setEditMode={setEditMode}
                refetch={refetch}
              />
            </>
          )}
          <DeleteItem
            component={component}
            openDeleteDialog={openDeleteDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            selectedItem={selectedItem}
            onItemDeleted={handleItemDeleted}
          />
        </Container>
      </Box>
    </Box>
  );
};

export default ItemDetails;
