import React, { useState } from "react";
import { post, API_URL, get } from "../../services/api";
import { v4 as uuidv4 } from "uuid";
import { Button, Typography, Container, Box } from "@mui/material";
import isEmail from "validator/lib/isEmail";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { InputOutlined } from "@mui/icons-material";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import ItemSearch from "../search/itemSearch";

export default function MovePatient({
  patientMode = false,
  tenantMode = false,
  patientEmail = "",
  selectedTenant = null,
  setIsSuccess,
  setResponseMessage,
  setAssignMode,
  onUpdateSuccess,
}) {
  const [localPatientEmail, setLocalPatientEmail] = useState("");
  const [localSelectedTenant, setLocalSelectedTenant] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handlePatientSelected = (patient) => {
    setLocalPatientEmail(patient.email);
  };

  const handleCancel = (e) => {
    setAssignMode(false);
  };

  const handleMovePatient = async () => {
    let useTenant;
    let usePatientEmail;

    if (patientMode) {
      useTenant = localSelectedTenant;
      usePatientEmail = patientEmail;
    } else if (tenantMode) {
      useTenant = selectedTenant;
      usePatientEmail = localPatientEmail;
    } else {
      useTenant = localSelectedTenant;
      usePatientEmail = localPatientEmail;
    }

    if (!isEmail(usePatientEmail)) {
      setResponseMessage("Invalid Email Address!");
      setIsSuccess(false);
      return;
    }

    if (!useTenant) {
      setResponseMessage("Please select a tenant.");
      setIsSuccess(false);
      return;
    }
    setIsLoading(true);
    try {
      // Directly fetch user profile here
      const userData = await get(
        `${API_URL}/v2/Patient/GetPatientProfile?email=${usePatientEmail}`
      );
      if (userData && userData.uid) {
        const requestId = uuidv4();
        const sendData = {
          requestId,
          data: {
            patientId: userData.uid,
            tenantId: useTenant.uid,
          },
        };
        const response = await post(
          `${API_URL}/v2/Patient/MovePatient`,
          sendData,
          true
        );
        if (response.statusCode === "OK") {
          setResponseMessage(response.messages[0]);
          setIsSuccess(true);
          setAssignMode(false);
          onUpdateSuccess(true);
        } else {
          setResponseMessage("Error moving patient.");
        }
      } else {
        setResponseMessage("Error fetching patient UID.");
        setIsSuccess(false);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        // If it's a 400 error and has error messages, display the first error message
        setResponseMessage(error.response.data.errors[0]);
      } else {
        // For other errors
        setResponseMessage("Error moving patient.");
      }
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" align="center">
          Move Patient
        </Typography>
        {tenantMode && (
          <Box my={2}>
            <ItemSearch
              onItemSelected={handlePatientSelected}
              component="Patient"
            />
          </Box>
        )}
        {patientMode && (
          <>
            <Box mb={2}>
              <ItemSearch onItemSelected={setLocalSelectedTenant} component="Tenant" />
            </Box>

            <Box
              mb={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" component="h2" mr={1}>
                Selected Tenant:
              </Typography>
              <Typography variant="h7" component="h2">
                {localSelectedTenant ? localSelectedTenant.name : "None"}
              </Typography>
            </Box>
          </>
        )}
        {isLoading && <CenteredProgress />}
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
            disabled={isLoading}
            onClick={handleMovePatient}
          >
            <InputOutlined sx={{ mr: "10px" }} />
            Move Patient
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            {" "}
            Cancel
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
