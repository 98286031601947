import React from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const CenteredProgress = ({ size = 40, thickness = 3.6 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box display="flex" justifyContent="center" width="100%" margin="20px">
      <CircularProgress
        style={{ color: colors.greenAccent[600] }}
        size={size}
        thickness={thickness}
      />
    </Box>
  );
};

export default CenteredProgress;
