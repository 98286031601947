import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import UpdateIcon from "@mui/icons-material/UpdateOutlined";

const SessionExpirationDialog = ({ open, onExtendSession, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        onClose();
      }, 60000); // 1 minute countdown
    }

    return () => {
      clearTimeout(timer); // Clear the timer when the dialog closes or the dependency [open] changes
    };
  }, [open, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: colors.primary[400],
        },
      }}
      aria-labelledby="bulk-update-dialog-title"
      aria-describedby="bulk-update-dialog-description"
    >
      <DialogTitle
        id="bulk-update-dialog-title"
        sx={{ color: colors.redAccent[400], typography: "h3" }}
      >
        Session Expiration, Warning!
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h5"
          sx={{
            color: colors.grey[100], // Set the font color
          }}
        >
          Your session is about to expire in 1 minute. Do you want to extend it?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <LogoutIcon sx={{ mr: "10px" }} />
          No
        </Button>
        <Button
          onClick={onExtendSession}
          sx={{
            backgroundColor: colors.greenAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          autoFocus
        >
          <UpdateIcon sx={{ mr: "10px" }} />
          Yes, extend session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpirationDialog;
