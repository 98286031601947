import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { put, API_URL } from "../../services/api";
import { Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DeviceForm from "../forms/DeviceForm";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import UpgradeIcon from "@mui/icons-material/UpgradeOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { toast } from "react-toastify";

const EditDevice = ({
  editableDevice,
  selectedDevice,
  setEditMode,
  onDeviceUpdateSuccess,
}) => {
  const methods = useForm();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editableDevice) {
      methods.reset(editableDevice);
    }
  }, [editableDevice, methods]);

  const onSubmit = async (formData) => {
    const changedValues = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== selectedDevice[key]) {
        acc[key] = formData[key]; // Only add to acc if the value has changed
      }
      return acc;
    }, {});
    if (Object.keys(changedValues).length === 0) {
      toast.info("No changes made to device data")
      return;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };
    setIsLoading(true);
    try {
      const response = await put(`${API_URL}/v2/Device/Update`, payload, true);
      if (response.statusCode === "OK") {
        const allMessages = response.messages.join("\n");
        toast.success(allMessages);
        setEditMode(false);
        methods.reset(editableDevice);
        const updateWasSuccessful = true;
        onDeviceUpdateSuccess(updateWasSuccessful);
      } else {
        const allErrors = response.errors.join("\n");
        toast.error(allErrors);
      }
    } catch (error) {
      toast.error("Failed to update device.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DeviceForm isEdit={true} editableDevice={editableDevice} />
        {isLoading && <CenteredProgress />}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          <Button
            type="submit"
            disabled={isLoading}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "8px",
            }}
          >
            <UpgradeIcon sx={{ mr: "10px" }} />
            Update
          </Button>
          <Button
            onClick={() => {
              setEditMode(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditDevice;
