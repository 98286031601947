import { useForm, FormProvider } from "react-hook-form";
import React, { useState } from "react";
import { post, API_URL } from "../../services/api";
import { Grid, Button, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DeviceForm from "../forms/DeviceForm";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/AddOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import { toast } from "react-toastify";

const AddDevice = ({ setNewDeviceId }) => {
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };

    try {
      const response = await post(`${API_URL}/v2/Device/Add`, payload, true);

      if (
        response.statusCode === "OK" &&
        response.errors &&
        response.errors.length > 0
      ) {
        const allErrors = response.errors.join("\n");
        toast.error(allErrors);
      } else if (
        response.statusCode === "OK" &&
        response.messages &&
        response.messages.length > 0
      ) {
        const allMessages = response.messages.join("\n");
        toast.success(allMessages);
        setNewDeviceId(response.data?.deviceUid);
        methods.reset();
      } else {
        toast.error("Unknown response from server.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        const allErrors = error.response.data.errors.join("\n");
        toast.error(allErrors);
      } else {
        toast.error("Error adding Device.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DeviceForm />
        {isLoading && <CenteredProgress />}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              type="submit"
              disabled={isLoading}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
            >
              <AddIcon sx={{ mr: "10px" }} />
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AddDevice;
