import React, { useEffect } from "react";
import { get, post, API_URL } from "../../services/api";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import MarkChatReadIcon from "@mui/icons-material/MarkChatReadOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettingsOutlined";
import { tokens } from "../../theme";
import { useQuery } from "@tanstack/react-query";
import { CastConnectedOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";

export const useFetchNotifications = (isAuthenticated) => {
  const url = `${API_URL}/v2/Notification/GetUnreadNotifications`;

  return useQuery({
    queryKey: ["notificationsList"],
    queryFn: async () => {
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(response.errors?.[0] || "Error fetching notifications");
      }
    },
    enabled: isAuthenticated,
  });
};

export const useFetchNotificationsCount = (isAuthenticated) => {
  const url = `${API_URL}/v2/Notification/GetUnreadNotificationsCount`;

  return useQuery({
    queryKey: ["notificationsCount"],
    queryFn: async () => {
      const response = await get(url, true);
      if (response.statusCode === "OK") {
        return response.data;
      } else {
        throw new Error(response.errors?.[0] || "Error fetching notifications");
      }
    },
    enabled: isAuthenticated,
  });
};

const markAsRead = (uid, setNotifications, notifications, refetchCount) => {
  post(`${API_URL}/v2/Notification/MarkAsRead`, [uid])
    .then(() => {
      refetchCount();
      // Update the state to reflect the notification's new status
      setNotifications(
        notifications.map((notification) =>
          notification.uid === uid
            ? { ...notification, status: "Read" }
            : notification
        )
      );
    })
    .catch((error) => {
      console.error("Error marking notification as read:", error);
    });
};

const authorizeOveride = async (uid, refetchCount) => {
  try {
    const response = await post(
      `${API_URL}/v2/RpmRequest/RequestAccepted?uid=${uid}`,
      null,
      true
    );
    if (response.statusCode === "OK") {
      refetchCount();
      toast.success(response.messages.join("\n"));
    } else if (response.statusCode === "BadRequest") {
      toast.error(response.errors.join("\n"));
    } else {
      toast.error("Error requesting email to be sent");
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      Array.isArray(error.response.data.errors) &&
      error.response.data.errors.length > 0
    ) {
      const validationErrorMessages = error.response.data.errors;
      const errorMessage = `Error authorising RPM ${uid}.\n${validationErrorMessages.join(
        "\n"
      )}`;
      toast.error(errorMessage);
    } else {
      // Handle other errors
      toast.error("An unexpected error occurred.");
    }
  }
};

const approveNotification = (
  uid,
  setNotifications,
  notifications,
  refetchCount
) => {
  authorizeOveride(uid, refetchCount);
  markAsRead(uid, setNotifications, notifications, refetchCount);
};

// Function to display a toast notification
export const displayNotification = (
  notification,
  colors,
  notifications,
  setNotifications,
  refetchCount,
  navigate
) => {
  const isRPMNotification = notification.type === "RPM";
  const isRPMAuthNotification = notification.type === "RPMAUTH";

  // Custom render function for the toast content
  const renderToastContent = ({ closeToast }) => (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <CastConnectedOutlined sx={{ color: colors.pastelColor.green }} />
      <p style={{ margin: 0, flex: 1 }}>{notification.message}</p>
      {isRPMNotification && (
        <button
          onClick={() => {
            approveNotification(
              notification.uid,
              setNotifications,
              notifications,
              refetchCount
            );
            closeToast();
          }}
          style={{
            background: colors.pastelColor.green,
            color: "white",
            border: colors.pastelColor.blue,
            borderRadius: "4px",
            padding: "5px 10px",
            cursor: "pointer",
          }}
        >
          Approve
        </button>
      )}
      {isRPMAuthNotification && (
        <button
          onClick={() => {
            navigate("/my-dashboard?tab=2");
            markAsRead(
              notification.uid,
              setNotifications,
              notifications,
              refetchCount
            );
            closeToast();
          }}
          style={{
            background: colors.pastelColor.green,
            color: "white",
            border: colors.pastelColor.blue,
            borderRadius: "4px",
            padding: "5px 10px",
            cursor: "pointer",
          }}
        >
          View RPM
        </button>
      )}
    </div>
  );

  toast(renderToastContent, {
    autoClose: false,
    position: "top-center",
    toastId: notification.uid,
  });
};

const NotificationsComponent = ({
  onClose,
  notifications,
  setNotifications,
  refetchCount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const unreadNotifications = notifications.filter(
    (notification) => notification.status !== "Read"
  );
  const readNotifications = notifications.filter(
    (notification) => notification.status === "Read"
  );
  useEffect(() => {
    refetchCount();
  }, []);

  const getTypeDetails = (type) => {
    switch (type) {
      case "RPM":
        return {
          icon: (
            <CastConnectedOutlined sx={{ colors: colors.pastelColor.pink }} />
          ),
          color: colors.redAccent[100],
        };
      case "Alert":
        return {
          icon: <WarningIcon color="error" />,
          color: colors.redAccent[100],
        };
      case "Info":
        return {
          icon: <InfoIcon color="primary" />,
          color: colors.greenAccent[100],
        };
      case "System":
        return {
          icon: <DisplaySettingsIcon color="warning" />,
          color: colors.greenAccent[100],
        };
      default:
        return { icon: <InfoIcon color="disabled" />, color: colors.grey[100] };
    }
  };

  return (
    <Box sx={{ width: "100%", minWidth: 280, bgcolor: colors.primary[400] }}>
      <List>
        {unreadNotifications.map((notification) => {
          const { icon, color } = getTypeDetails(notification.type);
          return (
            <ListItemComponent
              key={notification.uid}
              notification={notification}
              icon={icon}
              color={color}
              markAsRead={markAsRead}
              colors={colors}
              setNotifications={setNotifications}
              notifications={notifications}
              refetchCount={refetchCount}
            />
          );
        })}
      </List>

      {readNotifications.length > 0 && (
        <Accordion sx={{ bgcolor: colors.greenAccent[700] }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Read Messages</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {readNotifications.map((notification) => {
                const { icon, color } = getTypeDetails(notification.type);
                return (
                  <ListItemComponent
                    key={notification.uid}
                    notification={notification}
                    icon={icon}
                    color={color}
                    colors={colors}
                    setNotifications={setNotifications}
                    notifications={notifications}
                    refetchCount={refetchCount}
                  />
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}

      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

const ListItemComponent = ({
  notification,
  icon,
  color,
  markAsRead,
  colors,
  setNotifications,
  notifications,
  refetchCount,
}) => (
  <React.Fragment>
    <ListItem
      secondaryAction={
        notification.status !== "Read" && (
          <IconButton
            edge="end"
            aria-label="mark as read"
            onClick={() =>
              markAsRead(
                notification.uid,
                setNotifications,
                notifications,
                refetchCount
              )
            }
          >
            <MarkChatReadIcon />
          </IconButton>
        )
      }
      sx={{
        bgcolor:
          notification.status !== "Read" ? colors.primary[400] : "inherit",
      }}
    >
      <ListItemText
        primary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              color={color}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {icon}
              {"    "}
              {`${notification.type}: `}
            </Typography>
            {notification.message}
          </React.Fragment>
        }
      />
    </ListItem>
    <Divider />
  </React.Fragment>
);

export default NotificationsComponent;
