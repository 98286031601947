import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AccessibleIcon from "@mui/icons-material/Accessible";
import NotAccessibleIcon from "@mui/icons-material/NotAccessible";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SecurityIcon from "@mui/icons-material/Security";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupiedOutlined";
import AddLinkIcon from "@mui/icons-material/AddLinkOutlined";
import CastIcon from "@mui/icons-material/CastOutlined";
import QueryStatsIcon from "@mui/icons-material/QueryStatsOutlined";
import {
  DevicesOtherOutlined,
  RecentActorsOutlined,
} from "@mui/icons-material";
import { green, red, blue, teal, blueGrey } from "@mui/material/colors";

import Box from "@mui/material/Box";
import { LockReset } from "@mui/icons-material";

const ActionToolbar = ({
  status,
  onLink,
  onAdd,
  onEdit,
  onDelete,
  onAssign,
  onAssignTenant,
  onAssignPatient,
  onDeallocateTenant,
  onDeallocatePatient,
  onPasswordReset,
  onAssignRole,
  onRemoveRole,
  onLocation,
  onVitals,
  onRPM,
  onTenantPatients,
  onTenantDevices,
  onStats,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      {status.link !== undefined && (
        <Tooltip title={status.link.tooltip} arrow>
          <span>
            <IconButton
              onClick={onLink}
              disabled={status.link.disabled}
              sx={{
                color: status.link.disabled ? undefined : blue[500],
              }}
            >
              <AddLinkIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.add !== undefined && (
        <Tooltip title={status.add.tooltip} arrow>
          <span>
            <IconButton
              onClick={onAdd}
              disabled={status.add.disabled}
              sx={{
                color: status.add.disabled ? undefined : green[500],
              }}
            >
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.edit !== undefined && (
        <Tooltip title={status.edit.tooltip} arrow>
          <span>
            <IconButton
              onClick={onEdit}
              disabled={status.edit.disabled}
              sx={{
                color: status.edit.disabled ? undefined : blue[500],
              }}
            >
              <EditIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.assign !== undefined && (
        <Tooltip title={status.assign.tooltip} arrow>
          <span>
            <IconButton
              onClick={onAssign}
              disabled={status.assign.disabled}
              sx={{
                color: status.assign.disabled ? undefined : teal[300],
              }}
            >
              <AssignmentIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.assignTenant !== undefined && (
        <Tooltip title={status.assignTenant.tooltip} arrow>
          <span>
            <IconButton
              onClick={onAssignTenant}
              disabled={status.assignTenant.disabled}
              sx={{
                color: status.assignTenant.disabled ? undefined : red[400],
              }}
            >
              <LocalHospitalIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.assignPatient !== undefined && (
        <Tooltip title={status.assignPatient.tooltip} arrow>
          <span>
            <IconButton
              onClick={onAssignPatient}
              disabled={status.assignPatient.disabled}
              sx={{
                color: status.assignPatient.disabled ? undefined : teal[400],
              }}
            >
              <AccessibleIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.deallocatePatient !== undefined && (
        <Tooltip title={status.deallocatePatient.tooltip} arrow>
          <span>
            <IconButton
              onClick={onDeallocatePatient}
              disabled={status.deallocatePatient.disabled}
              sx={{
                color: status.deallocatePatient.disabled ? undefined : red[400],
              }}
            >
              <NotAccessibleIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.deallocateTenant !== undefined && (
        <Tooltip title={status.deallocateTenant.tooltip} arrow>
          <span>
            <IconButton
              onClick={onDeallocateTenant}
              disabled={status.deallocateTenant.disabled}
              sx={{
                color: status.deallocateTenant.disabled ? undefined : red[400],
              }}
            >
              <RemoveCircleIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.passwordReset !== undefined && (
        <Tooltip title={status.passwordReset.tooltip} arrow>
          <span>
            <IconButton
              onClick={onPasswordReset}
              disabled={status.passwordReset.disabled}
              sx={{
                color: status.passwordReset.disabled ? undefined : teal[500],
              }}
            >
              <LockReset fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.assignRole !== undefined && (
        <Tooltip title={status.assignRole.tooltip} arrow>
          <span>
            <IconButton
              onClick={onAssignRole}
              disabled={status.assignRole.disabled}
              sx={{
                color: status.assignRole.disabled ? undefined : teal[500],
              }}
            >
              <SecurityIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.removeRole !== undefined && (
        <Tooltip title={status.removeRole.tooltip} arrow>
          <span>
            <IconButton
              onClick={onRemoveRole}
              disabled={status.removeRole.disabled}
              sx={{
                color: status.removeRole.disabled ? undefined : red[500],
              }}
            >
              <SecurityIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.location !== undefined && (
        <Tooltip title={status.location.tooltip} arrow>
          <span>
            <IconButton
              onClick={onLocation}
              disabled={status.location.disabled}
              sx={{
                color: status.location.disabled ? undefined : red[500],
              }}
            >
              <LocationOnIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.vitals !== undefined && (
        <Tooltip title={status.vitals.tooltip} arrow>
          <span>
            <IconButton
              onClick={onVitals}
              disabled={status.vitals.disabled}
              sx={{
                color: status.vitals.disabled ? undefined : teal[300],
              }}
            >
              <SensorOccupiedIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.rpm !== undefined && (
        <Tooltip title={status.rpm.tooltip} arrow>
          <span>
            <IconButton
              onClick={onRPM}
              disabled={status.rpm.disabled}
              sx={{
                color: status.rpm.disabled ? undefined : blueGrey[100],
              }}
            >
              <CastIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.tenantPatients !== undefined && (
        <Tooltip title={status.tenantPatients.tooltip} arrow>
          <span>
            <IconButton
              onClick={onTenantPatients}
              disabled={status.tenantPatients.disabled}
              sx={{
                color: status.tenantPatients.disabled
                  ? undefined
                  : "lightcoral",
              }}
            >
              <RecentActorsOutlined fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.tenantDevices !== undefined && (
        <Tooltip title={status.tenantDevices.tooltip} arrow>
          <span>
            <IconButton
              onClick={onTenantDevices}
              disabled={status.tenantDevices.disabled}
              sx={{
                color: status.tenantDevices.disabled ? undefined : blue[400],
              }}
            >
              <DevicesOtherOutlined fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.stats !== undefined && (
        <Tooltip title={status.stats.tooltip} arrow>
          <span>
            <IconButton
              onClick={onStats}
              disabled={status.stats.disabled}
              sx={{
                color: status.stats.disabled ? undefined : green[500],
              }}
            >
              <QueryStatsIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {status.delete !== undefined && (
        <Tooltip title={status.delete.tooltip} arrow>
          <span>
            <IconButton
              onClick={onDelete}
              disabled={status.delete.disabled}
              sx={{
                color: status.delete.disabled ? undefined : red[300],
              }}
            >
              <DeleteIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Box>
  );
};

export default ActionToolbar;
