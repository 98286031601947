import { useForm, FormProvider } from "react-hook-form";
import React, { useState } from "react";
import { post, API_URL } from "../../services/api";
import { Grid, Button, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
import InsertLinkIcon from "@mui/icons-material/InsertLinkOutlined";
import CenteredProgress from "../../scenes/global/CenteredProgress";
import CategoryForm from "../forms/CategoryForm";

const LinkSkuForm = ({ component, selectedItem, setLinkMode, refetch }) => {
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isContentSaved, setIsContentSaved] = useState(false);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    if (component === "SKU") {
      formData.productSubCategoryId = selectedItem.id;
    }
    const payload = {
      requestId: uuidv4(),
      data: formData,
    };

    try {
      const response = await post(`${API_URL}/v2/${component}/Add`, payload, true);
      if (response.statusCode === "OK") {
        toast.success(response.messages[0]);
        if (response.data) {
          refetch();
          setLinkMode(false);
        }
        methods.reset();
      } else if (response.statusCode === "BadRequest") {
        toast.error(response.errors[0]);
      } else {
        toast.error(`Error adding ${component}.`);
      }
    } catch (error) {
      if (error.response.data && error.response.data.errors.length > 0) {
        // Check for validation errors in the response
        const validationErrorMessages = error.response.data.errors;
        const errorMessage =
          `Error adding ${component} Link.` +
          ".\n" +
          validationErrorMessages.join("\n");
        toast.error(errorMessage);
      } else {
        // Handle other errors
        toast.error(`Error adding ${component} Link.`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="center" mt={2} mb={2}>
          <CategoryForm
            component={`${component}`}
            setIsContentSaved={setIsContentSaved}
          />
        </Box>

        {isLoading && <CenteredProgress />}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginRight: "8px",
              }}
              disabled={isLoading || !isContentSaved}
              type="submit"
            >
              <InsertLinkIcon sx={{ mr: "10px" }} />
              Link
            </Button>
            <Button
              onClick={() => {
                setLinkMode(false);
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LinkSkuForm;
