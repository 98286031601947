import React from "react";
import { useFetchLatestPatientReport } from "../../components/patients/useFetchPatientInfo";
import PatientReportPage from "../../components/patients/reportView";
import { Container } from "@mui/material";
import CenteredProgress from "../global/CenteredProgress";
import ErrorsRefetch from "../global/ErrorsRefetch";

export default function LatestReport() {
  const { data, isLoading, isError, error, refetch } =
    useFetchLatestPatientReport();

  return (
    <Container>
      {isLoading && <CenteredProgress />}
      {isError && (
        <ErrorsRefetch
          message="Report may not have been generated yet, please retry after sometime."
          subtitle="Retry fetching the report"
          refetch={refetch}
        />
      )}
      {data && <PatientReportPage data={data} />}
    </Container>
  );
}
