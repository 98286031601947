import React, { useRef, useEffect } from "react";
import StarterKit from "@tiptap/starter-kit";
import { Underline } from "@tiptap/extension-underline";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import {
  LinkBubbleMenuHandler,
  TableImproved,
  RichTextEditor,
  ResizableImage,
} from "mui-tiptap";

const ReadOnlyContent = ({ content }) => {
  const rteRef = useRef(null);

  useEffect(() => {
    if (rteRef.current && rteRef.current.editor && content) {
      // Directly using the editor instance to set new content
      rteRef.current.editor.commands.setContent(content);
    }
  }, [content]); // Depend on content to update dynamically

  const CustomLinkExtension = Link.extend({
    inclusive: false,
  });

  return (
    <RichTextEditor
      ref={rteRef}
      content={content}
      editable={false}
      extensions={[
        StarterKit,
        TableCell,
        TableHeader,
        TableRow,
        Underline,
        Subscript,
        Superscript,
        TaskList,
        TaskItem,
        Image,
        ResizableImage,
        TextAlign.configure({
          types: ["heading", "paragraph", "image"],
        }),
        CustomLinkExtension.configure({
          autolink: true,
          linkOnPaste: true,
          openOnClick: false,
        }),
        TableImproved.configure({
          resizable: true,
        }),
        LinkBubbleMenuHandler,
      ]}
    />
  );
};

export default ReadOnlyContent;
